import {
  ApplicationConfigurationStore,
  AssetStore,
  AuthStore,
  CommonStore,
  CurrencyStore,
  setAppStore,
  SourceEntitiesStore,
  TextComponentsStore,
  TownsStore,
  UserSettingsStore,
  UserStore,
} from "@xala/common-services";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import { History } from "history";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  Store,
} from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";

export interface IAppState {
  applicationConfiguration: ApplicationConfigurationStore.Types.IApplicationConfigurationState;
  asset: AssetStore.Types.IAssetState;
  auth: AuthStore.Types.IAuthState;
  common: CommonStore.Types.ICommonState;
  router?: RouterState;
  textComponents: TextComponentsStore.Types.ITextComponentsState;
  user: UserStore.Types.IUserState;
  currencies: CurrencyStore.Types.ICurrenciesState;
  towns: TownsStore.Types.ITownsState;
  userSettings: UserSettingsStore.Types.IUserSettingsState;
}

export type AppActions = AuthStore.Types.AuthActionsTypes;

let appStore: Store<IAppState, AppActions>;

export function dispatch(action: AnyAction) {
  if (appStore && appStore.dispatch) {
    appStore.dispatch(action);
  }
}

const epicMiddleware = createEpicMiddleware<
  AppActions,
  AppActions,
  IAppState
>();

const reducers = {
  applicationConfiguration:
    ApplicationConfigurationStore.Reducers.applicationConfigurationReducer,
  asset: AssetStore.Reducers.assetReducer,
  auth: AuthStore.Reducers.authReducer,
  common: CommonStore.Reducers.commonReducer,
  textComponents: TextComponentsStore.Reducers.textComponentsReducer,
  user: UserStore.Reducers.userReducer,
  currencies: CurrencyStore.Reducers.currenciesReducer,
  sourceEntities: SourceEntitiesStore.Reducers.sourceEntitiesReducer,
  towns: TownsStore.Reducers.townsReducer,
  userSettings: UserSettingsStore.Reducers.userSettingsReducer,
};

export const rootEpics = combineEpics(
  ...ApplicationConfigurationStore.Epics.applicationConfigurationEpics,
  ...AssetStore.Epics.assetEpics,
  ...AuthStore.Epics.authEpics,
  ...CommonStore.Epics.commonEpics,
  ...TextComponentsStore.Epics.textComponentsEpics,
  ...SourceEntitiesStore.Epics.sourceEntitiesEpics,
  ...UserStore.Epics.userEpics,
  ...CurrencyStore.Epics.currenciesEpics,
  ...TownsStore.Epics.townsEpics,
  ...UserSettingsStore.Epics.userSettingsEpics
);

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export class ReduxStoreConfigurator {
  private middlewares: Middleware[] = [epicMiddleware];

  private initialState: IAppState = {
    applicationConfiguration:
      ApplicationConfigurationStore.Reducers.initialState,
    asset: AssetStore.Reducers.initialState,
    auth: AuthStore.Reducers.initialState,
    common: CommonStore.Reducers.initialState,
    textComponents: TextComponentsStore.Reducers.initialState,
    user: UserStore.Reducers.initialState,
    currencies: CurrencyStore.Reducers.initialState,
    towns: TownsStore.Reducers.initialState,
    userSettings: UserSettingsStore.Reducers.initialState,
  };

  private history: History | undefined = undefined;

  constructor(
    history?: History,
    middlewares?: Middleware[],
    initialState?: IAppState
  ) {
    this.history = history;

    if (middlewares && middlewares.length > 0) {
      this.middlewares = this.middlewares.concat(...middlewares);
    }

    this.initialState = initialState || this.initialState;
  }

  public initStore() {
    appStore = this.configureStore();
    epicMiddleware.run(rootEpics as any);
    setAppStore(appStore);

    return appStore;
  }

  private createRootReducer(history?: History) {
    let appReducer: any = combineReducers(reducers);

    if (history) {
      appReducer = combineReducers({
        router: connectRouter(history),
        ...reducers,
      });
    }

    return (state: IAppState | undefined, action: AppActions) => {
      if (action.type === AuthStore.Consts.SIGN_OUT_SUCCESS) {
        state = undefined;
      }

      return appReducer(state, action);
    };
  }

  private createEnhancer(middlewares: Middleware[], history?: History) {
    let enhancer = composeEnhancers(applyMiddleware(...middlewares));

    if (history) {
      enhancer = composeEnhancers(
        applyMiddleware(routerMiddleware(history), ...middlewares)
      );
    }
    return enhancer;
  }

  private configureStore() {
    const enhancer = this.createEnhancer(this.middlewares, this.history);
    const rootReducer = this.createRootReducer(this.history);
    const store = createStore(rootReducer, this.initialState, enhancer);
    return store;
  }
}
