import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenDesignerContext } from "../../context";
import {
  ApplicationHeaderItemPreview as ApplicationHeaderItemPreviewDefinition,
  IApplicationHeaderItemPreviewDispatchProps,
  IApplicationHeaderItemPreviewOwnProps,
  IApplicationHeaderItemPreviewStateProps,
} from "./ApplicationHeaderItemPreview";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationHeaderItemPreviewStateProps => {
  return {};
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationHeaderItemPreviewDispatchProps => ({});

export const ApplicationHeaderItemPreview = connect<
  IApplicationHeaderItemPreviewStateProps,
  IApplicationHeaderItemPreviewDispatchProps,
  IApplicationHeaderItemPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withScreenDesignerContext(ApplicationHeaderItemPreviewDefinition)
  )
);
