import React from "react";
import { Form, Input } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";

export const ImageUrlField = ({
  notification,
  isEditMode,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();

  if (isHidden || notification?.Provider === "EMAIL") {
    return null;
  }

  return (
    <Form.Item
      name="ImageUrl"
      label={t("NOTIFICATION_IMAGE_URL_FIELD")}
      key="ImageUrl"
      initialValue={isEditMode ? notification?.ImageUrl || "" : ""}
    >
      <Input placeholder={t("NOTIFICATION_IMAGE_URL_INPUT_FIELD")} />
    </Form.Item>
  );
};
