import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `./locales/{{lng}}/{{ns}}.json?version=${Date.now()}`,
    },
    debug: false,
    defaultNS: "scmtool",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: "en",
    ns: ["cms", "scmtool"],
  });

export default i18n;
