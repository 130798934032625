import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationSectionMenuProperties as ApplicationSectionMenuPropertiesDefinition,
  IApplicationSectionMenuPropertiesDispatchProps,
  IApplicationSectionMenuPropertiesOwnProps,
  IApplicationSectionMenuPropertiesStateProps,
} from "./ApplicationSectionMenuProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationSectionMenuPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationSectionMenuPropertiesDispatchProps => ({});

export const ApplicationSectionMenuProperties = connect<
  IApplicationSectionMenuPropertiesStateProps,
  IApplicationSectionMenuPropertiesDispatchProps,
  IApplicationSectionMenuPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationSectionMenuPropertiesDefinition));
