import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { ICommonAppState } from "..";
import { RecordStatus } from "../..";
import {
  IApplicationComponentTypeModel,
  IApplicationConfigurationBrandingListModel,
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationModel,
  IApplicationsConfigurationsListModel,
  IApplicationScreenModel,
  IApplicationScreenTypeModel,
  IApplicationsScreensListModel,
  IErrorModel,
  OperationResult,
  UploadFileInfoModel,
} from "../../models";
import {
  ApplicationBrandingsService,
  ApplicationComponentTypeService,
  ApplicationConfigurationService,
  ApplicationScreenService,
  ApplicationScreenTypeService,
  AssetService,
  StorageService,
} from "../../services";
import {
  browseScreensFailure,
  browseScreensSuccess,
  deleteBrandingFailure,
  deleteBrandingSuccess,
  deleteConfigurationFailure,
  deleteConfigurationSuccess,
  deleteScreenFailure,
  deleteScreenSuccess,
  exportTranslationsFailure,
  exportTranslationsSuccess,
  getBrandingFailure,
  getBrandingSuccess,
  getConfigurationFailure,
  getConfigurationSuccess,
  getImageUploadFileInfoFailure,
  getImageUploadFileInfoSuccess,
  getScreenFailure,
  getScreenSuccess,
  importTranslationsFailure,
  importTranslationsSuccess,
  insertBrandingFailure,
  insertBrandingSuccess,
  insertScreenFailure,
  insertScreenSuccess,
  publishConfigurationFailure,
  publishConfigurationSuccess,
  rebuildCacheFailure,
  rebuildCacheSuccess,
  searchBrandingSuccess,
  searchConfigurationsFailure,
  searchConfigurationsSuccess,
  searchScreensFailure,
  searchScreensSuccess,
  selectBrandingKeysFailure,
  selectBrandingKeysSuccess,
  selectComponentsTypesFailure,
  selectComponentsTypesSuccess,
  selectScreensTypesFailure,
  selectScreensTypesSuccess,
  synchronizeEPGFailure,
  synchronizeEPGSuccess,
  updateBrandingFailure,
  updateBrandingSuccess,
  updateConfigurationFailure,
  updateConfigurationSuccess,
  updateScreenFailure,
  updateScreenSuccess,
  uploadBrandingImageFailure,
  uploadBrandingImageSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IBrowseApplicationScreenAction,
  IDeleteApplicationBrandingAction,
  IDeleteApplicationConfigurationAction,
  IDeleteApplicationScreenAction,
  IExportTranslationsAction,
  IGetApplicationBrandingAction,
  IGetApplicationConfigurationAction,
  IGetApplicationScreenAction,
  IGetImageUploadFileInfoAction,
  IImportTranslationsAction,
  IInsertApplicationBrandingAction,
  IInsertApplicationScreenAction,
  IPublishApplicationConfigurationAction,
  IRebuildCacheAction,
  ISearchApplicationBrandingAction,
  ISearchApplicationConfigurationAction,
  ISearchApplicationScreenAction,
  ISelectApplicationComponentTypeAction,
  ISelectApplicationScreenTypeAction,
  ISelectKeysApplicationBrandingAction,
  ISynchronizeEPGAction,
  IUpdateApplicationBrandingAction,
  IUpdateApplicationConfigurationAction,
  IUpdateApplicationScreenAction,
  IUploadBrandingImageAction,
} from "./types";

const applicationBrandingsService = new ApplicationBrandingsService(); // TODO: epic
const applicationConfigurationService = new ApplicationConfigurationService();
const applicationScreenService = new ApplicationScreenService();
const applicationScreenTypeService = new ApplicationScreenTypeService();
const applicationComponentTypeService = new ApplicationComponentTypeService();
const storageService: StorageService = StorageService.getInstance();
const assetService: AssetService = new AssetService();

const getConfigurationEpic = (
  action$: ActionsObservable<IGetApplicationConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_APPLICATION_CONFIGURATION),
    switchMap((action: IGetApplicationConfigurationAction) =>
      applicationConfigurationService.get(action.id).pipe(
        map((data: IApplicationConfigurationModel) => {
          return getConfigurationSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getConfigurationFailure(error)))
      )
    )
  );

const deleteConfigurationEpic = (
  action$: ActionsObservable<IDeleteApplicationConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_APPLICATION_CONFIGURATION),
    switchMap((action: IDeleteApplicationConfigurationAction) =>
      applicationConfigurationService.delete(action.payload).pipe(
        map(() => {
          return deleteConfigurationSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(deleteConfigurationFailure(error))
        )
      )
    )
  );

const updateConfigurationEpic = (
  action$: ActionsObservable<IUpdateApplicationConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_APPLICATION_CONFIGURATION),
    switchMap((action: IUpdateApplicationConfigurationAction) =>
      applicationConfigurationService.update(action.payload).pipe(
        map((data: IApplicationConfigurationModel) => {
          return updateConfigurationSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(updateConfigurationFailure(error))
        )
      )
    )
  );

const publishConfigurationEpic = (
  action$: ActionsObservable<IPublishApplicationConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.PUBLISH_APPLICATION_CONFIGURATION),
    switchMap((action: IPublishApplicationConfigurationAction) =>
      applicationConfigurationService
        .publish(action.id, action.rowVersion)
        .pipe(
          map((data: IApplicationConfigurationModel) => {
            return publishConfigurationSuccess(data);
          }),
          catchError((error: IErrorModel) =>
            of(publishConfigurationFailure(error))
          )
        )
    )
  );

const searchConfigurationsEpic = (
  action$: ActionsObservable<ISearchApplicationConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_APPLICATION_CONFIGURATION),
    switchMap((action: ISearchApplicationConfigurationAction) =>
      applicationConfigurationService.search(action.filter).pipe(
        map((data: IApplicationsConfigurationsListModel) => {
          return searchConfigurationsSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(searchConfigurationsFailure(error))
        )
      )
    )
  );

const getScreenEpic = (
  action$: ActionsObservable<IGetApplicationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_APPLICATION_SCREEN),
    switchMap((action: IGetApplicationScreenAction) =>
      applicationScreenService.get(action.id).pipe(
        map((data: IApplicationScreenModel) => {
          return getScreenSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getScreenFailure(error)))
      )
    )
  );

const searchScreensEpic = (
  action$: ActionsObservable<ISearchApplicationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_APPLICATION_SCREEN),
    switchMap((action: ISearchApplicationScreenAction) =>
      applicationScreenService.search(action.filter).pipe(
        map((data: IApplicationsScreensListModel) => {
          data.Filter = action.filter;

          return searchScreensSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchScreensFailure(error)))
      )
    )
  );

const browseScreensEpic = (
  action$: ActionsObservable<IBrowseApplicationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BROWSE_APPLICATION_SCREEN),
    switchMap((action: IBrowseApplicationScreenAction) =>
      applicationScreenService.search(action.filter).pipe(
        map((response: IApplicationsScreensListModel) => {
          return browseScreensSuccess(response);
        }),
        takeUntil(
          action$.pipe(ofType(Consts.BROWSE_APPLICATION_SCREEN_CANCEL))
        ),
        catchError((error: IErrorModel) => of(browseScreensFailure(error)))
      )
    )
  );

const insertScreenEpic = (
  action$: ActionsObservable<IInsertApplicationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_APPLICATION_SCREEN),
    switchMap((action: IInsertApplicationScreenAction) =>
      applicationScreenService.insert(action.payload).pipe(
        map((data: IApplicationScreenModel) => {
          return insertScreenSuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertScreenFailure(error)))
      )
    )
  );

const updateScreenEpic = (
  action$: ActionsObservable<IUpdateApplicationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_APPLICATION_SCREEN),
    switchMap((action: IUpdateApplicationScreenAction) =>
      applicationScreenService.update(action.payload).pipe(
        map((data: IApplicationScreenModel) => {
          return updateScreenSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateScreenFailure(error)))
      )
    )
  );

const deleteScreenEpic = (
  action$: ActionsObservable<IDeleteApplicationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_APPLICATION_SCREEN),
    switchMap((action: IDeleteApplicationScreenAction) =>
      applicationScreenService.delete(action.payload).pipe(
        map(() => {
          return deleteScreenSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteScreenFailure(error)))
      )
    )
  );

//const saveApplicationConfigurationEpic =
//  (action$: ActionsObservable<ISaveApplicationConfigurationAction>, state: StateObservable<ICommonAppState>
//  ) =>
//    action$.pipe(
//      ofType(Consts.SAVE_APPLICATION_CONFIGURATION),
//      switchMap((action: ISaveApplicationConfigurationAction) => (
//        ApiHelper.requireValidToken(action$, state, () =>
//          configurationService.saveConfiguration(action.data).pipe(
//            map((config: ConfigurationModel) => {
//              action.onSuccess && action.onSuccess();
//              return saveApplicationConfigurationSuccess(config);
//            }),
//            catchError((error: IErrorModel) => of(saveApplicationConfigurationFailure(error)))
//          )
//        )
//      )
//      ));

const selectScreensTypesEpic = (
  action$: ActionsObservable<ISelectApplicationScreenTypeAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_APPLICATION_SCREEN_TYPE),
    switchMap((action: ISelectApplicationScreenTypeAction) =>
      applicationScreenTypeService.select().pipe(
        map((data: IApplicationScreenTypeModel[]) => {
          return selectScreensTypesSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectScreensTypesFailure(error)))
      )
    )
  );

const selectComponentsTypesEpic = (
  action$: ActionsObservable<ISelectApplicationComponentTypeAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_APPLICATION_COMPONENT_TYPE),
    switchMap((action: ISelectApplicationComponentTypeAction) =>
      applicationComponentTypeService.select().pipe(
        map((data: IApplicationComponentTypeModel[]) => {
          return selectComponentsTypesSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(selectComponentsTypesFailure(error))
        )
      )
    )
  );

const getBrandingEpic = (
  action$: ActionsObservable<IGetApplicationBrandingAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_APPLICATION_BRANDING),
    switchMap((action: IGetApplicationBrandingAction) =>
      applicationBrandingsService.get(action.id).pipe(
        map((data: IApplicationConfigurationBrandingModel) => {
          return getBrandingSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getBrandingFailure(error)))
      )
    )
  );

const insertBrandingEpic = (
  action$: ActionsObservable<IInsertApplicationBrandingAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_APPLICATION_BRANDING),
    switchMap((action: IInsertApplicationBrandingAction) =>
      applicationBrandingsService.insert(action.payload).pipe(
        map((data: IApplicationConfigurationBrandingModel) => {
          return insertBrandingSuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertBrandingFailure(error)))
      )
    )
  );

const updateBrandingEpic = (
  action$: ActionsObservable<IUpdateApplicationBrandingAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_APPLICATION_BRANDING),
    switchMap(async (action: IUpdateApplicationBrandingAction) => {
      try {
        if (action.payload.Values) {
          for (let brandingValue of action.payload.Values) {
            if (
              brandingValue.RecordStatus !== RecordStatus.NoChange &&
              brandingValue.Type === "URL" &&
              brandingValue.Value &&
              brandingValue.Value instanceof File
            ) {
              const fileUploadInfo: UploadFileInfoModel = await applicationConfigurationService
                .getImageUploadFileInfo(
                  action.payload.ApplicationConfigurationId
                )
                .toPromise();

              await storageService.uploadFile(
                brandingValue.Value,
                fileUploadInfo
              );

              brandingValue.Value = fileUploadInfo.Path;
            }
          }
        }
        const result = await applicationBrandingsService
          .update(action.payload)
          .toPromise();

        return updateBrandingSuccess(result);
      } catch (error) {
        return updateBrandingFailure(error as IErrorModel);
      }
    })
  );

const searchBrandingsEpic = (
  action$: ActionsObservable<ISearchApplicationBrandingAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_APPLICATION_BRANDING),
    switchMap((action: ISearchApplicationBrandingAction) =>
      applicationBrandingsService.search(action.filter).pipe(
        // TODO:epic
        map((data: IApplicationConfigurationBrandingListModel) => {
          // data.Filter = action.filter;

          return searchBrandingSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectScreensTypesFailure(error)))
      )
    )
  );

const selectBrandingsKeysEpic = (
  action$: ActionsObservable<ISelectKeysApplicationBrandingAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_KEYS_APPLICATION_BRANDING),
    switchMap((action: ISelectKeysApplicationBrandingAction) =>
      applicationBrandingsService
        .selectKeys(action.applicationConfigurationId)
        .pipe(
          // TODO:epic
          map((data: any) => {
            return selectBrandingKeysSuccess(data);
          }),
          catchError((error: IErrorModel) =>
            of(selectBrandingKeysFailure(error))
          )
        )
    )
  );

const deleteBrandingEpic = (
  action$: ActionsObservable<IDeleteApplicationBrandingAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_APPLICATION_BRANDING),
    switchMap((action: IDeleteApplicationBrandingAction) =>
      applicationBrandingsService.delete(action.payload).pipe(
        map(() => {
          return deleteBrandingSuccess(action.payload);
        }),
        catchError((error: IErrorModel) => of(deleteBrandingFailure(error)))
      )
    )
  );

const uploadBrandingImageEpic = (
  action$: ActionsObservable<IUploadBrandingImageAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPLOAD_BRANDING_IMAGE),
    switchMap((action: IUploadBrandingImageAction) =>
      from(storageService.uploadFile(action.file, action.fileUploadInfo)).pipe(
        map((response: OperationResult<UploadFileInfoModel>) => {
          return uploadBrandingImageSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(uploadBrandingImageFailure(error))
        )
      )
    )
  );

const getImageUploadFileInfoEpic = (
  action$: ActionsObservable<IGetImageUploadFileInfoAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_IMAGE_UPLOAD_FILE_INFO),
    switchMap((action: IGetImageUploadFileInfoAction) =>
      applicationConfigurationService.getImageUploadFileInfo(action.id).pipe(
        map((data: UploadFileInfoModel) => {
          return getImageUploadFileInfoSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getImageUploadFileInfoFailure(error))
        )
      )
    )
  );

const exportTranslationsEpic = (
  action$: ActionsObservable<IExportTranslationsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.EXPORT_TRANSLATIONS),
    switchMap((action: IExportTranslationsAction) =>
      applicationConfigurationService.exportTranslations().pipe(
        map((data: Blob) => {
          return exportTranslationsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(exportTranslationsFailure(error)))
      )
    )
  );

const importTranslationsEpic = (
  action$: ActionsObservable<IImportTranslationsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.IMPORT_TRANSLATIONS),
    switchMap((action: IImportTranslationsAction) =>
      from(
        applicationConfigurationService.importTranslations(action.file)
      ).pipe(
        map((response: OperationResult<any>) => {
          return importTranslationsSuccess(response);
        }),
        catchError((error: IErrorModel) => of(importTranslationsFailure(error)))
      )
    )
  );

const rebuildCacheEpic = (
  action$: ActionsObservable<IRebuildCacheAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.REBUILD_CACHE),
    switchMap(() =>
      assetService.rebuildCache().pipe(
        map(() => {
          return rebuildCacheSuccess();
        }),
        catchError((error: IErrorModel) => {
          return of(rebuildCacheFailure(error));
        })
      )
    )
  );

const synchronizeEPGEpic = (
  action$: ActionsObservable<ISynchronizeEPGAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SYNCHRONIZE_EPG),
    switchMap(() =>
      assetService.synchronizeEPG().pipe(
        map(() => {
          return synchronizeEPGSuccess();
        }),
        catchError((error: IErrorModel) => {
          return of(synchronizeEPGFailure(error));
        })
      )
    )
  );

export const applicationConfigurationEpics = [
  searchConfigurationsEpic,
  getConfigurationEpic,
  deleteConfigurationEpic,
  getScreenEpic,
  searchScreensEpic,
  browseScreensEpic,
  insertScreenEpic,
  updateScreenEpic,
  deleteScreenEpic,
  //saveApplicationConfigurationEpic,
  selectScreensTypesEpic,
  selectComponentsTypesEpic,
  updateConfigurationEpic,
  publishConfigurationEpic,
  getBrandingEpic,
  insertBrandingEpic,
  updateBrandingEpic,
  searchBrandingsEpic,
  selectBrandingsKeysEpic,
  deleteBrandingEpic,
  uploadBrandingImageEpic,
  getImageUploadFileInfoEpic,
  exportTranslationsEpic,
  importTranslationsEpic,
  rebuildCacheEpic,
  synchronizeEPGEpic,
];
