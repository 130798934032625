import React, { memo } from "react";
import {
  Icon,
  PageHeader,
  Button,
  IFiltersUpdater,
  Input,
} from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { IAnalyticsPaymentListFilterModel } from "@xala/common-services";

import "./PaymentListHeader.scss";
import { PaymentListTableDataProviderFilterModel } from "../../../types";

interface IPaymentListHeaderProps {
  fullTextSearch: string;
  setFullTextSearch: (newVal: string) => void;
  onRefresh: () => void;
  filters: {
    current: IAnalyticsPaymentListFilterModel;
    clear: () => void;
    update: (
      updater: IFiltersUpdater<PaymentListTableDataProviderFilterModel>
    ) => void;
  };
}

export const PaymentListHeader = memo(
  ({ filters, onRefresh }: IPaymentListHeaderProps) => {
    const { t } = useTranslation();

    const onFilterChange = (
      key: string,
      value: any,
      setLocalValue?: (val: any) => void
    ) => {
      filters.update((oldFilters) => ({
        ...oldFilters,
        [key]: value,
      }));
      setLocalValue?.(value);
    };

    const onClearFilters = () => {
      filters.clear();
    };

    return (
      <PageHeader
        title={t("PAYMENT_LIST_TITLE")}
        extra={
          <div className="PaymentListHeader__container">
            <div className="PaymentListHeader__container-row PaymentListHeader__container-row--reverse">
              <Button
                shape="circle"
                icon={<Icon type="clear" />}
                onClick={onClearFilters}
                title={t("BUTTON_RESET")}
              />
              <Button
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={onRefresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Input
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={filters.current.FullTextSearch}
                onChange={(val) =>
                  onFilterChange("FullTextSearch", val.target.value)
                }
                style={{ minWidth: 250, width: "50%" }}
                allowClear
              />
            </div>
          </div>
        }
      />
    );
  }
);
