import React from "react";

import { WithTranslation } from "react-i18next";
import { IApplicationModel } from "@xala/common-services";
import { Action, ActionCreator } from "redux";
import {
  Checkbox,
  ITableColumnProps,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Table,
} from "@xala/common-ui";

export interface IDictionaryApplicationListProps extends WithTranslation {
  applications: IApplicationModel[];
  isLoadingData: boolean;
  selectApplications: ActionCreator<Action>;
}

export class DictionaryApplicationList extends React.PureComponent<
  IDictionaryApplicationListProps
> {
  public componentDidMount() {
    const { selectApplications } = this.props;

    selectApplications();
  }

  private getColumnsProps(): Array<ITableColumnProps<IApplicationModel>> {
    const { t } = this.props;

    return [
      {
        key: "Key",
        dataIndex: "Key",
        title: t("MODEL_KEY"),
      },
      {
        key: "DisplayName",
        dataIndex: "DisplayName",
        title: t("MODEL_DISPLAY_NAME"),
      },
      {
        key: "ResourcesAvailable",
        dataIndex: "ResourcesAvailable",
        title: t("MODEL_RESOURCES_AVAILABLE"),
        render: (text, record) => (
          <Checkbox checked={record.ResourcesAvailable} disabled={true} />
        ),
      },
      {
        key: "ApplicationConfigurationComponentsAvailable",
        dataIndex: "ApplicationConfigurationComponentsAvailable",
        title: t("MODEL_CONFIGURATION_AVAILABLE"),
        render: (text, record) => (
          <Checkbox
            checked={record.ApplicationConfigurationComponentsAvailable}
            disabled={true}
          />
        ),
      },
    ];
  }

  public render() {
    const { applications, isLoadingData, t } = this.props;

    return (
      <div className="ApplicationList">
        <PageHeader
          title={t("DictionaryApplicationListTitle", "Applications")}
        />
        <PageContent>
          <Table<IApplicationModel>
            rowKey={(row) => row.Key}
            columns={this.getColumnsProps()}
            dataSource={applications}
            loading={isLoadingData}
            pagination={false}
          />
        </PageContent>
      </div>
    );
  }
}
