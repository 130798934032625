import React, { useEffect, useMemo } from "react";
import {
  ITownModel,
  ITownSearchFilterModel,
  RecordStatus,
  TownsService,
  useDataLoader,
  useServiceCaller,
  ITownWithChannelsModel,
  AssetStore,
  AssetType,
  IChannelsForTownModel,
} from "@xala/common-services";
import {
  Button,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  NotificationService,
  PageContent,
  PageHeader,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
} from "@xala/common-ui";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useState } from "react";
import { FormModal } from "../../../../components";
import { DictionaryTownForm } from "../DictionaryTownForm";
import { StringHelper } from "@xala/studio/src/helpers";
import { useDispatch } from "react-redux";

const defaultTown: ITownWithChannelsModel = {
  Town: {
    Id: -1,
    Code: "",
    Name: "",
    RecordStatus: RecordStatus.Inserted,
    UpToDate: true,
  },
};

const notificationService = NotificationService.getInstance();
const townsService = new TownsService().promisify();

type ColumnValue = string | number | boolean;

export const DictionaryTownsList = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [town, setTown] = useState<ITownWithChannelsModel>(defaultTown);
  const [stateFilter, setStateFilter] = useState<ITownSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      PageSize: 999,
      PageNumber: 1,
      IncludeCount: true,
      Types: [AssetType.Channel],
    };

    dispatch(AssetStore.Actions.searchAsset(data));
  }, []);

  const townsLoader = useDataLoader({
    loader: () => townsService.getTownsWithChannels(),
    deps: [],
  });

  const townModal = useCallback(
    (isShowForm?: boolean) => {
      setShowForm((showForm) =>
        typeof isShowForm === "boolean" ? isShowForm : !showForm
      );
      setTown(defaultTown);
    },
    [showForm]
  );

  const onRemoveTown = useCallback(() => {
    deleteTown(town.Town);
  }, [town.Town]);

  const headerButtons = useMemo(() => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => townModal()}
          title={t("DICTIONARY_TOWN_MODAL_BUTTON_NEW")}
        />
        <Button
          key="refresh"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={townsLoader.refresh}
          title={t("BUTTON_REFRESH_TITLE")}
        />
      </>
    );
  }, []);

  const [updateTown, { processing }] = useServiceCaller(
    async (town: ITownModel, channels: IChannelsForTownModel[]) => {
      const res = await townsService.update({
        ...town,
      });
      if (res.ok) {
        await townsService.updateTownChannels([
          {
            Town: res.data,
            Channels: channels,
          },
        ]);
        notificationService.success({
          message: t("DICTIONARY_TOWN_UPDATE_SUCCESS"),
        });
        await townsLoader.refresh();
        townModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_TOWN_UPDATE_FAILURE"),
          description: res.error?.Message,
        });
      }
    },
    []
  );

  const [deleteTown] = useServiceCaller(async (town: ITownModel) => {
    const res = await townsService.deleteTowns(town.Id);
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_TOWN_DELETE_SUCCESS"),
      });
      await townsLoader.refresh();
      townModal(false);
    } else {
      notificationService.error({
        message: t("DICTIONARY_TOWN_DELETE_FAILURE"),
        description: res.error?.Message,
      });
    }
  }, []);

  const paginationConfig = useMemo((): ITablePaginationConfig => {
    return {
      showTotal: (total, range) =>
        t("ITEMS_RANGE_LABEL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      pageSizeOptions: ["10", "30", "50", "100"],
    };
  }, []);

  const getColumnNames = useMemo((): Array<
    ITableColumnProps<ITownWithChannelsModel>
  > => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("CLASS_LIST_TABLE_CODE_COLUMN"),
        ...setTableColumnSearchProps(t("CLASS_LIST_TABLE_CODE_COLUMN")),
        filteredValue: stateFilter.Code ? [stateFilter.Code] : null,
        onFilter: (value: ColumnValue, row: ITownWithChannelsModel) =>
          StringHelper.latinize(row.Town.Code.toLowerCase()).includes(
            StringHelper.latinize(value.toString().toLowerCase())
          ),
        render: (_: any, row: ITownWithChannelsModel) => {
          return (
            <a
              title={row.Town.Code}
              className="Code"
              onClick={() => (townModal(), setTown(row))}
            >
              {row.Town.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("CLASS_LIST_TABLE_NAME_COLUMN"),
        ...setTableColumnSearchProps(t("CLASS_LIST_TABLE_NAME_COLUMN")),
        filteredValue: stateFilter.Name ? [stateFilter.Name] : null,
        onFilter: (value: ColumnValue, row: ITownWithChannelsModel) =>
          StringHelper.latinize(row.Town.Name.toLowerCase()).includes(
            StringHelper.latinize(value.toString().toLowerCase())
          ),
        render: (_: any, row: ITownWithChannelsModel) => {
          return <p>{row.Town.Name}</p>;
        },
      },
      {
        key: "Channels",
        dataIndex: "Channels",
        title: t("CLASS_LIST_TABLE_CHANNELS_COLUMN"),
        render: (_: any, row: ITownWithChannelsModel) => {
          return row.Channels?.map((el, index) => (
            <span key={index} style={{ marginRight: "6px" }}>
              {el.ChannelName}
            </span>
          ));
        },
      },
      {
        key: "Action",
        dataIndex: "Action",
        align: "center",
        title: t("TABLE_ACTION_COLUMN"),
        render: (_: any, row: ITownWithChannelsModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              deleteTown(row.Town);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  }, [stateFilter]);

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const filter = { ...stateFilter };
    filter.PageNumber = pagination.current!;
    filter.PageSize = pagination.pageSize;

    filter.Name = filters.Name?.[0]?.toString();
    filter.Code = filters.Code?.[0]?.toString();

    setStateFilter(filter);
  };

  return (
    <div className="DictionaryTowns">
      <FormModal
        isLoading={processing}
        isVisible={showForm}
        isNewForm={town.Town.Id === -1 ? true : false}
        isDeleteButtonEnabled={town.Town.Id === -1 ? false : true}
        createFormTitle={t("DICTIONARY_TOWN_MODAL_NEW")}
        editFormTitle={t("DICTIONARY_TOWN_MODAL_EDIT")}
        modalClassName="DictionaryTownModal"
        submitFormName="DictionaryTownForm"
        onCloseModal={townModal}
        onDeleteButtonClick={onRemoveTown}
      >
        <DictionaryTownForm
          isProcessing={processing}
          town={town}
          updateOrAddNewTown={updateTown}
        />
      </FormModal>
      <PageHeader title={t("DICTIONARY_TOWNS_TITLE")} extra={headerButtons} />
      <PageContent>
        <Table<ITownWithChannelsModel>
          rowKey={(rowKey) => rowKey.Town.Id}
          dataSource={townsLoader.data?.Entities}
          loading={townsLoader.loading}
          columns={getColumnNames}
          pagination={paginationConfig}
          onChange={onTableChange}
        />
      </PageContent>
    </div>
  );
};
