import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IErrorModel, ITownsListModel } from "../../models";
import { TownsService } from "../../services";
import { getTownsFailure, getTownsSuccess } from "./actions";
import * as Consts from "./consts";
import { IGetTownsAction } from "./types";

const townsService: TownsService = new TownsService();

const getTownsEpic = (action$: ActionsObservable<IGetTownsAction>) =>
  action$.pipe(
    ofType(Consts.GET_TOWNS),
    switchMap(() =>
      townsService.getTowns().pipe(
        map((data: ITownsListModel) => {
          return getTownsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getTownsFailure(error)))
      )
    )
  );

export const townsEpics = [getTownsEpic];
