import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IChooseProps,
  Choose,
  ChooseOption,
  IChooseValue,
} from "@xala/common-ui";
import { CurrencyStore } from "@xala/common-services";
import { find, first } from "lodash";

export interface ICurrencyChooseProps extends IChooseProps {
  value?: IChooseValue;
  onChange?: (value: IChooseValue) => void;
}

export const CurrencyChoose = React.forwardRef<Choose, ICurrencyChooseProps>(
  (props, ref) => {
    const dispatch = useDispatch();
    const currencies = useSelector(CurrencyStore.Selectors.currenciesSelector);

    const getCurrency = useCallback(
      () => dispatch(CurrencyStore.Actions.selectCurrencies()),
      [dispatch]
    );

    useEffect(() => {
      getCurrency();
    }, [getCurrency]);

    // Select first option by default
    useEffect(() => {
      if (currencies.data && !props.value) {
        let firstOption;

        if (process.env.REACT_APP_DEFAULT_CODE_OF_CURRENCY) {
          firstOption = find(currencies.data, {
            Code: process.env.REACT_APP_DEFAULT_CODE_OF_CURRENCY,
          });
        } else {
          firstOption = first(currencies.data);
        }

        if (firstOption) {
          props.onChange?.(firstOption.Id);
        }
      }
    }, [currencies.data, props.value]);

    return (
      <Choose
        ref={ref}
        loading={currencies?.isFetching}
        disabled={currencies?.isFetching}
        {...props}
      >
        {currencies?.data?.map((currency) => (
          <ChooseOption key={currency.Id} value={currency.Id}>
            {currency.Code}
          </ChooseOption>
        ))}
      </Choose>
    );
  }
);
