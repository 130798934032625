import React from "react";
import { Title } from "@xala/common-ui";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationFooterItemComponentPropertyModel } from "../../models";
import { IComponentPreviewBaseProps } from "../ComponentPreviewBase";
import "./ApplicationFooterItemComponentPreview.scss";

export interface IApplicationFooterItemComponentPreviewOwnProps
  extends IComponentPreviewBaseProps {
  properties: ApplicationFooterItemComponentPropertyModel;
}

export interface IApplicationFooterItemComponentPreviewProps
  extends IApplicationFooterItemComponentPreviewOwnProps,
    WithScreenDesignerContextProps {}

export function ApplicationFooterItemComponentPreview({
  properties,
}: IApplicationFooterItemComponentPreviewProps) {
  return (
    <div className="ApplicationFooterItemComponentPreview">
      <Title>{properties?.Title}</Title>
    </div>
  );
}
