import React, { memo } from "react";
import { AnalyticsAggregationPeriodType } from "@xala/common-services";
import { useTranslation } from "react-i18next";
import { Choose, ChooseOption } from "@xala/common-ui";

interface ISalesRevenueAggregationTypeSelect {
  value?: AnalyticsAggregationPeriodType;
  onChange?: (newValue: AnalyticsAggregationPeriodType) => void;
}

export const SalesRevenueAggregationTypeSelect = memo(
  ({
    value = AnalyticsAggregationPeriodType.YEAR,
    onChange,
  }: ISalesRevenueAggregationTypeSelect) => {
    const { t } = useTranslation();

    const onValueChange = (newValue: string) => {
      if (onChange) {
        onChange(newValue as AnalyticsAggregationPeriodType);
      }
    };

    return (
      <Choose
        placeholder={t("SALES_REVENUE_AGGREGATION_TYPE")}
        value={value}
        onChange={onValueChange}
      >
        {Object.keys(AnalyticsAggregationPeriodType)
          .map(
            (key) =>
              AnalyticsAggregationPeriodType[
                key as keyof typeof AnalyticsAggregationPeriodType
              ]
          )
          .map((val, index) => (
            <ChooseOption key={`select--${val}--${index}`} value={val}>
              {val}
            </ChooseOption>
          ))}
      </Choose>
    );
  }
);
