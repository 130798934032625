import React, { useMemo } from "react";
import {
  ICommonAppState,
  IUserModel,
  IUsersSearchFilterModel,
} from "@xala/common-services";
import {
  Avatar,
  Icon,
  ITableColumnProps,
  setTableColumnSearchProps,
  Tag,
} from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const userSelector = (state: ICommonAppState) => state.user;

const COLORS = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

interface IUseUserBrowserTableColumnsProps {
  profiles?: string[];
}

export const useUserBrowserTableColumns = ({
  profiles: filterProfiles,
}: IUseUserBrowserTableColumnsProps = {}) => {
  const { t } = useTranslation();
  const { usersBrowser, profiles } = useSelector(userSelector);
  const isProfilesFilterProvided = () =>
    !!filterProfiles && filterProfiles.length > 0;

  const filter: IUsersSearchFilterModel = useMemo(() => {
    const newFilter = usersBrowser?.Filter || {
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    };
    if (isProfilesFilterProvided()) {
      newFilter.Profiles = filterProfiles;
    }
    return newFilter;
  }, [usersBrowser, filterProfiles]);

  const getProfilesColumnDef = () => {
    const profilesColumnDef: any = {
      key: "Profiles",
      dataIndex: "Profiles",
      title: t("USERS_LIST_TABLE_PROFILE_COLUMN", "Profile"),
      render: (_: any, row: IUserModel) => {
        const profilesView: React.ReactNode[] = [];

        if (row.Profiles && row.Profiles.length > 0) {
          for (const profile of row.Profiles) {
            let color = "";

            switch (profile.ProfileCode) {
              case "ADMINISTRATOR":
                color = "red";
                break;
              case "ANONYMOUS":
                color = "geekblue";
                break;
              case "CREATOR":
                color = "green";
                break;
              case "USER":
                color = "blue";
                break;
              case "CHILD":
                color = "orange";
                break;
              default:
                color = "purple";
                break;
            }

            profilesView.push(
              <Tag color={color} style={{ marginBottom: "4px" }}>
                {profile.ProfileName}
              </Tag>
            );
          }
        }

        return profilesView;
      },
    };
    if (!isProfilesFilterProvided()) {
      profilesColumnDef.filters = profiles?.Entities.map((profile) => ({
        text: profile.Name,
        value: profile.Code,
        key: profile.Id,
      }));
      profilesColumnDef.filteredValue = filter.Profiles || null;
    } else {
      profilesColumnDef.filterable = false;
    }
    return profilesColumnDef;
  };
  const getColumnsProps = (): Array<ITableColumnProps<IUserModel>> => {
    return [
      {
        render: (_: any, row: IUserModel) => {
          let initials: string | undefined;
          let icon: string | undefined = "user";
          const style: React.CSSProperties = {
            verticalAlign: "middle",
          };

          if (row.FullName && !row.FullName.startsWith("+")) {
            const names = row.FullName.split(" ");

            if (names.length > 1) {
              initials = `${names[0].charAt(0).toUpperCase()}${names[1]
                .charAt(0)
                .toUpperCase()}`;
            } else if (names.length === 1) {
              initials =
                names[0].length > 1
                  ? names[0].substr(0, 2).toUpperCase()
                  : names[0].charAt(0).toUpperCase();
            }
          }

          if (initials && initials.length > 0) {
            const charIndex: number = initials.charCodeAt(0) - 65;
            const colorIndex: number = charIndex % 19;
            style.backgroundColor = COLORS[colorIndex];
            icon = undefined;
          }

          return (
            <div>
              <div className="UserBrowserModal-column-user">
                <div className="UserBrowserModal-column-user-avatar">
                  <Avatar
                    src={row.AvatarUrl}
                    style={style}
                    icon={<Icon type={icon} />}
                    className="UserBrowserModal"
                  >
                    {initials}
                  </Avatar>
                </div>
                <div className="UserBrowserModal-column-user-name">
                  <p>{row.FullName || row.PhoneNumber}</p>
                </div>
              </div>
            </div>
          );
        },
        key: "FullName",
        dataIndex: "FullName",
        title: t("USERS_LIST_TABLE_FULL_NAME_COLUMN", "User"),
        filteredValue: filter.FullName ? [filter.FullName] : null,
        ...setTableColumnSearchProps(
          "FullName",
          t("USERS_LIST_TABLE_FULL_NAME_COLUMN", "User")
        ),
      },
      getProfilesColumnDef(),
      {
        key: "Email",
        dataIndex: "Email",
        className: "UserBrowserModal-column-user-email",
        title: t("USERS_LIST_TABLE_EMAIL_COLUMN", "Email"),
        filteredValue: filter.Email ? [filter.Email] : null,
        ...setTableColumnSearchProps(
          "Email",
          t("USERS_LIST_TABLE_EMAIL_COLUMN", "Email")
        ),
      },
      {
        key: "PhoneNumber",
        dataIndex: "PhoneNumber",
        className: "UserBrowserModal-column-user-phone-number",
        title: t("USERS_LIST_TABLE_PHONE_NUMBER_COLUMN", "Phone number"),
        filteredValue: filter.PhoneNumber ? [filter.PhoneNumber] : null,
        ...setTableColumnSearchProps(
          "PhoneNumber",
          t("USERS_LIST_TABLE_PHONE_NUMBER_COLUMN", "Phone number")
        ),
      },
      {
        key: "Locked",
        dataIndex: "Locked",
        title: t("USERS_LIST_TABLE_STATUS_COLUMN", "Status"),
        filters: [
          {
            text: t("USERS_LIST_TABLE_STATUS_COLUMN_LOCKED_FILTER", "Locked"),
            value: "true",
          },
          {
            text: t("USERS_LIST_TABLE_STATUS_COLUMN_ACTIVE_FILTER", "Active"),
            value: "false",
          },
        ],
        filteredValue: filter.Locked ? [`${filter.Locked}`] : null,
        render: (text: any, row: IUserModel, index: number) => {
          return (
            <Tag color={row.Locked ? "#f50" : "#1890ff"}>
              {row.Locked
                ? t("USERS_LIST_TABLE_STATUS_COLUMN_LOCKED_FILTER", "Locked")
                : t("USERS_LIST_TABLE_STATUS_COLUMN_ACTIVE_FILTER", "Active")}
            </Tag>
          );
        },
      },
    ];
  };
  const columns = useMemo(() => getColumnsProps(), [filterProfiles, profiles]);

  return { columns, usersBrowser, profiles, filter, isProfilesFilterProvided };
};
