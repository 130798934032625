import React from "react";
import { useTranslation } from "react-i18next";
import {
  ICreatorStreamModel,
  AssetStreamsService,
  UserStreamsService,
} from "@xala/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Table,
  setTableColumnSearchProps,
  Popconfirm,
} from "@xala/common-ui";
import { MediaChannelStateTag } from "..";
import { useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "../../constants";

const notificationService = NotificationService.getInstance();
const assetStreamsService = new AssetStreamsService().promisify();
const userStreamsService = new UserStreamsService().promisify();

export const MediaChannelList: React.FC = () => {
  const { t } = useTranslation();

  const {
    dataLoader: channelSearchLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      UserFullName: "string",
      FullTextSearch: "string",
    },
    loader: (filters, pagination) =>
      assetStreamsService.searchChannel({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notificationService.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const columns: Array<ITableColumnProps<ICreatorStreamModel>> = [
    {
      key: "Name",
      dataIndex: "Name",
      title: t("MODEL_NAME"),
      render: (_: string, row: ICreatorStreamModel) => {
        return (
          <Link to={`${ROUTES.CHANNEL_DETAILS}/${row.AssetId}`}>
            {row.Name}
          </Link>
        );
      },
    },
    {
      key: "ChannelId",
      dataIndex: "ChannelId",
      title: t("MODEL_CHANNEL_ID"),
      width: "200px",
    },
    {
      key: "UserFullName",
      dataIndex: "UserFullName",
      title: t("MODEL_USER_FULLNAME"),
      filteredValue: filters.asTableArray.UserFullName || null,
      ...setTableColumnSearchProps("UserFullName", t("MODEL_USER_FULLNAME")),
    },
    {
      key: "State",
      dataIndex: "State",
      title: t("MODEL_STATE"),
      width: "150px",
      align: "center",
      render: (_text: string, row) => (
        <MediaChannelStateTag state={row.State}></MediaChannelStateTag>
      ),
    },
    {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      width: "100px",
      title: t("TABLE_ACTIONS_COLUMN", "Actions"),
      render: (_: string, row: ICreatorStreamModel) => (
        <Popconfirm
          title={t(
            "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
            "Are you sure you want to delete element?"
          )}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            const result = await userStreamsService.deleteUserLiveChannel({
              UserId: row.AssetId,
            });
            if (result.ok) {
              notificationService.success({
                message: t("DELETE_CHANNEL_SUCCESS"),
              });
              await channelSearchLoader.refresh();
            } else {
              notificationService.error({
                message: t("DELETE_CHANNEL_FAILURE"),
                description: result.error?.Message,
              });
            }
          }}
          okText={t("BUTTON_YES", "Yes")}
          cancelText={t("BUTTON_NO", "No")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT", "Delete element")}
          />
        </Popconfirm>
      ),
    },
  ];

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  return (
    <PageContent footer={<Pagination {...pagination.props} />}>
      <PageHeader
        title={t("MEDIA_CHANNEL_LIST_TITLE")}
        extra={
          <>
            <InputSearch
              placeholder={t("SEARCH_PLACEHOLDER")}
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={channelSearchLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
          </>
        }
      />
      <Table<ICreatorStreamModel>
        rowKey="Id"
        columns={columns}
        dataSource={channelSearchLoader.data?.Entities}
        loading={channelSearchLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
    </PageContent>
  );
};
