import { IAppBaseState } from "./types";
import { Store, AnyAction } from "redux";

let appStore: Store<IAppBaseState, AnyAction>;

export const setAppStore = (store: Store<IAppBaseState, AnyAction>) => {
  appStore = store;
};

export function dispatch(action: AnyAction) {
  if (appStore && appStore.dispatch) {
    appStore.dispatch(action);
  }
}
