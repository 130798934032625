import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { DictionaryConsentList as ConsentListDefinition } from "./DictionaryConsentList";
import {
  CommonStore,
  ICommonAppState,
  IConsentModel,
} from "@xala/common-services";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.common.actionType,
    error: state.common.error,
    consents: state.common.consents,
    isLoadingData: state.common.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectConsents: () => {
    return dispatch(CommonStore.Actions.selectConsents());
  },
  updateConsent: (data: IConsentModel) => {
    return dispatch(CommonStore.Actions.updateConsent(data));
  },
  deleteConsent: (data: IConsentModel) => {
    return dispatch(CommonStore.Actions.deleteConsent(data));
  },
});

export const ConsentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ConsentListDefinition));
