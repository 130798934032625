import {
  AssetCollectionService,
  AssetCollectionTypesService,
  AssetStore,
  IAssetCollectionCopyModel,
  IAssetCollectionModel,
  ICommonAppState,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import {
  Button,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  Spin,
  TabPane,
  Tabs,
  useSearchParamsTabs,
} from "@xala/common-ui";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { ROUTES } from "../../../../constants";
import { AssetCollectionAssetsGrid } from "../AssetCollectionAssetsGrid";
import { AssetCollectionAssetsTransfer } from "../AssetCollectionAssetsTransfer";
import { AssetCollectionFiltersForm } from "../AssetCollectionFiltersForm";
import { AssetCollectionForm } from "../AssetCollectionForm";
import "./AssetCollectionDetails.scss";
import { generateBreadcrumb } from "../../../../helpers";

const assetCollectionSelector = (state: ICommonAppState) => state.asset;
const assetCollectionService = new AssetCollectionService().promisify();
const assetCollectionTypesService = new AssetCollectionTypesService().promisify();
const notificationService = NotificationService.getInstance();

export const AssetCollectionDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { assetCollection, action } = useSelector(assetCollectionSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTabKey, setActiveTabKey] = useSearchParamsTabs("DETAILS");

  const DeleteAssetsCollectionButton = () => (
    <Button
      icon={<Icon type="delete" />}
      shape="circle"
      title={t("DeleteAssetsCollection", "Delete assets collection")}
      danger
      onClick={() => {
        Modal.confirm({
          title: t("Delete playlist"),
          content: t("Are you sure you want to delete playlist?"),
          okText: t("BUTTON_DELETE"),
          cancelText: t("BUTTON_CANCEL"),
          visible: true,
          onOk: () => {
            dispatch(
              AssetStore.Actions.deleteAssetsCollection(assetCollection.Data!)
            );
          },
        });
      }}
    />
  );

  useEffect(() => {
    switch (action?.type) {
      case "CREATE_ASSETS_COLLECTION_SUCCESS":
        notificationService.success({
          message: t(
            "AddPlaylistCollectionSuccess",
            "Playlist collection successfully created."
          ),
        });
        break;
      case "CREATE_ASSETS_COLLECTION_FAILURE":
        notificationService.error({
          message: t(
            "AddPlaylistCollectionFailure",
            "There was an error while creating playlist collection."
          ),
          description: action?.error?.Message,
        });
        break;
      case "DELETE_ASSETS_COLLECTION_SUCCESS":
        notificationService.success({
          message: t(
            "DeletePlaylistCollectionSuccess",
            "Playlist collection successfully deleted."
          ),
        });
        history.push(ROUTES.ASSET_COLLECTION_LIST);
        Modal.destroyAll();
        break;
      case "DELETE_ASSETS_COLLECTION_FAILURE":
        notificationService.error({
          message: t(
            "DeletePlaylistCollectionFailure",
            "There was an error while deleting playlist collection."
          ),
          description: action?.error?.Message,
        });
        Modal.destroyAll();
        break;
    }
  }, [action]);

  const assetCollectionLoader = useDataLoader({
    loader: () => assetCollectionService.get(+id),
    deps: [id],
  });

  const assetCollectionTypesLoader = useDataLoader({
    loader: () => assetCollectionTypesService.select(),
    deps: [],
  });

  const updateAssetCollection = useCallback(
    async (asset: IAssetCollectionModel) => {
      const result = await assetCollectionService.update(asset);
      if (result.ok) {
        notificationService.success({
          message: t(
            "UpdatePlaylistCollectionSuccess",
            "Playlist collection successfully updated."
          ),
        });
      } else {
        notificationService.error({
          message: t(
            "UpdatePlaylistCollectionFailure",
            "There was an error while updating playlist collection."
          ),
          description: result.error?.Message,
        });
      }
      await assetCollectionLoader.refresh();
      return result.ok;
    },
    []
  );

  const [
    copyAssetCollection,
    { processing: copyProcessing },
  ] = useServiceCaller(async (asset: IAssetCollectionCopyModel) => {
    const result = await assetCollectionService.copy({
      ...asset,
    });
    if (result.ok) {
      notificationService.success({
        message: t(
          "PLAYLIST_COLLECTION_COPY_SUCCESS",
          "Playlist collection successfully copied."
        ),
      });
    } else {
      notificationService.error({
        message: t(
          "PLAYLIST_COLLECTION_COPY_FAILURE",
          "There was an error while copied playlist collection."
        ),
        description: result.error?.Message,
      });
    }
  }, []);

  const onCopyClick = (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    assetCollectionLoader.data?.Name &&
      copyAssetCollection({
        SourceAssetCollectionId: assetCollectionLoader.data.Id,
        Name: `${assetCollectionLoader.data.Name} copy`,
      });
  };

  const [
    updateAssetCollectionGeneral,
    { processing: generalProcessing },
  ] = useServiceCaller(updateAssetCollection, [updateAssetCollection]);

  const [
    updateAssetCollectionDefinitions,
    { processing: definitionsProcessing },
  ] = useServiceCaller(updateAssetCollection, [updateAssetCollection]);

  return (
    <>
      <PageContent>
        <PageHeader
          title={t("PLAY_LIST_DETAILS_HEADER")}
          onBack={() => history.push(ROUTES.ASSET_COLLECTION_LIST)}
          breadcrumb={generateBreadcrumb([
            {
              path: `${ROUTES.ASSET_COLLECTION_LIST}`,
              breadcrumbName: t("PLAY_LIST_HEADER"),
            },
            {
              path: `${ROUTES.ASSET_COLLECTION_DETAILS}/${id}`,
              breadcrumbName:
                assetCollection.Data?.Name ?? t("PLAY_LIST_DETAILS_HEADER"),
            },
          ])}
          extra={
            <>
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={() => assetCollectionLoader.refresh()}
                title={t("BUTTON_REFRESH_TITLE", "Refresh data")}
                shape="circle"
              />
              <Button
                key="copy-reload"
                icon={<Icon type="copy" />}
                onClick={onCopyClick}
                title={t("BUTTON_COPY_TITLE", "Copy data")}
                shape="circle"
              />
              <DeleteAssetsCollectionButton />
            </>
          }
        />
        <Spin spinning={copyProcessing}>
          <Tabs activeKey={activeTabKey} onTabClick={setActiveTabKey}>
            <TabPane key="DETAILS" tab={t("PLAY_LIST_DETAILS_TAB_DETAILS")}>
              <SectionGrid style={{ maxWidth: "1200px" }}>
                <SectionGridItem
                  processing={generalProcessing}
                  header={t("PLAY_LIST_DETAILS_SECTION_GENERAL")}
                >
                  <AssetCollectionForm
                    assetCollection={assetCollectionLoader.data}
                    assetCollectionTypes={assetCollectionTypesLoader.data}
                    onSubmit={updateAssetCollectionGeneral}
                  />
                </SectionGridItem>
              </SectionGrid>
            </TabPane>
            <TabPane
              key="ASSETS"
              tab={t("PLAY_LIST_DETAILS_TAB_COLLECTION_SETTINGS")}
            >
              <SectionGrid>
                {assetCollectionLoader.data?.CollectionType === "MANUAL" ? (
                  <SectionGridItem>
                    <AssetCollectionAssetsTransfer />
                  </SectionGridItem>
                ) : (
                  <SectionGridItem processing={definitionsProcessing}>
                    <AssetCollectionFiltersForm
                      assetCollection={assetCollectionLoader.data}
                      onSubmit={updateAssetCollectionDefinitions}
                    />
                  </SectionGridItem>
                )}
              </SectionGrid>
            </TabPane>
            <TabPane key="PREVIEW" tab={t("PLAY_LIST_DETAILS_TAB_PREVIEW")}>
              <SectionGrid>
                <SectionGridItem>
                  <AssetCollectionAssetsGrid
                    isActive={activeTabKey === "PREVIEW"}
                  />
                </SectionGridItem>
              </SectionGrid>
            </TabPane>
          </Tabs>
        </Spin>
      </PageContent>
    </>
  );
};
