import {
  ApplicationConfigurationService,
  ApplicationScreenService,
  CommonStore,
  ComponentType,
  dispatch,
  GuidHelper,
  IApplicationComponentModel,
  IApplicationScreenModel,
  ICommonAppState,
  IErrorModel,
  PlatformType,
  RecordStatus,
  ScreenType,
  StorageService,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import { IApplicationScreenCopyModel } from "@xala/common-services/src/models/ApplicationConfiguration/IApplicationScreenCopyModel";
import {
  Button,
  Heading,
  Content,
  Icon,
  Layout,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  Sider,
  Spin,
  TabPane,
  Tabs,
  useSearchParamsTabs,
} from "@xala/common-ui";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ComponentTypeHelper } from "../../../../helpers";
import {
  ApplicationScreenProvider,
  IApplicationScreenContext,
} from "../../context";
import { DesignerModule } from "../../modules";
import { ApplicationComponentProperties } from "../ApplicationComponentProperties";
import { ApplicationScreenComponents } from "../ApplicationScreenComponents";
import { ApplicationScreenDetailsForm } from "../ApplicationScreenDetailsForm";
import "./ApplicationScreenDetails.scss";
import { ApplicationFooterProperties } from "../ApplicationFooterProperties";

const notificationService = NotificationService.getInstance();
const applicationScreenService = new ApplicationScreenService().promisify();
const applicationConfigurationService = new ApplicationConfigurationService();
const storageService: StorageService = StorageService.getInstance();

interface ITabContentProps {
  header?: string;
  sider: ReactNode;
  children: ReactNode;
}

type UploadImageFieldName = "IconUrl" | "BackgroundUrl" | "ImageUrl";

const TabContent = ({ header, sider, children }: ITabContentProps) => (
  <Layout>
    <Content>
      <SectionGrid>
        <SectionGridItem header={header}>{children}</SectionGridItem>
      </SectionGrid>
    </Content>
    {sider}
  </Layout>
);

export const ApplicationScreenDetails = () => {
  const [activeTabKey, setActiveTabKey] = useSearchParamsTabs("DETAILS");
  const [hasChanges, setHasChanges] = useState(false);
  const [component, setComponent] = useState<
    IApplicationComponentModel | undefined
  >();
  const [screen, setScreen] = useState<IApplicationScreenModel | undefined>();

  const { t } = useTranslation();

  const { isProcessingData, isLoadingData } = useSelector(
    (state: ICommonAppState) => state.applicationConfiguration
  );

  const platforms = useSelector(
    (state: ICommonAppState) => state.common.platforms
  );

  const { id } = useParams<{ id: string }>();

  const applicationScreenLoader = useDataLoader({
    loader: () => applicationScreenService.get(+id),
    deps: [id],
  });

  useEffect(() => {
    if (!platforms || platforms.length === 0) {
      dispatch(CommonStore.Actions.selectPlatforms());
    }
  }, []);

  useEffect(() => {
    if (applicationScreenLoader.data) {
      setScreen(applicationScreenLoader.data);
    }
    if (applicationScreenLoader.data?.Components) {
      const firstComponent = applicationScreenLoader.data.Components[0];

      const updatedComponent = applicationScreenLoader.data?.Components?.find(
        (row: IApplicationComponentModel) => row.Id === component?.Id
      );

      setComponent(updatedComponent ? updatedComponent : firstComponent);
    }
  }, [applicationScreenLoader.data]);

  const [
    copyApplicationScreen,
    { processing: copyProcessing },
  ] = useServiceCaller(async (asset: IApplicationScreenCopyModel) => {
    const result = await applicationScreenService.copy({
      ...asset,
    });
    if (result.ok) {
      notificationService.success({
        message: t(
          "APPLICATION_SCREEN_COPY_SUCCESS",
          "Application screen successfully copied."
        ),
      });
    } else {
      notificationService.error({
        message: t(
          "APPLICATION_SCREEN_COPY_FAILURE",
          "There was an error while copied Application screen."
        ),
        description: result.error?.Message,
      });
    }
  }, []);

  const [deleteApplicationScreen] = useServiceCaller(
    async (applicationScreen: IApplicationScreenModel) => {
      const result = await applicationScreenService.delete({
        ...applicationScreen,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        notificationService.success({
          message: t(
            "APPLICATION_SCREEN_DELETE_SUCCESS",
            "Delete application screen success"
          ),
        });
        window.history.back();
      } else {
        notificationService.error({
          message: t(
            "APPLICATION_SCREEN_DELETE_FAILURE",
            "Delete application screen failed"
          ),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [
    updateApplicationScreen,
    { processing: updateProcessing },
  ] = useServiceCaller(async (applicationScreen: IApplicationScreenModel) => {
    try {
      const bannerComponentsWithImageToUpload: IApplicationComponentModel[] = [];

      applicationScreen.Components?.filter(
        (component) => component.ComponentTypeCode === ComponentType.Banner
      ).forEach((banner) =>
        banner.Properties?.forEach((property) =>
          property.Values?.forEach((bannerItem) => {
            if (bannerItem.Component?.Image)
              return bannerComponentsWithImageToUpload.push(
                bannerItem.Component
              );
          })
        )
      );

      const otherComponentsWithImageToUpload =
        applicationScreen.Components?.filter((component) => component.Image) ||
        [];

      const componentsWithImageToUpload = [
        ...otherComponentsWithImageToUpload,
        ...bannerComponentsWithImageToUpload,
      ];

      const iconComponents = [
        ComponentType.ApplicationMenuItem,
        ComponentType.ApplicationFooterItem,
      ];
      const backgroundComponents = [
        ComponentType.Properties,
        ComponentType.SectionMenu,
      ];

      const getImageFieldName = (
        componentType: ComponentType
      ): UploadImageFieldName => {
        switch (true) {
          case iconComponents.includes(componentType):
            return "IconUrl";
          case backgroundComponents.includes(componentType):
            return "BackgroundUrl";
          default:
            return "ImageUrl";
        }
      };

      if (componentsWithImageToUpload.length > 0) {
        for (let i = 0; i < componentsWithImageToUpload.length; i++) {
          if (
            applicationScreen.ApplicationConfigurationId &&
            componentsWithImageToUpload[i].Image
          ) {
            const fileUploadInfo = await applicationConfigurationService
              .getImageUploadFileInfo(
                applicationScreen.ApplicationConfigurationId
              )
              .toPromise();

            await storageService.uploadFile(
              componentsWithImageToUpload[i].Image!,
              fileUploadInfo
            );

            const {
              ComponentTypeCode,
              Properties,
            } = componentsWithImageToUpload[i];

            const fieldName = getImageFieldName(ComponentTypeCode);
            const imageProperty = Properties?.find(
              (el) => el.Name === fieldName
            );

            if (imageProperty?.Value?.StringValue) {
              imageProperty.Value.StringValue = fileUploadInfo.Path;
            }
          }
        }
      }

      const result = await applicationScreenService.update({
        ...applicationScreen,
        RecordStatus: RecordStatus.Updated,
      });
      if (result.ok) {
        notificationService.success({
          message: t(
            "APPLICATION_SCREEN_UPDATE_SUCCESS",
            "Update application screen success"
          ),
        });
        await applicationScreenLoader.refresh();
      } else {
        notificationService.error({
          message: t(
            "APPLICATION_SCREEN_UPDATE_FAILURE",
            "Update application screen failed"
          ),
          description: result.error?.Message,
        });
      }
    } catch (error) {
      const uplaodError = error as IErrorModel;
      notificationService.error({
        message: t("IMAGE_UPLOAD_FAILURE"),
        description: uplaodError.Message,
      });
    }
  }, []);

  const onCopyClick = (e?: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    applicationScreenLoader.data?.Name &&
      copyApplicationScreen({
        SourceApplicationScreenId: applicationScreenLoader.data.Id,
        Name: `${applicationScreenLoader.data.Name} copy`,
      });
  };

  const onTabClick = (key: string) => {
    setActiveTabKey(key);
    setComponent(undefined);
  };

  const onSaveClick = () => {
    if (!applicationScreenLoader.data) {
      return;
    }

    updateApplicationScreen(applicationScreenLoader.data);
  };

  const onRefreshClick = () => {
    applicationScreenLoader.refresh();
  };

  const onDeleteClick = () => {
    if (!applicationScreenLoader.data) {
      return;
    }

    Modal.confirm({
      title: t("Delete screen"),
      content: `Are you sure to delete screen ${applicationScreenLoader.data.Name}?`,
      okText: "OK",
      cancelText: "Cancel",
      onOk: onConfirmDelete,
    });
  };

  const onConfirmDelete = () => {
    if (!applicationScreenLoader.data) {
      return;
    }
    deleteApplicationScreen(applicationScreenLoader.data);
  };

  const applicationScreenContext: IApplicationScreenContext = {
    screen,
    component,
    onScreenChange: (screenData: IApplicationScreenModel) => {
      if (!applicationScreenLoader.data) {
        return;
      }

      Object.assign(applicationScreenLoader.data, screenData);

      if (applicationScreenLoader.data.RecordStatus === RecordStatus.NoChange) {
        applicationScreenLoader.data.RecordStatus = RecordStatus.Updated;
      }

      setHasChanges(true);
    },
    onComponentAdd: (component: IApplicationComponentModel) => {
      if (!applicationScreenLoader.data) {
        return;
      }

      if (!applicationScreenLoader.data.Components) {
        applicationScreenLoader.data.Components = [];
      }

      const newComponent: IApplicationComponentModel = {
        ...component,
      };

      const minId =
        applicationScreenLoader.data.Components.length > 0
          ? applicationScreenLoader.data.Components.reduce(
              (min, component) => (component.Id < min ? component.Id : min),
              applicationScreenLoader.data.Components[0].Id
            )
          : 0;

      newComponent.Id = minId >= 0 ? -1 : minId - 1;
      newComponent.ApplicationConfigurationId =
        applicationScreenLoader.data.ApplicationConfigurationId;
      newComponent.ApplicationScreenId = applicationScreenLoader.data.Id;
      newComponent.IsVisible = true;
      newComponent.RecordStatus = RecordStatus.Inserted;
      newComponent.Sequence =
        applicationScreenLoader.data.Components.length > 0
          ? Math.max(
              ...applicationScreenLoader.data.Components.map((c) => c.Sequence)
            ) + 1
          : 0;

      if (!newComponent.Guid) {
        newComponent.Guid = GuidHelper.newGuid();
      }

      if (!newComponent.PlatformCode) {
        newComponent.PlatformCode = PlatformType.Any;
      }

      applicationScreenLoader.data.Components.push(newComponent);

      setHasChanges(true);
      setComponent(newComponent);
    },
    onComponentSelect: (component?: IApplicationComponentModel) => {
      setComponent(component);
    },
    onComponentChange: (component: IApplicationComponentModel) => {
      if (!screen || !screen.Components) {
        return;
      }

      const componentIndex = screen.Components.findIndex(
        (row: IApplicationComponentModel) => row.Id === component.Id
      );

      if (componentIndex >= 0) {
        screen.Components[componentIndex] = Object.assign({}, component);

        setScreen(screen);
        setComponent(screen.Components[componentIndex]);
        setHasChanges(true);
      }
    },
    onComponentsChange: (components: IApplicationComponentModel[]) => {
      if (!screen || !screen.Components) {
        return;
      }

      for (const component of components) {
        const componentIndex = screen.Components.findIndex(
          (row: IApplicationComponentModel) => row.Id === component.Id
        );

        if (componentIndex >= 0) {
          screen.Components[componentIndex] = Object.assign({}, component);
        }
      }

      setScreen({ ...screen });
      setHasChanges(true);
    },
    onComponentDelete: (componentId: number) => {
      if (!screen || !screen.Components) {
        return;
      }

      const componentIndex = screen.Components.findIndex(
        (row: IApplicationComponentModel) => row.Id === componentId
      );

      if (componentIndex >= 0) {
        if (
          screen.Components[componentIndex].RecordStatus ===
          RecordStatus.Inserted
        ) {
          screen.Components.splice(componentIndex, 1);
        } else {
          screen.Components[componentIndex].RecordStatus = RecordStatus.Deleted;
        }
      }

      setScreen(screen);
      setComponent(undefined);
      setHasChanges(true);
    },
    onNewChanges: (isChanged: boolean) => {
      setHasChanges(isChanged);
    },
  };

  let componentTypeTag: ReactNode;

  if (component && component.RecordStatus !== RecordStatus.Deleted) {
    componentTypeTag = ComponentTypeHelper.getTag(component.ComponentTypeCode, {
      marginLeft: "16px",
    });
  }

  const zeroWidthTriggerStyle: React.CSSProperties = {
    top: "60px",
    height: "33px",
    fontSize: "18px",
    lineHeight: "33px",
  };

  const propertiesSiderStyle: React.CSSProperties = {
    display: activeTabKey === "DETAILS" ? "none" : "block",
  };

  const sider = (
    <Sider
      className="ApplicationScreenDetails__Properties"
      style={propertiesSiderStyle}
      collapsed={!component}
      reverseArrow={true}
      collapsedWidth={0}
      width={500}
      zeroWidthTriggerStyle={zeroWidthTriggerStyle}
    >
      <SectionGrid>
        <SectionGridItem>
          <Heading
            title={
              <>
                {t("Properties")}
                {componentTypeTag}
              </>
            }
            actions={
              <Button
                icon={<Icon type="arrow-right" />}
                onClick={() => setComponent(undefined)}
              />
            }
          />
          <ApplicationComponentProperties
            key={`ApplicationComponentProperties-${applicationScreenLoader.data?.Id}`}
          />
        </SectionGridItem>
      </SectionGrid>
    </Sider>
  );

  return (
    <div className="ApplicationScreenDetails">
      <Spin
        spinning={
          isProcessingData ||
          isLoadingData ||
          copyProcessing ||
          updateProcessing
        }
      >
        <ApplicationScreenProvider value={applicationScreenContext}>
          <PageHeader
            title={applicationScreenLoader.data?.Name}
            onBack={() => window.history.back()}
            extra={
              <>
                <Button
                  key="action-save"
                  icon={<Icon type="save" />}
                  title="Save data"
                  loading={isProcessingData}
                  disabled={!hasChanges || isProcessingData || isLoadingData}
                  onClick={onSaveClick}
                >
                  {t("BUTTON_SAVE", "Save")}
                </Button>
                <Button
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  onClick={onRefreshClick}
                  title="Refresh data"
                />
                <Button
                  key="copy-reload"
                  icon={<Icon type="copy" />}
                  onClick={onCopyClick}
                  title={t("BUTTON_COPY_TITLE", "Copy data")}
                />
                <Button
                  icon={<Icon type="delete" />}
                  title="Delete screen"
                  onClick={onDeleteClick}
                />
              </>
            }
          ></PageHeader>
          <PageContent>
            <Tabs activeKey={activeTabKey} onTabClick={onTabClick}>
              <TabPane key="DETAILS" tab={t("details", "Details")}>
                <TabContent sider={sider} header={t("General information")}>
                  <ApplicationScreenDetailsForm
                    key={`ApplicationScreenDetailsForm-${applicationScreenLoader.data?.Id}`}
                    isActive={activeTabKey === "DETAILS"}
                  />
                </TabContent>
              </TabPane>
              <TabPane key="COMPONENTS" tab={t("components", "Components")}>
                <TabContent sider={sider}>
                  <ApplicationScreenComponents
                    key={`ApplicationScreenComponents-${applicationScreenLoader.data?.Id}`}
                  />
                </TabContent>
              </TabPane>
              <TabPane key="DESIGNER" tab={t("Designer", "Designer")}>
                <TabContent sider={sider}>
                  <DesignerModule.Components.ScreenDesigner
                    key={`ScreenDesigner-${applicationScreenLoader.data?.Id}`}
                  />
                </TabContent>
              </TabPane>
              {screen?.ScreenTypeCode === ScreenType.ApplicationFooter && (
                <TabPane key="PROPERTIES" tab={t("Properties", "Properties")}>
                  <TabContent sider={null}>
                    <ApplicationFooterProperties
                      key={`ApplicationFooterProperties-${screen?.Id}`}
                    />
                  </TabContent>
                </TabPane>
              )}
            </Tabs>
          </PageContent>
        </ApplicationScreenProvider>
      </Spin>
    </div>
  );
};
