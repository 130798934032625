import { IErrorModel, IUserSettingsModel } from "../../models";
import * as Consts from "./consts";
import {
  IGetUserSettingsByIdAction,
  IGetUserSettingsByIdFailureAction,
  IGetUserSettingsByIdSuccessAction,
  IUpdateUserSettingsAction,
  IUpdateUserSettingsFailureAction,
  IUpdateUserSettingsSuccessAction,
} from "./types";

export const getUserSettingsById = (id: number): IGetUserSettingsByIdAction => {
  return {
    payload: id,
    type: Consts.GET_USER_SETTINGS_BY_ID,
  };
};

export const getUserSettingsByIdSuccess = (
  data: IUserSettingsModel
): IGetUserSettingsByIdSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_USER_SETTINGS_BY_ID_SUCCESS,
  };
};

export const getUserSettingsByIdFailure = (
  error?: IErrorModel
): IGetUserSettingsByIdFailureAction => {
  return {
    error,
    type: Consts.GET_USER_SETTINGS_BY_ID_FAILURE,
  };
};

export const updateUserSettings = (
  data: IUserSettingsModel
): IUpdateUserSettingsAction => {
  return {
    payload: data,
    type: Consts.UPDATE_USER_SETTINGS,
  };
};

export const updateUserSettingsSuccess = (
  data: IUserSettingsModel
): IUpdateUserSettingsSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_USER_SETTINGS_SUCCESS,
  };
};

export const updateUserSettingsFailure = (
  error?: IErrorModel
): IUpdateUserSettingsFailureAction => {
  return {
    error,
    type: Consts.UPDATE_USER_SETTINGS_FAILURE,
  };
};

export const Actions = {
  getUserSettingsById,
  updateUserSettings,
};
