import { INotificationModel, NotificationStatus } from "@xala/common-services";
import { Button, Col, Form, IFormValues, Row, Spin } from "@xala/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationField } from "./NotificationFormUtils";
import {
  NameField,
  TextContent,
  RecipientTypeField,
  ProviderField,
  PrioritiesField,
  ScheduledOnField,
  ImageUrlField,
  NotificationTypeReadOnlyField,
  NotificationStatusReadOnlyField,
  CreatorReadOnlyField,
  UrlContentField,
  UrlDescriptionField,
  IsDraftField,
} from "./fields";
import { FormInstance } from "antd/lib/form";
import "./NotificationForm.scss";

const formLayouts = {
  formItem: {
    labelCol: {
      xs: { span: 8 },
      lg: { span: 6 },
      xl: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 16 },
      lg: { span: 18 },
      xl: { span: 20 },
    },
  },
  modalFormItem: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  },
  tailFormItem: {
    wrapperCol: {
      style: {
        textAlign: "right",
        marginBottom: 0,
      } as React.CSSProperties,
    },
  },
};

export interface INotificationFormProps {
  isEditMode?: boolean;
  notification?: INotificationModel;
  hiddenFields?: NotificationField[];
  onSubmit: (
    data: INotificationModel,
    form: FormInstance<IFormValues>,
    isSended?: boolean
  ) => void;
  onChangeRecipients?: (data: any) => void;
}

export const NotificationForm = ({
  isEditMode,
  onSubmit,
  hiddenFields,
  notification,
  onChangeRecipients,
}: INotificationFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formLayout = isEditMode
    ? formLayouts.formItem
    : formLayouts.modalFormItem;

  const isHidden = (key: NotificationField) =>
    !!hiddenFields && hiddenFields.includes(key);

  const onFinishForm = (values: IFormValues) => {
    if (isEditMode) {
      const data: INotificationModel = {
        ...notification,
        ...values,
        Id: notification!.Id,
        Status: values?.isDraft
          ? NotificationStatus.Draft
          : NotificationStatus.Scheduled,
      };
      onSubmit(data, form);
    } else {
      const data: INotificationModel = {
        ...values,
        Id: -1,
        RecipientType: "SELECTED_USERS",
      };
      onSubmit(data, form);
    }
  };

  const handleSendNowButton = () => {
    const values = form.getFieldsValue();

    if (notification?.RowVersion && notification?.Id) {
      const submitData = {
        ...notification,
        ...values,
        Id: notification!.Id,
        Status: values?.isDraft
          ? NotificationStatus.Draft
          : NotificationStatus.Scheduled,
      };

      onSubmit(submitData, form, true);
    }
  };

  if (isEditMode && !notification) {
    return <Spin spinning></Spin>;
  }

  return (
    <Form
      {...formLayout}
      name="NotificationForm"
      onFinish={onFinishForm}
      form={form}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          <NameField
            isEditMode={isEditMode}
            isHidden={isHidden("Name")}
            notification={notification}
          />
          <RecipientTypeField
            isEditMode={isEditMode}
            isHidden={isHidden("RecipientType")}
            notification={notification}
            onChangeRecipients={onChangeRecipients}
          />
          <PrioritiesField
            isEditMode={isEditMode}
            isHidden={isHidden("Priority")}
            notification={notification}
          />
          <ProviderField
            isEditMode={isEditMode}
            isHidden={isHidden("Provider")}
            notification={notification}
          />
          <ScheduledOnField
            isEditMode={isEditMode}
            isHidden={isHidden("ScheduledOn")}
            notification={notification}
          />
          <IsDraftField
            isEditMode={isEditMode}
            isHidden={isHidden("Status")}
            notification={notification}
          />
          <ImageUrlField
            isEditMode={isEditMode}
            isHidden={isHidden("ImageUrl")}
            notification={notification}
          />
          <NotificationTypeReadOnlyField
            isEditMode={isEditMode}
            isHidden={isHidden("Type")}
            notification={notification}
          />
          <NotificationStatusReadOnlyField
            isEditMode={isEditMode}
            isHidden={isHidden("Status")}
            notification={notification}
          />
          <CreatorReadOnlyField
            notification={notification}
            isHidden={isHidden("CreatorUserFullName")}
          />
          <UrlContentField
            isHidden={isHidden("UrlContent")}
            notification={notification}
          />
          <UrlDescriptionField
            isHidden={isHidden("UrlDescription")}
            notification={notification}
          />
          <TextContent
            isEditMode={isEditMode}
            isHidden={isHidden("TextContent")}
            notification={notification}
          />
          {isEditMode && (
            <div className="form_buttons">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    notification?.Status === NotificationStatus.Sent ||
                    notification?.Status === NotificationStatus.InProgress
                  }
                >
                  {t("BUTTON_SAVE")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleSendNowButton}
                  disabled={
                    notification?.Status === NotificationStatus.Sent ||
                    notification?.Status === NotificationStatus.InProgress
                  }
                >
                  {t("BUTTON_SEND_NOW")}
                </Button>
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};
