import {
  ComponentType,
  IApplicationComponentModel,
  RecordStatus,
  ScreenType,
} from "@xala/common-services";
import {
  Button,
  Checkbox,
  Empty,
  Icon,
  ITableColumnProps,
  ITablePaginationConfig,
  ITableRowSelection,
  Popconfirm,
  TableWithDraggableSorter,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ComponentTypeHelper, PlatformTypeHelper } from "../../../../helpers";
import { WithScreenContextProps } from "../../context";
import { ApplicationScreenAddComponentModal } from "../ApplicationScreenAddComponentModal";
import "./ApplicationScreenComponents.scss";

export interface IApplicationScreenComponentsProps
  extends WithTranslation,
    WithScreenContextProps {
  isLoadingData: boolean;
}

interface IApplicationScreenComponentsState {
  selectedRowKeys: React.Key[];
  addComponentModalVisible: boolean;
}

export class ApplicationScreenComponents extends React.PureComponent<
  IApplicationScreenComponentsProps,
  IApplicationScreenComponentsState
> {
  public state: Readonly<IApplicationScreenComponentsState> = {
    selectedRowKeys: [],
    addComponentModalVisible: false,
  };

  private getColumnsProps(): Array<
    ITableColumnProps<IApplicationComponentModel>
  > {
    const { t } = this.props;

    return [
      {
        key: "ComponentTypeCode",
        dataIndex: "ComponentTypeCode",
        title: t("type", "Type"),
        render: (text: any, row: IApplicationComponentModel) =>
          ComponentTypeHelper.getTag(row.ComponentTypeCode),
      },
      {
        key: "ComponentTitle",
        dataIndex: "ComponentTitle",
        title: t("MODEL_TITLE"),
        render: (_, row: IApplicationComponentModel) => {
          const title = row.Properties?.find(
            (property) => property?.Name === "Title"
          )?.Value?.StringValue;

          return <p>{title}</p>;
        },
      },
      {
        title: t("platform", "Platform"),
        render: (text: any, row: IApplicationComponentModel) =>
          PlatformTypeHelper.getTag(row.PlatformCode),
      },
      {
        dataIndex: "IsVisible",
        title: t("IS_VISIBLE"),
        render: (_, row: IApplicationComponentModel) => {
          return (
            <Checkbox
              defaultChecked={row.IsVisible}
              checked={row.IsVisible}
              disabled
            />
          );
        },
      },
      {
        key: "RemoveAssetFromCollectionAction",
        dataIndex: "RemoveAssetFromCollectionAction",
        title: t("Action", "Action"),
        width: "80px",
        render: (text: any, row: IApplicationComponentModel) => (
          <Popconfirm
            title={t(`Are you sure you want to delete commponent?`)}
            onConfirm={(e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              this.onDeleteComponent(row.Id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_COMPONENT", "Delete component")}
            ></Button>
          </Popconfirm>
        ),
      },
    ];
  }

  public onDeleteComponent = (componentId: number) => {
    const { onComponentDelete } = this.props;

    if (onComponentDelete) {
      onComponentDelete(componentId);
    }
  };

  public onAddComponentClick = () => {
    this.setState({ addComponentModalVisible: true });
  };

  public onAddComponentCancel = () => {
    this.setState({ addComponentModalVisible: false });
  };

  public onAddComponentSuccess = () => {
    this.setState({ addComponentModalVisible: false });
  };

  public onTableRow = (component: IApplicationComponentModel) => {
    const { onComponentSelect } = this.props;
    const self = this;

    return {
      onClick: () => {
        if (onComponentSelect) {
          onComponentSelect(component);
        }

        self.setState({ selectedRowKeys: [component.Id] });
      },
    };
  };

  public onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const { onComponentsChange } = this.props;
    const components = this.getComponents();

    const draggedComponent = components[dragIndex];
    const hoveredComponent = components[hoverIndex];

    let componentsToUpdate: IApplicationComponentModel[] = [
      {
        ...draggedComponent,
        Sequence:
          draggedComponent.Sequence === hoveredComponent.Sequence
            ? hoveredComponent.Sequence + 1
            : hoveredComponent.Sequence,
        RecordStatus:
          draggedComponent.RecordStatus === RecordStatus.Inserted
            ? RecordStatus.Inserted
            : RecordStatus.Updated,
      },
    ];

    // Update components order - required to render list before save
    if (draggedComponent.Sequence > hoveredComponent.Sequence) {
      componentsToUpdate = componentsToUpdate.concat(
        components
          .filter(
            (c) =>
              c.Sequence >= hoveredComponent.Sequence &&
              c.Sequence < draggedComponent.Sequence
          )
          .map((i) => {
            i.Sequence++;
            i.RecordStatus =
              i.RecordStatus === RecordStatus.Inserted
                ? RecordStatus.Inserted
                : RecordStatus.Updated;
            return i;
          })
      );
    } else {
      componentsToUpdate = componentsToUpdate.concat(
        components
          .filter(
            (c) =>
              c.Sequence <= hoveredComponent.Sequence &&
              c.Sequence > draggedComponent.Sequence
          )
          .map((i) => {
            i.Sequence--;
            i.RecordStatus =
              i.RecordStatus === RecordStatus.Inserted
                ? RecordStatus.Inserted
                : RecordStatus.Updated;
            return i;
          })
      );
    }

    componentsToUpdate.reduce(
      (
        previous: IApplicationComponentModel,
        current: IApplicationComponentModel,
        index: number
      ) => {
        if (componentsToUpdate[index].Sequence === previous.Sequence) {
          componentsToUpdate[index].Sequence =
            componentsToUpdate[index].Sequence + 1;
        }
        return current;
      }
    );

    if (onComponentsChange) {
      onComponentsChange(componentsToUpdate);
    }
  };

  componentDidMount() {
    const components = this.getComponents();
    this.props.onComponentSelect?.(components[0]);
  }

  private getComponents() {
    const { screen } = this.props;
    const components = screen?.Components
      ? screen?.Components.filter((component: IApplicationComponentModel) => {
          return (
            component.RecordStatus !== RecordStatus.Deleted &&
            component.ComponentTypeCode !== ComponentType.Properties
          );
        })
      : [];

    components.sort((a, b) => a.Sequence - b.Sequence);

    return components;
  }

  private getPaginationConfig(): ITablePaginationConfig {
    const { t } = this.props;

    return {
      showTotal: (total, range) =>
        t("ITEMS_RANGE_LABEL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      showSizeChanger: true,
      defaultPageSize: 10,
      defaultCurrent: 1,
      pageSizeOptions: ["10", "20", "30", "50"],
    };
  }

  public render() {
    const { screen, isLoadingData, onComponentSelect, t } = this.props;
    const { addComponentModalVisible, selectedRowKeys } = this.state;
    const self = this;

    if (!screen) {
      return <Empty>{t("No screen data")}</Empty>;
    }

    const columns = this.getColumnsProps();
    const components = this.getComponents();

    const rowSelection: ITableRowSelection<IApplicationComponentModel> = {
      onChange: (
        selectedRowKeys: React.Key[],
        selectedRows: IApplicationComponentModel[]
      ) => {
        if (selectedRows.length > 0) {
          const component = selectedRows[0];

          if (onComponentSelect) {
            onComponentSelect(component);
          }
        }
      },
      type: "radio",
      selectedRowKeys: [
        components.findIndex((c) => c.Id == this.props.component?.Id),
      ],
    };

    return (
      <div className="ApplicationScreenComponents">
        <div className="ApplicationScreenComponents__Table-header">
          <div className="title">
            <h1>{t("Components")}</h1>
          </div>
          <div className="actions">
            <Button
              icon={<Icon type="plus" />}
              onClick={this.onAddComponentClick}
              title={t("Add component")}
            />
          </div>
        </div>
        <TableWithDraggableSorter<IApplicationComponentModel>
          dragType="handler"
          columns={columns}
          dataSource={components}
          loading={isLoadingData}
          rowSelection={rowSelection}
          onRow={this.onTableRow}
          onMoveRow={this.onMoveRow}
          pagination={this.getPaginationConfig()}
        />
        <ApplicationScreenAddComponentModal
          screenType={screen.ScreenTypeCode}
          visible={addComponentModalVisible}
          onCancel={this.onAddComponentCancel}
          onSuccess={this.onAddComponentSuccess}
        />
      </div>
    );
  }
}
