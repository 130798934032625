import {
  INotificationRecipientModel,
  INotificationRecipientSearchFilterModel,
  INotificationRecipientListModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";

export class NotificationRecipientsService extends SimpleServiceBase<
  INotificationRecipientModel,
  INotificationRecipientListModel,
  number,
  INotificationRecipientSearchFilterModel
> {
  get url(): string {
    return "/NotificationRecipients";
  }
}
