import { Layout, Content } from "@xala/common-ui";
import React from "react";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router";
import { ROUTES } from "../../constants/routes";
import { Navbar, Sidebar } from "../../components";

interface IAuthenticatedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  component: React.ComponentType<any>;
}

export class AuthenticatedRoute extends React.Component<
  IAuthenticatedRouteProps
> {
  public renderRoute = (props: RouteComponentProps) => {
    const { isAuthenticated, component: Component } = this.props;

    if (!isAuthenticated) {
      const redirectTo = {
        pathname: `${ROUTES.LOGIN}`,
        state: { from: props.location },
      };

      return <Redirect to={redirectTo} />;
    }

    return (
      <Layout className="main">
        <Navbar />
        <Layout>
          <Sidebar />
          <Content>
            <Component {...props} />
          </Content>
        </Layout>
      </Layout>
    );
  };

  public render() {
    const { isAuthenticated, component: Component, ...rest } = this.props;

    return <Route {...rest} render={this.renderRoute} />;
  }
}
