import { Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { HTTP_METHOD } from "../../constants/http";
import {
  ITownsListModel,
  ITownModel,
  ITownsWithChannelsListModel,
  ITownWithChannelsModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class TownsService extends SimpleServiceBase<
  ITownModel,
  ITownsListModel,
  number,
  {}
> {
  public get url(): string {
    return "/Towns";
  }

  public getTowns = (): Observable<ITownsListModel> =>
    new Observable(
      (observer: Observer<ITownModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    ).pipe(
      map((data: ITownModel[]) => {
        const entities = data || [];

        const result: ITownsListModel = {
          Entities: entities,
          TotalCount: entities.length,
        };

        return result;
      })
    );

  public getTownsWithChannels = (): Observable<ITownsWithChannelsListModel> =>
    new Observable(
      (observer: Observer<ITownWithChannelsModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetTownChannels`,
        })
    ).pipe(
      map((data: ITownWithChannelsModel[]) => {
        const entities = data || [];

        const result: ITownsWithChannelsListModel = {
          Entities: entities,
          TotalCount: entities.length,
        };

        return result;
      })
    );

  public deleteTowns = (id: number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete?ids=${id}`,
        })
    );

  public updateTownChannels = (
    data: ITownWithChannelsModel[]
  ): Observable<ITownWithChannelsModel> =>
    new Observable(
      (observer: Observer<ITownWithChannelsModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/UpdateTownChannels`,
        })
    );
}
