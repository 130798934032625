import { ICommonAppState } from "@xala/common-services";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenDesignerContext } from "../../context";
import {
  ITextWidgetComponentPreviewDispatchProps,
  ITextWidgetComponentPreviewOwnProps,
  ITextWidgetComponentPreviewStateProps,
  TextWidgetComponentPreview as TextWidgetComponentPreviewDefinition,
} from "./TextWidgetComponentPreview";

const mapStateToProps = (
  state: ICommonAppState
): ITextWidgetComponentPreviewStateProps => ({});

const mapDispatchToProps = (
  dispatch: Dispatch
): ITextWidgetComponentPreviewDispatchProps => ({});
export const TextWidgetComponentPreview = connect<
  ITextWidgetComponentPreviewStateProps,
  ITextWidgetComponentPreviewDispatchProps,
  ITextWidgetComponentPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withScreenDesignerContext(TextWidgetComponentPreviewDefinition));
