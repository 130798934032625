import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IAssetAgeRestrictionModel,
  ICommonAppState,
  AgeRestrictionService,
  useDataLoader,
  RecordStatus,
  useServiceCaller,
} from "@xala/common-services";
import {
  Button,
  Icon,
  ITableColumnProps,
  NotificationService,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
} from "@xala/common-ui";
import "./DictionaryAgeRestrictionList.scss";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormModal } from "../../../../components/FormModal/FormModal";
import { DictionaryAgeRestrictionForm } from "../DictionaryAgeRestrictionForm/DictionaryAgeRestrictionForm";

const notificationService = NotificationService.getInstance();

const ageRestrictionService = new AgeRestrictionService().promisify();

const defaultAgeRestriction: IAssetAgeRestrictionModel = {
  ValueMin: 0,
};

export const DictionaryAgeRestrictionList = () => {
  const [showAgeRestrictionModal, setShowAgeRestrictionModal] = useState(false);
  const [editableAgeRestriction, setEditableAgeRestriction] = useState<
    IAssetAgeRestrictionModel
  >(defaultAgeRestriction);

  const isFetching = useSelector(
    (state: ICommonAppState) => state.asset.assetAgeRestrictions.isFetching
  );

  const { t } = useTranslation();

  const ageRestrictionLoader = useDataLoader({
    loader: () => ageRestrictionService.select(),
    deps: [],
  });

  const [
    insertAgeRestriction,
    { processing: insertProcessing },
  ] = useServiceCaller(async (ageRestriction: IAssetAgeRestrictionModel) => {
    const result = await ageRestrictionService.insert({
      ...ageRestriction,
      RecordStatus: RecordStatus.Inserted,
    });
    if (result.ok) {
      notificationService.success({
        message: t(
          "DICTIONARY_AGE_RESTRICTION_INSERT_SUCCESS",
          "Age restriction has been added successfully."
        ),
      });
      await ageRestrictionLoader.refresh();
      closeModal();
    } else {
      notificationService.error({
        message: t(
          "DICTIONARY_AGE_RESTRICTION_INSERT_FAILURE",
          "There was an error while adding age restriction."
        ),
        description: result.error?.Message,
      });
    }
  }, []);

  const [deleteAgeRestriction] = useServiceCaller(
    async (ageRestriction: IAssetAgeRestrictionModel) => {
      const result = await ageRestrictionService.delete({
        ...ageRestriction,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        notificationService.success({
          message: t(
            "DICTIONARY_AGE_RESTRICTION_DELETE_SUCCESS",
            "Age restriction has been deleted successfully."
          ),
        });
        await ageRestrictionLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t(
            "DICTIONARY_AGE_RESTRICTION_DELETE_FAILURE",
            "There was an error while deleting age restriction."
          ),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const getColumnsProps = (): Array<
    ITableColumnProps<IAssetAgeRestrictionModel>
  > => {
    return [
      {
        key: "Value",
        dataIndex: "ValueMin",
        title: t("MODEL_VALUE"),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IAssetAgeRestrictionModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              deleteAgeRestriction(row);
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const closeModal = () => {
    setShowAgeRestrictionModal(false);
    setEditableAgeRestriction(defaultAgeRestriction);
  };

  const onAddNewAgeRestrictionClick = () => {
    setShowAgeRestrictionModal(true);
  };

  const onRefreshClick = () => {
    ageRestrictionLoader.refresh();
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewAgeRestrictionClick}
          title={t("DICTIONARY_AGE_RESTRICTION_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title="Refresh data"
        />
      </>
    );
  };

  return (
    <div>
      <PageContent>
        <PageHeader
          title={t(
            "DictionaryAgeRestrictionListTitle",
            "Video Age Restrictions"
          )}
          extra={getExtraButtons()}
        />
        <Table<IAssetAgeRestrictionModel>
          rowKey={(row) => row.ValueMin}
          columns={getColumnsProps()}
          dataSource={ageRestrictionLoader.data}
          loading={isFetching}
          pagination={false}
        />
      </PageContent>
      <FormModal
        isLoading={insertProcessing}
        isVisible={showAgeRestrictionModal}
        isNewForm={true}
        isDeleteButtonEnabled={false}
        createFormTitle={t(
          "DICTIONARY_AGE_RESTRICTION_MODAL_NEW",
          "New age restriction"
        )}
        modalClassName="DictionaryAgeRestrictionModal"
        submitFormName="DictionaryAgeRestrictionForm"
        onCloseModal={closeModal}
      >
        <DictionaryAgeRestrictionForm
          ageRestriction={editableAgeRestriction}
          insertAgeRestriction={insertAgeRestriction}
          isProcessing={insertProcessing}
        />
      </FormModal>
    </div>
  );
};
