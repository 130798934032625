import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IErrorModel, IUserSettingsModel } from "../../models";
import { UserSettingsService } from "../../services";
import {
  getUserSettingsByIdFailure,
  getUserSettingsByIdSuccess,
  updateUserSettingsFailure,
  updateUserSettingsSuccess,
} from "./actions";
import * as Consts from "./consts";
import { IGetUserSettingsByIdAction, IUpdateUserSettingsAction } from "./types";

const userSettingsService: UserSettingsService = new UserSettingsService();

const getUserSettingsByIdEpic = (
  action$: ActionsObservable<IGetUserSettingsByIdAction>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_SETTINGS_BY_ID),
    switchMap((action) =>
      userSettingsService.getUserSettingsById(action.payload).pipe(
        map((data: IUserSettingsModel) => {
          return getUserSettingsByIdSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getUserSettingsByIdFailure(error))
        )
      )
    )
  );

const updateUserSettingsEpic = (
  action$: ActionsObservable<IUpdateUserSettingsAction>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_USER_SETTINGS),
    switchMap((action) =>
      userSettingsService.updateUserSettings(action.payload).pipe(
        map((data: IUserSettingsModel) => {
          return updateUserSettingsSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateUserSettingsFailure(error)))
      )
    )
  );

export const userSettingsEpics = [
  getUserSettingsByIdEpic,
  updateUserSettingsEpic,
];
