import React, { useRef } from "react";
import { PageContent } from "@xala/common-ui";
import { SalesRevenueChart } from "./SalesRevenueChart";
import { useSalesRevenueDataProvider } from "../../hooks";
import { SalesRevenueHeader } from "./SalesRevenueHeader";
import {
  SalesRevenuePaymentTypeSalesData,
  SalesRevenueSalesDataCard,
} from "./SalesRevenueCards";
import "./SalesRevenue.scss";

export const SalesRevenue = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    data,
    filter,
    loading,
    refreshData,
    setFilter,
    resetFilter,
  } = useSalesRevenueDataProvider();

  return (
    <PageContent ref={ref}>
      <SalesRevenueHeader
        filter={filter}
        setFilter={setFilter}
        refreshData={refreshData}
        resetFilter={resetFilter}
      />

      <SalesRevenueChart
        data={data?.Results}
        isLoading={loading}
        aggregationPeriod={filter.AggregationPeriod}
      />

      <div className="SalesRevenue__cards-container">
        <SalesRevenueSalesDataCard data={data} isLoading={loading} />
        <SalesRevenuePaymentTypeSalesData data={data} isLoading={loading} />
      </div>
    </PageContent>
  );
};
