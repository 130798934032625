import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@xala/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationFooterPropertyModel extends ComponentPropertyModel {
  Columns?: number;

  ItemsAlignment?: string;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  hasBackgroundUrl(): boolean {
    return true;
  }
  hasIconUrl(): boolean {
    return false;
  }
  hasImageUrl(): boolean {
    return false;
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let columnsProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Columns"
    );

    if (!columnsProperty) {
      this.onPropertyChange("Columns", component);
    }

    let itemsAlignmentProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ItemsAlignment"
    );

    if (!itemsAlignmentProperty) {
      this.onPropertyChange("ItemsAlignment", component);
    }

    let BackgroundUrlProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (!BackgroundUrlProperty) {
      this.onPropertyChange("BackgroundUrl", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let columnsProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Columns"
    );

    if (columnsProperty && columnsProperty.Value) {
      this.Columns = columnsProperty.Value.NumericValue;
    }

    let itemsAlignmentProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ItemsAlignment"
    );

    if (itemsAlignmentProperty && itemsAlignmentProperty.Value) {
      this.ItemsAlignment = itemsAlignmentProperty.Value.StringValue;
    }

    let BackgroundUrlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "BackgroundUrl"
    );

    if (BackgroundUrlProperty && BackgroundUrlProperty.Value) {
      this.BackgroundUrl = BackgroundUrlProperty.Value.StringValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Columns":
        property.PropertyType = PropertyType.Numeric;
        property.IsEnumerable = false;
        break;
      case "ItemsAlignment":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "BackgroundUrl":
        property.PropertyType = PropertyType.Link;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Columns":
        if (property.Value) {
          property.Value.NumericValue = this.Columns;
        }
        break;
      case "ItemsAlignment":
        if (property.Value) {
          property.Value.StringValue = this.ItemsAlignment;
        }
        break;
      case "BackgroundUrl":
        if (property.Value) {
          property.Value.StringValue = this.BackgroundUrl;
        }
        break;
    }
  }
}
