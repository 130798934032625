import {
  ActionType,
  ActionTypeHelper,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
  ScreenType,
  ScreenTypeHelper,
} from "@xala/common-services";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ActionPropertyModel extends ComponentPropertyModel {
  ActionType?: ActionType;

  ScreenType?: ScreenType;

  ScreenId?: number;

  ScreenName?: string;

  Url?: string;

  Email?: string;

  PhoneNo?: string;

  hasBackgroundUrl(): boolean {
    return false;
  }
  hasIconUrl(): boolean {
    return false;
  }
  hasImageUrl(): boolean {
    return false;
  }

  setProperties(component: IApplicationComponentModel): void {
    let actionTypeProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ActionType"
    );

    if (actionTypeProperty && actionTypeProperty.Value) {
      this.ActionType = actionTypeProperty.Value.StringValue
        ? ActionTypeHelper.getValue(actionTypeProperty.Value.StringValue)
        : undefined;
    }

    let screenTypeProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ScreenTypeCode"
    );

    if (screenTypeProperty && screenTypeProperty.Value) {
      this.ScreenType = ScreenTypeHelper.getValue(
        screenTypeProperty.Value.StringValue
      );
    }

    let screenIdProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ScreenId"
    );

    if (screenIdProperty && screenIdProperty.Value) {
      this.ScreenId = screenIdProperty.Value.IntegerValue;
    }

    let screenNameProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "ScreenName"
    );

    if (screenNameProperty && screenNameProperty.Value) {
      this.ScreenName = screenNameProperty.Value.StringValue;
    }

    let urlProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Url"
    );

    if (urlProperty && urlProperty.Value) {
      this.Url = urlProperty.Value.StringValue;
    }

    let emailProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Email"
    );

    if (emailProperty && emailProperty.Value) {
      this.Email = emailProperty.Value.StringValue;
    }

    let phoneNoProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "PhoneNo"
    );

    if (phoneNoProperty && phoneNoProperty.Value) {
      this.PhoneNo = phoneNoProperty.Value.StringValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "ActionType":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "ScreenTypeCode":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "ScreenId":
        property.PropertyType = PropertyType.Integer;
        property.IsEnumerable = false;
        break;
      case "ScreenName":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "Url":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "Email":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "PhoneNo":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "ActionType":
        if (property.Value) {
          property.Value.StringValue = ActionTypeHelper.getStringValue(
            this.ActionType
          );
        }
        break;
      case "ScreenTypeCode":
        if (property.Value) {
          property.Value.StringValue = ScreenTypeHelper.getStringValue(
            this.ScreenType
          );
        }
        break;
      case "ScreenId":
        if (property.Value) {
          property.Value.IntegerValue = this.ScreenId;
        }
        break;
      case "ScreenName":
        if (property.Value) {
          property.Value.StringValue = this.ScreenName;
        }
        break;
      case "Url":
        if (property.Value) {
          property.Value.StringValue = this.Url;
        }
        break;
      case "Email":
        if (property.Value) {
          property.Value.StringValue = this.Email;
        }
        break;
      case "PhoneNo":
        if (property.Value) {
          property.Value.StringValue = this.PhoneNo;
        }
        break;
    }
  }
}
