import { IErrorModel, ITownsListModel } from "../../models";
import * as Consts from "./consts";
import {
  IGetTownsAction,
  IGetTownsFailureAction,
  IGetTownsSuccessAction,
} from "./types";

export const getTowns = (): IGetTownsAction => {
  return {
    type: Consts.GET_TOWNS,
  };
};

export const getTownsSuccess = (
  data: ITownsListModel
): IGetTownsSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_TOWNS_SUCCESS,
  };
};

export const getTownsFailure = (
  error?: IErrorModel
): IGetTownsFailureAction => {
  return {
    error,
    type: Consts.GET_TOWNS_FAILURE,
  };
};

export const Actions = {
  getTowns,
};
