import { BaseModel } from "../Common";

export class CountryModel extends BaseModel {
  public Code?: string;

  public Flag?: string;

  public Guid?: string;

  public Name?: string;

  public PhoneMaxLength?: number;

  public PhoneMinLength?: number;

  public PhonePrefix?: string;

  public Sequence?: number;

  public UpToDate?: boolean;
}
