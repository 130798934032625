import React, { memo, ReactElement, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./StackedBarChart2.scss";
import { Card } from "../Card";
import { Switch } from "../Switch";
import { Text } from "../Typography";
import { useTranslation } from "react-i18next";
import { Row } from "../Row";
import { Form } from "antd";
import { FormItemProps } from "antd/lib/form";
import cx from "classnames";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type TStackedBarChart2Options = ChartOptions<"bar">;
export interface IStackedBarChart2DataSet<T> {
  label?: string;
  data: T[];
  backgroundColor?: string;
}

export interface IStackedBarChart2Data<T> {
  labels: string[];
  datasets: IStackedBarChart2DataSet<T>[];
}

export interface IStackedBarChart2<T> {
  title?: string;
  data?: IStackedBarChart2Data<T>;
  options?: TStackedBarChart2Options;
  showToolbar?: boolean;
  customToolbarControls?: ReactElement<typeof StackedBarChart2Control>[];
}

export const StackedBarChart2Control = memo(
  ({ className, ...props }: FormItemProps) => {
    return (
      <Form.Item
        {...props}
        className={cx("StackedBarChart2__control", className)}
      />
    );
  }
);

export const getRandomColorHex = () => {
  var hex = "0123456789ABCDEF",
    color = "#";
  for (var i = 1; i <= 6; i++) {
    color += hex[Math.floor(Math.random() * 16)];
  }
  return color;
};

export function StackedBarChart2<T>({
  title,
  data,
  options: propsOptions,
  showToolbar = false,
  customToolbarControls = [],
}: IStackedBarChart2<T>) {
  const [showLegend, setShowLegend] = useState(false);
  const { t } = useTranslation();
  const options: TStackedBarChart2Options = useMemo(
    () => ({
      plugins: {
        title: {
          display: true,
          text: title || "Stacked Bar Chart 2",
        },
        legend: {
          display: showLegend,
        },
        zoom: {
          // This should be zoom not plugins
          pan: {
            enabled: true,
            mode: "x",
          },
          limits: {
            x: { min: 5, max: 7 },
          },
          zoom: {
            pan: {
              enabled: true,
            },
          },
        },
        scrollbar: {
          visible: true,
        },
      },
      responsive: true,
      indexAxis: "x",
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      },
      ...(propsOptions || {}),
    }),
    [title, propsOptions, showLegend]
  );
  if (!data) {
    return null;
  }

  const chart = <Bar options={options} data={data} />;
  return showToolbar ? (
    <div className="StackedBarChart2">
      {chart}
      <Card>
        <div className="StackedBarChart2__toolbar">
          <StackedBarChart2Control
            label={t("STACKED_BAR_CHART_LEGEND")}
            className="StackedBarChart2__control"
          >
            <Switch
              checked={showLegend}
              onChange={(val) => setShowLegend(val)}
            />
          </StackedBarChart2Control>
          {customToolbarControls}
        </div>
      </Card>
    </div>
  ) : (
    chart
  );
}
