export const formValues = {
  assetSortData: [
    {
      name: "Access Type",
      key: "a.[AssetAccessTypeCode]",
    },
    {
      name: "Type",
      key: "a.[AssetTypeCode]",
    },
    {
      name: "Age Restriction Min Value",
      key: "a.[AssetAgeRestrictionValueMin]",
    },
    {
      name: "Title",
      key: "a.[Title]",
    },
    {
      name: "Description",
      key: "a.[Description]",
    },
    {
      name: "Year",
      key: "a.[Year]",
    },
    {
      name: "Duration",
      key: "a.[DurationMiliseconds]",
    },
    {
      name: "Parent Asset Id",
      key: "a.[ParentAssetId]",
    },
    {
      name: "Order In Parent",
      key: "a.[OrderInParent]",
    },
    {
      name: "Available From",
      key: "a.[AvailableFrom]",
    },
    {
      name: "Available To",
      key: "a.[AvailableTo]",
    },
    {
      name: "Start Date Time",
      key: "a.[StartDateTime]",
    },
    {
      name: "End Date Time",
      key: "a.[EndDateTime]",
    },
    {
      name: "Creator User Id",
      key: "a.[CreatorUserId]",
    },
    {
      name: "Is Premium",
      key: "a.[IsPremium]",
    },
    {
      name: "Price",
      key: "a.[Price]",
    },
    {
      name: "Currency Id",
      key: "a.[CurrencyId]",
    },
    {
      name: "Created",
      key: "a.[Created]",
    },
  ],
  sortOptions: [
    {
      name: "Ascending",
      key: "ASC",
    },
    {
      name: "Descending",
      key: "DESC",
    },
  ],
  assetPaymentOptions: [
    {
      name: "Any",
      key: "",
    },
    {
      name: "Free",
      key: "FREE",
    },
    {
      name: "Premium",
      key: "PREMIUM",
    },
  ],
  assetTimeOptions: [
    {
      name: "Any",
      key: "ANY",
    },
    {
      name: "Past",
      key: "PAST",
    },
    {
      name: "Upcoming",
      key: "UPCOMING",
    },
  ],
};

export const formLayouts = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      xl: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      xl: { span: 18 },
    },
  },
  tailFormItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
      xl: {
        span: 18,
        offset: 6,
      },
    },
  },
};
