import { IResourceModel, RecordStatus } from "@xala/common-services";
import {
  Button,
  Col,
  Dragger,
  Form,
  Icon,
  IFormValues,
  Input,
  Link,
  PreviewImage,
  Row,
  Switch,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import * as TranslationsModule from "../../../../../Translations";
import { ApplicationFooterItemPropertyModel } from "../../models";
import { ActionProperties } from "../ActionProperties";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";

export interface IApplicationFooterItemPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IApplicationFooterItemPropertiesDispatchProps {}

export interface IApplicationFooterItemPropertiesOwnProps {}

export interface IApplicationFooterItemPropertiesProps
  extends IApplicationFooterItemPropertiesStateProps,
    IApplicationFooterItemPropertiesDispatchProps,
    IApplicationFooterItemPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationFooterItemPropertiesState
  extends IComponentPropertiesBaseState {
  translationsBrowserVisible: boolean;
}

export class ApplicationFooterItemProperties extends ComponentPropertiesBase<
  IApplicationFooterItemPropertiesProps,
  IApplicationFooterItemPropertiesState,
  ApplicationFooterItemPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IApplicationFooterItemPropertiesState> = {
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): ApplicationFooterItemPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationFooterItemPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onIsVisibleChange = () => {
    const { component, onComponentChange } = this.props;

    const updatedComponent = {
      ...component,
      IsVisible: !component.IsVisible,
      RecordStatus: RecordStatus.Updated,
    };

    if (onComponentChange) {
      onComponentChange(updatedComponent);
    }
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("SELECT_TRANSLATION_KEY", "Select translation key")}</label>
    );

    return (
      <Form.Item
        name="TitleTranslationKey"
        label={t("TITLE_TRANSLATION_KEY", "Title Key")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("Title")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("Enter title")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public renderActionProperty = () => {
    const { component, onComponentChange } = this.props;

    return (
      <ActionProperties
        key={`Action-${component.Id}`}
        component={component}
        onComponentChange={onComponentChange}
      />
    );
  };

  public renderIconUrlProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="IconUrl"
        label={t("Icon Url")}
        initialValue={this.properties.IconUrl}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("https://")}
          onChange={(e) => {
            this.properties.IconUrl = e.target.value;
            this.onPropertyChange("IconUrl");
          }}
        />
      </Form.Item>
    );
  };

  public renderFileProperty = () => {
    const { t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form.Item
        key="FileValue"
        name="FileValue"
        valuePropName="FileValue"
        getValueFromEvent={this.onImageChange}
        {...formItemLayout}
        style={{ justifyContent: "flex-end" }}
      >
        <Dragger
          name="FileValue"
          accept="image/*"
          multiple={false}
          beforeUpload={this.beforeUpload}
          fileList={[]}
          withPreview={true}
          maxCount={1}
        >
          {(previewSrc: string) => {
            return (
              <>
                <p className="ant-upload-drag-icon">
                  <PreviewImage
                    src={previewSrc || this.properties.IconUrl}
                    height={100}
                    width={100}
                    placeholderIconType={"inbox"}
                  />
                </p>
                <p className="ant-upload-text">
                  {t(
                    "DRAG_AND_DROP",
                    "Click or drag a file to this area to upload."
                  )}
                </p>
              </>
            );
          }}
        </Dragger>
      </Form.Item>
    );
  };

  public renderIsVisibleProperty = () => {
    const { t, component } = this.props;

    return (
      <Form.Item name="IsVisible" label={t("IS_VISIBLE")}>
        <Switch
          defaultChecked={component.IsVisible}
          onChange={() => this.onIsVisibleChange()}
        />
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ApplicationFooterItemProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderIconUrlProperty()}
            {this.renderFileProperty()}
            {this.renderActionProperty()}
            {this.renderIsVisibleProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
