import { IAssetCollectionTypesModel } from "../../models";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base/PromisifiableBase";

export class AssetCollectionTypesService extends PromisifiableBase {
  get url(): string {
    return "/AssetCollectionsTypes";
  }

  public select = (): Observable<IAssetCollectionTypesModel[]> =>
    new Observable(
      (observer: Observer<IAssetCollectionTypesModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
