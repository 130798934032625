import { IApplicationComponentTypeModel } from "@xala/common-services";
import {
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Table,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./ApplicationComponentsTypesList.scss";

export interface IApplicationComponentsTypesListProps extends WithTranslation {
  componentsTypes: IApplicationComponentTypeModel[];
  isLoadingData: boolean;
  selectComponentsTypes: ActionCreator<Action>;
}

interface IApplicationComponentsTypesListState {
  filters?: ITableFilter;
}

export class ApplicationComponentsTypesList extends React.PureComponent<
  IApplicationComponentsTypesListProps,
  IApplicationComponentsTypesListState
> {
  public state: Readonly<IApplicationComponentsTypesListState> = {};

  public componentDidMount() {
    const { selectComponentsTypes } = this.props;

    selectComponentsTypes();
  }

  private getColumnsProps(): Array<
    ITableColumnProps<IApplicationComponentTypeModel>
  > {
    const { t } = this.props;
    const { filters } = this.state;

    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("Code", "Code"),
        filteredValue: filters?.Code,
        onFilter: (value, record) => record.Code.includes(`${value}`),
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("Name", "Name"),
        filteredValue: filters?.Name,
        onFilter: (value, record) => record.Name.includes(`${value}`),
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("Description", "Description"),
        filteredValue: filters?.Description,
        onFilter: (value, record) =>
          record.Description?.includes(`${value}`) || false,
        ellipsis: true,
      },
    ];
  }

  public onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    this.setState({ filters });
  };

  public render() {
    const { componentsTypes, isLoadingData, t } = this.props;

    const columns = this.getColumnsProps();

    return (
      <PageContent>
        <PageHeader
          title={t(
            "ApplicationComponentsTypesListTitle",
            "Application Components Types List"
          )}
        />
        <Table<IApplicationComponentTypeModel>
          rowKey={(row) => row.Code}
          columns={columns}
          dataSource={componentsTypes}
          loading={isLoadingData}
          pagination={false}
          onChange={this.onTableChange}
        />
      </PageContent>
    );
  }
}
