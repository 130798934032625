import {
  AdvertisementStore,
  IAdvertisementBlockModel,
  ICommonAppState,
  IStateModel,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AdvertisementBoardDetails as AdvertisementBoardDetailsDefinition } from "./AdvertisementBoardDetails";

const mapStateToProps = (state: ICommonAppState) => ({
  actionType: state.advertisement.actionType,
  advertisementBlock: state.advertisement.advertisementBlock,
  advertisementBoard: state.advertisement.advertisementBoard,
  isProcessingData: state.advertisement.isProcessingData,
  isLoadingData: state.advertisement.isLoadingData,
  error: state.advertisement.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdvertisementBoard: (id: number) => {
    return dispatch(AdvertisementStore.Actions.getAdvertisementBoard(id));
  },
  updateAdvertisementBlockWithDetails: (
    data: IStateModel<IAdvertisementBlockModel>
  ) => {
    return dispatch(
      AdvertisementStore.Actions.updateAdvertisementBlockWithDetails(data)
    );
  },
});

export const AdvertisementBoardDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBoardDetailsDefinition));
