import React from "react";
import { Choose, ChooseOption, Form, required } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import {
  INotificationProviderModel,
  NotificationProvidersService,
  useDataLoader,
} from "@xala/common-services";
import { INotificationField } from "./INotificationField";

const notificationProviderService = new NotificationProvidersService().promisify();

export const ProviderField = ({
  notification,
  isEditMode,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();
  const notificationsProviderLoader = useDataLoader({
    loader: () => notificationProviderService.select(),
    deps: [],
  });

  if (isHidden) {
    return null;
  }

  const providers = notificationsProviderLoader.data?.filter((el) => {
    if (el.Code !== "SIGNALR") {
      return el;
    }
  });

  return (
    <Form.Item
      name="Provider"
      label={t("NOTIFICATION_PROVIDER_FIELD")}
      key="Provider"
      initialValue={isEditMode ? notification?.Provider || "" : ""}
      rules={[required()]}
    >
      <Choose
        placeholder={t("NOTIFICATION_PROVIDER_INPUT_FIELD")}
        key="providerInput"
      >
        {providers?.map((type: INotificationProviderModel) => (
          <ChooseOption key={type.Code} value={type.Code}>
            {type.DisplayName}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );
};
