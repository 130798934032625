import { CommonStore, ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenContext } from "../../../../context";
import { withScreenDesignerContext } from "../../context";
import { DeviceFrame as DeviceFrameDefinition } from "./DeviceFrame";

const mapStateToProps = (state: ICommonAppState) => ({
  platforms: state.common.platforms,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectPlatforms: () => {
    return dispatch(CommonStore.Actions.selectPlatforms());
  },
});

export const DeviceFrame = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withScreenDesignerContext(withScreenContext(DeviceFrameDefinition))
  )
);
