import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationFooterProperties as ApplicationFooterPropertiesDefinition,
  IApplicationFooterPropertiesDispatchProps,
  IApplicationFooterPropertiesOwnProps,
  IApplicationFooterPropertiesStateProps,
} from "./ApplicationFooterProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationFooterPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationFooterPropertiesDispatchProps => ({});

export const ApplicationFooterProperties = connect<
  IApplicationFooterPropertiesStateProps,
  IApplicationFooterPropertiesDispatchProps,
  IApplicationFooterPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationFooterPropertiesDefinition));
