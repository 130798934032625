import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationBannerProperties as ApplicationBannerPropertiesDefinition,
  IApplicationBannerPropertiesDispatchProps,
  IApplicationBannerPropertiesOwnProps,
  IApplicationBannerPropertiesStateProps,
} from "./ApplicationBannerProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationBannerPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationBannerPropertiesDispatchProps => ({});

export const ApplicationBannerProperties = connect<
  IApplicationBannerPropertiesStateProps,
  IApplicationBannerPropertiesDispatchProps,
  IApplicationBannerPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationBannerPropertiesDefinition));
