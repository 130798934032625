import {
  IChangePasswordModel,
  ICommonAppState,
  UserStore,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { UserResetPasswordModal as UserResetPasswordModalDefinition } from "./UserResetPasswordModal";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.user.actionType,
    isProcessingData: state.user.isProcessingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetUserPassword: (data: IChangePasswordModel) => {
    return dispatch(UserStore.Actions.changeUserPassword(data));
  },
});

export const UserResetPasswordModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserResetPasswordModalDefinition));
