import React from "react";
import { Col, Row, SectionGrid, SectionGridItem } from "@xala/common-ui";
import "./AuthContainer.scss";
import { Link } from "react-router-dom";
import resources from "../../../../resources";

interface AuthContainerProps {
  error?: string;
  footerLink?: {
    href: string;
    title: React.ReactNode;
  };
}

export const AuthContainer: React.FC<AuthContainerProps> = ({
  children,
  footerLink,
  error,
}) => (
  <div className="AuthContainer">
    <div className="AuthContainer__Content">
      <SectionGrid>
        <SectionGridItem>
          <Row direction="column" style={{ height: "100%" }}>
            <Col>
              <div className="AuthContainer__Logo">
                <img src={resources.logo} alt="logo" />
              </div>
              <h3 className="AuthContainer__Error">{error}</h3>
            </Col>
            <Col flex="1 1 auto">{children}</Col>
            <Col>
              <Row justify="space-between" align="middle">
                <Col>{process.env.REACT_APP_VERSION}</Col>
                <Col>
                  {footerLink && (
                    <Link
                      className="AuthContainer__FooterLink"
                      to={footerLink.href}
                    >
                      {footerLink.title}
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </SectionGridItem>
      </SectionGrid>
    </div>
  </div>
);
