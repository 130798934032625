import {
  AssetImageType,
  AssetStore,
  IAssetImageModel,
  ICollectionAssetsSearchFilterModel,
  ICommonAppState,
} from "@xala/common-services";
import {
  Card,
  Col,
  Empty,
  Modal,
  Pagination,
  Row,
  Spin,
} from "@xala/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import resources from "../../../../resources";
import "./AssetCollectionAssetsGrid.scss";

const assetCollectionSelector = (state: ICommonAppState) => state.asset;

export interface IAssetCollectionAssetsGridProps {
  isActive?: boolean;
}

export const AssetCollectionAssetsGrid: React.FC<IAssetCollectionAssetsGridProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { isActive } = props;
  const dispatch = useDispatch();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>("");
  const { assetCollectionAssets } = useSelector(assetCollectionSelector);
  const assetCollectionAssetsFilter: ICollectionAssetsSearchFilterModel = {
    ...assetCollectionAssets.Filter,
    PageSize: assetCollectionAssets.Filter?.PageSize ?? 12,
    PageNumber: assetCollectionAssets.Filter?.PageNumber ?? 1,
    IncludeCount: true,
    AvailableNow: true,
    Id: +id,
  };

  const searchAssetsByAssetCollectionId = useCallback(
    (filter: ICollectionAssetsSearchFilterModel) => {
      return dispatch(
        AssetStore.Actions.searchAssetsByAssetCollectionId(filter)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isActive) {
      return;
    }

    searchAssetsByAssetCollectionId(assetCollectionAssetsFilter);
  }, [isActive]);

  const handleClickPreviewImage = (url: string | undefined) => {
    if (!url) {
      return;
    }

    setPreviewVisible(true);
    setPreviewImageUrl(url);
  };

  return (
    <div className="AssetsCollectionPreview">
      <Spin spinning={assetCollectionAssets.IsLoading}>
        <Row gutter={[16, 16]} style={{ minHeight: "30vh" }}>
          {!assetCollectionAssets.IsLoading &&
            assetCollectionAssets.Entities.length === 0 && (
              <Empty style={{ width: "100%", paddingTop: "24px" }} />
            )}
          {assetCollectionAssets.Entities.map((asset) => {
            let imageUrl: string | undefined = "";

            if (asset.Images) {
              const assetFrameImage = asset.Images.find(
                (row: IAssetImageModel) =>
                  row.AssetImageTypeCode === AssetImageType.Frame
              );

              if (assetFrameImage) {
                imageUrl = assetFrameImage.Url;
              }
            }

            if (!imageUrl) {
              imageUrl = resources.listItemFramePlaceholder;
            }

            return (
              <Col span={6} key={asset.Guid}>
                <Card
                  onClick={() => handleClickPreviewImage(imageUrl)}
                  key={asset.Guid}
                  hoverable={true}
                  cover={
                    <div
                      style={{ position: "relative", paddingBottom: "56.2%" }}
                    >
                      <img
                        style={{
                          position: "absolute",
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                        alt={asset.Title}
                        src={imageUrl}
                      />
                    </div>
                  }
                >
                  <Card.Meta title={asset.Title} key={asset.Guid} />
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col style={{ display: "block", width: "100%", textAlign: "right" }}>
            <Pagination
              defaultCurrent={1}
              total={assetCollectionAssets.TotalCount}
              showTotal={(total, range) =>
                t("ITEMS_RANGE_LABEL", {
                  rangeFrom: range[0],
                  rangeTo: range[1],
                  total: total,
                })
              }
              pageSize={assetCollectionAssetsFilter.PageSize}
              current={assetCollectionAssetsFilter.PageNumber}
              defaultPageSize={12}
              pageSizeOptions={["12", "24", "48", "96"]}
              showSizeChanger
              onShowSizeChange={(current, pageSize) => {
                assetCollectionAssetsFilter.PageSize = pageSize;
                assetCollectionAssetsFilter.PageNumber = current;
                searchAssetsByAssetCollectionId(assetCollectionAssetsFilter);
              }}
              onChange={(page: number, pageSize?: number) => {
                assetCollectionAssetsFilter.PageSize = pageSize;
                assetCollectionAssetsFilter.PageNumber = page;
                searchAssetsByAssetCollectionId(assetCollectionAssetsFilter);
              }}
            />
          </Col>
        </Row>
      </Spin>
      <Modal
        visible={previewVisible}
        footer={null}
        preview
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="" src={previewImageUrl} />
      </Modal>
    </div>
  );
};
