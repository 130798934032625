import React from "react";

export const Notifications = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.177 15.1466L13.9219 13.8915V9.02662C13.9219 6.03959 12.3262 3.53905 9.54353 2.87743V2.21581C9.54353 1.40824 8.89164 0.756348 8.08407 0.756348C7.27651 0.756348 6.62461 1.40824 6.62461 2.21581V2.87743C3.83218 3.53905 2.24624 6.02986 2.24624 9.02662V13.8915L0.991101 15.1466C0.378128 15.7596 0.806236 16.8104 1.67218 16.8104H14.4862C15.3619 16.8104 15.79 15.7596 15.177 15.1466ZM11.976 14.8645H4.19218V9.02662C4.19218 6.61365 5.66137 4.64824 8.08407 4.64824C10.5068 4.64824 11.976 6.61365 11.976 9.02662V14.8645ZM10.03 17.7834C10.03 18.8536 9.15434 19.7293 8.08407 19.7293C7.00407 19.7293 6.13813 18.8536 6.13813 17.7834H10.03Z" />
  </svg>
);
