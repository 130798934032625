import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@xala/common-services";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenDesignerContext } from "../../context";
import {
  IListComponentPreviewDispatchProps,
  IListComponentPreviewOwnProps,
  IListComponentPreviewStateProps,
  ListComponentPreview as ListComponentPreviewDefinition,
} from "./ListComponentPreview";

const mapStateToProps = (
  state: ICommonAppState
): IListComponentPreviewStateProps => {
  return {
    sources: state.applicationConfiguration.sources,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IListComponentPreviewDispatchProps => ({
  getDataSource: (sourceId: number) => {
    return dispatch({ type: "nothing" });
  },
});

export const ListComponentPreview = connect<
  IListComponentPreviewStateProps,
  IListComponentPreviewDispatchProps,
  IListComponentPreviewOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withScreenDesignerContext(ListComponentPreviewDefinition));
