import React from "react";
import { DatePicker, Form } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";
import { TimeHelper } from "@xala/common-services";

export const ScheduledOnField = ({
  notification,
  isEditMode,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="ScheduledOn"
      label={t("NOTIFICATION_SCHEDULEDON_FIELD")}
      key="ScheduledOn"
      initialValue={
        isEditMode && notification?.ScheduledOn
          ? TimeHelper.parse(notification?.ScheduledOn)
          : undefined
      }
    >
      <DatePicker
        showTime={true}
        placeholder={t("NOTIFICATION_SCHEDULEDON_INPUT_FIELD")}
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};
