import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { IUserSettingsModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class UserSettingsService {
  public get url(): string {
    return "/UserSettings";
  }

  public getUserSettingsById = (id: number): Observable<IUserSettingsModel> =>
    new Observable(
      (observer: Observer<IUserSettingsModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetByUserId?UserId=${id}`,
        })
    );

  public updateUserSettings = (
    data: IUserSettingsModel
  ): Observable<IUserSettingsModel> =>
    new Observable(
      (observer: Observer<IUserSettingsModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );
}
