import React from "react";
import { Form, Input, required } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";

export const NameField = ({
  notification,
  isEditMode,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Name"
      label={t("MODEL_NAME")}
      key="Name"
      initialValue={isEditMode ? notification?.Name || "" : ""}
      rules={[required()]}
    >
      <Input placeholder={t("NOTIFICATION_NAME_INPUT_FIELD")} />
    </Form.Item>
  );
};
