import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Choose, Spin } from "@xala/common-ui";
import { usePaymentAssetsDataProvider } from "../../hooks";

interface IPaymentAssetSelectProps {
  onChange?: (newValue: number[]) => void;
  style?: any;
}

export const PaymentAssetSelect = memo(
  ({ onChange, style }: IPaymentAssetSelectProps) => {
    const [value, setValue] = useState<any>([]);
    const { t } = useTranslation();
    const { assets, loading, setTextSearch } = usePaymentAssetsDataProvider();

    const onValueChange = (newValue: any) => {
      if (onChange) {
        onChange(newValue.map((it: any) => it.value));
        setValue(newValue);
      }
    };

    return (
      <Choose
        mode="multiple"
        labelInValue={true}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={setTextSearch}
        value={value}
        style={{ width: "100%", ...style }}
        placeholder={t("SALES_REVENUE_ASSETS_PLACEHOLDER")}
        onChange={onValueChange}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : null}
        options={assets}
      />
    );
  }
);
