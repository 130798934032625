import {
  IApplicationConfigurationModel,
  IStateModel,
  TimeHelper,
} from "@xala/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Input,
  Row,
  SectionGrid,
  SectionGridItem,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./ApplicationConfigurationDetailsForm.scss";

export interface IApplicationConfigurationDetailsFormProps
  extends WithTranslation {
  actionType?: string;
  configuration: IStateModel<IApplicationConfigurationModel>;
  updateConfiguration: ActionCreator<Action>;
  isActive: boolean;
}

export class ApplicationConfigurationDetailsForm extends React.PureComponent<
  IApplicationConfigurationDetailsFormProps
> {
  public static defaultProps = {
    isActive: false,
  };

  public onFinish = (values: IFormValues) => {
    const { configuration, updateConfiguration } = this.props;

    if (!configuration?.Data) {
      return;
    }

    configuration.Data.Name = values.Name;

    updateConfiguration(configuration.Data);
  };

  public renderNameField = () => {
    const { t, configuration } = this.props;

    return (
      <Form.Item
        name="Name"
        label={t("Name")}
        initialValue={configuration?.Data?.Name}
      >
        <Input
          disabled={configuration.IsProcessing}
          placeholder={t("Enter name")}
        />
      </Form.Item>
    );
  };

  public render() {
    const { configuration, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <SectionGrid style={{ maxWidth: "900px" }}>
        <SectionGridItem header={t("General information")}>
          <Form
            name="ApplicationConfigurationDetailsForm"
            {...formItemLayout}
            className="ApplicationConfigurationDetailsForm"
            onFinish={this.onFinish}
          >
            <Row
              direction="column"
              justify="space-between"
              className="full-height"
            >
              <Col>
                <Form.Item label={t("Application Key")}>
                  <label>{configuration?.Data?.ApplicationKey}</label>
                </Form.Item>
                {this.renderNameField()}
                <Form.Item label={t("Version")}>
                  <label>{configuration?.Data?.VersionNumber}</label>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    style={{ float: "right" }}
                    type="primary"
                    htmlType="submit"
                    loading={configuration.IsProcessing}
                  >
                    {t("BUTTON_SAVE", "Save")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SectionGridItem>
        <SectionGridItem header={t("Publication")}>
          <Form
            name="ApplicationConfigurationDetailsForm"
            {...formItemLayout}
            className="ApplicationConfigurationDetailsForm"
            onFinish={this.onFinish}
          >
            <Row
              direction="column"
              justify="space-between"
              className="full-height"
            >
              <Col>
                <Form.Item label={t("Publication date")}>
                  <label>
                    {configuration?.Data?.PublicationDate
                      ? TimeHelper.format(configuration?.Data?.PublicationDate)
                      : null}
                  </label>
                </Form.Item>
                <Form.Item label={t("Published by")}>
                  <label>{configuration?.Data?.PublishedByFullName}</label>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SectionGridItem>
      </SectionGrid>
    );
  }
}
