import {
  ApplicationConfigurationStore,
  IApplicationConfigurationScreensSearchFilterModel,
  IApplicationScreenModel,
  IApplicationScreenTypeModel,
  IApplicationsScreensSearchFilterModel,
  IErrorModel,
  IPlatformModel,
  TimeHelper,
} from "@xala/common-services";
import {
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  NotificationService,
  Popconfirm,
  SectionGrid,
  SectionGridItem,
  setTableColumnSearchProps,
  Table,
  UrlHelper,
  useSearchParamsFilters,
} from "@xala/common-ui";
import { StringHelper } from "@xala/studio/src/helpers";
import React from "react";
import { WithTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ActionCreator } from "redux";
import { PlatformTypeHelper } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { ScreenTypeHelper } from "../../helpers";
import { ApplicationScreenAddModal } from "../ApplicationScreenAddModal";
import "./ApplicationConfigurationScreensList.scss";

const notificationService = NotificationService.getInstance();

type ColumnValue = string | number | boolean;

const Input = () => {
  const history = useHistory();

  const searchParams = useSearchParamsFilters({
    FullTextSearch: "string",
  });

  const defaultValue = searchParams.current?.FullTextSearch || "";

  return (
    <InputSearch
      key={defaultValue}
      placeholder="Search"
      onSearch={(value) => {
        history.push({
          ...history.location,
          search: UrlHelper.joinQueries(history.location.search, {
            ["FullTextSearch"]: value,
          }),
        });
      }}
      style={{ width: 250 }}
      allowClear={true}
      defaultValue={defaultValue}
    />
  );
};

export interface IApplicationConfigurationScreensListProps
  extends WithTranslation {
  configurationId?: number;
  screens?: IApplicationScreenModel[];
  isLoadingData: boolean;
  actionType?: string;
  error?: IErrorModel;
  getConfiguration: ActionCreator<
    ApplicationConfigurationStore.Types.IGetApplicationConfigurationAction
  >;
  deleteScreen: ActionCreator<
    ApplicationConfigurationStore.Types.IDeleteApplicationScreenAction
  >;
  searchScreens: (
    filter: IApplicationsScreensSearchFilterModel
  ) => ApplicationConfigurationStore.Types.ApplicationConfigurationActionsTypes;
  screensTypes: IApplicationScreenTypeModel[];
  platforms: IPlatformModel[];
  locationSearch?: string;
}

interface IApplicationConfigurationScreensListState {
  addScreenModalVisible: boolean;
  stateFilter: IApplicationConfigurationScreensSearchFilterModel;
  sortedScreens?: IApplicationScreenModel[];
}
export class ApplicationConfigurationScreensList extends React.PureComponent<
  IApplicationConfigurationScreensListProps,
  IApplicationConfigurationScreensListState
> {
  public state: Readonly<IApplicationConfigurationScreensListState> = {
    addScreenModalVisible: false,
    stateFilter: {
      PageSize: 30,
      PageNumber: 1,
      IncludeCount: true,
    },
    sortedScreens: undefined,
  };

  componentDidMount() {
    const { configurationId, searchScreens, locationSearch } = this.props;

    const queryParams = new URLSearchParams(locationSearch);
    const fullTextSearch = queryParams.get("FullTextSearch");
    const filter = {
      PageSize: 999,
      PageNumber: 1,
      ApplicationConfigurationId: configurationId,
      IncludeCount: true,
      IncludePlatforms: true,
    };

    if (fullTextSearch) {
      const filterWithFullTextSearch = {
        ...filter,
        FullTextSearch: fullTextSearch,
      };
      return searchScreens(filterWithFullTextSearch);
    }

    return searchScreens(filter);
  }

  componentDidUpdate(prevProps: IApplicationConfigurationScreensListProps) {
    const {
      actionType,
      error,
      locationSearch,
      searchScreens,
      configurationId,
      screens,
    } = this.props;

    if (!this.state.sortedScreens && !!screens?.length) {
      this.setState({ sortedScreens: screens });
    }

    const prevQueryParams = new URLSearchParams(prevProps.locationSearch);
    const prevFullTextSearch = prevQueryParams.get("FullTextSearch");

    const queryParams = new URLSearchParams(locationSearch);
    const fullTextSearch = queryParams.get("FullTextSearch");

    if (prevFullTextSearch !== fullTextSearch) {
      const filter = {
        PageSize: 999,
        PageNumber: 1,
        ApplicationConfigurationId: configurationId,
        IncludeCount: true,
        IncludePlatforms: true,
        FullTextSearch: fullTextSearch || undefined,
      };
      searchScreens(filter);
    }

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_FAILURE:
        return notificationService.error({
          message: "Delete application screen failed",
          description: error ? error.Message : undefined,
        });
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_SUCCESS:
        notificationService.success({
          message: "Delete application screen success",
        });
        const paramId = location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        );
        this.props.getConfiguration(paramId);
        break;
    }
  }

  private getColumnsProps(): Array<ITableColumnProps<IApplicationScreenModel>> {
    const { t, screensTypes, platforms } = this.props;
    const { stateFilter } = this.state;

    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("name", "Name"),
        ...setTableColumnSearchProps("Name"),
        filteredValue: stateFilter.Name ? [stateFilter.Name] : null,
        onFilter: (value: ColumnValue, row: IApplicationScreenModel) =>
          StringHelper.latinize(row.Name.toLowerCase()).includes(
            StringHelper.latinize(value.toString().toLowerCase())
          ),
        render: (text: any, row: IApplicationScreenModel) => {
          return (
            <Link to={`${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${row.Id}`}>
              {row.Name}
            </Link>
          );
        },
        sorter: true,
      },
      {
        key: "ScreenTypeCode",
        dataIndex: "ScreenTypeCode",
        title: t("type", "Type"),
        filters: screensTypes.map((screenType) => ({
          text: screenType.Name,
          value: screenType.Code,
        })),
        filteredValue: stateFilter.ScreenTypeCode
          ? stateFilter.ScreenTypeCode
          : null,
        onFilter: (value: ColumnValue, row: IApplicationScreenModel) => {
          return value === row.ScreenTypeCode;
        },
        render: (text: any, row: IApplicationScreenModel) =>
          ScreenTypeHelper.getTag(row.ScreenTypeCode),
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("Platforms", "Platforms"),
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: stateFilter.Platforms ? stateFilter.Platforms : null,
        onFilter: (value: ColumnValue, row: IApplicationScreenModel) => {
          return row.Platforms?.map(
            (platform) => platform.PlatformCode === value
          ).find((el) => el === true)
            ? true
            : false;
        },
        render: (text: any, row: IApplicationScreenModel) => {
          const platformsView: React.ReactNode[] = [];

          if (row.Platforms && row.Platforms.length > 0) {
            for (const platform of row.Platforms) {
              platformsView.push(
                PlatformTypeHelper.getTag(platform.PlatformCode)
              );
            }
          }

          return platformsView;
        },
      },
      {
        dataIndex: "Description",
        title: t("Description", "Description"),
        ...setTableColumnSearchProps("Description"),
        filteredValue: stateFilter.Description
          ? [stateFilter.Description]
          : null,
        onFilter: (value: ColumnValue, row: IApplicationScreenModel) =>
          row.Description
            ? StringHelper.latinize(row.Description.toLowerCase()).includes(
                StringHelper.latinize(value.toString().toLowerCase())
              )
            : false,
      },
      {
        key: "Created",
        dataIndex: "Created",
        title: t("Created", "Created"),
        width: "150px",
        align: "center",
        render: (_: any, row: IApplicationScreenModel) => (
          <p>{TimeHelper.format(row.Created || "")}</p>
        ),
        sorter: true,
      },
      {
        key: "LastModified",
        dataIndex: "LastModified",
        title: t("LastModified", "Last modified"),
        width: "150px",
        align: "center",
        render: (_: any, row: IApplicationScreenModel) => (
          <p>{TimeHelper.format(row.LastModified || row.Created || "")}</p>
        ),
        sorter: true,
      },
      {
        key: "Actions",
        width: "100px",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IApplicationScreenModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              this.props.deleteScreen(row);
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  }

  public onAddScreenClick = () => {
    const { configurationId } = this.props;

    if (!configurationId) {
      return;
    }

    this.setState({ addScreenModalVisible: true });
  };

  public onAddScreenCancel = () => {
    this.setState({ addScreenModalVisible: false });
  };

  public onAddScreenSuccess = () => {
    this.setState({ addScreenModalVisible: false });
  };

  public onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter,
    sorter: any
  ) => {
    const { stateFilter, sortedScreens } = this.state;

    const filter = { ...stateFilter };
    filter.PageNumber = pagination.current!;
    filter.PageSize = pagination.pageSize;

    filter.Name = filters.Name?.[0]?.toString();
    filter.ScreenTypeCode = filters?.ScreenTypeCode
      ? (filters?.ScreenTypeCode as string[])
      : undefined;
    filter.Platforms = filters?.Platforms
      ? (filters?.Platforms as string[])
      : undefined;
    filter.Description = filters.Description?.[0]?.toString();

    const screensToSort = sortedScreens?.map((el) => el);
    switch (sorter.field) {
      case "Name":
        this.nameColumnSorter(sorter, screensToSort);
        break;
      case "Created":
        this.createdColumnSorter(sorter, screensToSort);
        break;
      case "LastModified":
        this.lastModifiedColumnSorter(sorter, screensToSort);
        break;
      default:
        break;
    }

    this.setState({ stateFilter: filter });
  };

  private nameColumnSorter = (
    sorter: any,
    screensToSort: IApplicationScreenModel[] | undefined
  ) => {
    const { screens } = this.props;

    if (sorter.order === "ascend") {
      const sorted = screensToSort?.sort((a, b) => (a.Name < b.Name ? -1 : 1));
      this.setState({ sortedScreens: sorted });
    }
    if (sorter.order === "descend") {
      const sorted = screensToSort?.sort((a, b) => (a.Name < b.Name ? 1 : -1));
      this.setState({ sortedScreens: sorted });
    }
    if (!sorter.order) {
      this.setState({ sortedScreens: screens });
    }
  };

  private createdColumnSorter = (
    sorter: any,
    screensToSort: IApplicationScreenModel[] | undefined
  ) => {
    const { screens } = this.props;

    if (sorter.order === "ascend") {
      const sorted = screensToSort?.sort((a, b) =>
        TimeHelper.compareIsBefore(a?.Created || "", b?.Created || "") ? -1 : 1
      );
      this.setState({ sortedScreens: sorted });
    }
    if (sorter.order === "descend") {
      const sorted = screensToSort?.sort((a, b) =>
        TimeHelper.compareIsBefore(a?.Created || "", b?.Created || "") ? 1 : -1
      );
      this.setState({ sortedScreens: sorted });
    }
    if (!sorter.order) {
      this.setState({ sortedScreens: screens });
    }
  };

  private lastModifiedColumnSorter = (
    sorter: any,
    screensToSort: IApplicationScreenModel[] | undefined
  ) => {
    const { screens } = this.props;

    if (sorter.order === "ascend") {
      const sorted = screensToSort?.sort((a, b) =>
        TimeHelper.compareIsBefore(
          a?.LastModified || a?.Created || "",
          b?.LastModified || b?.Created || ""
        )
          ? -1
          : 1
      );
      this.setState({ sortedScreens: sorted });
    }
    if (sorter.order === "descend") {
      const sorted = screensToSort?.sort((a, b) =>
        TimeHelper.compareIsBefore(
          a?.LastModified || a?.Created || "",
          b?.LastModified || b?.Created || ""
        )
          ? 1
          : -1
      );
      this.setState({ sortedScreens: sorted });
    }
    if (!sorter.order) {
      this.setState({ sortedScreens: screens });
    }
  };

  public paginationConfig = (): ITablePaginationConfig => {
    const { t } = this.props;

    return {
      showTotal: (total, range) =>
        t("ITEMS_RANGE_LABEL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      showSizeChanger: true,
      defaultPageSize: 30,
      pageSizeOptions: ["30", "50", "100"],
    };
  };

  public render() {
    const { isLoadingData, t, configurationId } = this.props;
    const { addScreenModalVisible, sortedScreens } = this.state;

    const columns = this.getColumnsProps();

    return (
      <SectionGrid>
        <SectionGridItem>
          <Heading
            title={t("Screens")}
            actions={[
              <Input />,
              <Button
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={this.onAddScreenClick}
                title={t("Add screen")}
              />,
            ]}
          />
          <Table<IApplicationScreenModel>
            pagination={this.paginationConfig()}
            rowKey="Guid"
            columns={columns}
            dataSource={sortedScreens}
            loading={isLoadingData}
            onChange={this.onTableChange}
          />
          <ApplicationScreenAddModal
            configurationId={configurationId!}
            visible={addScreenModalVisible}
            onCancel={this.onAddScreenCancel}
            onSuccess={this.onAddScreenSuccess}
          />
        </SectionGridItem>
      </SectionGrid>
    );
  }
}
