import { AssetType } from "@xala/common-services";
import { intersection } from "lodash";
import { AssetProperties } from "../../enums";

export type FieldType = AssetProperties;

interface IFieldsSections {
  restriction: FieldType[];
  general: FieldType[];
  people: FieldType[];
}

export type ISection = keyof IFieldsSections;

export const baseCommonFields: FieldType[] = [
  AssetProperties.CreationDate,
  AssetProperties.LastModificationDate,
  AssetProperties.AssetTypeCode,
  AssetProperties.Title,
  AssetProperties.IsDraft,
  AssetProperties.StreamingPermission,
  AssetProperties.DurationMiliseconds,
  AssetProperties.AssetAgeRestrictionValueMin,
  AssetProperties.AdvertisingTags,
  AssetProperties.IsFree,
  AssetProperties.AllowUnregistered,
  AssetProperties.AssetDatesVisible,
  AssetProperties.ShortDescriptionForm,
  AssetProperties.AvailableDate,
  AssetProperties.CreatorUserId,
  AssetProperties.Categories,
  AssetProperties.Tags,
  AssetProperties.ContextTags,
  AssetProperties.Genre,
  AssetProperties.EntityId,
];

export const descriptionCommonFields: FieldType[] = [
  AssetProperties.Description,
  AssetProperties.ShortDescription,
  AssetProperties.LongDescription,
];

export const commonFields: FieldType[] = [
  ...baseCommonFields,
  ...descriptionCommonFields,
];
const peopleFields: FieldType[] = [
  AssetProperties.Director,
  AssetProperties.Presenter,
  AssetProperties.Editor,
  AssetProperties.Cast,
  AssetProperties.Producer,
  AssetProperties.Writer,
];
export const childFields: FieldType[] = [
  AssetProperties.ParentAssetId,
  AssetProperties.OrderInParent,
];

export const getFieldNamesByParams = (assetType: AssetType): FieldType[] => {
  switch (assetType) {
    case AssetType.Video:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.YearText,
        AssetProperties.Captions,
        AssetProperties.Nationality,
        AssetProperties.Label,
        ...peopleFields,
      ];
    case AssetType.Series:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Label,
        ...peopleFields,
        AssetProperties.YearText,
      ];
    case AssetType.Season:
      return [
        ...commonFields,
        AssetProperties.Subtitles,
        AssetProperties.Label,
        ...childFields,
        AssetProperties.YearText,
        AssetProperties.OrderRule,
      ];
    case AssetType.Episode:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Label,
        ...childFields,
      ];
    case AssetType.Channel:
      return [
        ...commonFields,
        AssetProperties.ChannelName,
        AssetProperties.ChannelId,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.BCID,
      ];
    case AssetType.Program:
      return [
        ...commonFields,
        AssetProperties.RecordingPermission,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Captions,
        AssetProperties.Label,
        AssetProperties.StartEndDates,
        AssetProperties.Nationality,
        ...childFields,
        ...peopleFields,
        AssetProperties.YearText,
      ];
    case AssetType.Live:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        ...peopleFields,
        AssetProperties.YearText,
        AssetProperties.Captions,
        AssetProperties.Nationality,
        AssetProperties.Label,
        AssetProperties.StartEndDates,
      ];
    case AssetType.Intro:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Label,
        ...peopleFields,
        AssetProperties.YearText,
      ];
    case AssetType.Package:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Label,
      ];
    case AssetType.Premiere:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.StartEndDates,
        AssetProperties.Label,
      ];
    case AssetType.Article:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Label,
        AssetProperties.StartEndDates,
      ];
    case AssetType.Event:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Subtitles,
        AssetProperties.Label,
        AssetProperties.StartEndDates,
        AssetProperties.EventDateTime,
      ];
    case AssetType.Album:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.YearText,
        AssetProperties.Nationality,
        AssetProperties.Label,
        ...peopleFields,
      ];
    case AssetType.Podcast:
      return [
        ...commonFields,
        AssetProperties.LanguageId,
        AssetProperties.Label,
        ...childFields,
      ];
    default:
      return [];
  }
};

const restrictionSectionFields: FieldType[] = [
  AssetProperties.Categories,
  AssetProperties.Tags,
  AssetProperties.AssetAgeRestrictionValueMin,
  AssetProperties.AvailableDate,
  AssetProperties.StartEndDates,
  AssetProperties.EventDateTime,
];
const generalSectionFields: FieldType[] = [
  AssetProperties.CreationDate,
  AssetProperties.LastModificationDate,
  AssetProperties.AssetTypeCode,
  AssetProperties.ParentAssetId,
  AssetProperties.OrderInParent,
  AssetProperties.Title,
  AssetProperties.ChannelName,
  AssetProperties.ChannelId,
  AssetProperties.IsDraft,
  AssetProperties.Captions,
  AssetProperties.Description,
  AssetProperties.LongDescription,
  AssetProperties.ShortDescription,
  AssetProperties.LanguageId,
  AssetProperties.Subtitles,
  AssetProperties.DurationMiliseconds,
  AssetProperties.YearText,
  AssetProperties.CreatorUserId,
  AssetProperties.AdvertisingTags,
  AssetProperties.ContextTags,
  AssetProperties.Label,
  AssetProperties.Genre,
  AssetProperties.Nationality,
  AssetProperties.BCID,
  AssetProperties.IsFree,
  AssetProperties.StreamingPermission,
  AssetProperties.RecordingPermission,
  AssetProperties.EntityId,
  AssetProperties.AllowUnregistered,
  AssetProperties.AssetDatesVisible,
  AssetProperties.OrderRule,
];
const peopleSectionFields: FieldType[] = peopleFields;

export const getSectionsFields = (assetType: AssetType): IFieldsSections => {
  const fieldNames = getFieldNamesByParams(assetType);

  return {
    restriction: intersection(fieldNames, restrictionSectionFields),
    general: intersection(fieldNames, generalSectionFields),
    people: intersection(fieldNames, peopleSectionFields),
  };
};

export const formLayouts = {
  formItem: {
    labelCol: {
      xs: { span: 9 },
      lg: { span: 7 },
      xl: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 16 },
      lg: { span: 18 },
      xl: { span: 20 },
    },
  },
  modalFormItem: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  },
  tailFormItem: {
    wrapperCol: {
      style: {
        textAlign: "right",
        marginBottom: 0,
      } as React.CSSProperties,
    },
  },
};

export interface AssetCurrentDescriptionFields {
  [key: string]: {
    value: string;
  };
}

export interface AssetDescriptionMode {
  name: string;
  label: string;
  value: string;
  maxLength?: number;
  initialValue?: string;
}
