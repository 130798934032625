import {
  AssetCategoryModel,
  AssetCategoryService,
  AssetService,
  IAssetCategoryModel,
  IAssetCollectionModel,
  IUserModel,
  useDataLoader,
} from "@xala/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  Icon,
  IFormValues,
  Input,
  Link,
  RadioGroup,
  Row,
  Spin,
  Switch,
  urlValid,
  useSendable,
  useSyncedState,
} from "@xala/common-ui";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserBrowserModal } from "../../../User/components/UserBrowserModal";
import { ROUTES as USER_ROUTES } from "../../../User/constants";
import { formLayouts, formValues } from "./AssetCollectionFiltersFormUtils";
import { Select, Tooltip } from "antd";
import { LazyChoose } from "../../../../components";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";

const assetCategoryService = new AssetCategoryService().promisify();
const assetService = new AssetService().promisify();

export interface IAssetCollectionFiltersFormProps {
  assetCollection?: IAssetCollectionModel;
  onSubmit: (collection: IAssetCollectionModel) => Promise<boolean>;
}

interface ISortDirectionInputProps {
  value?: "ASC" | "DESC";
  onChange?: (value?: "ASC" | "DESC") => void;
}

const SortDirectionInput: React.FC<ISortDirectionInputProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip overlay={t("SortDirection", "Sort direction")}>
      <Button
        icon={
          value === "ASC" ? (
            <SortAscendingOutlined />
          ) : (
            <SortDescendingOutlined />
          )
        }
        onClick={() => onChange?.(value === "ASC" ? "DESC" : "ASC")}
      />
    </Tooltip>
  );
};

export const AssetCollectionFiltersForm: React.FC<IAssetCollectionFiltersFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { assetCollection, onSubmit } = props;
  const [userBrowserVisible, setUserBrowserVisible] = useState(false);
  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: number;
    FullName?: string | number;
  }>(
    () => ({
      Id: assetCollection?.FilterCreatorUserId,
      FullName:
        assetCollection?.FilterCreatorUserFullName ||
        assetCollection?.FilterCreatorUserId,
    }),
    [assetCollection]
  );

  const assetTypesLoader = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    deps: [],
  });

  const assetTypes = useMemo(() => assetTypesLoader.data ?? [], [
    assetTypesLoader.data,
  ]);

  const radioFieldNames = ["FilterMyFavourites", "FilterPurchased"];

  const [radioValues, setRadioValues] = useState([
    assetCollection?.FilterMyFavourites !== undefined
      ? assetCollection?.FilterMyFavourites
      : false,
    assetCollection?.FilterPurchased !== undefined
      ? assetCollection?.FilterPurchased
      : false,
  ]);

  const changeRadioValues = (index: number, value: boolean) => {
    const temp = radioValues.map((v) => false);
    temp[index] = value;
    let newRadioValue: any = {};
    for (let i = 0; i < temp.length; i++) {
      newRadioValue[radioFieldNames[i]] = temp[i];
    }
    form.setFieldsValue(newRadioValue);
    setRadioValues(temp);
  };

  useEffect(() => {
    const temp = [
      assetCollection?.FilterMyFavourites !== undefined
        ? assetCollection?.FilterMyFavourites
        : false,
      assetCollection?.FilterPurchased !== undefined
        ? assetCollection?.FilterPurchased
        : false,
    ];
    setRadioValues(temp);
  }, [assetCollection]);

  const sendable = useSendable();

  const onFinish = async (values: IFormValues) => {
    const payload: IAssetCollectionModel = {
      ...assetCollection,
      Id: assetCollection?.Id ?? -1,
      FilterCreatorUserId: userCreator.Id,
      Categories: values.Categories,
      FilterTags: values.Tags,
      FilterMustIncludeAllCategoriesAndTags: values.FilterMustIncludeAllCategoriesAndTags
        ? true
        : undefined,
      FilterIsFree: values.FilterIsFree
        ? values.FilterIsFree === "FREE"
        : undefined,
      FilterMyFavourites: values.FilterMyFavourites ? true : undefined,
      FilterPurchased: values.FilterPurchased ? true : undefined,
      FilterTypes: values.MediaType?.join(";"),
      FilterFutureStart:
        values.FilterTime === "UPCOMING"
          ? true
          : values.FilterTime === "PAST"
          ? false
          : undefined,
      FilterRecentlyFinished:
        values.FilterTime === "PAST"
          ? true
          : values.FilterTime === "UPCOMING"
          ? false
          : undefined,
      ExternalDefinitionUrl: values.KonodracAI,
    };

    if (values.SortBy) {
      payload.SortByExpression = values.SortBy;

      if (values.SortDirection) {
        payload.SortByExpression += ` ${values.SortDirection}`;
      }
    } else {
      payload.SortByExpression = undefined;
    }
    const ok = await onSubmit(payload);
    if (ok) {
      sendable.resetSendable();
    }
    form.resetFields();
  };

  const onUserSelect = (row: IUserModel) => {
    setUserCreator({ Id: row.Id, FullName: row.FullName || row.Id });
    setUserBrowserVisible(false);
    sendable.setDirty();
  };

  const onUserClear = () => {
    setUserCreator({ Id: undefined, FullName: undefined });
  };

  const renderCreatorField = () => {
    let filterCreatorUserView: React.ReactElement = userCreator.Id ? (
      <Link
        to={`${USER_ROUTES.USER_DETAILS}/${userCreator.Id}`}
        style={{ color: "white" }}
      >
        {userCreator.FullName}
      </Link>
    ) : (
      t("UNDEFINED", "Undefined")
    );

    return (
      <Form.Item name="FilterCreatorUserId" label={t("Creator", "Creator")}>
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item>
              <div
                style={{
                  backgroundColor: "#2e2e2e",
                  borderRadius: "2px",
                  padding: "6px 12px",
                }}
              >
                {filterCreatorUserView}
              </div>
            </Form.Item>
            <UserBrowserModal
              profiles={["CREATOR"]}
              visible={userBrowserVisible}
              onCancel={() => setUserBrowserVisible(false)}
              onSelect={onUserSelect}
            />
          </Col>
          <Col style={{ textAlign: "right" }}>
            {userCreator.Id && (
              <Button
                icon={<Icon type="delete" />}
                onClick={onUserClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => setUserBrowserVisible(true)}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderKonodracAIField = () => {
    const initialValues = assetCollection?.ExternalDefinitionUrl;

    return (
      <Form.Item
        name="KonodracAI"
        label={t("KonodracAI", "Konodrac AI")}
        key="KonodracAI"
        initialValue={initialValues}
        rules={[urlValid()]}
      >
        <Input />
      </Form.Item>
    );
  };

  const renderMediaTypesField = () => {
    const initialValues = assetTypes
      .filter((el) =>
        assetCollection?.FilterTypes?.split(";").includes(el.Code)
      )
      .map((el) => el.Code);

    return (
      <Form.Item
        name="MediaType"
        label={t("Media type", "Media type")}
        key="Media type"
        initialValue={initialValues || []}
      >
        <Choose mode={"multiple"}>
          {assetTypes &&
            assetTypes.map((el) => (
              <ChooseOption key={el.Code} value={el.Code}>
                {el.DisplayName}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderCategoriesField = () => {
    return (
      <Form.Item
        name="Categories"
        label={t("Categories", "Categories")}
        key="Categories"
        initialValue={assetCollection?.Categories || []}
      >
        <LazyChoose<IAssetCategoryModel, AssetCategoryModel>
          loader={(search: string) =>
            assetCategoryService.search({
              PageNumber: 1,
              PageSize: 100,
              FullTextSearch: search,
              UpToDate: true,
            })
          }
          candidateToOption={(item) => ({
            label: item.Name,
            value: `${item.Id}`,
            item: {
              AssetCategoryId: item.Id,
              AssetCategoryName: item.Name,
              AssetCategoryCode: item.Code,
              RowVersion: item.RowVersion,
            },
          })}
          selectedToOption={(item) => ({
            label: item.AssetCategoryName!,
            value: `${item.AssetCategoryId!}`,
            item,
          })}
        />
      </Form.Item>
    );
  };

  const renderTagsField = () => {
    return (
      <Form.Item
        name="Tags"
        label={t("TAGS_LABEL")}
        key="Tags"
        initialValue={assetCollection?.FilterTags || []}
      >
        <Select mode="tags" placeholder={t("TAGS_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderTimeFilterFields = () => {
    let timeTypeValue = "";

    if (
      !assetCollection?.FilterRecentlyFinished &&
      !assetCollection?.FilterFutureStart
    ) {
      timeTypeValue = "ANY";
    } else if (assetCollection?.FilterRecentlyFinished) {
      timeTypeValue = "PAST";
    } else if (assetCollection?.FilterFutureStart) {
      timeTypeValue = "UPCOMING";
    }

    return (
      <Form.Item
        name="FilterTime"
        label={t("Time", "Time")}
        key="FilterTime"
        initialValue={timeTypeValue}
      >
        <RadioGroup
          buttons
          data={formValues.assetTimeOptions.map(({ name, key }) => ({
            name,
            label: name,
            value: key,
          }))}
        />
      </Form.Item>
    );
  };

  const renderAssetVisibilityFilterFields = () => {
    let visibilityTypeValue = "";

    if (assetCollection?.FilterIsFree !== undefined) {
      visibilityTypeValue = assetCollection.FilterIsFree ? "FREE" : "PREMIUM";
    }

    return (
      <Form.Item
        name="FilterIsFree"
        label={t("ACCESSIBILITY_LABEL")}
        key="FilterIsFree"
        initialValue={visibilityTypeValue}
      >
        <RadioGroup
          buttons
          data={formValues.assetPaymentOptions.map(({ name, key }) => ({
            name,
            label: name,
            value: key,
          }))}
        />
      </Form.Item>
    );
  };

  const renderSortFields = () => {
    let sortBy = "";
    let sortDirection = "";
    const sortValue = assetCollection?.SortByExpression ?? "";
    const sortValues = sortValue.split(" ");

    if (sortValues.length > 0) {
      sortBy = sortValues[0].trim();
    }

    if (sortValues.length > 1) {
      sortDirection = sortValues[1].trim();
    }

    return (
      <Form.Item label={t("SortBy", "Sort by")}>
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item name="SortBy" key="SortBy" initialValue={sortBy}>
              <Choose
                placeholder={t("SelectSortValue", "Select sort value")}
                allowClear
              >
                {formValues.assetSortData.map((data, index) => (
                  <ChooseOption key={index} value={data.key}>
                    {data.name}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="SortDirection"
              key="SortDirection"
              initialValue={sortDirection}
              labelCol={{ flex: "auto" }}
              wrapperCol={{ flex: "auto" }}
            >
              <SortDirectionInput />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderMustIncludeAllCategoriesAndTags = () => {
    return (
      <Form.Item
        name="FilterMustIncludeAllCategoriesAndTags"
        initialValue={
          assetCollection?.FilterMustIncludeAllCategoriesAndTags ?? false
        }
        valuePropName="checked"
        label={t(
          "MODEL_ASSET_COLLECTION_FILTER_MUST_INCLUDE_ALL_CATEGORIES_AND_TAGS"
        )}
      >
        <Switch />
      </Form.Item>
    );
  };

  const renderMyFavouritesFilterField = (radioIndex: number) => {
    return (
      <Form.Item
        name="FilterMyFavourites"
        initialValue={assetCollection?.FilterMyFavourites ?? false}
        valuePropName="checked"
        label={t(
          "MODEL_ASSET_COLLECTION_FILTER_MY_FAVOURITES",
          "My favourites"
        )}
      >
        <Switch onClick={(value) => changeRadioValues(radioIndex, value)} />
      </Form.Item>
    );
  };

  const renderPurchasedFilterField = (radioIndex: number) => {
    return (
      <Form.Item
        name="FilterPurchased"
        initialValue={assetCollection?.FilterPurchased ?? false}
        valuePropName="checked"
        label={t("MODEL_ASSET_COLLECTION_FILTER_PURCHASED", "Purchased")}
      >
        <Switch onClick={(value) => changeRadioValues(radioIndex, value)} />
      </Form.Item>
    );
  };

  if (!assetCollection || assetTypes.length === 0) {
    return <Spin />;
  }

  return (
    <Form
      id="AssetCollectionFiltersForm"
      name="AssetCollectionFiltersForm"
      {...formLayouts.formItemLayout}
      onFieldsChange={sendable.onFieldsChange}
      onFinish={onFinish}
      form={form}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {assetCollection.CollectionType === "DEFINITION_BASED" ? (
            <>
              {renderCreatorField()}
              {renderMediaTypesField()}
              {renderCategoriesField()}
              {renderTagsField()}
              {renderTimeFilterFields()}
              {renderAssetVisibilityFilterFields()}
              {renderSortFields()}
              {renderMustIncludeAllCategoriesAndTags()}
              {renderMyFavouritesFilterField(0)}
              {renderPurchasedFilterField(1)}
            </>
          ) : (
            renderKonodracAIField()
          )}
          <Form.Item style={{ float: "right" }}>
            <Button
              disabled={!sendable.sendable}
              type="primary"
              htmlType="submit"
            >
              {t("SAVE", "Save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
