import { AdvertisementStore, ICommonAppState } from "@xala/common-services";
import { AdvertisementBoardsTypesList as AdvertisementBoardsTypesListDefinition } from "./AdvertisementBoardsTypesList";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isLoadingData: state.advertisement.isLoadingData,
    boardsTypes: state.advertisement.boardsTypes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectBoardsTypes: () => {
    return dispatch(AdvertisementStore.Actions.selectBoardsTypes());
  },
});

export const AdvertisementBoardsTypesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBoardsTypesListDefinition));
