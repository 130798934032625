import { IAssetCategoryModel, RecordStatus } from "@xala/common-services";
import {
  Col,
  Form,
  IFormValues,
  Input,
  Row,
  Switch,
  TextArea,
  useValidate,
} from "@xala/common-ui";
import { omit } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";

export interface IAssetCategoryFormProps {
  updateCategory: ActionCreator<Action>;
  insertCategory: ActionCreator<Action>;
  isNewCategory: boolean;
  isProcessing: boolean;
  category: IAssetCategoryModel;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 },
    lg: { span: 18 },
  },
};

export const AssetCategoryForm: React.FC<IAssetCategoryFormProps> = (props) => {
  const { category, isNewCategory } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const validate = useValidate();

  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = (values: IFormValues) => {
    const { updateCategory, insertCategory } = props;
    const data: IAssetCategoryModel = {
      ...category,
      ...values,
    };

    if (isNewCategory) {
      insertCategory(omit({ ...data, RecordStatus: RecordStatus.Inserted }, ["Sequence"]));
    } else {
      updateCategory({ ...data, RecordStatus: RecordStatus.Updated });
    }
  };

  return (
    <Form
      id="AssetCategoryForm"
      form={form}
      {...formItemLayout}
      initialValues={category}
      onFinish={onFinish}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          <Form.Item
            name="Code"
            label={t("Code", "Code")}
            rules={validate.required()}
          >
            <Input placeholder={t("Code", "Code")} />
          </Form.Item>

          <Form.Item
            name="Name"
            label={t("Name", "Name")}
            rules={validate.required()}
          >
            <Input placeholder={t("Name", "Name")} />
          </Form.Item>

          <Form.Item name="Description" label={t("Description", "Description")}>
            <TextArea
              placeholder={t("Description", "Description")}
              autoSize={{ minRows: 4 }}
            />
          </Form.Item>

          <Form.Item
            name="UpToDate"
            label={t("UpToDate", "UpToDate")}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
