import {
  ApplicationConfigurationService,
  IApplicationConfigurationModel,
  IApplicationsConfigurationsListModel,
  TimeHelper,
  useDataLoader,
} from "@xala/common-services";
import {
  CardGrid,
  CardGridItem,
  Col,
  Icon,
  Link,
  PageContent,
  PageHeader,
  Row,
  Spin,
} from "@xala/common-ui";
import React from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Action, ActionCreator } from "redux";
import { ROUTES as USER_ROUTES } from "../../../User/constants/routes";
import { ROUTES } from "../../constants";
import "./ApplicationsConfigurationsList.scss";

export interface IApplicationsConfigurationsListProps
  extends WithTranslation,
    RouteComponentProps {
  configurations: IApplicationsConfigurationsListModel;
  isLoadingData: boolean;
  searchConfigurations: ActionCreator<Action>;
  deleteConfiguration: ActionCreator<Action>;
}

interface IConfigurationCardProps {
  configuration: IApplicationConfigurationModel;
}

const configurationService = new ApplicationConfigurationService().promisify();

const ConfigurationCard = ({ configuration }: IConfigurationCardProps) => {
  const [t] = useTranslation();
  const configLoader = useDataLoader({
    loader: () => configurationService.get(configuration.Id),
    deps: [configuration.Id],
  });

  return (
    <CardGridItem
      header={configuration.Name}
      footerLink={{
        href: `${ROUTES.CONFIGURATION_DETAILS}/${configuration.Id}`,
        title: (
          <>
            {t("BUTTON_SEE_DETAILS")}
            <Icon style={{ marginLeft: "8px" }} type="double_right" />
          </>
        ),
      }}
    >
      <Row gutter={[16, 32]}>
        <Col xs={12}>
          <h4>
            {t("MODEL_LAST_MODIFICATION")} <Icon type="edit" />
          </h4>
          <div>{TimeHelper.format(configuration.LastModificationDate)}</div>
          <div>
            <Link
              to={`${USER_ROUTES.USER_DETAILS}/${configuration.LastModifiedBy}`}
            >
              {configuration.LastModifiedByFullName}
            </Link>
          </div>
        </Col>
        <Col xs={12}>
          <h4>
            {t("MODEL_LAST_PUBLICATION")} <Icon type="upload" />
          </h4>
          <div>{TimeHelper.format(configuration.PublicationDate)}</div>
          <div>
            <Link
              to={`${USER_ROUTES.USER_DETAILS}/${configuration.PublishedBy}`}
            >
              {configuration.PublishedByFullName}
            </Link>
          </div>
        </Col>
        <Col xs={12}>
          <h4>{t("MODEL_APPLICATION_KEY")}</h4>
          <strong>{configuration.ApplicationKey}</strong>
        </Col>
        <Col xs={12}>
          <h4>{t("MODEL_APPLICATION_VERSION")}</h4>
          <strong>{configuration.VersionNumber}</strong>
        </Col>
        {configLoader.data?.Branding && (
          <Col xs={24}>
            <h4>{t("MODEL_COLOR_PALETTE")}</h4>
            <div className="palette-wrapper">
              {configLoader.data?.Branding?.Values?.filter(
                (item) => item.Type === "COLOR"
              ).map((item, index) => (
                <span
                  key={index}
                  className="palette-item"
                  style={{ backgroundColor: `${item.Value}` }}
                  title={item.Name}
                />
              ))}
            </div>
          </Col>
        )}
      </Row>
    </CardGridItem>
  );
};

export class ApplicationsConfigurationsList extends React.PureComponent<
  IApplicationsConfigurationsListProps
> {
  public componentDidMount() {
    this.props.searchConfigurations({});
  }

  public render() {
    const { configurations, isLoadingData } = this.props;

    return (
      <div className="ApplicationsConfigurationsList">
        <PageContent>
          <PageHeader title="Applications configurations"></PageHeader>
          <Spin spinning={isLoadingData}>
            <CardGrid cardHeight={400} cardWidth={350}>
              {configurations.Entities.map((configuration) => (
                <ConfigurationCard
                  key={configuration.Id}
                  configuration={configuration}
                />
              ))}
            </CardGrid>
          </Spin>
        </PageContent>
      </div>
    );
  }
}
