import React from "react";
import { Form } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";

export const NotificationTypeReadOnlyField = ({
  notification,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item label={t("NOTIFICATION_TYPE_FIELD")} key="NotificationType">
      {notification?.TypeDisplayName}
    </Form.Item>
  );
};
