import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@xala/common-services";
import { ActionPropertyModel } from "./ActionPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationSectionMenuItemPropertyModel extends ComponentPropertyModel {
  Title?: string;

  TitleTranslationKey?: string;

  Action?: ActionPropertyModel;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  hasBackgroundUrl(): boolean {
    return false;
  }
  hasIconUrl(): boolean {
    return false;
  }
  hasImageUrl(): boolean {
    return false;
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let titleProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (!titleProperty) {
      this.onPropertyChange("Title", component);
    }

    let titleTranslationKeyProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey"
    );

    if (!titleTranslationKeyProperty) {
      this.onPropertyChange("TitleTranslationKey", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let titleProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Title"
    );

    if (titleProperty && titleProperty.Value) {
      this.Title = titleProperty.Value.StringValue;
    }

    let titleTranslationKeyProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) =>
        row.Name === "TitleTranslationKey"
    );

    if (titleTranslationKeyProperty && titleTranslationKeyProperty.Value) {
      this.TitleTranslationKey = titleTranslationKeyProperty.Value.StringValue;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "TitleTranslationKey":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        if (property.Value) {
          property.Value.StringValue = this.Title;
        }
        break;
      case "TitleTranslationKey":
        if (property.Value) {
          property.Value.StringValue = this.TitleTranslationKey;
        }
        break;
    }
  }
}
