import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../../constants/http";
import { AxiosSubscriber } from "../../../services/AxiosSubscriber";
import { IConfigurationModel } from "../models";

export class ConfigurationService {
  get url(): string {
    return "/Configurations";
  }

  public getConfiguration = (): Observable<IConfigurationModel> =>
    new Observable(
      (observer: Observer<IConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            timeout: 60000,
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetConfiguration?platformCode=${process.env.REACT_APP_KEY}`,
        })
    );

  public getCurrentConfigVersion = (): Observable<number> =>
    new Observable(
      (observer: Observer<number>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetCurrentConfigurationVersion`,
        })
    );

  public getConfigurationForUser = (
    userId: number
  ): Observable<IConfigurationModel> =>
    new Observable(
      (observer: Observer<IConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              userId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetConfigurationForUser`,
        })
    );

  public saveConfiguration = (
    data: IConfigurationModel
  ): Observable<IConfigurationModel> =>
    new Observable(
      (observer: Observer<IConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {},
          },
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/SaveConfiguration`,
        })
    );
}
