import React, { useCallback, useMemo, useState } from "react";
import {
  AssetService,
  IAssetPeopleModel,
  IAssetPeopleSearchFilterModel,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import {
  Button,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  NotificationService,
  PageContent,
  PageHeader,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
} from "@xala/common-ui";
import { FormModal } from "../../../../components";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { DictionaryPeopleForm } from "../DictionaryPeopleForm";

import "./DictionaryPeople.scss";
import { StringHelper } from "@xala/studio/src/helpers";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

type ColumnValue = string | number | boolean;

const defaultPerson: IAssetPeopleModel = {
  Id: -1,
  FullName: "",
  // Role: "", TODO: add to backend "role" for people
  RecordStatus: RecordStatus.Inserted,
};

export const DictionaryPeople = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [personId, setPersonId] = useState<IAssetPeopleModel>(defaultPerson);
  const [isProcessing, setIsProcessing] = useState(false);
  const [stateFilter, setStateFilter] = useState<IAssetPeopleSearchFilterModel>(
    {
      PageSize: 10,
      PageNumber: 1,
      IncludeCount: true,
    }
  );
  const validPersonId = personId.Id <= 0;

  const peopleLoader = useDataLoader({
    loader: (data: string) =>
      assetService.searchAssetPeople({
        PageNumber: 1,
        PageSize: 999,
        FullTextSearch: data,
        IncludeCount: true,
      }),
    deps: [],
  });

  const paginationConfig = useMemo((): ITablePaginationConfig => {
    return {
      total: peopleLoader.data?.Entities.length,
      showTotal: (total, range) =>
        t("ITEMS_RANGE_LABEL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      showSizeChanger: true,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "30", "50", "100"],
    };
  }, []);

  const [addNewPerson] = useServiceCaller(async (person: IAssetPeopleModel) => {
    setIsProcessing(true);
    const res = await assetService.addAssetPeople({
      ...person,
      RecordStatus: RecordStatus.Inserted,
    });
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_PEOPLE_INSERT_SUCCESS"),
      });
      await peopleLoader.refresh();
      setIsProcessing(false);
      personModal();
    } else {
      notificationService.error({
        message: t("DICTIONARY_PEOPLE_INSERT_FAILURE"),
        description: res.error?.Message,
      });
      setIsProcessing(false);
    }
  }, []);

  const [updatePerson] = useServiceCaller(async (person: IAssetPeopleModel) => {
    setIsProcessing(true);
    const res = await assetService.updateAssetPeople({
      ...person,
      RecordStatus: RecordStatus.Updated,
    });
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_PEOPLE_UPDATE_SUCCESS"),
      });
      await peopleLoader.refresh();
      setIsProcessing(false);
      personModal();
    } else {
      notificationService.error({
        message: t("DICTIONARY_PEOPLE_UPDATE_FAILURE"),
        description: res.error?.Message,
      });
      setIsProcessing(false);
    }
  }, []);

  const [deletePerson] = useServiceCaller(async (person: IAssetPeopleModel) => {
    setIsProcessing(true);
    const res = await assetService.deleteAssetPeople({
      ...person,
      RecordStatus: RecordStatus.Deleted,
    });
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_PEOPLE_DELETE_SUCCESS"),
      });
      await peopleLoader.refresh();
      setIsProcessing(false);
      personModal();
    } else {
      notificationService.error({
        message: t("DICTIONARY_PEOPLE_DELETE_FAILURE"),
        description: res.error?.Message,
      });
      setIsProcessing(false);
    }
  }, []);

  const getColumnNames = useMemo((): Array<
    ITableColumnProps<IAssetPeopleModel>
  > => {
    return [
      {
        width: "80%",
        key: "FullName",
        dataIndex: "FullName",
        title: t("DICTIONARY_PEOPLE_COLUMN_TITLE"),
        ...setTableColumnSearchProps("FullName"),
        filteredValue: stateFilter.FullName ? [stateFilter.FullName] : null,
        onFilter: (value: ColumnValue, row: IAssetPeopleModel) =>
          StringHelper.latinize(row.FullName.toLowerCase()).includes(
            StringHelper.latinize(value.toString().toLowerCase())
          ),
        render: (_: any, row: IAssetPeopleModel) => {
          return (
            <a
              title={row.FullName}
              className="FullName"
              onClick={() => (personModal(), setPersonId(row))}
            >
              {row.FullName}
            </a>
          );
        },
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IAssetPeopleModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              const res = await assetService.deleteAssetPeople({
                ...row,
                RecordStatus: RecordStatus.Deleted,
              });
              if (res.ok) {
                notificationService.success({
                  message: t("DICTIONARY_PEOPLE_DELETE_SUCCESS"),
                });
                await peopleLoader.refresh();
              } else {
                notificationService.error({
                  message: t("DICTIONARY_PEOPLE_DELETE_FAILURE"),
                  description: res.error?.Message,
                });
              }
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
      // Role, TODO: add to backend "role" for people
      // {
      //   key: "Role",
      //   dataIndex: "Role",
      //   title: t("DICTIONARY_PEOPLE_COLUMN_ROLE"),
      // },
    ];
  }, [stateFilter]);

  const onRemovePerson = useCallback(() => {
    deletePerson(personId);
  }, [personId]);

  const personModal = useCallback(() => {
    setShowForm((showForm) => !showForm);
    setPersonId(defaultPerson);
  }, [showForm]);

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const filter = { ...stateFilter };
    filter.PageNumber = pagination.current!;
    filter.PageSize = pagination.pageSize;

    filter.FullName = filters.FullName?.[0]?.toString();

    setStateFilter(filter);
  };

  const headerButtons = useMemo(() => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={personModal}
          title={t("DICTIONARY_PEOPLE_MODAL_BUTTON_NEW")}
        />
        <Button
          key="refresh"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={peopleLoader.refresh}
          title={t("refresh", "Refresh")}
        />
      </>
    );
  }, []);

  return (
    <div className="DictionaryPeople">
      <FormModal
        isLoading={isProcessing}
        isVisible={showForm}
        isNewForm={validPersonId}
        isDeleteButtonEnabled={!validPersonId}
        createFormTitle={t("DICTIONARY_PEOPLE_MODAL_NEW")}
        editFormTitle={t("DICTIONARY_PEOPLE_MODAL_EDIT")}
        modalClassName="DictionaryPeopleModal"
        submitFormName="DictionaryPeopleForm"
        onCloseModal={personModal}
        onDeleteButtonClick={onRemovePerson}
      >
        <DictionaryPeopleForm
          isProcessing={isProcessing}
          person={personId}
          addNewPerson={addNewPerson}
          updatePerson={updatePerson}
        />
      </FormModal>
      <PageHeader title={t("DICTIONARY_PEOPLE_TITLE")} extra={headerButtons} />
      <PageContent>
        <Table<IAssetPeopleModel>
          rowKey={(rowKey) => rowKey.Id}
          dataSource={peopleLoader.data?.Entities}
          loading={peopleLoader.loading}
          columns={getColumnNames}
          pagination={paginationConfig}
          onChange={onTableChange}
        />
      </PageContent>
    </div>
  );
};
