import { CommonStore, ICommonAppState } from "@xala/common-services";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { DictionaryApplicationList as DictionaryApplicationListDefinition } from "./DictionaryApplicationList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    applications: state.common.applications,
    isLoadingData: state.common.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectApplications: () => {
    return dispatch(CommonStore.Actions.selectApplications());
  },
});

export const DictionaryApplicationList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DictionaryApplicationListDefinition));
