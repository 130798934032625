import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { HTTP_METHOD } from "../../constants";
import { PromisifiableBase } from "../Base";
import {
  IPaymentSearchFilterModel,
  IPaymentListModel,
  IPaymentModel,
  IPaymentOptionsModel
} from "../..";

export class PaymentsService extends PromisifiableBase {
  get url(): string {
    return "/Payments";
  }

  public get = (id: number): Observable<IPaymentModel> =>
    new Observable(
      (observer: Observer<IPaymentModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        })
    );

  public getByKey = (key: string): Observable<IPaymentModel> =>
    new Observable(
      (observer: Observer<IPaymentModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              key,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetByKey`,
        })
    );

  public search = (
    filter: IPaymentSearchFilterModel
  ): Observable<IPaymentListModel> =>
    new Observable(
      (observer: Observer<IPaymentListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Search`,
        })
    );

  public options = (): Observable<IPaymentOptionsModel> =>
    new Observable(
      (observer: Observer<IPaymentOptionsModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetOptions`,
        })
    );
}
