import { AnalyticsService } from "@xala/common-services";
import { NotificationService } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../..//helpers";

const notificationService = NotificationService.getInstance();
const analyticsService = new AnalyticsService().promisify();

export const usePaymentListDataProvider = () => {
  const { t } = useTranslation();
  const provider = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      ExportToXlsx: "boolean",
      SortAscending: "boolean",
      PaymentDateFrom: "string",
      PaymentDateTo: "string",
      PaymentStatuses: "strings",
      PaymentTypes: "strings",
      PriceFrom: "number",
      PriceTo: "number",
      Users: "numbers",
      Assets: "numbers",
    },
    debounce: 800,
    loader: (filters, pagination) =>
      analyticsService.getPaymentsList({
        ...filters,
        ...pagination,
      }),
    onError: (error) =>
      notificationService.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
    deps: [],
  });

  return provider;
};
