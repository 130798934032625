import React from "react";
import {
  ActionType,
  ActionTypeHelper as CommonActionTypeHelper,
} from "@xala/common-services";
import { ChooseOption } from "@xala/common-ui";

export class ActionTypeHelper extends CommonActionTypeHelper {
  static getOptions(): React.ReactElement[] {
    return [
      <ChooseOption key="OPEN_SCREEN" value={ActionType.OpenScreen}>
        {ActionTypeHelper.getDescription(ActionType.OpenScreen)}
      </ChooseOption>,
      <ChooseOption key="OPEN_URL" value={ActionType.OpenUrl}>
        {ActionTypeHelper.getDescription(ActionType.OpenUrl)}
      </ChooseOption>,
      <ChooseOption key="CALL_TO" value={ActionType.CallTo}>
        {ActionTypeHelper.getDescription(ActionType.CallTo)}
      </ChooseOption>,
      <ChooseOption key="EMAIL_TO" value={ActionType.EmailTo}>
        {ActionTypeHelper.getDescription(ActionType.EmailTo)}
      </ChooseOption>,
    ];
  }
}
