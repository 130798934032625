import { PlatformType } from "../enums";

export class PlatformTypeHelper {
  static getDescription(value: PlatformType): string {
    switch (value) {
      case "ANY":
        return "Any";
      case "ANDROID_PHONE":
        return "Android Phone";
      case "ANDROID_TABLET":
        return "Android Tablet";
      case "ANDROID_TV":
        return "Android TV";
      case "APPLE_TV":
        return "Apple TV";
      case "IOS_PHONE":
        return "iOS Phone";
      case "IPAD":
        return "iPad";
      case "TIZEN":
        return "Tizen";
      case "WEB":
        return "Web";
      case "WEB_OS":
        return "Web OS";
      case "ROKU":
        return "Roku";
      default:
        return "Undefined";
    }
  }

  static getValue(
    value: string,
    defaultValue: PlatformType = PlatformType.Any
  ): PlatformType {
    switch (value) {
      case "ANY":
        return PlatformType.Any;
      case "ANDROID_PHONE":
        return PlatformType.AndroidPhone;
      case "ANDROID_TABLET":
        return PlatformType.AndroidTablet;
      case "ANDROID_TV":
        return PlatformType.AndroidTV;
      case "APPLE_TV":
        return PlatformType.AppleTV;
      case "IOS_PHONE":
        return PlatformType.iOSPhone;
      case "IPAD":
        return PlatformType.iPad;
      case "TIZEN":
        return PlatformType.Tizen;
      case "WEB":
        return PlatformType.Web;
      case "WEB_OS":
        return PlatformType.WebOS;
      case "ROKU":
        return PlatformType.Roku;
      default:
        return defaultValue;
    }
  }

  static getColor(value: PlatformType): string {
    let color: string;

    switch (value) {
      case "ANY":
        color = "#555";
        break;
      case "ANDROID_PHONE":
        color = "#393";
        break;
      case "ANDROID_TABLET":
        color = "#393";
        break;
      case "ANDROID_TV":
        color = "#bf7c2a";
        break;
      case "APPLE_TV":
        color = "#bf7c2a";
        break;
      case "IOS_PHONE":
        color = "#393";
        break;
      case "IPAD":
        color = "#393";
        break;
      case "TIZEN":
        color = "#bf7c2a";
        break;
      case "WEB":
        color = "#b33";
        break;
      case "WEB_OS":
        color = "#bf7c2a";
        break;
      case "ROKU":
        color = "#662d91";
        break;
      default:
        color = "#555";
        break;
    }

    return color;
  }
}
