import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import { IAssetQualityModel } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { PromisifiableBase } from "../Base";

export class AssetQualityService extends PromisifiableBase {
  get url(): string {
    return "/AssetQuality";
  }

  public getAssetQuality = (): Observable<IAssetQualityModel[]> =>
    new Observable(
      (observer: Observer<IAssetQualityModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
