import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
} from "@xala/common-services";
import { ActionPropertyModel } from "./ActionPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";

export class ApplicationBannerPropertyModel extends ComponentPropertyModel {
  Items?: any;

  Action?: ActionPropertyModel;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  hasBackgroundUrl(): boolean {
    return false;
  }
  hasIconUrl(): boolean {
    return false;
  }
  hasImageUrl(): boolean {
    return false;
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }

    let itemsProperty = component.Properties.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Items"
    );

    if (!itemsProperty) {
      this.onPropertyChange("Items", component);
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    let itemsProperty = component.Properties?.find(
      (row: IApplicationComponentPropertyModel) => row.Name === "Items"
    );

    if (itemsProperty && itemsProperty.Values) {
      this.Items = itemsProperty.Values;
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Items":
        property.PropertyType = PropertyType.Component;
        property.IsEnumerable = true;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Items":
        if (property.Values) {
          property.Values = this.Items;
        }
        break;
    }
  }
}
