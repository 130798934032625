import {
  CellType,
  IAssetCollectionModel,
  IResourceModel,
  RecordStatus,
} from "@xala/common-services";
import {
  Button,
  Checkbox,
  Choose,
  Col,
  Form,
  IChooseValue,
  Icon,
  IFormValues,
  Input,
  InputNumber,
  Link,
  Row,
  Switch,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ROUTES } from "../../../../../../constants";
import { AssetCollectionBrowserModal } from "../../../../../Asset/components/AssetCollectionBrowserModal";
import * as TranslationsModule from "../../../../../Translations";
import { CellTypeHelper } from "../../../../helpers";
import { ListComponentPropertyModel, SourceType } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./ListComponentProperties.scss";

export interface IListComponentPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IListComponentPropertiesDispatchProps {}

export interface IListComponentPropertiesOwnProps {}

export interface IListComponentPropertiesProps
  extends IListComponentPropertiesStateProps,
    IListComponentPropertiesDispatchProps,
    IListComponentPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IListComponentPropertiesState
  extends IComponentPropertiesBaseState {
  assetCollectionsBrowserVisible: boolean;
  translationsBrowserVisible: boolean;
}

export class ListComponentProperties extends ComponentPropertiesBase<
  IListComponentPropertiesProps,
  IListComponentPropertiesState,
  ListComponentPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IListComponentPropertiesState> = {
    assetCollectionsBrowserVisible: false,
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): ListComponentPropertyModel {
    const { component } = this.props;
    const properties = new ListComponentPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public onIsVisibleChange = () => {
    const { component, onComponentChange } = this.props;

    const updatedComponent = {
      ...component,
      IsVisible: !component.IsVisible,
      RecordStatus: RecordStatus.Updated,
    };

    if (onComponentChange) {
      onComponentChange(updatedComponent);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("SELECT_TRANSLATION_KEY", "Select translation key")}</label>
    );

    return (
      <Form.Item
        name="TitleTranslationKey"
        label={t("TITLE_TRANSLATION_KEY", "Title Key")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("Title")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("Enter title")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public onVisibleItemsPropertyChange = (
    value: string | number | undefined
  ) => {
    this.properties.VisibleItemsCount = value ? +value : 1;
    this.onPropertyChange("VisibleItemsCount");
  };

  public renderVisibleItemsProperty = () => {
    const { isProcessingData, t } = this.props;

    let disableInput = false;
    let minValue = 1;
    let maxValue = 10;

    switch (this.properties.CellType) {
      case CellType.HeroLandscape:
      case CellType.Highlights:
        disableInput = true;
        minValue = 1;
        maxValue = 1;
        break;
      case CellType.Channel:
      case CellType.Square:
      case CellType.Round:
      case CellType.Promo:
      case CellType.Cover:
      case CellType.Frame:
      default:
        break;
    }

    return (
      <Form.Item
        name="VisibleItemsCount"
        label={t("Visible items")}
        initialValue={this.properties.VisibleItemsCount}
      >
        <InputNumber
          disabled={isProcessingData || disableInput}
          placeholder={t("Enter visible items count")}
          onChange={this.onVisibleItemsPropertyChange}
          min={minValue}
          max={maxValue}
          defaultValue={minValue}
        />
      </Form.Item>
    );
  };

  public onCellTypePropertyChange = (value: IChooseValue) => {
    this.properties.CellType = CellTypeHelper.getValue(value as string);
    this.onPropertyChange("CellType");

    const isTypeOfChannel = this.properties.CellType === CellType.Channel;
    const isTypeOfRounded = this.properties.CellType === CellType.Round;

    if (isTypeOfChannel || isTypeOfRounded) {
      this.onSourcePropertyClear();
    }
  };

  public renderCellTypeProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="CellType"
        label={t("Cell type")}
        initialValue={this.properties.CellType}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("Select cell type")}
          defaultValue={CellType.Default}
          onChange={this.onCellTypePropertyChange}
        >
          {CellTypeHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public onSourcePropertyCancel = () => {
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertySelect = (row: IAssetCollectionModel) => {
    this.properties.SourceId = row.Id;
    this.properties.SourceName = row.Name;
    this.onPropertyChange("SourceId");
    this.onPropertyChange("SourceName");

    const getSourceType = () => {
      if (row.FilterRecentlyWatched && !row.FilterMyFavourites) {
        return SourceType.RecentlyWatched;
      } else if (!row.FilterRecentlyWatched && row.FilterMyFavourites) {
        return SourceType.MyFavorites;
      } else {
        return SourceType.Standard;
      }
    };

    this.properties.SourceType = getSourceType();
    this.onPropertyChange("SourceType");
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.SourceId = undefined;
    this.properties.SourceName = undefined;
    this.properties.deleteProperty("SourceId", component);
    this.properties.deleteProperty("SourceName", component);
    this.properties.deleteProperty("SourceType", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderSourceProperty = () => {
    const { t } = this.props;
    const { assetCollectionsBrowserVisible } = this.state;

    const isTypeOfChannel = this.properties.CellType === CellType.Channel;
    const isTypeOfRounded = this.properties.CellType === CellType.Round;

    let sourceValueView: React.ReactElement = this.properties.SourceId ? (
      <label>
        <Link
          to={`${ROUTES.ASSET_COLLECTION_DETAILS}/${this.properties.SourceId}`}
        >
          {this.properties.SourceName || this.properties.SourceId}
        </Link>
      </label>
    ) : (
      <label>{t("SELECT_SOURCE", "Select source")}</label>
    );

    return !isTypeOfChannel && !isTypeOfRounded ? (
      <Form.Item
        name="SourceId"
        label={t("SOURCE", "Source")}
        initialValue={{
          key: `${this.properties.SourceId}`,
          label: this.properties.SourceName,
        }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{sourceValueView}</Form.Item>
            <AssetCollectionBrowserModal
              visible={assetCollectionsBrowserVisible}
              onCancel={this.onSourcePropertyCancel}
              onSelect={this.onSourcePropertySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.SourceId && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onSourcePropertyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ assetCollectionsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    ) : null;
  };

  public renderIsVisibleProperty = () => {
    const { t, component } = this.props;

    return (
      <Form.Item name="IsVisible" label={t("IS_VISIBLE")}>
        <Switch
          defaultChecked={component.IsVisible}
          onChange={() => this.onIsVisibleChange()}
        />
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ListComponentProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderVisibleItemsProperty()}
            {this.renderCellTypeProperty()}
            {this.renderSourceProperty()}
            {this.renderIsVisibleProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
