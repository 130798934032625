import {
  AssetImageType,
  AssetType,
  IAssetImageModel,
  StreamType,
  TimeHelper,
} from "@xala/common-services";
import {
  Col,
  ImageWithPlaceholder,
  Row,
  Skeleton,
  Tag,
  Text,
  Title,
} from "@xala/common-ui";
import cx from "classnames";
import moment from "moment";
import React from "react";
import { IListItemComponentPreviewProps } from "./ListItemComponentPreview";
import "./ListItemFrameComponentPreview.scss";

export interface IListItemFrameComponentPreviewProps
  extends IListItemComponentPreviewProps {}

export class ListItemFrameComponentPreview extends React.Component<
  IListItemFrameComponentPreviewProps
> {
  public static defaultProps: IListItemFrameComponentPreviewProps = {
    isPlaceholder: false,
    width: 0,
  };

  public renderStatus = () => {
    const { asset } = this.props;

    if (!asset) {
      return null;
    }

    const isLiveBroadcast = asset.AssetTypeCode === AssetType.Live;
    let duration = "";

    switch (asset.AssetTypeCode) {
      case AssetType.Live:
      case AssetType.Channel: {
        if (asset.AvailableFrom) {
          const now = moment();
          const availableFrom = moment(asset.AvailableFrom);
          duration = availableFrom.from(now);
        } else {
          duration = TimeHelper.formatDurationMilliseconds(
            asset.DurationMiliseconds
              ? asset.DurationMiliseconds
              : asset.Contents?.find(
                  (c) => c.StreamTypeCode === StreamType.Main
                )?.DurationMiliseconds
          );
        }
        break;
      }
      default:
        duration = TimeHelper.formatDurationMilliseconds(
          asset.DurationMiliseconds
            ? asset.DurationMiliseconds
            : asset.Contents?.find((c) => c.StreamTypeCode === StreamType.Main)
                ?.DurationMiliseconds
        );
    }

    const liveIndicator = isLiveBroadcast && (
      <div className="ListItemFrameComponentPreview-status-online-indicator">
        <Tag color="#f50">Live</Tag>
      </div>
    );
    const durationIndicator = duration && (
      <div className="ListItemFrameComponentPreview-status-duration">
        <Tag color="#2D2D2D">{duration}</Tag>
      </div>
    );

    return (
      <div className="ListItemFrameComponentPreview-status">
        <Row justify="space-between">
          <Col>{liveIndicator}</Col>
          <Col>{durationIndicator}</Col>
        </Row>
      </div>
    );
  };

  public render() {
    const { isHovered, asset, width, placeholderSrc } = this.props;

    let frameImageUrl: string | undefined = "";
    let title: string | undefined = "";
    let description: string | undefined = "";

    if (asset) {
      description = asset.Description;
      title = asset.Title;

      if (asset.Images) {
        const assetFrameImage = asset.Images.find(
          (row: IAssetImageModel) =>
            row.AssetImageTypeCode === AssetImageType.Frame
        );

        if (assetFrameImage) {
          frameImageUrl = assetFrameImage.Url;
        }
      }
    }

    const appListItemPaddingHorizontal = 20;

    const mainStyle: React.CSSProperties = {
      width: `${width}px`,
    };

    const containerStyle: React.CSSProperties = {
      width: `${width - appListItemPaddingHorizontal}px`,
      border: "2px solid #23232e",
      overflow: "hidden",
    };

    const imageContainerStyle: React.CSSProperties = {
      width: `${width - appListItemPaddingHorizontal}px`,
      height: `${Math.round(
        (9 * (width - appListItemPaddingHorizontal)) / 16
      )}px`,
    };

    return (
      <div
        className={cx("ListItemFrameComponentPreview", {
          "ListItemFrameComponentPreview-active": isHovered,
        })}
        style={mainStyle}
      >
        <div style={containerStyle}>
          <div
            className="ListItemFrameComponentPreview-image-container"
            style={imageContainerStyle}
          >
            <ImageWithPlaceholder
              animated={true}
              imageSrc={frameImageUrl}
              imageContainerClassName="ListItemFrameComponentPreview-image"
              placeholderSrc={placeholderSrc}
            />
            {this.renderStatus()}
          </div>
          <div
            className={cx("ListItemFrameComponentPreview-info", {
              "ListItemFrameComponentPreview-info-active": isHovered,
            })}
          >
            <div className="ListItemFrameComponentPreview-info-title-container">
              <Skeleton active={true} loading={!title} paragraph={false}>
                <Row
                  justify="space-between"
                  align="middle"
                  wrap="nowrap"
                  gutter={10}
                >
                  <Col className="ellipsis">
                    <Title
                      className="ListItemFrameComponentPreview-info-title"
                      ellipsis={true}
                      level={4}
                      style={{ margin: 0 }}
                    >
                      {title}
                    </Title>
                  </Col>
                </Row>
              </Skeleton>
            </div>
            <Text
              className="ListItemFrameComponentPreview-info-description"
              style={{ margin: 0 }}
              ellipsis={true}
            >
              <Skeleton
                active={true}
                loading={!description}
                paragraph={false}
                title={{ width: "70%" }}
              >
                {description}
              </Skeleton>
            </Text>
          </div>
        </div>
      </div>
    );
  }
}
