import {
  IAssetContentModel,
  IAssetModel,
  dispatch,
  AssetStore,
  ICommonAppState,
  ContentStatus,
  RecordStatus,
} from "@xala/common-services";
import {
  Choose,
  ChooseOption,
  Form,
  IFormValues,
  required,
  Input,
} from "@xala/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { assign } from "lodash";
import { ContentSource } from "../../enums";

const assetSelector = (state: ICommonAppState) => state.asset;

const ASSET_CONTENT_TYPE_CODE = "video/mp4";

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
interface AssetExternalIdUploadModalProps {
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
}

export const AssetExternalIdUploadModal = ({
  ...props
}: AssetExternalIdUploadModalProps) => {
  const { asset, assetContent } = props;

  const { t } = useTranslation();
  const [form] = useForm();

  const [contentStreamTypeState, setContentStreamTypeState] = useState<
    string
  >();

  const { assetContentStreamTypes } = useSelector(assetSelector);

  const contentExistsForStreamType =
    contentStreamTypeState &&
    asset?.Contents?.some(
      (content) =>
        content.ContentTypeCode === ASSET_CONTENT_TYPE_CODE &&
        content.StreamTypeCode === contentStreamTypeState
    );

  const validateContentTypeUniqueness = (
    _rule: any,
    value: string | number,
    callback: (error?: string) => void
  ) =>
    contentExistsForStreamType
      ? callback(t("CONTENT_TYPE_EXISTS_FOR_STREAM_TYPE"))
      : callback();

  useEffect(() => {
    if (ASSET_CONTENT_TYPE_CODE && contentStreamTypeState) {
      form.validateFields(["AssetContentStreamTypeCode"]);
    }
  }, [contentStreamTypeState]);

  const onFinish = async (values: IFormValues) => {
    if (!asset) {
      return;
    }

    const payload: IAssetContentModel = assign({}, assetContent, {
      AssetId: asset?.Id,
      Path: values.ExternalId,
      ContentTypeCode: ASSET_CONTENT_TYPE_CODE,
      ContentStatusCode: ContentStatus.Ready,
      StreamTypeCode: values.AssetContentStreamTypeCode,
      UrlSource: values.UrlSource,
    });

    if (assetContent?.Id) {
      dispatch(AssetStore.Actions.updateAssetContent({ ...payload, RecordStatus: RecordStatus.Updated }));
    } else {
      dispatch(AssetStore.Actions.addAssetContent({ ...payload, RecordStatus: RecordStatus.Inserted }));
    }
  };

  const renderContentStreamTypeField = () => {
    return (
      <Form.Item
        name="AssetContentStreamTypeCode"
        label={t("ASSET_CONTENT_STREAM_TYPE_LABEL")}
        key="streamType"
        initialValue={assetContent?.StreamTypeCode}
        rules={[required(), { validator: validateContentTypeUniqueness }]}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_TYPE_PLACEHOLDER")}
          loading={assetContentStreamTypes.isFetching}
          disabled={assetContentStreamTypes.isFetching}
          onChange={(value) => setContentStreamTypeState(value as string)}
        >
          {assetContentStreamTypes.data?.map((assetContentStreamType) => (
            <ChooseOption
              key={assetContentStreamType.Code}
              value={assetContentStreamType.Code}
            >
              {assetContentStreamType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderExternalIdField = () => {
    return (
      <Form.Item
        name="ExternalId"
        label={t("ASSET_EXTERNAL_ID_LABEL")}
        key="externalId"
        initialValue={assetContent?.Url}
        {...formLayout}
        rules={[required()]}
      >
        <Input placeholder={t("ASSET_EXTERNAL_ID_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderUrlSourceField = () => {
    return (
      <Form.Item
        name="UrlSource"
        label={t("ASSET_EXTERNAL_SOURCE_LABEL")}
        key="urlSource"
        {...formLayout}
        initialValue={assetContent?.UrlSource}
        rules={[required()]}
      >
        <Choose placeholder={t("ASSET_EXTERNAL_SOURCE_PLACEHOLDER")}>
          <ChooseOption
            key={ContentSource.Konodrac}
            value={ContentSource.Konodrac}
          >
            {ContentSource.Konodrac}
          </ChooseOption>
          <ChooseOption
            key={ContentSource.Brightcove}
            value={ContentSource.Brightcove}
          >
            {ContentSource.Brightcove}
          </ChooseOption>
        </Choose>
      </Form.Item>
    );
  };

  return (
    <Form
      form={form}
      name="AssetExternalIdUploadForm"
      className="AssetContentForm"
      onFinish={onFinish}
    >
      {renderContentStreamTypeField()}
      {renderExternalIdField()}
      {renderUrlSourceField()}
    </Form>
  );
};
