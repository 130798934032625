import {
  AdvertisementStore,
  IAdvertisementBlockModel,
  IAdvertisementBlocksSearchFilterModel,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AdvertisementBlocksList as AdvertisementBlocksListDefinition } from "./AdvertisementBlocksList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    advertisementBlocks: state.advertisement.advertisementBlocks,
    isLoadingData: state.advertisement.isLoadingData,
    addModalVisible: state.advertisement.addModalVisible,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleAdvertisementBlockModal: (visibility: boolean) => {
    return dispatch(
      AdvertisementStore.Actions.toggleAdvertisementBlockModal(visibility)
    );
  },
  addAdvertisementBlock: (data: IAdvertisementBlockModel) => {
    return dispatch(AdvertisementStore.Actions.addAdvertisementBlock(data));
  },
  searchAdvertisementBlocks: (
    filter: IAdvertisementBlocksSearchFilterModel
  ) => {
    return dispatch(
      AdvertisementStore.Actions.searchAdvertisementBlocks(filter)
    );
  },
});

export const AdvertisementBlocksList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBlocksListDefinition));
