import {
  Button,
  Col,
  Form,
  Icon,
  IFormValues,
  Input,
  Row,
  Switch,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationBannerPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ApplicationBannerItemsModal } from "../../../../components/ApplicationBannerItemsModal/ApplicationBannerItemsModal";
import { RecordStatus } from "@xala/common-services";

export interface IApplicationBannerPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IApplicationBannerPropertiesDispatchProps {}

export interface IApplicationBannerPropertiesOwnProps {}

export interface IApplicationBannerPropertiesProps
  extends IApplicationBannerPropertiesStateProps,
    IApplicationBannerPropertiesDispatchProps,
    IApplicationBannerPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationBannerPropertiesState
  extends IComponentPropertiesBaseState {
  itemsModalVisible: boolean;
  isItemsPropertyCreated: boolean;
}

export class ApplicationBannerProperties extends ComponentPropertiesBase<
  IApplicationBannerPropertiesProps,
  IApplicationBannerPropertiesState,
  ApplicationBannerPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
    itemsBrowserVisible: false,
  };

  public state: Readonly<IApplicationBannerPropertiesState> = {
    itemsModalVisible: false,
    isItemsPropertyCreated: false,
  };

  getPropertiesModel(): ApplicationBannerPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationBannerPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    component.Name = values.Name;
  };

  public onIsVisibleChange = () => {
    const { component, onComponentChange } = this.props;
    const updatedComponent = {
      ...component,
      IsVisible: !component.IsVisible,
      RecordStatus: RecordStatus.Updated,
    };
    if (onComponentChange) {
      onComponentChange(updatedComponent);
    }
  };

  public onItemsModalCancel = () => {
    this.setState({ itemsModalVisible: false });
  };

  public onItemsPropertyCreate = () => {
    this.setState({ isItemsPropertyCreated: true });
  };

  private checkIfPropertyAlreadyExists = this.props?.component?.Properties?.find(
    (property) => property.Name === "Items"
  );

  public renderItemsProperty = () => {
    const { t } = this.props;
    const { itemsModalVisible } = this.state;
    return (
      <Form.Item
        name="Items"
        label={t("Items")}
        initialValue={this.properties.Items}
      >
        <Row gutter={8}>
          <Col span={18}>
            {itemsModalVisible && (
              <ApplicationBannerItemsModal
                onItemsModalCancel={this.onItemsModalCancel}
                modalVisible={itemsModalVisible}
              />
            )}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ itemsModalVisible: true });

                if (
                  !this.state.isItemsPropertyCreated &&
                  !this.checkIfPropertyAlreadyExists
                ) {
                  this.properties.Items = [];
                  this.onPropertyChange("Items");
                  this.onItemsPropertyCreate();
                }
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderIsVisibleProperty = () => {
    const { t, component } = this.props;
    return (
      <Form.Item name="IsVisible" label={t("IS_VISIBLE")}>
        <Switch
          defaultChecked={component.IsVisible}
          onChange={() => this.onIsVisibleChange()}
        />
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ApplicationMenuItemProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderItemsProperty()}
            {this.renderIsVisibleProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
