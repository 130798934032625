import {
  GuidHelper,
  IAdvertisementBlockModel,
  IAdvertisementBoardModel,
  IAdvertisementBoardTypeModel,
  IErrorModel,
  IStateModel,
  RecordStatus,
  AdvertisementBoardType,
  AdvertisementBoardTypeHelper,
} from "@xala/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  IChooseValue,
  IFormInstance,
  IFormValues,
  Input,
  Modal,
  required,
  urlValid,
  Row,
  Switch,
} from "@xala/common-ui";
import { LabeledValue } from "antd/lib/select";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./AdvertisementBoardAddModal.scss";
import { advertisementBoardLeadHelper as utils } from "../../helpers";

export interface IAdvertisementBoardAddModalStateProps {
  advertisementBlock: IStateModel<IAdvertisementBlockModel>;
  advertisementBoard?: IAdvertisementBoardModel;
  actionType?: string;
  error?: IErrorModel;
  boardsTypes: IAdvertisementBoardTypeModel[];
  isProcessingData: boolean;
}

export interface IAdvertisementBoardAddModalDispatchProps {
  selectBoardsTypes: ActionCreator<Action>;
}

export interface IAdvertisementBoardAddModalOwnProps {
  advertisementBlockId: number;
  visible: boolean;
  onCancel?: () => void;
  onSuccess: () => void;
}

export interface IAdvertisementBoardAddModalProps
  extends IAdvertisementBoardAddModalStateProps,
    IAdvertisementBoardAddModalDispatchProps,
    IAdvertisementBoardAddModalOwnProps,
    WithTranslation {}

interface IAdvertisementBoardAddModalState {
  isBoardTypeLead: boolean;
}

export class AdvertisementBoardAddModal extends React.PureComponent<
  IAdvertisementBoardAddModalProps
> {
  public static defaultProps = {
    visible: false,
  };

  public state: IAdvertisementBoardAddModalState = {
    isBoardTypeLead: false,
  };

  formRef = React.createRef<IFormInstance>();

  public componentDidMount() {
    const { boardsTypes, selectBoardsTypes } = this.props;

    if (!boardsTypes || boardsTypes.length === 0) {
      selectBoardsTypes();
    }
  }

  public onCancelClick = () => {
    const { onCancel } = this.props;

    this.formRef?.current?.resetFields();

    if (onCancel) {
      onCancel();
    }
  };

  public onFinish = (values: IFormValues) => {
    const { advertisementBlock, advertisementBlockId, onSuccess } = this.props;

    const boardType =
      AdvertisementBoardTypeHelper.getValue(values.BoardType.value) ??
      AdvertisementBoardType.Image;

    let advertisementBoard: IAdvertisementBoardModel = {
      Id: -1,
      AdBlockId: advertisementBlockId,
      Guid: GuidHelper.newGuid(),
      Name: values.Name,
      ContentUrl: values.ContentUrl,
      Sequence: values.Sequence,
      BoardType: boardType,
      RecordStatus: RecordStatus.Inserted,
    };

    if (utils.isLead(boardType)) {
      let consentTextColor = values.ConsentTextColor;
      if (consentTextColor && consentTextColor[0] !== "#") {
        consentTextColor = "#" + consentTextColor;
      }
      let titleColor = values.InputTitleColor;
      if (titleColor && titleColor[0] !== "#") {
        titleColor = "#" + titleColor;
      }

      advertisementBoard.InputX = Number(values.InputX);
      advertisementBoard.ConsentText = values.ConsentText;
      advertisementBoard.ConsentTextColor = consentTextColor;
      advertisementBoard.InputY = Number(values.InputY);
      advertisementBoard.InputWidth = Number(values.InputWidth);
      advertisementBoard.InputHeight = Number(values.InputHeight);
      advertisementBoard.ShowText = Boolean(values.InputTitleIsVisible);
      advertisementBoard.TextColor = titleColor;
      advertisementBoard.TextColor = advertisementBoard.ShowText
        ? advertisementBoard.TextColor
        : "";
    }

    if (advertisementBlock.Data) {
      if (!advertisementBlock.Data.Boards) {
        advertisementBlock.Data.Boards = [];
      }

      advertisementBlock.Data.Boards.push(advertisementBoard);

      this.formRef?.current?.resetFields();
      this.setState({ isBoardTypeLead: false });

      onSuccess();
    }
  };

  public renderBoardTypeField = () => {
    const { isProcessingData, t, boardsTypes } = this.props;

    const options: React.ReactElement[] = [];

    for (const boardType of boardsTypes) {
      options.push(
        <ChooseOption key={boardType.Code} value={boardType.Code}>
          {boardType.DisplayName}
        </ChooseOption>
      );
    }

    return (
      <Form.Item
        name="BoardType"
        label={t("BOARD_TYPE")}
        key="BoardType"
        rules={[{ required: true, message: t("PLEASE_SELECT_BOARD_TYPE") }]}
      >
        <Choose
          labelInValue={true}
          defaultActiveFirstOption={false}
          filterOption={false}
          placeholder={t("CHOOSE_TYPE")}
          loading={isProcessingData}
          onChange={this.onChangeBoardType}
        >
          {options}
        </Choose>
      </Form.Item>
    );
  };

  public onChangeBoardType = (value?: IChooseValue): void => {
    value = value as LabeledValue;

    if (value) {
      const boardType = AdvertisementBoardTypeHelper.getValue(
        String(value.value)
      );
      if (boardType && utils.isLead(boardType)) {
        return this.setState({ isBoardTypeLead: true });
      }
    }
    return this.setState({ isBoardTypeLead: false });
  };

  public renderNameField = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item name="Name" label={t("NAME")} rules={[required()]}>
        <Input disabled={isProcessingData} placeholder={t("ENTER_NAME")} />
      </Form.Item>
    );
  };

  public renderContentUrlField = () => {
    const { t } = this.props;

    return (
      <Form.Item
        name="ContentUrl"
        label={t("CONTENT_URL")}
        key="path"
        rules={[urlValid(), required()]}
      >
        <Input placeholder={t("PLEASE_ENTER_CONTENT_URL")} />
      </Form.Item>
    );
  };

  public renderLeadExtraFields = () => {
    const { t } = this.props;
    const { isBoardTypeLead } = this.state;

    if (isBoardTypeLead) {
      return (
        <>
          <Form.Item
            rules={[required()]}
            name="ConsentText"
            label={t("LEAD_INPUT_CONSENT_TEXT")}
          >
            <Input placeholder={t("PLEASE_ENTER_LEAD_INPUT_CONSENT_TEXT")} />
          </Form.Item>
          <Form.Item
            name="ConsentTextColor"
            label={t("LEAD_INPUT_CONSENT_TEXT_COLOR")}
          >
            <Input
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_CONSENT_TEXT_COLOR")}
            />
          </Form.Item>
          <Form.Item name="InputX" label={t("LEAD_INPUT_POSITION_LEFT")}>
            <Input placeholder={t("PLEASE_ENTER_LEAD_INPUT_POSITION_LEFT")} />
          </Form.Item>
          <Form.Item name="InputY" label={t("LEAD_INPUT_POSITION_TOP")}>
            <Input placeholder={t("PLEASE_ENTER_LEAD_INPUT_POSITION_TOP")} />
          </Form.Item>
          <Form.Item name="InputWidth" label={t("LEAD_INPUT_SIZE_WIDTH")}>
            <Input placeholder={t("PLEASE_ENTER_LEAD_INPUT_SIZE_WIDTH")} />
          </Form.Item>
          <Form.Item name="InputHeight" label={t("LEAD_INPUT_SIZE_HEIGHT")}>
            <Input placeholder={t("PLEASE_ENTER_LEAD_INPUT_SIZE_HEIGHT")} />
          </Form.Item>
          <Form.Item
            name="InputTitleIsVisible"
            valuePropName="checked"
            label={t("LEAD_INPUT_TITLE_IS_VISIBLE")}
          >
            <Switch />
          </Form.Item>
          <Form.Item name="InputTitleColor" label={t("LEAD_INPUT_TITLE_COLOR")}>
            <Input placeholder={t("PLEASE_ENTER_LEAD_INPUT_TITLE_COLOR")} />
          </Form.Item>
        </>
      );
    }
  };

  public renderError = () => {
    const { error, actionType, t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
        md: {
          span: 16,
          offset: 8,
        },
        lg: {
          span: 16,
          offset: 8,
        },
      },
    };

    return null;
  };

  public render() {
    const { t, visible, actionType, advertisementBoard } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 16 },
      },
    };

    return (
      <Modal
        title={t("ADD_NEW_ADVERTISEMENT_BOARD")}
        onCancel={this.onCancelClick}
        visible={visible}
        footer={
          <>
            <Button key="cancel" onClick={this.onCancelClick}>
              {t("CANCEL")}
            </Button>
            <Button
              form="AdvertisementBoardAddForm"
              key="submit"
              htmlType="submit"
            >
              {t("ADD")}
            </Button>
          </>
        }
      >
        <Form
          name="AdvertisementBoardAddModal"
          id="AdvertisementBoardAddForm"
          {...formItemLayout}
          className="AdvertisementBoardAddModal"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              {this.renderBoardTypeField()}
              {this.renderNameField()}
              {this.renderContentUrlField()}
              {this.renderLeadExtraFields()}
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
