import { CreatorStreamState } from "@xala/common-services";
import { Tag } from "@xala/common-ui";
import React from "react";

export interface ChannelStateTagProps {
  state: CreatorStreamState;
}

export const MediaChannelStateTag = ({ state }: ChannelStateTagProps) => {
  let color;

  switch (state) {
    case CreatorStreamState.Starting:
      color = "#e29800";
      break;
    case CreatorStreamState.Running:
      color = "#0a0";
      break;
    case CreatorStreamState.Stopping:
      color = "#a00";
      break;
  }

  return <Tag color={color}>{state}</Tag>;
};
