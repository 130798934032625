import { Observable, Observer } from "rxjs";
import {
  AxiosSubscriber,
  HTTP_METHOD,
  IAnalyticsPaymentListFilterModel,
  IAnalyticsSalesRevenueFilterModel,
  IAnalyticsSalesRevenueModel,
} from "../..";
import { IAnalyticsPaymentListModel } from "../../models/Analytics/IAnalyticsPaymentListModel";
import { PromisifiableBase } from "../Base";

export class AnalyticsService extends PromisifiableBase {
  get url(): string {
    return "/Analytics";
  }

  public getSalesRevenue = (
    filter: IAnalyticsSalesRevenueFilterModel
  ): Observable<IAnalyticsSalesRevenueModel> =>
    new Observable(
      (observer: Observer<IAnalyticsSalesRevenueModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetSalesRevenue`,
        })
    );

  public getPaymentsList = (
    filter: IAnalyticsPaymentListFilterModel
  ): Observable<IAnalyticsPaymentListModel> =>
    new Observable(
      (observer: Observer<IAnalyticsPaymentListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetPaymentsList`,
        })
    );
}
