import {
  AssetStore,
  AssetType,
  Gender,
  IProfileModel,
  IUserModel,
  IUserSettingsModel,
  RecordStatus,
  TownsStore,
  UserService,
  UserSettingsStore,
  useServiceCaller,
} from "@xala/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  IDaggerChangeEvent,
  Input,
  NotificationService,
  Row,
  Switch,
  useValidate,
} from "@xala/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";
import { UserAvatarDragger } from "../UserAvatarDragger";
import { FormModal } from "../../../../components";
import "./UserCreateModalForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "@xala/studio/src/store";
import { push } from "connected-react-router";

const notificationService = NotificationService.getInstance();
const userService = new UserService().promisify();

const GENDERS_ARRAY = [Gender.Female, Gender.Male, Gender.Other];

interface UserCreateModalFormProps {
  visible: boolean;
  onCancel: () => void;
  profiles: IProfileModel[];
  viewType: "customers" | "administrators" | "all";
}

const DEFAULT_DOMAIN_NAME = "laxarxames.cat";

export const UserCreateModalForm = ({
  visible,
  onCancel,
  profiles,
  viewType,
}: UserCreateModalFormProps) => {
  const [t] = useTranslation();
  const validate = useValidate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState<number | null>(null);

  const towns = useSelector((state: IAppState) => state.towns.towns.Entities);
  const categories = useSelector(
    (state: IAppState) => state.asset.assetCategories.Entities
  );
  const channels = useSelector(
    (state: IAppState) => state.asset.assets.Entities
  );
  const updateUserSettingsActionType = useSelector(
    (state: IAppState) => state.userSettings.actionType
  );

  if (
    updateUserSettingsActionType ===
      UserSettingsStore.Consts.UPDATE_USER_SETTINGS_SUCCESS &&
    userId
  ) {
    dispatch(
      push(
        `${
          viewType === "customers"
            ? ROUTES.CUSTOMER_DETAILS
            : viewType === "administrators"
            ? ROUTES.ADMINISTRATOR_DETAILS
            : ROUTES.USER_DETAILS
        }/${userId}`
      )
    );
  }

  useEffect(() => {
    const channelsPayload = {
      PageSize: 999,
      IncludeCount: true,
      Types: [AssetType.Channel],
    };

    const categoriesPayload = {
      PageSize: 999,
      UpToDate: true,
      IncludeCount: true,
    };

    dispatch(TownsStore.Actions.getTowns());
    dispatch(AssetStore.Actions.searchAsset(channelsPayload));
    dispatch(AssetStore.Actions.searchAssetCategories(categoriesPayload));
  }, []);

  const [createUser, { processing }] = useServiceCaller(
    async (data: IUserModel) => {
      const result = await userService.insert(data);
      if (!result.ok) {
        notificationService.error({
          message: t("USER_CREATE_INSERT_USER_FAILURE"),
          description: result.error.Message || result.error,
        });
      } else {
        notificationService.success({
          message: t("USER_CREATE_INSERT_USER_SUCCESS"),
        });
        if (result.data.Id) {
          const userId = result.data.Id;
          const rowVersion = result.data.RowVersion;
          setUserId(userId);
          return { userId, rowVersion };
        }
      }
    },
    []
  );

  const avatarFileField = () => (
    <Form.Item
      name="AvatarFile"
      getValueFromEvent={onAvatarImageChange}
      valuePropName="file"
      style={{ textAlign: "center", marginBottom: 0 }}
    >
      <UserAvatarDragger name="Avatar" />
    </Form.Item>
  );

  const firstNameField = () => (
    <Form.Item
      name="FirstName"
      label={t("USER_ACCOUNT_FORM_NAME_LABEL")}
      rules={validate.required()}
    >
      <Input placeholder={t("USER_ACCOUNT_FORM_NAME_PLACEHOLDER")} />
    </Form.Item>
  );

  const surnameField = () => (
    <Form.Item
      name="Surname"
      label={t("USER_ACCOUNT_FORM_SURNAME_LABEL")}
      rules={validate.required()}
    >
      <Input placeholder={t("USER_ACCOUNT_FORM_SURNAME_PLACEHOLDER")} />
    </Form.Item>
  );

  const emailField = () => (
    <Form.Item
      name="Email"
      label={t("USER_ACCOUNT_FORM_EMAIL_LABEL")}
      rules={[...validate.required(), ...validate.email()]}
    >
      <Input
        addonBefore="@"
        placeholder={t("USER_ACCOUNT_FORM_EMAIL_PLACEHOLDER")}
      />
    </Form.Item>
  );

  const dateOfBirthField = () => (
    <Form.Item name="DateOfBirth" label={t("USER_ACCOUNT_FORM_BIRTHDAY_LABEL")}>
      <Input type="date" />
    </Form.Item>
  );

  const genderField = () => (
    <Form.Item name="GenderCode" label={t("USER_ACCOUNT_FORM_GENDER_LABEL")}>
      <Choose
        placeholder={t("USER_ACCOUNT_FORM_GENDER_PLACEHOLDER")}
        optionFilterProp="children"
      >
        {GENDERS_ARRAY.map((gender, index) => (
          <ChooseOption key={index} value={gender}>
            {gender}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const cityField = () => (
    <Form.Item
      name="City"
      label={t("USER_ACCOUNT_FORM_CITY_LABEL")}
      rules={validate.required({
        choiceField: true,
      })}
    >
      <Choose
        placeholder={t("USER_ACCOUNT_FORM_CITY_PLACEHOLDER")}
        optionFilterProp="children"
        showSearch
      >
        {towns.map((town) => (
          <ChooseOption key={town.Id} value={town.Id}>
            {town.Name}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const channelsField = () => (
    <Form.Item
      name="Channels"
      label={t("USER_ACCOUNT_FORM_CHANNELS_LABEL")}
      rules={validate.maxItems(2)}
    >
      <Choose
        mode="multiple"
        placeholder={t("USER_ACCOUNT_FORM_CHANNELS_PLACEHOLDER")}
        optionFilterProp="children"
      >
        {channels.map((channel) => (
          <ChooseOption key={channel.Id} value={channel.Id}>
            {channel.Title}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const preferencesField = () => (
    <Form.Item
      name="Preferences"
      label={t("USER_ACCOUNT_FORM_PREFERENCES_LABEL")}
      rules={validate.maxItems(5)}
    >
      <Choose
        mode="multiple"
        placeholder={t("USER_ACCOUNT_FORM_PREFERENCES_PLACEHOLDER")}
        optionFilterProp="children"
      >
        {categories.map((category) => (
          <ChooseOption key={category.Id} value={category.Id}>
            {category.Name}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const profilesField = () => (
    <Form.Item
      name="Profiles"
      label={t("USER_ACCOUNT_FORM_PROFILE_LABEL")}
      rules={validate.required({
        choiceField: true,
        message: t("USER_ACCOUNT_FORM_REQUIRE_VALIDATION_MESSAGE"),
      })}
    >
      <Choose
        mode="multiple"
        placeholder={t("USER_ACCOUNT_FORM_PROFILE_PLACEHOLDER")}
        optionFilterProp="children"
      >
        {profiles.map((profile) => (
          <ChooseOption key={profile.Id} value={profile.Id}>
            {profile.Name}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const onFinish = useCallback(
    async (values) => {
      const payload: IUserModel = {
        Id: -1,
        FullName: `${values.FirstName} ${values.Surname}`,
        Email: values.Email,
        FirstName: values.FirstName,
        Surname: values.Surname,
        DateOfBirth: new Date(values.DateOfBirth),
        GenderCode: values.GenderCode,
        RecordStatus: RecordStatus.Inserted,
        AvatarFile: values.AvatarFile,
        EmailConfirmed: !values.EmailConfirmed,
        Profiles: values.Profiles.map((id: number) => {
          const profile = profiles.find((profile) => profile.Id === id);
          return (
            profile && {
              ProfileId: profile.Id,
              ProfileCode: profile.Code,
              ProfileName: profile.Name,
              RecordStatus: RecordStatus.Inserted,
            }
          );
        }).filter(Boolean),
        Origin: DEFAULT_DOMAIN_NAME,
      };

      const data = await createUser(payload);

      if (data) {
        const filter: IUserSettingsModel = {
          UserId: data.userId,
          Towns: [{ Id: values.City }],
          Channels: values.Channels?.map((channel: number) => {
            return {
              Id: channel,
            };
          }),
          Categories: values.Preferences?.map((category: number) => {
            return {
              Id: category,
            };
          }),
          RecordStatus: RecordStatus.Updated,
          RowVersion: data.rowVersion,
        };

        dispatch(UserSettingsStore.Actions.updateUserSettings(filter));
      }
    },
    [profiles]
  );

  const onAvatarImageChange = (e: IDaggerChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={processing}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t("USER_CREATE_ACCOUNT_MODAL_TITLE")}
      editFormTitle={t("USER_CREATE_ACCOUNT_MODAL_TITLE")}
      modalClassName="UserCreateAccountForm"
      submitFormName="UserCreateAccountForm"
      onCloseModal={onCancel}
      onDeleteButtonClick={() => {}}
    >
      <Form
        form={form}
        name="UserCreateAccountForm"
        className="UserCreateAccountForm"
        layout="vertical"
        onFinish={onFinish}
      >
        {avatarFileField()}
        {firstNameField()}
        {surnameField()}
        {emailField()}
        {dateOfBirthField()}
        {genderField()}
        {cityField()}
        {channelsField()}
        {preferencesField()}
        {profilesField()}
        <Form.Item>
          <Row gutter={8}>
            <Col>
              <Form.Item
                name="EmailConfirmed"
                key="EmailConfirmed"
                valuePropName="checked"
                initialValue="true"
              >
                <Switch style={{ marginRight: "8px" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="EmailConfirmed"
                key="EmailConfirmed"
                valuePropName="checked"
                initialValue="true"
              >
                <label>
                  {t("USER_ACCOUNT_FORM_SEND_LINK_TO_CREATE_PASSWORD")}
                </label>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </FormModal>
  );
};
