import {
  AssetStore,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  ICommonAppState,
  TimeHelper,
} from "@xala/common-services";
import {
  Button,
  Empty,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  setTableColumnSearchProps,
  Table,
  useSearchParamsFilters,
  useSearchParamsPagination,
  useSyncedState,
  Popconfirm,
  DateRangePicker,
  ITableColumnFilterDropdownProps,
} from "@xala/common-ui";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../constants";
import { AddAssetCollectionModal } from "../AddAssetCollectionModal";

const notificationService = NotificationService.getInstance();

const assetCollectionSelector = (state: ICommonAppState) => state.asset;

export const AssetsCollectionsList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    addAssetCollectionModalVisible,
    setAddAssetCollectionModalVisible,
  ] = useState<boolean>(false);
  const { assetCollections, isFetching, action } = useSelector(
    assetCollectionSelector
  );

  const pagination = useSearchParamsPagination(assetCollections.TotalCount);
  const filters = useSearchParamsFilters({
    Name: "string",
    Description: "string",
    FullTextSearch: "string",
    CreatedByFullName: "string",
    CreatedFrom: "string",
    CreatedTo: "string",
  });

  const [fullTextSearch, setFullTextSearch] = useSyncedState(
    () => filters.current.FullTextSearch,
    [filters.current.FullTextSearch]
  );

  const searchAssetCollection = useCallback(
    (filter: IAssetCollectionSearchFilterModel) =>
      dispatch(AssetStore.Actions.searchAssetCollection(filter)),
    [dispatch]
  );

  useEffect(() => {
    searchAssetCollection({
      ...filters.current,
      ...pagination.current,
    });
  }, [filters.current, pagination.current]);

  useEffect(() => {
    switch (action?.type) {
      case AssetStore.Consts.SEARCH_ASSETS_COLLECTION_FAILURE:
        notificationService.error({
          message: t(
            "SEARCH_ASSETS_COLLECTION_FAILURE",
            "There was an error while loading play list data."
          ),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.DELETE_ASSETS_COLLECTION_SUCCESS:
        notificationService.success({
          message: t("DELETE_ELEMENT_SUCCESS", "Element successfully deleted."),
        });
        onRefreshClick();
        break;
      case AssetStore.Consts.DELETE_ASSETS_COLLECTION_FAILURE:
        notificationService.error({
          message: t(
            "DELETE_ELEMENT_FAILTURE",
            "There was an error while deleting element."
          ),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const onRefreshClick = () =>
    searchAssetCollection({
      ...filters.current,
      ...pagination.current,
    });

  const getColumnsProps = (): Array<
    ITableColumnProps<IAssetCollectionModel>
  > => [
    {
      key: "Name",
      dataIndex: "Name",
      title: t("Name", "Name"),
      ellipsis: true,
      width: "300px",
      filteredValue: filters.current.Name ? [filters.current.Name] : null,
      render: (_: any, row: IAssetCollectionModel) => (
        <Link to={`${ROUTES.ASSET_COLLECTION_DETAILS}/${row.Id}`}>
          {row.Name}
        </Link>
      ),
      ...setTableColumnSearchProps("Title"),
    },
    {
      key: "Description",
      dataIndex: "Description",
      ellipsis: true,
      title: t("description", "Description"),
      filteredValue: filters.current.Description
        ? [filters.current.Description]
        : null,
      ...setTableColumnSearchProps("Description"),
    },
    {
      key: "CreatedByFullName",
      dataIndex: "CreatedByFullName",
      title: t("createdBy", "Created by"),
      align: "center",
      width: "250px",
      filteredValue: filters.current.CreatedByFullName
        ? [filters.current.CreatedByFullName]
        : null,
      ...setTableColumnSearchProps("Created by"),
    },
    {
      key: "Created",
      dataIndex: "Created",
      align: "center",
      width: "140px",
      title: t("PLAY_LIST_TABLE_CREATION_DATE_COLUMN", "Creation date"),
      filteredValue:
        filters.current.CreatedFrom !== undefined &&
        filters.current.CreatedTo !== undefined
          ? [filters.current.CreatedFrom, filters.current.CreatedTo]
          : undefined,
      filterDropdown: (event: ITableColumnFilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;

        const [dateRange, setDateRange] = useState<any>([
          filters.current.CreatedFrom && moment(filters.current.CreatedFrom),
          filters.current.CreatedTo && moment(filters.current.CreatedTo),
        ]);

        const filterValue: [string, string] | undefined =
          selectedKeys && selectedKeys.length > 1
            ? [selectedKeys[0]?.toString(), selectedKeys[1]?.toString()]
            : undefined;

        return (
          <div style={{ padding: 8 }}>
            <DateRangePicker
              value={dateRange}
              onChange={(values: any, dateStrings: [string, string]) => {
                setSelectedKeys([
                  TimeHelper.toUTCString(dateStrings[0]),
                  TimeHelper.toUTCString(`${dateStrings[1]} 23:59:59`),
                ]);
                setDateRange(values);
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="link"
                size="small"
                disabled={!filterValue}
                onClick={() => {
                  if (clearFilters) {
                    if (setSelectedKeys) {
                      setSelectedKeys([]);
                    }
                    setDateRange([]);

                    clearFilters();
                  }
                }}
              >
                <Trans i18nKey="BUTTON_RESET">Reset</Trans>
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (confirm) {
                    confirm();
                  }
                }}
                size="small"
              >
                <Trans i18nKey="BUTTON_OK">OK</Trans>
              </Button>
            </div>
          </div>
        );
      },
      render: (created?: string) =>
        created ? TimeHelper.format(created) : null,
    },
    {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      width: "100px",
      title: t("TABLE_ACTIONS_COLUMN", "Actions"),
      render: (_: any, row: IAssetCollectionModel) => (
        <Popconfirm
          title={t(
            "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
            "Are you sure you want to delete element?"
          )}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            dispatch(AssetStore.Actions.deleteAssetsCollection(row));
          }}
          okText={t("BUTTON_YES", "Yes")}
          cancelText={t("BUTTON_NO", "No")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT", "Delete element")}
          />
        </Popconfirm>
      ),
    },
  ];

  const onTableChange = (
    _: any,
    { Name, Description, CreatedByFullName, Created }: ITableFilter
  ) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      Name: Name?.[0]?.toString(),
      Description: Description?.[0]?.toString(),
      CreatedByFullName: CreatedByFullName?.[0]?.toString(),
      CreatedFrom: Created?.[0]?.toString(),
      CreatedTo: Created?.[1]?.toString(),
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const columns = getColumnsProps();

  const renderAddAssetCollectionModal = () => {
    return (
      <AddAssetCollectionModal
        visible={addAssetCollectionModalVisible}
        onCancel={() => setAddAssetCollectionModalVisible(false)}
        onSuccess={() => setAddAssetCollectionModalVisible(false)}
      />
    );
  };

  return (
    <PageContent footer={<Pagination {...pagination.props} />}>
      <PageHeader
        title="Play List"
        extra={
          <>
            <InputSearch
              placeholder="Search"
              value={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              shape="circle"
              disabled={!filters.anyActive}
              icon={<Icon type="clear" />}
              onClick={filters.clear}
              title="Refresh data"
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={onRefreshClick}
              title="Refresh data"
            />
            <Button
              shape="circle"
              type="primary"
              icon={<Icon type="plus" />}
              onClick={() => setAddAssetCollectionModalVisible(true)}
              title="Add assets collection"
            />
          </>
        }
      />
      <Table<IAssetCollectionModel>
        locale={{
          emptyText: (
            <>
              <Empty />
              {filters.anyActive && (
                <Button icon={<Icon type="clear" />} onClick={filters.clear}>
                  {t("MENU_OPTION_CLEAR_FILTERS")}
                </Button>
              )}
            </>
          ),
        }}
        rowKey="Guid"
        columns={columns}
        dataSource={assetCollections.Entities}
        loading={isFetching}
        pagination={false}
        onChange={onTableChange}
      />
      {renderAddAssetCollectionModal()}
    </PageContent>
  );
};
