import {
  CaretRightOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  DollarOutlined,
  ForwardOutlined,
  GiftOutlined,
  TagsOutlined,
  UserSwitchOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  AreaChartOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import {
  ApplicationConfigurationModule,
  AssetModule,
  DictionariesModule,
  PaymentModule,
  ProductModule,
  UserModule,
  MediaChannelModule,
  ROUTES,
  NotificationModule,
} from "@xala/common-cms";
import {
  Divider,
  Icon,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  NotificationService,
  Sider,
  SubMenu,
} from "@xala/common-ui";
import cx from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { BooleanHelper } from "../../helpers";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import {
  ArrowLeft,
  ArrowRight,
  Layouts,
  Settings,
  Users,
  Video,
} from "../../resources/icons";
import "./Sidebar.scss";

const notificationService = NotificationService.getInstance();

const featureFlags = {
  mediaChannelsEnabled: BooleanHelper.toBool(
    process.env.REACT_APP_FEATURE_FLAG_MEDIA_CHANNELS,
    false
  ),
  channelsListEnabled: BooleanHelper.toBool(
    process.env.REACT_APP_FEATURE_FLAG_CHANNELS_LIST,
    true
  ),
};

export const Sidebar: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [collapsed, setCollapsed] = useLocalStorage<boolean>({
    key: "side-menu-state",
  });
  const [submenus, setSubmenus] = useLocalStorage<string[]>({
    key: "side-menu-submenus",
  });

  const isCollapsed = collapsed ?? false;

  const toggle = useCallback(() => setCollapsed(isCollapsed ? null : true), [
    isCollapsed,
    setCollapsed,
  ]);

  const { t } = useTranslation();

  const menuOnClick = useCallback(
    ({ key }: MenuItemClickEvent) => {
      const keyString = key.toString();
      if (keyString.startsWith("todo")) {
        notificationService.warning({
          message: "Not supported yet",
          description: "Full menu support coming soon",
        });
      } else {
        push(keyString);
      }
    },
    [push]
  );

  const menu = [
    {
      key: ROUTES.DASHBOARD,
      icon: <HomeOutlined />,
      label: t("MENU_DASHBOARD"),
    },
    {
      key: "CMS",
      icon: <Icon component={Video} />,
      label: t("MENU_CMS"),
      children: [
        {
          key: AssetModule.ROUTES.ASSET_LIST,
          icon: <CaretRightOutlined />,
          label: t("MENU_CMS_MEDIA"),
          children:
            process.env.REACT_APP_SHOW_ADDITIONAL_ASSET_LIST === "true"
              ? [
                  {
                    key: `${AssetModule.ROUTES.ASSET_LIST}`,
                    icon: null,
                    label: t("MENU_CMS_MEDIA_COLLECTIONS_ALL", "All"),
                    accept: [AssetModule.ROUTES.ASSET_DETAILS],
                  },
                  {
                    key: `${AssetModule.ROUTES.ASSET_LIST}/upcoming`,
                    icon: null,
                    label: t("MENU_CMS_MEDIA_COLLECTIONS_UPCOMING", "Upcoming"),
                  },
                  {
                    key: `${AssetModule.ROUTES.ASSET_LIST}/past`,
                    icon: null,
                    label: t("MENU_CMS_MEDIA_COLLECTIONS_PAST", "Past"),
                  },
                  {
                    key: `${AssetModule.ROUTES.ASSET_LIST}/article`,
                    icon: null,
                    label: t("MENU_CMS_MEDIA_COLLECTIONS_ARTICLE", "Articles"),
                  },
                ]
              : undefined,
        },
        {
          key: AssetModule.ROUTES.ASSET_COLLECTION_LIST,
          icon: <ForwardOutlined />,
          label: t("MENU_CMS_MEDIA_COLLECTIONS"),
          accept: [AssetModule.ROUTES.ASSET_COLLECTION_DETAILS],
        },
        {
          key: AssetModule.ROUTES.ASSET_CATEGORIES,
          icon: <TagsOutlined />,
          label: t("MENU_CMS_MEDIA_CATEGORIES"),
        },
        featureFlags.mediaChannelsEnabled
          ? {
              key: MediaChannelModule.ROUTES.CHANNEL_LIST,
              icon: <VideoCameraOutlined />,
              label: t("MENU_CMS_MEDIA_CHANNELS"),
              accept: [MediaChannelModule.ROUTES.CHANNEL_DETAILS],
            }
          : null,
        featureFlags.channelsListEnabled
          ? {
              key: `${AssetModule.ROUTES.ASSET_LIST}/channel`,
              icon: <MenuUnfoldOutlined />,
              label: t("MENU_CMS_MEDIA_CHANNELS_LIST"),
            }
          : null,
      ],
    },
    {
      key: "CRM",
      icon: <UserSwitchOutlined />,
      label: t("MENU_CRM"),
      children: [
        {
          key: UserModule.ROUTES.CUSTOMER_LIST,
          icon: <ContactsOutlined />,
          label: t("MENU_CRM_CUSTOMERS"),
          accept: [UserModule.ROUTES.CUSTOMER_DETAILS],
        },
        {
          key: ProductModule.ROUTES.PRODUCTS_LIST,
          icon: <GiftOutlined />,
          label: t("MENU_CRM_PRODUCTS"),
          accept: [ProductModule.ROUTES.PRODUCTS_DETAILS],
        },
        {
          key: AssetModule.ROUTES.ASSET_PRICE_LIST,
          icon: <DollarOutlined />,
          label: t("MENU_CRM_PRICES"),
          accept: [AssetModule.ROUTES.ASSET_PRICE_DETAILS],
        },
        {
          key: PaymentModule.ROUTES.PAYMENT_LIST,
          icon: <CreditCardOutlined />,
          label: t("MENU_CRM_PAYMENTS"),
          accept: [PaymentModule.ROUTES.PAYMENT_DETAILS],
        },
        {
          key: PaymentModule.ROUTES.SALES_REVENUE,
          icon: <AreaChartOutlined />,
          label: t("MENU_CRM_SALES_REVENUE"),
          accept: [PaymentModule.ROUTES.SALES_REVENUE],
        },
      ],
    },
    {
      key: ApplicationConfigurationModule.ROUTES.CONFIGURATION_LIST,
      icon: <Icon component={Layouts} />,
      label: t("MENU_STUDIO"),
      accept: [
        ApplicationConfigurationModule.ROUTES.CONFIGURATION_DETAILS,
        ApplicationConfigurationModule.ROUTES.CONFIGURATION_SCREEN_DETAILS,
      ],
    },
    {
      key: "ADMINISTRATION_GROUP",
      icon: <Icon component={Settings} />,
      label: t("MENU_ADMINISTRATION"),
      children: [
        {
          key: UserModule.ROUTES.ADMINISTRATOR_LIST,
          icon: <Icon component={Users} />,
          label: t("MENU_ADMINISTRATION_USERS"),
          accept: [UserModule.ROUTES.ADMINISTRATOR_DETAILS],
        },
        {
          key: NotificationModule.ROUTES.NOTIFICATION_LIST,
          icon: <NotificationOutlined />,
          label: t("MENU_NOTIFICATIONS"),
          accept: [NotificationModule.ROUTES.NOTIFICATION_LIST],
        },
        {
          key: "DICTIONARIES_GROUP",
          icon: <Icon type="read" />,
          label: t("MENU_ADMINISTRATION_DICTIONARIES"),
          children: [
            {
              key: DictionariesModule.ROUTES.DICTIONARY_APPLICATION_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_APPLICATIONS"),
            },
            {
              key:
                ApplicationConfigurationModule.ROUTES
                  .CONFIGURATION_COMPONENT_TYPES_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_COMPONENT_TYPES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_LANGUAGE_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_LANGUAGES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_CURRENCY_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_CURRIENCIES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_PLATFORM_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_PLATFORMS"),
            },
            {
              key:
                ApplicationConfigurationModule.ROUTES
                  .CONFIGURATION_SCREEN_TYPES_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_SCREEN_TYPES"),
            },
            {
              key:
                DictionariesModule.ROUTES.DICTIONARY_VIDEO_AGE_RESTRICTION_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_AGE_RESTRICTIONS"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_CONSENT_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_CONSENTS"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_PEOPLE,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_PEOPLE"),
            },
            {
              key:
                DictionariesModule.ROUTES
                  .DICTIONARY_ASSET_PURCHASE_PERIOD_TYPE_LIST,
              label: t(
                "MENU_ADMINISTRATION_DICTIONARIES_PURCHASE_PERIOD_TYPES"
              ),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_TOWNS_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_TOWNS"),
            },
          ],
        },
      ],
    },
  ];

  const mapNode = (item: {
    key: string;
    icon?: React.ReactNode;
    label: string;
    children?: any[];
    accept?: string[];
  }) => {
    if (!item) return null;

    return item.children ? (
      <SubMenu
        key={item.key}
        title={
          <span>
            {item.icon}
            <span>{item.label}</span>
          </span>
        }
      >
        {item.children.map((child) => mapNode(child))}
      </SubMenu>
    ) : (
      <MenuItem key={item.key}>
        {item.icon}
        <span>{item.label}</span>
      </MenuItem>
    );
  };

  const reduceToHighlightedKey = (
    found: string | undefined,
    node: {
      key: string;
      children?: any[];
      accept?: string[];
    }
  ): string | undefined => {
    if (found) {
      return found;
    }
    if (!node) {
      return undefined;
    }
    if (node.key === pathname) {
      return node.key;
    }
    if (node.accept?.some((v) => pathname.startsWith(v))) {
      return node.key;
    }
    if (node.children) {
      return node.children.reduce(reduceToHighlightedKey, undefined);
    }
    return undefined;
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      trigger={null}
      width={248}
      className={cx("sidebar", { "sidebar-collapsed": collapsed })}
    >
      <Menu
        mode="inline"
        selectedKeys={[menu.reduce(reduceToHighlightedKey, undefined) ?? ""]}
        onClick={menuOnClick}
        openKeys={submenus}
        onOpenChange={(openSubmenus) => setSubmenus(openSubmenus as string[])}
      >
        {menu.map(mapNode)}
      </Menu>
      <div className="sidebar-footer">
        <Divider />
        <div onClick={toggle} className="sidebar-trigger-container">
          <Icon
            component={collapsed ? () => <ArrowRight /> : () => <ArrowLeft />}
          />
        </div>
      </div>
    </Sider>
  );
};
