import { PlusOutlined } from "@ant-design/icons";
import {
  ApplicationConfigurationStore,
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationBrandingSearchFilterModel,
  ICommonAppState,
  RecordStatus,
} from "@xala/common-services";
import {
  Button,
  Heading,
  Icon,
  IPaginationProps,
  ITableColumnProps,
  NotificationService,
  Popconfirm,
  SectionGrid,
  SectionGridItem,
  TableWithDraggableSorter,
} from "@xala/common-ui";
import { Tag } from "antd";
import { noop } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrandingModal } from "./BrandingModal";
import { BrandingValuesForm } from "./BrandingValuesForm";
import { createActionHandler } from "./utils/brandingActions";
import "./ApplicationConfigurationBrandingThemesList.scss";

export interface IApplicationConfigurationBrandingThemesListProps {
  configurationId?: number;
}

const notificationService = NotificationService.getInstance();

const applicationConfigurationSelector = (state: ICommonAppState) =>
  state.applicationConfiguration;

export const ApplicationConfigurationBrandingThemesList: React.FC<IApplicationConfigurationBrandingThemesListProps> = ({
  configurationId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalBrandData, setModalBrandData] = useState<
    IApplicationConfigurationBrandingModel
  >();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const {
    brandings: { Entities: brandingsList, Filter },
    brandingsKeys: { Entities: brandingsKeysData },
    actionType,
    isLoadingData,
    error,
  } = useSelector(applicationConfigurationSelector);

  const searchBrandings = useCallback(
    (filter: IApplicationConfigurationBrandingSearchFilterModel) =>
      dispatch(ApplicationConfigurationStore.Actions.searchBranding(filter)),
    [dispatch]
  );

  const selectBrandingKeys = useCallback(
    (configId: number) =>
      dispatch(
        ApplicationConfigurationStore.Actions.selectBrandingKeys(configId)
      ),
    [dispatch]
  );

  const updateBranding = useCallback(
    (branding: IApplicationConfigurationBrandingModel) =>
      dispatch(ApplicationConfigurationStore.Actions.updateBranding(branding)),
    [dispatch]
  );

  const getFilters = (): IApplicationConfigurationBrandingSearchFilterModel => ({
    IncludeCount: true,
    PageNumber: 1,
    PageSize: 10,
    ...Filter,
  });

  const onTableChange = ({ current, pageSize }: IPaginationProps) => {
    const filter = getFilters();

    filter.PageSize = pageSize;
    filter.PageNumber = current;

    searchBrandings(filter);
  };

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedBranding = brandingsList[dragIndex];
    const hoveredBranding = brandingsList[hoverIndex];

    if (
      draggedBranding.Sequence === undefined ||
      hoveredBranding.Sequence === undefined
    ) {
      return;
    }

    const brandingToUpdate: IApplicationConfigurationBrandingModel = {
      ...draggedBranding,
      Sequence: hoveredBranding.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedBranding.Sequence !== brandingToUpdate.Sequence;
    const draggedToNewPosition = draggedBranding.Id !== hoveredBranding.Id;

    if (sequenceChanged && draggedToNewPosition) {
      updateBranding(brandingToUpdate);
    }
  };

  const getBrandKey = ({ Id }: IApplicationConfigurationBrandingModel) =>
    `${Id ? Id : -1}`;

  const initialBrandingSearch = () =>
    searchBrandings({
      ApplicationConfigurationId: configurationId,
      IncludeCount: true,
      PageNumber: 1,
      PageSize: 10,
    });
  const actionHandler = createActionHandler({
    additionalHandlers: {
      closeModal: () => {
        setModalBrandData(undefined);
        setModalVisible(false);
      },
    },
    notificationService,
    t,
    triggerListRefresh: initialBrandingSearch,
  });

  useEffect(() => {
    if (actionType) {
      actionHandler(actionType, error);
    }
  }, [actionType]);

  const handleExpandableClose = (
    brandData: IApplicationConfigurationBrandingModel
  ) => {
    setExpandedRowKeys(
      expandedRowKeys.filter((Key) => Key !== getBrandKey(brandData))
    );
  };

  useEffect(() => {
    if (configurationId) {
      searchBrandings({
        ApplicationConfigurationId: configurationId,
        IncludeCount: true,
        PageNumber: 1,
        PageSize: 10,
      });
      selectBrandingKeys(configurationId);
    }
  }, [configurationId]);

  const columns: Array<ITableColumnProps<
    IApplicationConfigurationBrandingModel
  >> = [
    {
      dataIndex: "Name",
      key: "Name",
      title: t("name", "Name"),
      className: "drag-visible",
      render: (_: any, row: IApplicationConfigurationBrandingModel) => {
        return <a onClick={onBrandingModalOpen(row)}>{row.Name}</a>;
      },
    },
    {
      dataIndex: "IsDefault",
      key: "IsDefault",
      width: "150px",
      render: (_: any, row: IApplicationConfigurationBrandingModel) =>
        row.IsDefault ? <Tag color="darkgreen">DEFAULT</Tag> : null,
      title: t("IsDefault", "IsDefault"),
    },
    {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      title: t("TABLE_ACTIONS_COLUMN", "Actions"),
      render: (_: any, row: IApplicationConfigurationBrandingModel) => (
        <Popconfirm
          title={t(
            "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
            "Are you sure you want to delete element?"
          )}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            dispatch(
              ApplicationConfigurationStore.Actions.deleteBranding({
                ...row,
                RecordStatus: RecordStatus.Deleted,
              })
            );
          }}
          okText={t("BUTTON_YES", "Yes")}
          cancelText={t("BUTTON_NO", "No")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT", "Delete element")}
          />
        </Popconfirm>
      ),
    },
  ];

  const renderExpandedRow = (
    brandData: IApplicationConfigurationBrandingModel
  ) => (
    <BrandingValuesForm
      brandData={brandData}
      brandingsKeys={brandingsKeysData}
      handleClose={handleExpandableClose}
    />
  );

  const onExpandedChange = (expandedRows: Array<string | number>) =>
    setExpandedRowKeys(expandedRows as string[]);

  const onBrandingModalOpen = (
    branding: IApplicationConfigurationBrandingModel
  ) => () => {
    setModalBrandData(branding);
    setModalVisible(true);
  };

  const onBrandingModalClose = () => {
    setModalBrandData(undefined);
    setModalVisible(false);
  };

  const onAddBrandClick = () => {
    setModalVisible(true);
  };

  return (
    <SectionGrid>
      <SectionGridItem>
        <Heading
          title={t("Brandings", "Brandings")}
          actions={[
            <Button
              shape="circle"
              type="primary"
              key="AddBranding"
              icon={<PlusOutlined />}
              title={t("Add branding")}
              onClick={onAddBrandClick}
            />,
          ]}
        />
        <TableWithDraggableSorter<IApplicationConfigurationBrandingModel>
          dragType="handler"
          dragDisabled={expandedRowKeys.length > 0}
          expandedRowKeys={expandedRowKeys}
          columns={columns}
          pagination={false}
          dataSource={brandingsList}
          loading={isLoadingData}
          rowKey={getBrandKey}
          expandedRowRender={renderExpandedRow}
          onChange={onTableChange}
          onExpandedRowsChange={onExpandedChange}
          onMoveRow={onMoveRow}
        />
        <BrandingModal
          brandData={modalBrandData}
          brandingsKeys={brandingsKeysData}
          configurationId={configurationId}
          visible={modalVisible}
          onCancel={onBrandingModalClose}
          onOk={noop}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
