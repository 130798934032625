import React from "react";
import {
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  Switch,
  TextArea,
  InputNumber,
} from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { ICurrencyModel } from "@xala/common-services";

export interface IDictionaryCurrencyFormProps {
  currency: ICurrencyModel;
  isProcessing: boolean;
  updateCurrency: (currency: ICurrencyModel) => void;
  insertCurrency: (currency: ICurrencyModel) => void;
}

export const DictionaryCurrencyForm: React.FC<IDictionaryCurrencyFormProps> = (
  props
) => {
  const { currency, insertCurrency, updateCurrency, isProcessing } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isNewLanguage = currency.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const data: ICurrencyModel = {
      ...currency,
      ...values,
      Sequence: parseInt(values.Sequence, 10),
    };

    if (isNewLanguage) {
      data.Sequence = undefined;
      insertCurrency(data);
    } else {
      updateCurrency(data);
    }
  };

  const renderSequenceField = () => {
    if (!isNewLanguage) {
      return (
        <Form.Item
          name="Sequence"
          label={t("Sequence", "Sequence")}
          rules={[required()]}
        >
          <Input placeholder={t("Sequence", "Sequence")} />
        </Form.Item>
      );
    }
  };

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryCurrencyForm"
        className="DictionaryCurrencyForm"
        form={form}
        initialValues={{ ...currency, Sequence: `${currency.Sequence}` }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Code"
              label={t("Code", "Code")}
              rules={[required()]}
            >
              <Input placeholder={t("Code", "Code")} />
            </Form.Item>

            <Form.Item
              name="Name"
              label={t("Name", "Name")}
              rules={[required()]}
            >
              <Input placeholder={t("Name", "Name")} />
            </Form.Item>

            <Form.Item
              name="Description"
              label={t("Description", "Description")}
            >
              <TextArea
                placeholder={t("Description", "Description")}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>

            <Form.Item
              name="MinPayment"
              label={t("PAYMENT_MIN_PRICE", "Min. price")}
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${`${value}`?.replace(/[^0-9.]/g, "") || 0}`
                }
                parser={(value?: string) => value?.replace(/[^0-9.]/g, "") || 0}
                min={0}
                step={1.0}
                defaultValue={0}
                placeholder={t("PAYMENT_MIN_PRICE", "Min. price")}
              />
            </Form.Item>

            {renderSequenceField()}

            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="UpToDate" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="UpToDate">
                    <label>{t("UpToDate", "Up to date")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
