import {
  AssetImageType,
  AssetService,
  AssetStore,
  AssetType,
  IAssetModel,
  IAssetSearchFilterModel,
  StreamType,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import {
  AgeRestrictionTag,
  Button,
  Heading,
  Icon,
  InputSearch,
  ITableColumnFilterDropdownProps,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  NotificationService,
  SectionGrid,
  SectionGridItem,
  setTableColumnSearchProps,
  Slider,
  Table,
  DatePickerPagerType,
  DatePickerPager,
} from "@xala/common-ui";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";
import { AddAssetModal } from "../AddAssetModal";
import { AssetForm } from "../AssetForm";
import { createAssetDataPagerSource } from "../../hooks";

import "./AssetChildrenList.scss";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

const FORM_WIDTH = 650;

export interface IAssetChildrenProps {
  title: string;
  asset?: IAssetModel;
}

export const AssetChildrenList = ({ title, asset }: IAssetChildrenProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = TimeHelper.getCurrentDate();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const assetType = asset?.AssetTypeCode;
  const [filter, setFilter] = useState<IAssetSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
    IncludeImages: assetType === AssetType.Channel,
    Parents: asset?.Id ? [asset.Id] : undefined,
    StartDateTimeFrom:
      assetType === AssetType.Channel
        ? TimeHelper.toString(currentDate)
        : undefined,
    StartDateTimeTo:
      assetType === AssetType.Channel
        ? TimeHelper.toString(
            TimeHelper.getDateWithOffset(currentDate, 1, "day")
          )
        : undefined,
  });

  const assetsLoader = useDataLoader({
    loader: () => assetService.search(filter),
    deps: [filter],
  });

  const assets = assetsLoader.data ?? { Entities: [], TotalCount: 0 };

  const [fullTextSearchValue, setFullTextSearchValue] = useState<string>("");

  const getAssetTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetTypes()),
    [dispatch]
  );

  useEffect(() => {
    getAssetTypes();
  }, []);

  const onClearFiltersClick = () => {
    const clearFilter: IAssetSearchFilterModel = {
      Parents: asset?.Id ? [asset.Id] : undefined,
      PageSize: filter.PageSize,
      PageNumber: filter.PageNumber,
      IncludeCount: filter.IncludeCount,
    };
    setFilter(clearFilter);
    setFullTextSearchValue("");
  };

  const getColumnsProps = (): Array<ITableColumnProps<IAssetModel>> => {
    const titleColumn: ITableColumnProps<IAssetModel> = {
      key: "Title",
      dataIndex: "Title",
      title: t("TITLE", "Title"),
      ellipsis: true,
      filteredValue: filter.Title ? [filter.Title] : null,
      render: (_: string, row: IAssetModel) => {
        const dataPagerSource = createAssetDataPagerSource(assets, filter);
        const link = {
          pathname: `${ROUTES.ASSET_DETAILS}/${row.Id}`,
          state: { dataPagerSource: dataPagerSource },
        };
        return <Link to={link}>{row.Title}</Link>;
      },
      ...setTableColumnSearchProps("Title", t("TITLE", "Title")),
    };
    const descriptionColumn: ITableColumnProps<IAssetModel> = {
      key: "Description",
      dataIndex: "Description",
      ellipsis: true,
      title: t("DESCRIPTION", "Description"),
    };
    const yearColumn: ITableColumnProps<IAssetModel> = {
      key: "Year",
      dataIndex: "Year",
      title: t("YEAR", "Year"),
      width: "70px",
    };
    const durationColumn: ITableColumnProps<IAssetModel> = {
      key: "DurationMiliseconds",
      dataIndex: "DurationMiliseconds",
      title: t("DURATION", "Duration"),
      width: "100px",
      align: "center",
      render: (text: string, row: IAssetModel) => {
        const durationMiliseconds =
          row.DurationMiliseconds ||
          asset?.Contents?.find((c) => c.StreamTypeCode === StreamType.Main)
            ?.DurationMiliseconds;

        return durationMiliseconds
          ? moment(TimeHelper.fromDurationMilliseconds(durationMiliseconds))
          : undefined;
      },
    };
    const ageRestrictionColumn: ITableColumnProps<IAssetModel> = {
      key: "AssetAgeRestrictionValueMin",
      dataIndex: "AssetAgeRestrictionValueMin",
      title: t("AGE_RESTRICTIONS", "Age restrictions"),
      align: "center",
      width: "165px",
      filteredValue:
        filter.AgeRestrictionFrom !== undefined &&
        filter.AgeRestrictionTo !== undefined
          ? [filter.AgeRestrictionFrom, filter.AgeRestrictionTo]
          : null,
      filterDropdown: (event: ITableColumnFilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;
        const filterValue: [number, number] | undefined =
          selectedKeys && selectedKeys.length > 1
            ? [+selectedKeys[0], +selectedKeys[1]]
            : undefined;

        return (
          <div style={{ padding: 8 }}>
            <Slider
              range
              min={0}
              max={18}
              value={filterValue || [0, 0]}
              key="AssetAgeRestrictionValueMin"
              onChange={(value: [number, number]) => {
                if (setSelectedKeys) {
                  setSelectedKeys(value || []);
                }
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="link"
                size="small"
                disabled={!filterValue}
                onClick={() => {
                  if (clearFilters) {
                    if (setSelectedKeys) {
                      setSelectedKeys([]);
                    }

                    clearFilters();
                  }
                }}
              >
                <Trans i18nKey="BUTTON_RESET">Reset</Trans>
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (confirm) {
                    confirm();
                  }
                }}
                size="small"
              >
                <Trans i18nKey="BUTTON_OK">OK</Trans>
              </Button>
            </div>
          </div>
        );
      },
      render: (_, row: IAssetModel) => (
        <AgeRestrictionTag age={row.AssetAgeRestrictionValueMin} />
      ),
    };
    const availableFromColumn: ITableColumnProps<IAssetModel> = {
      key: "AvailableFrom",
      dataIndex: "AvailableFrom",
      width: "200px",
      align: "center",
      title: t("MODEL_AVAILABLE_FROM", "Available From"),
      render: (text: string) => TimeHelper.format(new Date(text)),
    };
    const availableToColumn: ITableColumnProps<IAssetModel> = {
      key: "AvailableTo",
      dataIndex: "AvailableTo",
      width: "200px",
      align: "center",
      title: t("MODEL_AVAILABLE_TO", "Available To"),
      render: (text: string) => {
        if (!text) {
          return null;
        }
        return TimeHelper.format(new Date(text));
      },
    };
    const startDateColumn: ITableColumnProps<IAssetModel> = {
      key: "StartDateTime",
      dataIndex: "StartDateTime",
      width: "200px",
      align: "center",
      title: t("MODEL_START_DATE_TIME", "Start date"),
      render: (text: string) => TimeHelper.format(new Date(text)),
    };
    const endDateColumn: ITableColumnProps<IAssetModel> = {
      key: "EndDateTime",
      dataIndex: "EndDateTime",
      width: "200px",
      align: "center",
      title: t("MODEL_END_DATE_TIME", "End date"),
      render: (text: string) => {
        if (!text) {
          return null;
        }
        return TimeHelper.format(new Date(text));
      },
    };
    const orderInParentColumn: ITableColumnProps<IAssetModel> = {
      key: "OrderInParent",
      dataIndex: "OrderInParent",
      width: "80px",
      align: "center",
      title: t("MODEL_NO", "No."),
    };
    const imageColumn: ITableColumnProps<IAssetModel> = {
      key: "Image",
      dataIndex: "Image",
      align: "center",
      width: "130px",
      title: t("Image", "Image"),
      render: (_: any, row: IAssetModel) => {
        const image = row.Images?.find(
          (img) => img.AssetImageTypeCode === AssetImageType.Frame
        );

        if (image) {
          return <img src={image.Url} alt="" className="asetImagePreview" />;
        }

        return null;
      },
    };

    switch (assetType) {
      case AssetType.Series:
        return [
          orderInParentColumn,
          titleColumn,
          descriptionColumn,
          yearColumn,
          durationColumn,
          ageRestrictionColumn,
        ];
      case AssetType.Season:
        return [
          orderInParentColumn,
          titleColumn,
          durationColumn,
          ageRestrictionColumn,
        ];
      case AssetType.Channel:
        return [imageColumn, titleColumn, startDateColumn, endDateColumn];
      default:
        return [
          titleColumn,
          descriptionColumn,
          yearColumn,
          durationColumn,
          ageRestrictionColumn,
          availableFromColumn,
          availableToColumn,
        ];
    }
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const searchFilter: IAssetSearchFilterModel = {
      ...filter,
      Parents: asset?.Id ? [asset.Id] : undefined,
      IncludeCount: true,
      PageNumber: pagination.current,
      PageSize: pagination.pageSize,
      Title: filters.Title?.length ? `${filters.Title[0]}` : undefined,
    };

    if (filters.AssetAgeRestrictionValueMin?.length === 2) {
      searchFilter.AgeRestrictionFrom = +filters.AssetAgeRestrictionValueMin[0];
      searchFilter.AgeRestrictionTo = +filters.AssetAgeRestrictionValueMin[1];
    } else {
      searchFilter.AgeRestrictionFrom = undefined;
      searchFilter.AgeRestrictionTo = undefined;
    }

    setFilter(searchFilter);
  };

  const onSearch = (value: string) => {
    const searchFilter: IAssetSearchFilterModel = {
      ...filter,
      Parents: asset?.Id ? [asset.Id] : undefined,
      FullTextSearch: value,
    };

    setFilter(searchFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    current: filter?.PageNumber,
    defaultPageSize: 10,
    pageSize: assets.Filter?.PageSize,
    pageSizeOptions: ["10", "30", "50", "100"],
    showSizeChanger: true,
    showTotal: (total, range) =>
      t("ITEMS_RANGE_LABEL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    total: assets.TotalCount ?? 0,
  };

  const columns = getColumnsProps();
  const [createChildAsset, createChildAssetState] = useServiceCaller(
    async (data: IAssetModel) => {
      const result = await assetService.createAsset(data);
      if (result.ok) {
        setModalOpen(false);
        notificationService.success({
          message: t("CREATE_ASSET_SUCCESS", "Asset successfully created."),
        });
        history.push(`${ROUTES.ASSET_DETAILS}/${result.data.Id}`);
      } else {
        notificationService.error({
          message: t(
            "CREATE_ASSET_FAILURE",
            "There was an error while creating asset."
          ),
          description: result?.error?.Message,
        });
      }
    },
    []
  );

  return (
    <>
      <SectionGrid>
        <SectionGridItem>
          <Heading
            title={title}
            actionsClassName="AssetChildrenList__actions"
            actions={
              <>
                <div>
                  {assetType === AssetType.Channel && (
                    <DatePickerPager
                      defaultValue={Date.now()}
                      pager={DatePickerPagerType.Days}
                      onChange={(value) => {
                        const dateValue = value
                          ? TimeHelper.getDate(value)
                          : undefined;
                        const searchFilter: IAssetSearchFilterModel = {
                          ...filter,
                          PageNumber: 1,
                          StartDateTimeFrom: dateValue
                            ? TimeHelper.toString(dateValue)
                            : undefined,
                          StartDateTimeTo: dateValue
                            ? TimeHelper.toString(
                                TimeHelper.getDateWithOffset(
                                  dateValue,
                                  1,
                                  "day"
                                )
                              )
                            : undefined,
                        };

                        setFilter(searchFilter);
                      }}
                    />
                  )}
                </div>
                <div className="right">
                  <InputSearch
                    key="search"
                    placeholder={t("SEARCH", "Search")}
                    value={fullTextSearchValue}
                    onChange={({ target: { value } }) =>
                      setFullTextSearchValue(value)
                    }
                    onSearch={onSearch}
                    style={{ width: 250 }}
                    allowClear={true}
                  />
                  <Button
                    key="clear"
                    shape="circle"
                    icon={<Icon type="clear" />}
                    onClick={onClearFiltersClick}
                    title={t("OPTION_CLEAR_FILTERS", "Clear filters")}
                  />
                  <Button
                    key="add"
                    shape="circle"
                    type="primary"
                    icon={<Icon type="plus" />}
                    onClick={() => setModalOpen(true)}
                    title={t("ADD_SEASON", "Add season")}
                  />
                </div>
              </>
            }
          />
          <Table<IAssetModel>
            columns={columns}
            dataSource={assets.Entities}
            rowKey="Id"
            loading={assets.IsLoading}
            pagination={assets.TotalCount > 10 && tablePagination}
            onChange={onTableChange}
          />
        </SectionGridItem>
      </SectionGrid>
      <AddAssetModal
        visible={isModalOpen}
        close={() => setModalOpen(false)}
        processing={createChildAssetState.processing}
        width={FORM_WIDTH}
      >
        <AssetForm
          isEditMode={false}
          parent={asset}
          onSubmit={createChildAsset}
          childrenAssetsTotalCount={assetsLoader.data?.TotalCount}
          childrenAssetsListForm={true}
        />
      </AddAssetModal>
    </>
  );
};
