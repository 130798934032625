import {
  AssetStore,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  ICommonAppState,
} from "@xala/common-services";
import {
  Button,
  Dropdown,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  Modal,
  setTableColumnSearchProps,
  Table,
} from "@xala/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AddAssetCollectionModal } from "../AddAssetCollectionModal";
import "./AssetCollectionBrowserModal.scss";

export interface IAssetCollectionBrowserModalProps {
  visible: boolean;
  onCancel?: () => void;
  onSelect?: (row: IAssetCollectionModel) => void;
}

const assetCollectionSelector = (state: ICommonAppState) => state.asset;

export const AssetCollectionBrowserModal: React.FC<IAssetCollectionBrowserModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    addAssetCollectionModalVisible,
    setAddAssetCollectionModalVisible,
  ] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IAssetCollectionModel>();
  const { assetCollectionsBrowser } = useSelector(assetCollectionSelector);
  const filter: IAssetCollectionSearchFilterModel = assetCollectionsBrowser.Filter || {
    PageNumber: 1,
    PageSize: 10,
    IncludeCount: true,
  };

  const browseAssetCollection = useCallback(
    (newFilter: IAssetCollectionSearchFilterModel) =>
      dispatch(AssetStore.Actions.browseAssetCollection(newFilter)),
    [dispatch]
  );

  useEffect(() => {
    browseAssetCollection(filter);
  }, []);

  const onAdditionalOptionsMenuItemClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "OPTION_CLEAR_FILTERS":
        onClearFiltersClick();
        break;
    }
  };

  const _additionalOptionsMenu: React.ReactElement = (
    <Menu onClick={onAdditionalOptionsMenuItemClick}>
      <MenuItem key="OPTION_CLEAR_FILTERS">Clear filters</MenuItem>
    </Menu>
  );

  const onRefreshClick = () => browseAssetCollection(filter);

  const onClearFiltersClick = () => {
    const newFilter: IAssetCollectionSearchFilterModel = {
      PageSize: assetCollectionsBrowser.Filter?.PageSize || 10,
      PageNumber: 1,
      IncludeCount: true,
    };
    browseAssetCollection(newFilter);
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<IAssetCollectionModel>
  > => {
    return [
      {
        key: "Name",
        dataIndex: "Name",
        ellipsis: true,
        title: t("Name", "Name"),
        filteredValue: filter.Name ? [filter.Name] : null,
        ...setTableColumnSearchProps("Title"),
      },
      {
        key: "Description",
        dataIndex: "Description",
        ellipsis: true,
        title: t("description", "Description"),
        filteredValue: filter.Description ? [filter.Description] : null,
        ...setTableColumnSearchProps("Description"),
      },
      {
        key: "CreatedByFullName",
        dataIndex: "CreatedByFullName",
        width: "150px",
        title: t("createdBy", "Created by"),
      },
    ];
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const newFilter: IAssetCollectionSearchFilterModel = {
      ...filter,
      Name: filters.Name?.length ? `${filters.Name[0]}` : undefined,
      Description: filters.Description?.length
        ? `${filters.Description[0]}`
        : undefined,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    };

    browseAssetCollection(newFilter);
  };

  const onSearch = (value: string) => {
    const newFilter: IAssetCollectionSearchFilterModel = {
      ...filter,
      FullTextSearch: value,
      PageNumber: 1,
    };

    browseAssetCollection(newFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: filter?.PageSize,
    total: assetCollectionsBrowser.TotalCount,
    current: filter?.PageNumber,
    showTotal: (total, range) =>
      t("ITEMS_RANGE_LABEL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };
  const columns = getColumnsProps();

  const onCancelClick = () => {
    const { onCancel } = props;

    if (onCancel) {
      onCancel();
    }
  };

  const onSelectClick = () => {
    const { onSelect } = props;

    if (onSelect && selectedRow) {
      onSelect(selectedRow);
    }
  };

  const onTableRow = (row: IAssetCollectionModel, index?: number) => {
    return {
      onClick: (event: React.MouseEvent) => {
        setSelectedRow(row);
      },
      onDoubleClick: (event: React.MouseEvent) => {
        const { onSelect } = props;

        if (onSelect) {
          onSelect(row);
        } else {
          setSelectedRow(row);
        }
      },
    };
  };

  const rowSelection: ITableRowSelection<IAssetCollectionModel> = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IAssetCollectionModel[]
    ) => {
      if (selectedRows.length > 0) {
        setSelectedRow(selectedRows[0]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRow?.Id ? [selectedRow.Id] : [],
  };

  const hasSelectedRow = selectedRow ? true : false;

  return (
    <>
      <Modal
        title={
          <Heading
            title={t("SELECT_PLAY_LIST", "Select Play List")}
            actions={[
              <InputSearch
                key="search"
                placeholder="Search"
                defaultValue={filter?.FullTextSearch}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />,
              <Button
                key="add"
                icon={<Icon type="plus" />}
                onClick={() => setAddAssetCollectionModalVisible(true)}
                title="Add assets collection"
              />,
              <Button
                key="reload"
                icon={<Icon type="reload" />}
                onClick={onRefreshClick}
                title="Refresh data"
              />,
              <Dropdown key="more-options" overlay={_additionalOptionsMenu}>
                <Button key="option-more" icon={<Icon type="more" />}></Button>
              </Dropdown>,
            ]}
          />
        }
        onCancel={onCancelClick}
        visible={props.visible}
        className="AssetCollectionBrowserModal"
        closable={false}
        centered={true}
        width="70%"
        footer={
          <>
            <Button key="cancel" onClick={onCancelClick}>
              Cancel
            </Button>
            <Button
              key="cancel"
              disabled={!hasSelectedRow}
              onClick={onSelectClick}
            >
              Select
            </Button>
          </>
        }
      >
        <Table<IAssetCollectionModel>
          rowKey="Id"
          style={{ minHeight: "40vh" }}
          columns={columns}
          dataSource={assetCollectionsBrowser.Entities}
          loading={assetCollectionsBrowser.IsLoading}
          pagination={tablePagination}
          onChange={onTableChange}
          rowSelection={rowSelection}
          onRow={onTableRow}
        />
      </Modal>
      <AddAssetCollectionModal
        visible={addAssetCollectionModalVisible}
        onCancel={() => setAddAssetCollectionModalVisible(false)}
        onSuccess={() => setAddAssetCollectionModalVisible(false)}
      />
    </>
  );
};
