import * as Consts from "./consts";
import { ITownsState, TownsActionsTypes } from "./types";

export const initialState: ITownsState = {
  actionType: undefined,
  towns: {
    Entities: [],
    TotalCount: 0,
  },
};

export const townsReducer = (
  state = initialState,
  action: TownsActionsTypes
): ITownsState => {
  state.actionType = action.type;

  switch (action.type) {
    case Consts.GET_TOWNS: {
      return {
        ...state,
        towns: {
          ...state.towns,
          IsLoading: true,
        },
      };
    }
    case Consts.GET_TOWNS_SUCCESS: {
      return {
        ...state,
        towns: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_TOWNS_FAILURE: {
      return {
        ...state,
        towns: {
          ...state.towns,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
