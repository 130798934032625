import {
  ComponentType,
  ComponentTypeHelper,
  GuidHelper,
  IApplicationComponentModel,
  IErrorModel,
  PlatformType,
  RecordStatus,
} from "@xala/common-services";
import { Button, Empty } from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenContextProps } from "../../context";
import { DesignerModule } from "../../modules";
import "./ApplicationFooterProperties.scss";

export interface IApplicationComponentPropertiesProps
  extends WithTranslation,
    WithScreenContextProps {
  isProcessingData: boolean;
}

export interface IApplicationComponentPropertiesState {}

export class ApplicationFooterProperties extends React.PureComponent<
  IApplicationComponentPropertiesProps,
  IApplicationComponentPropertiesState
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  onComponentChange = (component: IApplicationComponentModel) => {
    const { onComponentChange } = this.props;

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  _renderPropertiesComponent(component: IApplicationComponentModel) {
    switch (component.ComponentTypeCode) {
      case ComponentType.Properties:
        return (
          <DesignerModule.Components.ApplicationFooterProperties
            key={`ApplicationFooterProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      default:
        return <Empty>Not known component</Empty>;
    }
  }

  public render() {
    const { screen, onComponentAdd } = this.props;

    const propertiesComponent = screen?.Components?.find(
      (component) => component.ComponentTypeCode === ComponentType.Properties
    );

    const addPropertiesComponent = () => {
      return new Promise<IApplicationComponentModel>((resolve, reject) => {
        const component: IApplicationComponentModel = {
          Id: -1,
          Guid: GuidHelper.newGuid(),
          Name: ComponentTypeHelper.getDescription(ComponentType.Properties),
          ComponentTypeCode: ComponentType.Properties,
          PlatformCode: PlatformType.Any,
          Sequence: 0,
          RecordStatus: RecordStatus.Inserted,
        };

        resolve(component);
      })
        .then((component: IApplicationComponentModel) => {
          if (onComponentAdd) {
            onComponentAdd(component);
          }
        })
        .catch((error: IErrorModel) => console.error(error));
    };

    if (
      !propertiesComponent ||
      propertiesComponent.RecordStatus === RecordStatus.Deleted
    ) {
      return <Button onClick={addPropertiesComponent}>+</Button>;
    }

    return this._renderPropertiesComponent(propertiesComponent);
  }
}
