import {
  IAssetContentModel,
  IAssetModel,
  IAssetSourceEntities,
} from "@xala/common-services";
import { Button, Icon } from "@xala/common-ui";
import * as React from "react";

interface Props {
  asset?: IAssetModel | IAssetContentModel;
  externalSource?: string;
  assetSourceEntities?: IAssetSourceEntities[];
  type: EditType;
}

export enum EditType {
  AssetDetails = "AssetDetails",
  StreamDetails = "StreamDetails",
}

export function EditExternalAsset({
  externalSource,
  assetSourceEntities,
  asset,
  type,
}: Props) {
  const data = assetSourceEntities?.find(
    ({ SourceSystemAlias }) => SourceSystemAlias === externalSource
  );

  const handleClick = () => {
    if (data && asset) {
      const regexp = new RegExp("{(.*?)}");
      const baseUrl = data.SourceSystemConfig.BaseUri;
      const value = regexp.exec(data.SourceSystemConfig[type]);
      const configUrls =
        value &&
        //@ts-ignore
        data.SourceSystemConfig[type].replace(value[0], asset[value[1]] || "");

      window.open(`${baseUrl}${configUrls}`);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Button
      key="edit"
      icon={<Icon type="edit" />}
      shape="circle"
      onClick={handleClick}
      title="Edit external media"
    />
  );
}
