import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationComponentsTypesList as ApplicationComponentsTypesListDefinition } from "./ApplicationComponentsTypesList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isLoadingData: state.applicationConfiguration.isLoadingData,
    componentsTypes: state.applicationConfiguration.componentsTypes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectComponentsTypes: () => {
    return dispatch(
      ApplicationConfigurationStore.Actions.selectComponentsTypes()
    );
  },
});

export const ApplicationComponentsTypesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationComponentsTypesListDefinition));
