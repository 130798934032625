import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import { CheckboxProps as AntCheckboxProps } from "antd/lib/checkbox";

export class Checkbox extends React.PureComponent<AntCheckboxProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntCheckbox {...rest}>{children}</AntCheckbox>;
  }
}
