import {
  AssetContentType,
  ContentStatus,
  GuidHelper,
  IAssetContentModel,
  IAssetModel,
  ICommonAppState,
  UploadType,
  UploadTypeHelper,
  AssetService,
  StorageService,
  OperationResultType,
  ICompleteMultipartUploadFileModel,
  IErrorModel,
  STORAGE,
  AssetQualityService,
  useDataLoader,
  AssetType,
  RecordStatus,
} from "@xala/common-services";
import {
  Choose,
  ChooseOption,
  Dragger,
  Form,
  Icon,
  IFormValues,
  IUploadChangeEvent,
  IUploadFile,
  NotificationService,
  required,
  Modal,
} from "@xala/common-ui";
import { FormModal } from "../../../../components";
import { takeRight } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ContentSource } from "../../enums";

const assetService = new AssetService();
const storageService: StorageService = StorageService.getInstance();
const notificationService = NotificationService.getInstance();
const assetQualityService = new AssetQualityService().promisify();
const assetSelector = (state: ICommonAppState) => state.asset;

const MIN_MULTIPART_FILE_SIZE = 5_000_000;

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
interface IAssetContentUploadModalProps {
  visible: boolean;
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}
interface IAssetContentUploadModalState {
  contentFile?: File;
  fileList: IUploadFile[];
  assetContentGuid?: string;
  ContentTypeCode?: AssetContentType;
  ContentStreamTypeCode?: string;
}

interface IProgressEvent {
  percent: number;
}

export const AssetContentUploadModal: React.FC<IAssetContentUploadModalProps> = (
  props
) => {
  const { visible, asset, assetContent, onCancel, onSuccess } = props;
  const { t } = useTranslation();
  const { assetContentTypes, assetContentStreamTypes } = useSelector(
    assetSelector
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [state, setState] = useState<IAssetContentUploadModalState>({
    contentFile: undefined,
    fileList: [],
    assetContentGuid: undefined,
  });
  const [contentTypeState, setContentTypeState] = useState<string>(
    AssetContentType.HLS
  );
  const [uploadTypeState, setUploadTypeState] = useState<UploadType>(
    UploadType.Transcoding
  );
  const [contentStreamTypeState, setContentStreamTypeState] = useState<
    string
  >();
  const abortControllerRef = useRef<AbortController>();
  const [form] = Form.useForm();

  const assetQualityLoader = useDataLoader({
    loader: () => assetQualityService.getAssetQuality(),
    deps: [],
  });

  const contentExistsForSteamType =
    contentTypeState &&
    contentStreamTypeState &&
    (asset?.Contents?.find(
      (content) =>
        content.ContentTypeCode == contentTypeState &&
        content.StreamTypeCode == contentStreamTypeState
    ) ??
      false);

  const validateContentStreamTypeUniqueness = (
    _: any,
    value: string | number,
    callback: (error?: string) => void
  ) =>
    contentExistsForSteamType
      ? callback(t("CONTENT_STREAM_TYPE_EXISTS_FOR_CONTENT_TYPE"))
      : callback();

  useEffect(() => {
    if (contentTypeState && contentStreamTypeState) {
      form.validateFields(["AssetContentStreamTypeCode"]);
    }
  }, [contentTypeState, contentStreamTypeState]);

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].status = "done";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadProgress = (event: IProgressEvent) => {
    const { fileList } = state;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].percent = event.percent || 0;
      latestFiles[0].status =
        latestFiles[0].percent === 100 ? "success" : "uploading";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadFailed = (error: any) => {
    console.log("Something went wron during uploading file.", error);
    const appError = error as IErrorModel;
    notificationService.error({
      message: t("UPLOAD_ASSET_CONTENT_FAILURE"),
      description: appError?.Message,
    });
    setProcessing(false);
  };

  const onUploadSuccess = () => {
    notificationService.success({
      message: t("UPLOAD_ASSET_CONTENT_SUCCESS"),
    });
    setProcessing(false);
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    onSuccess?.();
  };

  const uploadAssetContent = async (
    file: File,
    assetContent: IAssetContentModel
  ) => {
    try {
      if (!assetContent.AssetId || !assetContent.Guid) {
        return;
      }

      const assetId: number = assetContent.AssetId;
      const assetContentGuid: string = assetContent.Guid;

      const uploadFileInfo = await assetService
        .getAssetContentUploadFileInfo(
          assetId,
          assetContentGuid,
          uploadTypeState
        )
        .toPromise();

      assetContent.Path = uploadFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFile(
          file,
          uploadFileInfo,
          assetContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            return assetService.addAssetContent(assetContent).toPromise();
          }
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const uploadAssetContentMultipart = async (
    file: File,
    assetContent: IAssetContentModel
  ) => {
    try {
      if (!assetContent.AssetId || !assetContent.Guid) {
        return;
      }

      const assetId: number = assetContent.AssetId;
      const assetContentGuid: string = assetContent.Guid;
      const contentType = file.type; //"application/octet-stream",
      const chunkQuantity = storageService.getFileUploadChunksNumber(file);
      const uploadMultipartFileInfo = await assetService
        .getContentMultipartUploadFileInfo({
          AssetId: assetId,
          AssetContentGuid: assetContentGuid,
          ChunkQuantity: chunkQuantity,
          ContentType: contentType,
          UploadType: uploadTypeState,
        })
        .toPromise();

      assetContent.Path = uploadMultipartFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFileMultipart(
          file,
          uploadMultipartFileInfo,
          assetContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            const completeMultipartUpload: ICompleteMultipartUploadFileModel = {
              AssetId: assetId,
              AssetContentGuid: assetContentGuid,
              UploadType: uploadTypeState,
              UploadId: uploadMultipartFileInfo.UploadId,
              Parts: result.Result
                ? result.Result?.Parts.map((part) => {
                  return {
                    ETag: part.ETag,
                    Number: part.Number,
                  };
                })
                : [],
            };

            return assetService
              .completeMultipartUploadFile(completeMultipartUpload)
              .toPromise();
          }
        })
        .then(() => {
          return assetService.addAssetContent(assetContent).toPromise();
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          const appError = error as IErrorModel;

          if (appError?.Code === STORAGE.UPLOAD_ABORT_ERROR_CODE) {
            assetService
              .abortMultipartUploadFile({
                AssetId: assetId,
                AssetContentGuid: assetContentGuid,
                UploadId: uploadMultipartFileInfo.UploadId,
                ContentType: contentType,
              })
              .toPromise();
          }

          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const onFinish = async (values: IFormValues) => {
    const { contentFile, fileList, assetContentGuid } = state;
    const { AssetContentTypeCode, AssetContentStreamTypeCode } = values;

    if (!asset || !contentFile || !assetContentGuid) {
      return;
    }

    const assetContent: IAssetContentModel = {
      Guid: state.assetContentGuid,
      AssetId: asset?.Id,
      ContentTypeCode: AssetContentTypeCode,
      StreamTypeCode: AssetContentStreamTypeCode,
      ContentStatusCode:
        uploadTypeState === UploadType.Direct
          ? ContentStatus.Ready
          : ContentStatus.Queued,
      QualityCode: values.QualityCode?.value,
      UrlSource: ContentSource.Internal,
      RecordStatus: RecordStatus.Inserted,
    };

    if (contentFile.size > MIN_MULTIPART_FILE_SIZE) {
      uploadAssetContentMultipart(contentFile, assetContent);
    } else {
      uploadAssetContent(contentFile, assetContent);
    }

    if (fileList.length) {
      setState((oldState) => ({
        ...oldState,
        ContentTypeCode: AssetContentTypeCode,
        ContentStreamTypeCode: AssetContentStreamTypeCode,
      }));
    }

    setProcessing(true);
  };
  const onBeforeContentFileUpload = (file: File) => {
    setState((oldState) => ({
      ...oldState,
      contentFile: file,
      assetContentGuid: GuidHelper.newGuid(),
    }));

    return true;
  };

  const onContentFileChange = (e: IUploadChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const renderUpload = () => {
    return (
      <Form.Item
        key="ContentFile"
        name="ContentFile"
        valuePropName="ContentFile"
        getValueFromEvent={onContentFileChange}
      >
        <Dragger
          className="AssetContentModal__Dragger"
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept="video/*"
          beforeUpload={onBeforeContentFileUpload}
          fileList={state.fileList}
          onChange={onChangeContent}
          disabled={processing}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    );
  };

  const renderContentTypeField = () => {
    return (
      <Form.Item
        name="AssetContentTypeCode"
        label={t("ASSET_CONTENT_TYPE_LABEL")}
        key="contentType"
        initialValue={contentTypeState}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={uploadTypeState === UploadType.Transcoding || processing}
        >
          {assetContentTypes.data?.map((assetContentType) => (
            <ChooseOption
              key={assetContentType.Code}
              value={assetContentType.Code}
            >
              {assetContentType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderUploadTypeField = () => {
    return (
      <Form.Item
        name="AssetUploadTypeCode"
        label={t("ASSET_UPLOAD_TYPE_LABEL")}
        key="uploadType"
        initialValue={uploadTypeState}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_UPLOAD_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={processing}
          onChange={(value) => {
            const uploadType = UploadTypeHelper.getValue(value as string);

            switch (uploadType) {
              case UploadType.Transcoding:
                setContentTypeState(AssetContentType.HLS);
                setUploadTypeState(uploadType);
                break;
              case UploadType.Direct:
                setUploadTypeState(uploadType);
                break;
            }
          }}
        >
          {UploadTypeHelper.getOptions().map((option) => (
            <ChooseOption key={option?.value} value={option?.value ?? ""}>
              {option?.text}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const onCancelUploading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    onCancel?.();
  };

  const onCloseModal = () => {
    if (processing) {
      Modal.confirm({
        title: t(
          "ASSET_CONTENT_UPLOAD_MODAL_CANCEL_UPLOADING_TITLE",
          "Cancel uploading"
        ),
        content: t(
          "ASSET_CONTENT_UPLOAD_MODAL_CANCEL_UPLOADING_MESSAGE",
          "Are you sure you want to cancel uploading?"
        ),
        okText: t("BUTTON_YES"),
        cancelText: t("BUTTON_NO"),
        onOk: onCancelUploading,
      });
    } else {
      onCancelUploading();
    }
  };

  const renderContentStreamTypeField = () => {
    return (
      <Form.Item
        name="AssetContentStreamTypeCode"
        label={t("ASSET_CONTENT_STREAM_TYPE_LABEL")}
        key="streamType"
        initialValue={assetContent?.StreamTypeCode}
        rules={[required(), { validator: validateContentStreamTypeUniqueness }]}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_TYPE_PLACEHOLDER")}
          loading={assetContentStreamTypes.isFetching}
          disabled={processing}
          onChange={(value) => setContentStreamTypeState(value as string)}
        >
          {assetContentStreamTypes.data?.map((assetContentStreamType) => (
            <ChooseOption
              key={assetContentStreamType.Code}
              value={assetContentStreamType.Code}
            >
              {assetContentStreamType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderQualityCodeField = () => {
    if (
      asset?.AssetTypeCode === AssetType.Album ||
      asset?.AssetTypeCode === AssetType.Podcast
    ) {
      return null;
    }
    const initialAssetQuality = assetQualityLoader.data?.find(
      (element) => element.Code === assetContent?.QualityCode
    );

    const chooseOptions = assetQualityLoader.data?.map((quality) => (
      <ChooseOption key={quality.Code} value={quality.Code}>
        {quality.DisplayName}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="QualityCode"
        label={t("ASSET_CONTENT_QUALITY_CODE_LABEL")}
        key="qualityCode"
        initialValue={
          initialAssetQuality && {
            key: initialAssetQuality.Code,
            value: initialAssetQuality.Code,
            label: initialAssetQuality.DisplayName,
          }
        }
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_QUALITY_CODE_PLACEHOLDER")}
          children={chooseOptions}
          labelInValue={true}
        />
      </Form.Item>
    );
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={false}
      isNewForm={true}
      isDisabled={processing}
      isDeleteButtonEnabled={false}
      createFormTitle={t(
        "ASSET_CONTENT_UPLOAD_MODAL_TITLE",
        "Upload asset content"
      )}
      editFormTitle={t(
        "ASSET_CONTENT_UPLOAD_MODAL_TITLE",
        "Upload asset content"
      )}
      modalClassName="AssetContentModal"
      submitFormName="AssetContentUploadForm"
      onCloseModal={onCloseModal}
    >
      <Form
        form={form}
        name="AssetContentUploadForm"
        className="AssetContentForm"
        onFinish={onFinish}
      >
        {renderContentStreamTypeField()}
        {renderUploadTypeField()}
        {renderContentTypeField()}
        {renderQualityCodeField()}
        {renderUpload()}
      </Form>
    </FormModal>
  );
};
