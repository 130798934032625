import React from "react";
import { useTranslation } from "react-i18next";

import { BrandingImageForm } from "./BrandingImageForm";

import { Button, Modal } from "@xala/common-ui";
import { IApplicationConfigurationBrandingValueModel } from "@xala/common-services";

interface IImageModal {
  loading: boolean;
  imageData?: IApplicationConfigurationBrandingValueModel;
  configurationId: number;
  visible: boolean;
  onCancel: () => any;
  onSubmit: (imageKey: string, imageUrl?: string, imageFile?: File) => void;
}

export const ImageModal: React.FC<IImageModal> = ({
  loading,
  imageData: propsImageData,
  configurationId,
  visible,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  if (!propsImageData) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      title={t("EditImage", "Edit image")}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel}>{t("Cancel", "Cancel")}</Button>
          <Button
            form="BrandingImageModalForm"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            {t("Save", "Save")}
          </Button>
        </>
      }
    >
      <BrandingImageForm
        configurationId={configurationId}
        imageData={propsImageData}
        closeModal={onCancel}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
