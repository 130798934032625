import {
  AssetImageType,
  AssetService,
  AssetType,
  IAssetModel,
  IAssetSearchFilterModel,
  LanguageService,
  TimeHelper,
  useDataLoader,
  useExternalSources,
  useServiceCaller,
} from "@xala/common-services";
import {
  AgeRestrictionTag,
  Button,
  Icon,
  InputSearch,
  ITableColumnFilterDropdownProps,
  ITableColumnProps,
  ITableFilter,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  setTableColumnSearchProps,
  Slider,
  Table,
  Tag,
  Tooltip,
  Popconfirm,
  DateRangePicker,
  DatePicker,
  ICurrentFilters,
} from "@xala/common-ui";
import { Pagination } from "antd";
import { useTableDataProvider } from "../../../../helpers";
import { createAssetDataPagerSource } from "../../hooks";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ROUTES } from "../../constants/routes";
import { AddAssetModal } from "../AddAssetModal";
import { AssetForm, STATUS_ARRAY } from "../AssetForm";
import { useMemo } from "react";
import {
  EditExternalAsset,
  EditType,
} from "../../../../components/EditExternalAsset/EditExternalAsset";
import moment from "moment";
import "./AssetsList.scss";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();
const languageService = new LanguageService().promisify();

const filterDropdownFunction = (
  event: ITableColumnFilterDropdownProps,
  currentFilters: ICurrentFilters<{
    FullTextSearch: "string";
    Types: "strings";
    AgeRestrictionFrom: "number";
    AgeRestrictionTo: "number";
    IncludeImages: "boolean";
    Title: "string";
    ShortDescription: "string";
    IsPublished: "boolean";
    CreatedDateTimeFrom: "string";
    CreatedDateTimeTo: "string";
    AvailableFromFrom: "string";
    AvailableToTo: "string";
  }>
) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;

  const [dateRange, setDateRange] = useState<any>(
    currentFilters.AvailableToTo && moment(currentFilters.AvailableToTo)
  );

  const filterValue: [string] | undefined =
    selectedKeys && selectedKeys.length >= 1
      ? [selectedKeys[0]?.toString()]
      : undefined;

  return (
    <div style={{ padding: 8 }}>
      <DatePicker
        showTime
        value={dateRange}
        onChange={(value: any, _) => {
          setSelectedKeys([value.toISOString()]);
          setDateRange(value);
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          type="link"
          size="small"
          disabled={!filterValue}
          onClick={() => {
            if (clearFilters) {
              if (setSelectedKeys) {
                setSelectedKeys([]);
              }
              setDateRange(undefined);

              clearFilters();
            }
          }}
        >
          <Trans i18nKey="BUTTON_RESET">Reset</Trans>
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (confirm) {
              confirm();
            }
          }}
          size="small"
        >
          <Trans i18nKey="BUTTON_OK">OK</Trans>
        </Button>
      </div>
    </div>
  );
};

const FORM_WIDTH = 650;

const toNumberOrUndefined = (value: any | undefined) =>
  typeof value === "undefined" ? undefined : +value;

enum ListType {
  Upcoming = "upcoming",
  Past = "past",
  Article = "article",
  Channel = "channel",
}

interface RouteParams {
  type: ListType;
}

export const AssetsList = ({
  match,
  location,
  history,
}: RouteComponentProps<RouteParams>) => {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const sources = useExternalSources();

  const listType = match.params.type;

  const languageLoader = useDataLoader({
    loader: () => languageService.select(),
    deps: [],
  });

  const assetTypesLoader = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    deps: [],
  });

  const assetTypes = useMemo(() => assetTypesLoader.data ?? [], [
    assetTypesLoader.data,
  ]);

  const assetsFilter = useMemo<IAssetSearchFilterModel>(() => {
    return {
      ...(listType == ListType.Upcoming
        ? {
            StartDateTimeFrom: TimeHelper.getCurrentTimezoneTime(),
            Types: [AssetType.Event],
          }
        : {}),
      ...(listType == ListType.Past
        ? {
            StartDateTimeTo: TimeHelper.getCurrentTimezoneTime(),
            Types: [AssetType.Event],
          }
        : {}),
      ...(listType == ListType.Article
        ? {
            Types: [AssetType.Article],
          }
        : {}),
      ...(listType == ListType.Channel
        ? {
            Types: [AssetType.Channel],
            IncludeImages: true,
          }
        : {}),
    };
  }, [listType]);

  const {
    dataLoader: assetsLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Types: "strings",
      AgeRestrictionFrom: "number",
      AgeRestrictionTo: "number",
      IncludeImages: "boolean",
      Title: "string",
      ShortDescription: "string",
      IsPublished: "boolean",
      CreatedDateTimeFrom: "string",
      CreatedDateTimeTo: "string",
      AvailableFromFrom: "string",
      AvailableToTo: "string",
    },
    loader: (filters, pagination) => {
      return assetService.search({
        ...filters,
        ...assetsFilter,
        ...pagination,
      });
    },
    deps: [listType],
    onError: (error) =>
      notificationService.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const dataPagerSource = createAssetDataPagerSource(assetsLoader.data, {
    ...filters.current,
    ...pagination.current,
    ...assetsFilter,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
      AgeRestrictionFrom: toNumberOrUndefined(
        incomingFilters.AssetAgeRestrictionValueMin?.[0]
      ),
      AgeRestrictionTo: toNumberOrUndefined(
        incomingFilters.AssetAgeRestrictionValueMin?.[1]
      ),
      CreatedDateTimeFrom: incomingFilters.CreationDate?.[0]?.toString(),
      CreatedDateTimeTo: incomingFilters.CreationDate?.[1]?.toString(),
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const titleColumn: ITableColumnProps<IAssetModel> = {
    key: "Title",
    dataIndex: "Title",
    title: t("ASSETS_LIST_TABLE_TITLE_COLUMN"),
    width: "300px",
    ellipsis: true,
    filteredValue: filters.asTableArray.Title
      ? [filters.asTableArray.Title]
      : null,
    render: (_, row: IAssetModel) => {
      const assetDetailLocation = {
        pathname: `${ROUTES.ASSET_DETAILS}/${row.Id}`,
        state: {
          from: location,
          dataPagerSource: dataPagerSource,
        },
      };
      return <Link to={assetDetailLocation}>{row.Title}</Link>;
    },
    ...setTableColumnSearchProps(t("ASSETS_LIST_TABLE_TITLE_COLUMN")),
  };
  const descriptionColumn: ITableColumnProps<IAssetModel> = {
    key: "ShortDescription",
    dataIndex: "ShortDescription",
    align: "left",
    ellipsis: true,
    title: t("ASSETS_LIST_TABLE_DESCRIPTION_COLUMN"),
    filteredValue: filters.asTableArray.ShortDescription
      ? [filters.asTableArray.ShortDescription]
      : null,
    ...setTableColumnSearchProps(t("ASSETS_LIST_TABLE_DESCRIPTION_COLUMN")),
  };
  const publishStatusColumn: ITableColumnProps<IAssetModel> = {
    width: "160px",
    key: "IsPublished",
    dataIndex: "IsPublished",
    title: t("ASSETS_LIST_TABLE_PUBLISH_STATUS_COLUMN"),
    filters: STATUS_ARRAY.map((status) => ({
      text: status.Name,
      value: !status.Value,
    })),
    filteredValue: filters.asTableArray.IsPublished,
    render: (_, row: IAssetModel) => {
      const status = STATUS_ARRAY.find(
        (element) => element.Value === row?.IsDraft
      );

      return <p>{status?.Name}</p>;
    },
  };
  const typesColumn: ITableColumnProps<IAssetModel> = {
    key: "Types",
    dataIndex: "Types",
    align: "center",
    width: "180px",
    title: t("ASSETS_LIST_TABLE_ASSET_TYPE_CODE_COLUMN"),
    filters: assetTypes.map((assetType) => ({
      text: assetType.DisplayName,
      value: assetType.Code,
    })),
    filteredValue: filters.asTableArray.Types,
    render: (_, row: IAssetModel) => (
      <Tag
        colorRotate={assetTypes.findIndex(
          ({ Code }) => Code === row.AssetTypeCode
        )}
      >
        {row.AssetTypeDisplayName}
      </Tag>
    ),
  };
  const creationDateColumn: ITableColumnProps<IAssetModel> = {
    width: "150px",
    key: "CreationDate",
    dataIndex: "CreationDate",
    align: "center",
    title: t("ASSETS_LIST_TABLE_CREATION_DATE_COLUMN"),
    filteredValue:
      filters.current.CreatedDateTimeFrom !== undefined &&
      filters.current.CreatedDateTimeTo !== undefined
        ? [
            filters.current.CreatedDateTimeFrom,
            filters.current.CreatedDateTimeTo,
          ]
        : undefined,
    filterDropdown: (event: ITableColumnFilterDropdownProps) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;

      const [dateRange, setDateRange] = useState<any>([
        filters.current.CreatedDateTimeFrom &&
          moment(filters.current.CreatedDateTimeFrom),
        filters.current.CreatedDateTimeTo &&
          moment(filters.current.CreatedDateTimeTo),
      ]);

      const filterValue: [string, string] | undefined =
        selectedKeys && selectedKeys.length > 1
          ? [selectedKeys[0]?.toString(), selectedKeys[1]?.toString()]
          : undefined;

      return (
        <div style={{ padding: 8 }}>
          <DateRangePicker
            value={dateRange}
            onChange={(values: any, dateStrings: [string, string]) => {
              setSelectedKeys([
                TimeHelper.toUTCString(dateStrings[0]),
                TimeHelper.toUTCString(`${dateStrings[1]} 23:59:59`),
              ]);
              setDateRange(values);
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="link"
              size="small"
              disabled={!filterValue}
              onClick={() => {
                if (clearFilters) {
                  if (setSelectedKeys) {
                    setSelectedKeys([]);
                  }
                  setDateRange([]);

                  clearFilters();
                }
              }}
            >
              <Trans i18nKey="BUTTON_RESET">Reset</Trans>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              size="small"
            >
              <Trans i18nKey="BUTTON_OK">OK</Trans>
            </Button>
          </div>
        </div>
      );
    },
    render: (_, row: IAssetModel) => {
      if (!row?.CreationDate) {
        return;
      }
      return <p>{TimeHelper.format(row.CreationDate)}</p>;
    },
  };
  const ageRestrictionColumn: ITableColumnProps<IAssetModel> = {
    key: "AssetAgeRestrictionValueMin",
    dataIndex: "AssetAgeRestrictionValueMin",
    title: t("ASSETS_LIST_TABLE_MINIMUM_AGE_COLUMN"),
    align: "center",
    width: "141px",
    filteredValue:
      filters.current.AgeRestrictionFrom !== undefined &&
      filters.current.AgeRestrictionTo !== undefined
        ? [filters.current.AgeRestrictionFrom, filters.current.AgeRestrictionTo]
        : null,
    filterDropdown: (event: ITableColumnFilterDropdownProps) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;
      const filterValue: [number, number] | undefined =
        selectedKeys && selectedKeys.length > 1
          ? [+selectedKeys[0], +selectedKeys[1]]
          : undefined;

      return (
        <div style={{ padding: 8 }}>
          <Slider
            range
            min={0}
            max={18}
            value={filterValue || [0, 0]}
            key="AssetAgeRestrictionValueMin"
            onChange={(value: [number, number]) => {
              if (setSelectedKeys) {
                setSelectedKeys(value || []);
              }
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="link"
              size="small"
              disabled={!filterValue}
              onClick={() => {
                if (clearFilters) {
                  if (setSelectedKeys) {
                    setSelectedKeys([]);
                  }

                  clearFilters();
                }
              }}
            >
              <Trans i18nKey="BUTTON_RESET">Reset</Trans>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              size="small"
            >
              <Trans i18nKey="BUTTON_OK">OK</Trans>
            </Button>
          </div>
        </div>
      );
    },
    render: (_, row: IAssetModel) => (
      <AgeRestrictionTag age={row.AssetAgeRestrictionValueMin} />
    ),
  };
  const actionsColumn: ITableColumnProps<IAssetModel> = {
    key: "Actions",
    dataIndex: "Actions",
    align: "center",
    width: "100px",
    title: t("TABLE_ACTIONS_COLUMN", "Actions"),
    render: (_, asset: IAssetModel) => (
      <div className="asset-table__actions">
        <EditExternalAsset
          asset={asset}
          externalSource={asset.ExternalSource}
          assetSourceEntities={sources}
          type={EditType.AssetDetails}
        />
        <Popconfirm
          title={t(
            "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
            "Are you sure you want to delete element?"
          )}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            const result = await assetService.deleteAsset(asset!);
            if (result.ok) {
              await assetsLoader.refresh();
            } else {
              notificationService.error({
                message: t(
                  "DELETE_ELEMENT_FAILTURE",
                  "There was an error while deleting element."
                ),
                description: result.error?.Message,
              });
            }
          }}
          okText={t("BUTTON_YES", "Yes")}
          cancelText={t("BUTTON_NO", "No")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT", "Delete element")}
          />
        </Popconfirm>
      </div>
    ),
  };
  const availableFromColumn: ITableColumnProps<IAssetModel> = {
    key: "AvailableFromFrom",
    dataIndex: "AvailableFromFrom",
    width: "200px",
    align: "center",
    title: t("MODEL_AVAILABLE_FROM", "Available From"),
    render: (_, row: IAssetModel) => {
      if (!row?.AvailableFrom) {
        return;
      }
      return <p>{TimeHelper.format(new Date(row.AvailableFrom))}</p>;
    },
    filteredValue:
      filters.current.AvailableFromFrom !== undefined
        ? [filters.current.AvailableFromFrom]
        : undefined,
    filterDropdown: (event: ITableColumnFilterDropdownProps) =>
      filterDropdownFunction(event, filters.current),
  };
  const availableToColumn: ITableColumnProps<IAssetModel> = {
    key: "AvailableToTo",
    dataIndex: "AvailableToTo",
    width: "200px",
    align: "center",
    title: t("MODEL_AVAILABLE_TO", "Available To"),
    render: (_, row: IAssetModel) => {
      if (!row?.AvailableTo) {
        return;
      }
      return <p>{TimeHelper.format(new Date(row.AvailableTo))}</p>;
    },
    filteredValue:
      filters.current.AvailableToTo !== undefined
        ? [filters.current.AvailableToTo]
        : undefined,
    filterDropdown: (event: ITableColumnFilterDropdownProps) =>
      filterDropdownFunction(event, filters.current),
  };
  const imageColumn: ITableColumnProps<IAssetModel> = {
    key: "Image",
    dataIndex: "Image",
    width: "100px",
    title: t("Image", "Image"),
    render: (text: any, row: IAssetModel) => {
      const image = row.Images?.find(
        (img) => img.AssetImageTypeCode === AssetImageType.Frame
      );

      if (image) {
        return <img src={image.Url} alt="" className="asetImagePreview" />;
      }

      return null;
    },
  };
  let columns: Array<ITableColumnProps<IAssetModel>> = [];

  switch (listType) {
    case ListType.Article:
    case ListType.Past:
    case ListType.Upcoming:
      delete typesColumn.filters;
      delete typesColumn.filteredValue;
      columns = [
        titleColumn,
        descriptionColumn,
        typesColumn,
        ageRestrictionColumn,
        actionsColumn,
      ];
      break;
    case ListType.Channel:
      columns = [
        imageColumn,
        titleColumn,
        publishStatusColumn,
        availableFromColumn,
        availableToColumn,
        ageRestrictionColumn,
        actionsColumn,
      ];
      break;
    default:
      columns = [
        titleColumn,
        descriptionColumn,
        publishStatusColumn,
        typesColumn,
        creationDateColumn,
        ageRestrictionColumn,
        actionsColumn,
      ];
      break;
  }

  const [createAsset, createAssetState] = useServiceCaller(
    async (data: IAssetModel) => {
      const result = await assetService.createAsset(data);
      if (result.ok) {
        setModalOpen(false);
        notificationService.success({
          message: t("CREATE_ASSET_SUCCESS", "Asset successfully created."),
        });
        history.push(`${ROUTES.ASSET_DETAILS}/${result.data.Id}`);
      } else {
        notificationService.error({
          message: t(
            "CREATE_ASSET_FAILURE",
            "There was an error while creating asset."
          ),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <>
      <AddAssetModal
        visible={isModalOpen}
        close={() => setModalOpen(false)}
        processing={createAssetState.processing || assetsLoader.loading}
        width={FORM_WIDTH}
      >
        <AssetForm
          isEditMode={false}
          onSubmit={createAsset}
          availableAssets={assetTypes}
          languages={languageLoader.data}
        />
      </AddAssetModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title="Video List"
          extra={
            <>
              <InputSearch
                key="search"
                placeholder="Search"
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear={true}
              />
              <Tooltip
                overlay={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
              >
                <Button
                  key="clear-filters"
                  shape="circle"
                  icon={<Icon type="clear" />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS", "Clear filters")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={assetsLoader.refresh}
                title="Refresh data"
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title="Add assets collection"
              />
              {sources.map(({ SourceSystemAlias, SourceSystemConfig }) => (
                <Button
                  key="add"
                  shape="round"
                  type="primary"
                  icon={<Icon type="plus" />}
                  onClick={() =>
                    window.open(
                      `${SourceSystemConfig.BaseUri}${SourceSystemConfig.AddAsset}`
                    )
                  }
                  title={SourceSystemAlias}
                >
                  {SourceSystemAlias}
                </Button>
              ))}
            </>
          }
        />
        <Table<IAssetModel>
          rowKey="Id"
          columns={columns}
          dataSource={assetsLoader.data?.Entities}
          loading={assetsLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
      </PageContent>
    </>
  );
};
