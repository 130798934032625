import { Select } from "antd";
import {
  SelectProps as AntSelectProps,
  SelectValue as AntSelectValue,
} from "antd/lib/select";
import React from "react";
import { StringHelper } from "@xala/studio/src/helpers";
import { OptionData, OptionGroupData } from "rc-select/es/interface";

const { Option } = Select;

export const ChooseOption = Option;

declare type ChooseRawValue = string | number;

export interface IChooseLabeledValue {
  key?: string;
  value: ChooseRawValue;
  label: React.ReactNode;
}

export declare type ChooseValue =
  | ChooseRawValue
  | ChooseRawValue[]
  | IChooseLabeledValue
  | IChooseLabeledValue[];

export declare type IChooseValue = AntSelectValue;

export declare type IChooseTagProps = {
  label: ChooseValue;
  value: ChooseValue;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

export interface IChooseProps
  extends Omit<AntSelectProps<IChooseValue>, "tagRender" | "onChange"> {
  tagRender?: (props: IChooseTagProps) => React.ReactElement;
  onChange?: (value: string) => void;
}

export class Choose extends React.PureComponent<IChooseProps> {
  public handleDiacritics(
    inputValue: string,
    option: OptionData | OptionGroupData | undefined
  ) {
    let searchValue = inputValue.toLowerCase();
    let childrenItem = "";

    if (typeof option?.children === "string") {
      childrenItem = option?.children.toLowerCase();
    } else if (typeof option?.label === "string") {
      childrenItem = option.label.toLowerCase();
    } else {
      childrenItem = option?.children.join("").toLowerCase();
    }

    return StringHelper.latinize(childrenItem).includes(
      StringHelper.latinize(searchValue)
    );
  }

  public render() {
    const { tagRender, ...rest } = this.props;
    const props = rest as AntSelectProps<IChooseValue>;

    if (tagRender) {
      props.tagRender = (props) => tagRender(props as IChooseTagProps);
    }

    return (
      <Select<IChooseValue> filterOption={this.handleDiacritics} {...props} />
    );
  }
}
