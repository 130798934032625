import { IApplicationScreenTypeModel } from "@xala/common-services";
import {
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Table,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./ApplicationScreensTypesList.scss";

export interface IApplicationScreensTypesListProps extends WithTranslation {
  screensTypes: IApplicationScreenTypeModel[];
  isLoadingData: boolean;
  selectScreensTypes: ActionCreator<Action>;
}

interface IApplicationScreensTypesListState {
  filters?: ITableFilter;
}

export class ApplicationScreensTypesList extends React.PureComponent<
  IApplicationScreensTypesListProps,
  IApplicationScreensTypesListState
> {
  public state: Readonly<IApplicationScreensTypesListState> = {};

  public componentDidMount() {
    const { selectScreensTypes } = this.props;

    selectScreensTypes();
  }

  private getColumnsProps(): Array<
    ITableColumnProps<IApplicationScreenTypeModel>
  > {
    const { t } = this.props;
    const { filters } = this.state;

    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("Code", "Code"),
        filteredValue: filters?.Code,
        onFilter: (value, record) => record.Code.includes(`${value}`),
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("Name", "Name"),
        filteredValue: filters?.Name,
        onFilter: (value, record) => record.Name.includes(`${value}`),
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("Description", "Description"),
        filteredValue: filters?.Description,
        onFilter: (value, record) =>
          record.Description?.includes(`${value}`) || false,
        ellipsis: true,
      },
    ];
  }

  public onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    this.setState({ filters });
  };

  public render() {
    const { screensTypes, isLoadingData, t } = this.props;

    const columns = this.getColumnsProps();

    return (
      <PageContent>
        <PageHeader
          title={t(
            "ApplicationScreensTypesListTitle",
            "Application Screens Types List"
          )}
        />
        <Table<IApplicationScreenTypeModel>
          rowKey={(row) => row.Code}
          columns={columns}
          dataSource={screensTypes}
          loading={isLoadingData}
          pagination={false}
          onChange={this.onTableChange}
        />
      </PageContent>
    );
  }
}
