import {
  IResourceModel,
  IErrorModel,
  CommonStore,
  ICommonAppState,
} from "@xala/common-services";
import { FormModal } from "../../../../components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TranslationDetailsForm } from "../TranslationDetailsForm";
import "./TranslationDetailsModal.scss";

const resourceSelector = (state: ICommonAppState) => state.common;

interface ITranslationDetailsModalProps {
  actionType?: string;
  error?: IErrorModel;
  isOpen: boolean;
  isNewTranslation: boolean;
  translation: IResourceModel;
  resourceKey?: string;
  closeModal: () => void;
}

export const TranslationDetailsModal: React.FC<ITranslationDetailsModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteTranslation = () => {
    dispatch(CommonStore.Actions.deleteResource(translation));
  };
  const { isOpen, isNewTranslation, translation, closeModal } = props;
  const { isLoadingData, isProcessingData } = useSelector(resourceSelector);

  return (
    <div className="TranslationDetailsModal">
      <FormModal
        isLoading={isLoadingData || isProcessingData}
        isVisible={isOpen}
        isNewForm={isNewTranslation}
        isDeleteButtonEnabled={!isNewTranslation}
        createFormTitle={t("NewTranslation", "New translation")}
        editFormTitle={t("EditTranslation", "Edit translation")}
        modalClassName="TranslationDetailsModal"
        submitFormName="TranslationDetailsForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={deleteTranslation}
      >
        <TranslationDetailsForm
          translation={translation}
          isNewTranslation={isNewTranslation}
        />
      </FormModal>
    </div>
  );
};
