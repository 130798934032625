import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

/*
  Coverts provided HTML to format used by Quill.
*/
export const convertHtmlToEditorContent = (value?: string) => {
  if (!value) {
    return "";
  }

  const html = value.replace(/\n/gms, "<br>");

  return html;
};

/*
  Converts Quill Delta to HTML.
  Quill provides getHTML method but returned HTML might containt
  tags that we do not want and that are not supported by mobile.
  For example <span class="ql-cursor"></span>
*/
export const deltaToHtml = (delta: any) => {
  const converter = new QuillDeltaToHtmlConverter(delta, {
    multiLineHeader: false,
    paragraphTag: "", // block tags such as <p> or <div> are not supported by editor used on mobile
  });

  const html = converter
    .convert()
    .replace(/<strong>/gm, "<b>") // <strong> tag is not supported on mobile
    .replace(/<\/strong>/gm, "</b>")
    .replace(/<em>/gm, "<i>") // <em> tag is not supported on mobile
    .replace(/<\/em>/gm, "</i>");
    //.replace(/<br\/>/gm, "\n"); // <br> tag is not supported on mobile

  return html;
};
