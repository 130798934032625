import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ActionProperties as ActionPropertiesDefinition,
  IActionPropertiesDispatchProps,
  IActionPropertiesOwnProps,
  IActionPropertiesStateProps,
} from "./ActionProperties";

const mapStateToProps = (
  state: ICommonAppState
): IActionPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
    screensTypes: state.applicationConfiguration.screensTypes,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IActionPropertiesDispatchProps => ({
  selectScreensTypes: () => {
    return dispatch(ApplicationConfigurationStore.Actions.selectScreensTypes());
  },
});

export const ActionProperties = connect<
  IActionPropertiesStateProps,
  IActionPropertiesDispatchProps,
  IActionPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ActionPropertiesDefinition));
