import React from "react";
import {
  AdvertisementBoardType,
  AdvertisementBoardTypeHelper as CommonAdvertisementBoardTypeHelper,
} from "@xala/common-services";
import { ChooseOption, Tag, IChooseTagProps } from "@xala/common-ui";

export class AdvertisementBoardTypeHelper extends CommonAdvertisementBoardTypeHelper {
  static getTag(value?: AdvertisementBoardType): React.ReactElement {
    let color: string = AdvertisementBoardTypeHelper.getColor(value);

    return (
      <Tag color={color}>
        {AdvertisementBoardTypeHelper.getDescription(value)}
      </Tag>
    );
  }

  static getOptions(): React.ReactElement[] {
    return [
      <ChooseOption key="IMAGE" value={AdvertisementBoardType.Image}>
        {AdvertisementBoardTypeHelper.getDescription(
          AdvertisementBoardType.Image
        )}
      </ChooseOption>,
      <ChooseOption key="VIDEO" value={AdvertisementBoardType.Video}>
        {AdvertisementBoardTypeHelper.getDescription(
          AdvertisementBoardType.Video
        )}
      </ChooseOption>,
      <ChooseOption key="QR" value={AdvertisementBoardType.QR}>
        {AdvertisementBoardTypeHelper.getDescription(AdvertisementBoardType.QR)}
      </ChooseOption>,
      <ChooseOption key="LEAD_PHONE" value={AdvertisementBoardType.LeadPhone}>
        {AdvertisementBoardTypeHelper.getDescription(
          AdvertisementBoardType.LeadPhone
        )}
      </ChooseOption>,
      <ChooseOption key="LEAD_EMAIL" value={AdvertisementBoardType.LeadEmail}>
        {AdvertisementBoardTypeHelper.getDescription(
          AdvertisementBoardType.LeadEmail
        )}
      </ChooseOption>,
    ];
  }

  static getTagOption = (props: IChooseTagProps): React.ReactElement => {
    const { label, value, closable, onClose } = props;
    const advertisementBoardType = AdvertisementBoardTypeHelper.getValue(
      value as string
    );

    return (
      <Tag
        color={AdvertisementBoardTypeHelper.getColor(advertisementBoardType)}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
}
