import {
  AdvertisementBoardType,
  AdvertisementStore,
  IAdvertisementBlockModel,
  IStateModel,
  RecordStatus,
} from "@xala/common-services";
import {
  required,
  urlValid,
  Col,
  Input,
  Row,
  Form,
  Switch,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ActionCreator } from "redux";
import { WithAdvertisementBoardContextProps } from "../../context";
import {
  advertisementBlockStatusHelper as utils,
  advertisementBoardLeadHelper,
} from "../../helpers";
import "./AdvertisementBoardDetailsForm.scss";
import { AdvertisementBoardTypeHelper } from "../../helpers";

export interface IAdvertisementBoardDetailsFormProps
  extends WithTranslation,
    WithAdvertisementBoardContextProps {
  actionType?: string;
  advertisementBlock: IStateModel<IAdvertisementBlockModel>;
  isActive: boolean;
  isProcessingData: boolean;
  updateTemporaryAdvertisementBlock: ActionCreator<
    AdvertisementStore.Types.IUpdateTemporaryAdvertisementBlockAction
  >;
}

export class AdvertisementBoardDetailsForm extends React.PureComponent<
  IAdvertisementBoardDetailsFormProps
> {
  public static defaultProps = {
    isActive: false,
    isProcessingData: false,
  };

  public onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.Name = e.currentTarget.value;
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public renderNameField = () => {
    const { advertisementBlock, advertisementBoard, t } = this.props;

    return (
      <Form.Item
        name="Name"
        label={t("NAME")}
        initialValue={advertisementBoard?.Name}
        rules={[required()]}
      >
        <Input
          disabled={!utils.isEditable(advertisementBlock)}
          placeholder={t("ENTER_NAME")}
          onChange={this.onChangeName}
        />
      </Form.Item>
    );
  };

  public onChangeContentUrl = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.ContentUrl = e.currentTarget.value;
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public renderContentUrlField = () => {
    const { advertisementBlock, advertisementBoard, t } = this.props;

    return (
      <Form.Item
        name="ContentUrl"
        label={t("CONTENT_URL")}
        initialValue={advertisementBoard?.ContentUrl}
        key="path"
        rules={[urlValid(), required()]}
      >
        <Input
          disabled={!utils.isEditable(advertisementBlock)}
          placeholder={t("PLEASE_ENTER_CONTENT_URL")}
          onChange={this.onChangeContentUrl}
        />
      </Form.Item>
    );
  };

  public onChangeConsentText = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.ConsentText = e.currentTarget.value;
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );
    }

    updateTemporaryAdvertisementBlock(advertisementBlock);
  };

  public onChangeConsentTextColor = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      let textColor = e.currentTarget.value;
      if (textColor && textColor[0] !== "#") {
        textColor = "#" + textColor;
      }
      advertisementBoard.ConsentTextColor = textColor;
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );
    }

    updateTemporaryAdvertisementBlock(advertisementBlock);
  };

  public onChangeInputX = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.InputX = Number(e.currentTarget.value);
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public onChangeInputY = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.InputY = Number(e.currentTarget.value);
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public onChangeInputWidth = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.InputWidth = Number(e.currentTarget.value);
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public onChangeInputHeight = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.InputHeight = Number(e.currentTarget.value);
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public onChangeInputTitleIsVisible = (
    checked: boolean,
    event: MouseEvent
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      advertisementBoard.ShowText = checked;
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public onChangeInputTitleColor = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateTemporaryAdvertisementBlock,
    } = this.props;

    if (advertisementBoard) {
      let textColor = e.currentTarget.value;
      if (textColor && textColor && textColor[0] !== "#") {
        textColor = "#" + textColor;
      }
      advertisementBoard.TextColor = textColor;
      advertisementBoard.RecordStatus = RecordStatus.Updated;
    }

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map((x) =>
        x.Id == advertisementBoard?.Id ? advertisementBoard : x
      );

      updateTemporaryAdvertisementBlock(advertisementBlock);
    }
  };

  public renderLeadExtraFields = () => {
    const { advertisementBlock, advertisementBoard, t } = this.props;

    if (
      advertisementBoard?.BoardType &&
      advertisementBoardLeadHelper.isLead(advertisementBoard?.BoardType)
    ) {
      return (
        <>
          <Form.Item
            name="ConsentText"
            label={t("LEAD_INPUT_CONSENT_TEXT")}
            initialValue={advertisementBoard?.ConsentText}
            rules={[required()]}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_CONSENT_TEXT")}
              onChange={this.onChangeConsentText}
            />
          </Form.Item>
          <Form.Item
            name="ConsentTextColor"
            label={t("LEAD_INPUT_CONSENT_TEXT_COLOR")}
            initialValue={advertisementBoard?.ConsentTextColor}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_CONSENT_TEXT_COLOR")}
              onChange={this.onChangeConsentTextColor}
            />
          </Form.Item>
          <Form.Item
            name="InputX"
            label={t("LEAD_INPUT_POSITION_LEFT")}
            initialValue={advertisementBoard?.InputX}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_POSITION_LEFT")}
              onChange={this.onChangeInputX}
            />
          </Form.Item>
          <Form.Item
            name="InputY"
            label={t("LEAD_INPUT_POSITION_TOP")}
            initialValue={advertisementBoard?.InputY}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_POSITION_TOP")}
              onChange={this.onChangeInputY}
            />
          </Form.Item>
          <Form.Item
            name="InputWidth"
            label={t("LEAD_INPUT_SIZE_WIDTH")}
            initialValue={advertisementBoard?.InputWidth}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_SIZE_WIDTH")}
              onChange={this.onChangeInputWidth}
            />
          </Form.Item>
          <Form.Item
            name="InputHeight"
            label={t("LEAD_INPUT_SIZE_HEIGHT")}
            initialValue={advertisementBoard?.InputHeight}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_SIZE_HEIGHT")}
              onChange={this.onChangeInputHeight}
            />
          </Form.Item>
          <Form.Item
            name="InputTitleIsVisible"
            valuePropName="checked"
            label={t("LEAD_INPUT_TITLE_IS_VISIBLE")}
            initialValue={advertisementBoard?.ShowText}
          >
            <Switch
              disabled={!utils.isEditable(advertisementBlock)}
              onChange={this.onChangeInputTitleIsVisible}
            />
          </Form.Item>
          <Form.Item
            name="InputTitleColor"
            label={t("LEAD_INPUT_TITLE_COLOR")}
            initialValue={advertisementBoard?.TextColor}
          >
            <Input
              disabled={!utils.isEditable(advertisementBlock)}
              placeholder={t("PLEASE_ENTER_LEAD_INPUT_TITLE_COLOR")}
              onChange={this.onChangeInputTitleColor}
            />
          </Form.Item>
        </>
      );
    }
  };

  public render() {
    const { advertisementBoard, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
        lg: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 18 },
        lg: { span: 12 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
        md: {
          span: 18,
          offset: 6,
        },
        lg: {
          span: 21,
          offset: 3,
        },
      },
    };

    return (
      <Form
        name="AdvertisementBoardDetailsForm"
        {...formItemLayout}
        className="AdvertisementBoardDetailsForm"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item {...tailFormItemLayout}>
              <h1>{t("GENERAL_INFORMATION")}</h1>
            </Form.Item>
            {this.renderNameField()}
            <Form.Item label={t("BOARD_TYPE")}>
              {AdvertisementBoardTypeHelper.getTag(
                advertisementBoard?.BoardType
              )}
            </Form.Item>
            {this.renderContentUrlField()}
            {this.renderLeadExtraFields()}
          </Col>
        </Row>
      </Form>
    );
  }
}
