import { IApplicationConfigurationBrandingValueModel } from "@xala/common-services";
import { BrandingImageForm as BrandingImageFormDefinition } from "./BrandingImageForm";

export interface IBrandingImageModal {
  configurationId: number;
  imageData: IApplicationConfigurationBrandingValueModel;
  closeModal: () => any;
  onSubmit: (imageKey: string, imageUrl?: string, imageFile?: File) => void;
}

export const BrandingImageForm = BrandingImageFormDefinition;
