import { ICommonAppState } from "@xala/common-services";
import { push } from "connected-react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ROUTES } from "../../constants";
import { ApplicationScreenComponents as ApplicationScreenComponentsDefinition } from "./ApplicationScreenComponents";
import { withScreenContext } from "../../context";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isLoadingData: state.applicationConfiguration.isLoadingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToDetails: (id: number) => {
    return dispatch(push(`${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${id}`));
  },
});

export const ApplicationScreenComponents = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withScreenContext(ApplicationScreenComponentsDefinition)));
