import { Action } from "redux";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { ICommonAppState } from "..";
import { OperationResultType } from "../../enums";
import {
  IClassesListModel,
  IClassQuizCategoryModel,
  IErrorModel,
  ILessonsListModel,
  IQuizCategoriesListModel,
  IQuizModel,
  IQuizQuestionModel,
  IQuizStatusModel,
  IQuizzesListModel,
  IUserClassQuizCategoryModel,
  IUsersListModel,
  OperationResult,
  UploadFileInfoModel,
} from "../../models";
import {
  ClassQuizCategoryService,
  ClassService,
  LessonService,
  QuizCategoryService,
  QuizQuestionService,
  QuizService,
  QuizStatusService,
  StorageService,
  UserClassQuizCategoryService,
  UserService,
} from "../../services";
import {
  approveQuizFailure,
  approveQuizSuccess,
  blockQuizFailure,
  blockQuizSuccess,
  browseQuizzesFailure,
  browseQuizzesSuccess,
  cancelQuizReviewFailure,
  cancelQuizReviewSuccess,
  deleteQuizFailure,
  deleteQuizQuestionFailure,
  deleteQuizQuestionSuccess,
  deleteQuizSuccess,
  getQuizFailure,
  getQuizQuestionFailure,
  getQuizQuestionSuccess,
  getQuizSuccess,
  insertQuizFailure,
  insertQuizQuestionFailure,
  insertQuizQuestionSuccess,
  insertQuizSuccess,
  rejectQuizFailure,
  rejectQuizSuccess,
  searchLessonsFailure,
  searchLessonsSuccess,
  searchQuizzesFailure,
  searchQuizzesSuccess,
  selectClassFailure,
  selectClassQuizCategoryFailure,
  selectClassQuizCategorySuccess,
  selectClassSuccess,
  selectCurrentUserClassQuizCategoryFailure,
  selectCurrentUserClassQuizCategorySuccess,
  selectQuizCategoriesFailure,
  selectQuizCategoriesSuccess,
  selectQuizStatusFailure,
  selectQuizStatusSuccess,
  selectUserClassQuizCategoryFailure,
  selectUserClassQuizCategorySuccess,
  selectUserReviewerFailure,
  selectUserReviewerSuccess,
  selectUserTeacherFailure,
  selectUserTeacherSuccess,
  sendQuizToReviewFailure,
  sendQuizToReviewSuccess,
  startQuizReviewFailure,
  startQuizReviewSuccess,
  unblockQuizFailure,
  unblockQuizSuccess,
  updateCollectiontUserClassQuizCategorySuccess,
  updateCollectionUserClassQuizCategoryFailure,
  updateQuizFailure,
  updateQuizQuestionFailure,
  updateQuizQuestionSuccess,
  updateQuizSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IApproveQuizAction,
  IBlockQuizAction,
  IBrowseQuizAction,
  ICancelQuizReviewAction,
  IDeleteQuizAction,
  IDeleteQuizQuestionAction,
  IGetQuizAction,
  IGetQuizQuestionAction,
  IInsertQuizAction,
  IInsertQuizQuestionAction,
  IRejectQuizAction,
  ISearchLessonAction,
  ISearchQuizAction,
  ISelectClassAction,
  ISelectClassQuizCategoryAction,
  ISelectCurrentUserClassQuizCategoryAction,
  ISelectQuizCategoryAction,
  ISelectQuizStatusAction,
  ISelectUserClassQuizCategoryAction,
  ISelectUserReviewerAction,
  ISelectUserTeacherAction,
  ISendQuizToReviewAction,
  IStartQuizReviewAction,
  IUnblockQuizAction,
  IUpdateCollectionUserClassQuizCategoryAction,
  IUpdateQuizAction,
  IUpdateQuizQuestionAction,
} from "./types";

const quizCategoryService = new QuizCategoryService();
const quizService = new QuizService();
const quizQuestionService = new QuizQuestionService();
const classService = new ClassService();
const quizStatusService = new QuizStatusService();
const userService = new UserService();
const lessonService = new LessonService();
const userClassQuizCategoryService = new UserClassQuizCategoryService();
const classQuizCategoryService = new ClassQuizCategoryService();
const storageService: StorageService = StorageService.getInstance();

const getQuizQuestionEpic = (
  action$: ActionsObservable<IGetQuizQuestionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_QUIZ_QUESTION),
    switchMap((action: IGetQuizQuestionAction) =>
      quizQuestionService.get(action.id).pipe(
        map((data: IQuizQuestionModel) => {
          return getQuizQuestionSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getQuizQuestionFailure(error)))
      )
    )
  );

const deleteQuizQuestionEpic = (
  action$: ActionsObservable<IDeleteQuizQuestionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_QUIZ_QUESTION),
    switchMap((action: IDeleteQuizQuestionAction) =>
      quizQuestionService.delete(action.payload).pipe(
        map(() => {
          return deleteQuizQuestionSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteQuizQuestionFailure(error)))
      )
    )
  );

const uploadQuizQuestionFiles = (
  action$: ActionsObservable<Action>,
  state: StateObservable<ICommonAppState>,
  quizQuestion: IQuizQuestionModel
): Promise<Array<OperationResult<UploadFileInfoModel>>> => {
  const uploadFilesInfo: Array<Promise<UploadFileInfoModel>> = [];

  if (quizQuestion.QuestionImageFile) {
    uploadFilesInfo.push(
      quizQuestionService
        .getUploadFileInfo(quizQuestion.Guid, 0, "QuestionImage")
        .toPromise()
    );
  }

  if (quizQuestion.Answer1ImageFile) {
    uploadFilesInfo.push(
      quizQuestionService
        .getUploadFileInfo(quizQuestion.Guid, 1, "Answer1ImageFile")
        .toPromise()
    );
  }

  if (quizQuestion.Answer2ImageFile) {
    uploadFilesInfo.push(
      quizQuestionService
        .getUploadFileInfo(quizQuestion.Guid, 2, "Answer2ImageFile")
        .toPromise()
    );
  }

  if (quizQuestion.Answer3ImageFile) {
    uploadFilesInfo.push(
      quizQuestionService
        .getUploadFileInfo(quizQuestion.Guid, 3, "Answer3ImageFile")
        .toPromise()
    );
  }

  if (quizQuestion.Answer4ImageFile) {
    uploadFilesInfo.push(
      quizQuestionService
        .getUploadFileInfo(quizQuestion.Guid, 4, "Answer4ImageFile")
        .toPromise()
    );
  }

  return Promise.all(uploadFilesInfo).then(
    (uploadFilesInfo: Array<UploadFileInfoModel>) => {
      const filesToUpload: Array<Promise<
        OperationResult<UploadFileInfoModel>
      >> = [];

      if (uploadFilesInfo && uploadFilesInfo.length > 0) {
        for (const uploadFileInfo of uploadFilesInfo) {
          switch (uploadFileInfo.Key) {
            case "QuestionImage":
              if (quizQuestion.QuestionImageFile) {
                filesToUpload.push(
                  storageService.uploadFile(
                    quizQuestion.QuestionImageFile,
                    uploadFileInfo
                  )
                );
              }
              break;
            case "Answer1ImageFile":
              if (quizQuestion.Answer1ImageFile) {
                filesToUpload.push(
                  storageService.uploadFile(
                    quizQuestion.Answer1ImageFile,
                    uploadFileInfo
                  )
                );
              }
              break;
            case "Answer2ImageFile":
              if (quizQuestion.Answer2ImageFile) {
                filesToUpload.push(
                  storageService.uploadFile(
                    quizQuestion.Answer2ImageFile,
                    uploadFileInfo
                  )
                );
              }
              break;
            case "Answer3ImageFile":
              if (quizQuestion.Answer3ImageFile) {
                filesToUpload.push(
                  storageService.uploadFile(
                    quizQuestion.Answer3ImageFile,
                    uploadFileInfo
                  )
                );
              }
              break;
            case "Answer4ImageFile":
              if (quizQuestion.Answer4ImageFile) {
                filesToUpload.push(
                  storageService.uploadFile(
                    quizQuestion.Answer4ImageFile,
                    uploadFileInfo
                  )
                );
              }
              break;
          }
        }
      }

      return Promise.all(filesToUpload);
    }
  );
};

const updateQuizQuestionEpic = (
  action$: ActionsObservable<IUpdateQuizQuestionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_QUIZ_QUESTION),
    switchMap((action: IUpdateQuizQuestionAction) => {
      const uploadFilesInfo: Array<Promise<UploadFileInfoModel>> = [];

      if (action.payload.QuestionImageFile) {
        uploadFilesInfo.push(
          quizQuestionService
            .getUploadFileInfo(action.payload.Guid, 0, "QuestionImage")
            .toPromise()
        );
      }

      if (action.payload.Answer1ImageFile) {
        uploadFilesInfo.push(
          quizQuestionService
            .getUploadFileInfo(action.payload.Guid, 1, "Answer1ImageFile")
            .toPromise()
        );
      }

      if (action.payload.Answer2ImageFile) {
        uploadFilesInfo.push(
          quizQuestionService
            .getUploadFileInfo(action.payload.Guid, 2, "Answer2ImageFile")
            .toPromise()
        );
      }

      if (action.payload.Answer3ImageFile) {
        uploadFilesInfo.push(
          quizQuestionService
            .getUploadFileInfo(action.payload.Guid, 3, "Answer3ImageFile")
            .toPromise()
        );
      }

      if (action.payload.Answer4ImageFile) {
        uploadFilesInfo.push(
          quizQuestionService
            .getUploadFileInfo(action.payload.Guid, 4, "Answer4ImageFile")
            .toPromise()
        );
      }

      return Promise.all(uploadFilesInfo)
        .then((uploadFilesInfo: Array<UploadFileInfoModel>) => {
          const filesToUpload: Array<Promise<
            OperationResult<UploadFileInfoModel>
          >> = [];

          if (uploadFilesInfo && uploadFilesInfo.length > 0) {
            for (const uploadFileInfo of uploadFilesInfo) {
              switch (uploadFileInfo.Key) {
                case "QuestionImage":
                  if (action.payload.QuestionImageFile) {
                    action.payload.QuestionImageUrl = uploadFileInfo.Path;
                    action.payload.QuestionImageAbsoluteUrl =
                      uploadFileInfo.AbsoluteUrl;
                    filesToUpload.push(
                      storageService.uploadFile(
                        action.payload.QuestionImageFile,
                        uploadFileInfo
                      )
                    );
                  }
                  break;
                case "Answer1ImageFile":
                  if (action.payload.Answer1ImageFile) {
                    action.payload.Answer1ImageUrl = uploadFileInfo.Path;
                    action.payload.Answer1ImageAbsoluteUrl =
                      uploadFileInfo.AbsoluteUrl;
                    filesToUpload.push(
                      storageService.uploadFile(
                        action.payload.Answer1ImageFile,
                        uploadFileInfo
                      )
                    );
                  }
                  break;
                case "Answer2ImageFile":
                  if (action.payload.Answer2ImageFile) {
                    action.payload.Answer2ImageUrl = uploadFileInfo.Path;
                    action.payload.Answer2ImageAbsoluteUrl =
                      uploadFileInfo.AbsoluteUrl;
                    filesToUpload.push(
                      storageService.uploadFile(
                        action.payload.Answer2ImageFile,
                        uploadFileInfo
                      )
                    );
                  }
                  break;
                case "Answer3ImageFile":
                  if (action.payload.Answer3ImageFile) {
                    action.payload.Answer3ImageUrl = uploadFileInfo.Path;
                    action.payload.Answer3ImageAbsoluteUrl =
                      uploadFileInfo.AbsoluteUrl;
                    filesToUpload.push(
                      storageService.uploadFile(
                        action.payload.Answer3ImageFile,
                        uploadFileInfo
                      )
                    );
                  }
                  break;
                case "Answer4ImageFile":
                  if (action.payload.Answer4ImageFile) {
                    action.payload.Answer4ImageUrl = uploadFileInfo.Path;
                    action.payload.Answer4ImageAbsoluteUrl =
                      uploadFileInfo.AbsoluteUrl;
                    filesToUpload.push(
                      storageService.uploadFile(
                        action.payload.Answer4ImageFile,
                        uploadFileInfo
                      )
                    );
                  }
                  break;
              }
            }
          }

          return Promise.all(filesToUpload);
        })
        .then(
          (uploadedFilesInfo: Array<OperationResult<UploadFileInfoModel>>) => {
            if (uploadedFilesInfo && uploadedFilesInfo.length > 0) {
              for (const uploadedFileInfo of uploadedFilesInfo) {
                if (
                  uploadedFileInfo.ResultType === OperationResultType.Ok &&
                  uploadedFileInfo.Result
                ) {
                  switch (uploadedFileInfo.Result.Key) {
                    case "QuestionImage":
                      action.payload.QuestionImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.QuestionImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.QuestionImageFile = undefined;

                      break;
                    case "Answer1ImageFile":
                      action.payload.Answer1ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer1ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer1ImageFile = undefined;
                      break;
                    case "Answer2ImageFile":
                      action.payload.Answer2ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer2ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer2ImageFile = undefined;
                      break;
                    case "Answer3ImageFile":
                      action.payload.Answer3ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer3ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer3ImageFile = undefined;
                      break;
                    case "Answer4ImageFile":
                      action.payload.Answer4ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer4ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer4ImageFile = undefined;
                      break;
                  }
                }
              }
            }

            return;
          }
        )
        .then(() => {
          return quizQuestionService
            .update(action.payload)
            .pipe(
              map((data: IQuizQuestionModel) => {
                return updateQuizQuestionSuccess(data);
              }),
              catchError((error: IErrorModel) =>
                of(updateQuizQuestionFailure(error))
              )
            )
            .toPromise();
        })
        .catch((error: IErrorModel) => {
          return updateQuizQuestionFailure(error);
        });
    })
  );

const insertQuizQuestionsEpic = (
  action$: ActionsObservable<IInsertQuizQuestionAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_QUIZ_QUESTION),
    switchMap((action: IInsertQuizQuestionAction) => {
      return uploadQuizQuestionFiles(action$, state, action.payload)
        .then(
          (uploadedFilesInfo: Array<OperationResult<UploadFileInfoModel>>) => {
            if (uploadedFilesInfo && uploadedFilesInfo.length > 0) {
              for (const uploadedFileInfo of uploadedFilesInfo) {
                if (
                  uploadedFileInfo.ResultType === OperationResultType.Ok &&
                  uploadedFileInfo.Result
                ) {
                  switch (uploadedFileInfo.Result.Key) {
                    case "QuestionImage":
                      action.payload.QuestionImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.QuestionImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.QuestionImageFile = undefined;

                      break;
                    case "Answer1ImageFile":
                      action.payload.Answer1ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer1ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer1ImageFile = undefined;
                      break;
                    case "Answer2ImageFile":
                      action.payload.Answer2ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer2ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer2ImageFile = undefined;
                      break;
                    case "Answer3ImageFile":
                      action.payload.Answer3ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer3ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer3ImageFile = undefined;
                      break;
                    case "Answer4ImageFile":
                      action.payload.Answer4ImageUrl =
                        uploadedFileInfo.Result.Path;
                      action.payload.Answer4ImageAbsoluteUrl =
                        uploadedFileInfo.Result.AbsoluteUrl;
                      action.payload.Answer4ImageFile = undefined;
                      break;
                  }
                }
              }
            }

            return;
          }
        )
        .then(() => {
          return quizQuestionService
            .insert(action.payload)
            .pipe(
              map((data: IQuizQuestionModel) => {
                return insertQuizQuestionSuccess(data);
              }),
              catchError((error: IErrorModel) =>
                of(insertQuizQuestionFailure(error))
              )
            )
            .toPromise();
        })
        .catch((error: IErrorModel) => {
          return insertQuizQuestionFailure(error);
        });
    })
  );

const getQuizEpic = (
  action$: ActionsObservable<IGetQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_QUIZ),
    switchMap((action: IGetQuizAction) =>
      quizService.get(action.id).pipe(
        map((data: IQuizModel) => {
          return getQuizSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getQuizFailure(error)))
      )
    )
  );

const sendQuizToReviewEpic = (
  action$: ActionsObservable<ISendQuizToReviewAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEND_QUIZ_TO_REVIEW),
    switchMap((action: ISendQuizToReviewAction) =>
      quizService.sendToReview(action.payload).pipe(
        map(() => {
          return sendQuizToReviewSuccess();
        }),
        catchError((error: IErrorModel) => of(sendQuizToReviewFailure(error)))
      )
    )
  );

const startQuizReviewEpic = (
  action$: ActionsObservable<IStartQuizReviewAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.START_QUIZ_REVIEW),
    switchMap((action: IStartQuizReviewAction) =>
      quizService.startReview(action.payload).pipe(
        map(() => {
          return startQuizReviewSuccess();
        }),
        catchError((error: IErrorModel) => of(startQuizReviewFailure(error)))
      )
    )
  );

const cancelQuizReviewEpic = (
  action$: ActionsObservable<ICancelQuizReviewAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CANCEL_QUIZ_REVIEW),
    switchMap((action: ICancelQuizReviewAction) =>
      quizService.cancelReview(action.payload).pipe(
        map(() => {
          return cancelQuizReviewSuccess();
        }),
        catchError((error: IErrorModel) => of(cancelQuizReviewFailure(error)))
      )
    )
  );

const rejectQuizEpic = (
  action$: ActionsObservable<IRejectQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.REJECT_QUIZ),
    switchMap((action: IRejectQuizAction) =>
      quizService.reject(action.payload).pipe(
        map(() => {
          return rejectQuizSuccess();
        }),
        catchError((error: IErrorModel) => of(rejectQuizFailure(error)))
      )
    )
  );

const blockQuizEpic = (
  action$: ActionsObservable<IBlockQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BLOCK_QUIZ),
    switchMap((action: IBlockQuizAction) =>
      quizService.block(action.payload).pipe(
        map(() => {
          return blockQuizSuccess();
        }),
        catchError((error: IErrorModel) => of(blockQuizFailure(error)))
      )
    )
  );

const unblockQuizEpic = (
  action$: ActionsObservable<IUnblockQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UNBLOCK_QUIZ),
    switchMap((action: IUnblockQuizAction) =>
      quizService.unblock(action.payload).pipe(
        map(() => {
          return unblockQuizSuccess();
        }),
        catchError((error: IErrorModel) => of(unblockQuizFailure(error)))
      )
    )
  );

const approveQuizEpic = (
  action$: ActionsObservable<IApproveQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.APPROVE_QUIZ),
    switchMap((action: IApproveQuizAction) =>
      quizService.approve(action.payload).pipe(
        map(() => {
          return approveQuizSuccess();
        }),
        catchError((error: IErrorModel) => of(approveQuizFailure(error)))
      )
    )
  );

const searchQuizzesEpic = (
  action$: ActionsObservable<ISearchQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_QUIZ),
    switchMap((action: ISearchQuizAction) =>
      quizService.search(action.filter).pipe(
        map((data: IQuizzesListModel) => {
          data.Filter = action.filter;

          return searchQuizzesSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchQuizzesFailure(error)))
      )
    )
  );

const browseQuizzesEpic = (
  action$: ActionsObservable<IBrowseQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BROWSE_QUIZ),
    switchMap((action: IBrowseQuizAction) =>
      quizService.search(action.filter).pipe(
        map((response: IQuizzesListModel) => {
          return browseQuizzesSuccess(response);
        }),
        takeUntil(action$.pipe(ofType(Consts.BROWSE_QUIZ_CANCEL))),
        catchError((error: IErrorModel) => of(browseQuizzesFailure(error)))
      )
    )
  );

const insertQuizEpic = (
  action$: ActionsObservable<IInsertQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_QUIZ),
    switchMap((action: IInsertQuizAction) =>
      quizService.insert(action.payload).pipe(
        map((data: IQuizModel) => {
          return insertQuizSuccess(data);
        }),
        catchError((error: IErrorModel) => of(insertQuizFailure(error)))
      )
    )
  );

const updateQuizEpic = (
  action$: ActionsObservable<IUpdateQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_QUIZ),
    switchMap((action: IUpdateQuizAction) =>
      quizService.update(action.payload).pipe(
        map((data: IQuizModel) => {
          return updateQuizSuccess(data);
        }),
        catchError((error: IErrorModel) => of(updateQuizFailure(error)))
      )
    )
  );

const deleteQuizEpic = (
  action$: ActionsObservable<IDeleteQuizAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_QUIZ),
    switchMap((action: IDeleteQuizAction) =>
      quizService.delete(action.payload).pipe(
        map(() => {
          return deleteQuizSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteQuizFailure(error)))
      )
    )
  );

const selectClassEpic = (
  action$: ActionsObservable<ISelectClassAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_CLASS),
    switchMap((action: ISelectClassAction) =>
      classService.select().pipe(
        map((data: IClassesListModel) => {
          return selectClassSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectClassFailure(error)))
      )
    )
  );

const selectQuizStatusEpic = (
  action$: ActionsObservable<ISelectQuizStatusAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_QUIZ_STATUS),
    switchMap((action: ISelectQuizStatusAction) =>
      quizStatusService.select().pipe(
        map((data: IQuizStatusModel[]) => {
          return selectQuizStatusSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectQuizStatusFailure(error)))
      )
    )
  );

const selectQuizCategoriesEpic = (
  action$: ActionsObservable<ISelectQuizCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_QUIZ_CATEGORY),
    mergeMap((action: ISelectQuizCategoryAction) =>
      quizCategoryService.select().pipe(
        map((data: IQuizCategoriesListModel) => {
          return selectQuizCategoriesSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(selectQuizCategoriesFailure(error))
        )
      )
    )
  );

const selectUsersReviewersEpic = (
  action$: ActionsObservable<ISelectUserReviewerAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_USER_REVIEWER),
    mergeMap((action: ISelectUserReviewerAction) =>
      userService.select(action.filter).pipe(
        map((data: IUsersListModel) => {
          data.Filter = action.filter;
          return selectUserReviewerSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectUserReviewerFailure(error)))
      )
    )
  );

const selectUsersTeachersEpic = (
  action$: ActionsObservable<ISelectUserTeacherAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_USER_TEACHER),
    mergeMap((action: ISelectUserTeacherAction) =>
      userService.select(action.filter).pipe(
        map((data: IUsersListModel) => {
          data.Filter = action.filter;
          return selectUserTeacherSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectUserTeacherFailure(error)))
      )
    )
  );

const searchLessonsEpic = (
  action$: ActionsObservable<ISearchLessonAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_LESSON),
    switchMap((action: ISearchLessonAction) =>
      lessonService.select(action.filter).pipe(
        map((response: ILessonsListModel) => {
          return searchLessonsSuccess(response);
        }),
        catchError((error: IErrorModel) => of(searchLessonsFailure(error)))
      )
    )
  );

const updateCollectionUserClassQuizCategoryEpic = (
  action$: ActionsObservable<IUpdateCollectionUserClassQuizCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY),
    switchMap((action: IUpdateCollectionUserClassQuizCategoryAction) =>
      userClassQuizCategoryService.updateCollection(action.payload).pipe(
        map((data: IUserClassQuizCategoryModel[]) => {
          return updateCollectiontUserClassQuizCategorySuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(updateCollectionUserClassQuizCategoryFailure(error))
        )
      )
    )
  );

const selectUserClassQuizCategoryEpic = (
  action$: ActionsObservable<ISelectUserClassQuizCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_USER_CLASS_QUIZ_CATEGORY),
    switchMap((action: ISelectUserClassQuizCategoryAction) =>
      userClassQuizCategoryService.selectByUser(action.userId).pipe(
        map((data: IUserClassQuizCategoryModel[]) => {
          return selectUserClassQuizCategorySuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(selectUserClassQuizCategoryFailure(error))
        )
      )
    )
  );

const selectCurrentUserClassQuizCategoryEpic = (
  action$: ActionsObservable<ISelectCurrentUserClassQuizCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY),
    switchMap((action: ISelectCurrentUserClassQuizCategoryAction) =>
      userClassQuizCategoryService.selectByCurrentUser().pipe(
        map((data: IUserClassQuizCategoryModel[]) => {
          return selectCurrentUserClassQuizCategorySuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(selectCurrentUserClassQuizCategoryFailure(error))
        )
      )
    )
  );

const selectClassQuizCategoryEpic = (
  action$: ActionsObservable<ISelectClassQuizCategoryAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_CLASS_QUIZ_CATEGORY),
    switchMap((action: ISelectClassQuizCategoryAction) =>
      classQuizCategoryService.select().pipe(
        map((data: IClassQuizCategoryModel[]) => {
          return selectClassQuizCategorySuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(selectClassQuizCategoryFailure(error))
        )
      )
    )
  );

export const quizEpics = [
  insertQuizQuestionsEpic,
  getQuizQuestionEpic,
  deleteQuizQuestionEpic,
  getQuizEpic,
  sendQuizToReviewEpic,
  startQuizReviewEpic,
  cancelQuizReviewEpic,
  rejectQuizEpic,
  approveQuizEpic,
  blockQuizEpic,
  unblockQuizEpic,
  searchQuizzesEpic,
  browseQuizzesEpic,
  insertQuizEpic,
  updateQuizEpic,
  deleteQuizEpic,
  selectClassEpic,
  selectQuizStatusEpic,
  updateQuizQuestionEpic,
  selectQuizCategoriesEpic,
  selectUsersReviewersEpic,
  selectUsersTeachersEpic,
  searchLessonsEpic,
  updateCollectionUserClassQuizCategoryEpic,
  selectUserClassQuizCategoryEpic,
  selectCurrentUserClassQuizCategoryEpic,
  selectClassQuizCategoryEpic,
];
