import * as Consts from "./consts";
import { IUserSettingsState, UserSettingsActionsTypes } from "./types";

export const initialState: IUserSettingsState = {
  actionType: undefined,
  userSettings: {
    UserId: -1,
    LanguageId: -1,
    LanguageName: "",
  },
};

export const userSettingsReducer = (
  state = initialState,
  action: UserSettingsActionsTypes
): IUserSettingsState => {
  state.actionType = action.type;

  switch (action.type) {
    case Consts.GET_USER_SETTINGS_BY_ID: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          IsLoading: true,
        },
      };
    }
    case Consts.GET_USER_SETTINGS_BY_ID_SUCCESS: {
      return {
        ...state,
        userSettings: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_USER_SETTINGS_BY_ID_FAILURE: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.UPDATE_USER_SETTINGS: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          IsLoading: true,
        },
      };
    }
    case Consts.UPDATE_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        userSettings: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.UPDATE_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    default:
      return state;
  }
};
