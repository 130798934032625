import {
  AnalyticsAggregationPeriodType,
  IAnalyticsSalesRevenueAssetModel,
} from "@xala/common-services";
import { getRandomColorHex, IStackedBarChart2DataSet } from "@xala/common-ui";

export type TGroupBy = "asset" | "creator";

export interface ISalesRevenueGrupedData {
  [key: string]: IAnalyticsSalesRevenueAssetModel[];
}

export const groupBy = (
  data: IAnalyticsSalesRevenueAssetModel[] = [],
  groupBy: keyof IAnalyticsSalesRevenueAssetModel
) => {
  const result: ISalesRevenueGrupedData = {};
  data.forEach((it) => {
    if (!result[it[groupBy]]) {
      result[it[groupBy]] = [];
    }
    result[it[groupBy]].push(it);
  });
  return result;
};

const padZero = (str: string, len: number) => {
  let result = str;
  while (result.length < len) {
    result = `0${result}`;
  }
  return result;
};

export const getLabels = (data: IAnalyticsSalesRevenueAssetModel[] = []) =>
  Array.from(new Set(data.map((it) => it.Period)));

export const convertLabelsByAggregationPeriodtype = (
  labels: string[],
  type: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR
) =>
  labels.map((it) => {
    const date = new Date(it);
    const day = padZero((date.getDay() + 1).toString(), 2);
    const month = padZero((date.getMonth() + 1).toString(), 2);
    const year = date.getFullYear();
    switch (type) {
      case AnalyticsAggregationPeriodType.DAY:
        return `${day}-${month}-${year}`;
      case AnalyticsAggregationPeriodType.MONTH:
        return `${month}-${year}`;
      case AnalyticsAggregationPeriodType.YEAR:
        return `${year}`;
      default:
        return "";
    }
  });

export const prepareChartData = (
  data: IAnalyticsSalesRevenueAssetModel[] = [],
  aggregationPeriod: AnalyticsAggregationPeriodType = AnalyticsAggregationPeriodType.YEAR,
  _groupBy: TGroupBy = "asset"
) => {
  const labels = getLabels(data);
  const groupedByAssetId = groupBy(
    data,
    _groupBy === "asset" ? "AssetId" : "CreatorUserId"
  );
  const datasets: IStackedBarChart2DataSet<number>[] = [];
  Object.keys(groupedByAssetId).forEach((key) => {
    const dataset: IStackedBarChart2DataSet<number> = {
      data: [],
      backgroundColor: getRandomColorHex(),
    };
    labels.forEach((lbl, index) => {
      const found = groupedByAssetId[key].find((val) => val.Period === lbl);
      if (found) {
        if (!dataset.label) {
          dataset.label =
            _groupBy === "asset"
              ? `${found.CreatorUserName} - ${found.AssetTitle}`
              : found.CreatorUserName;
        }
        dataset.data[index] = found.Count;
      } else {
        dataset.data[index] = 0;
      }
    });
    datasets.push(dataset);
  });
  return {
    labels: convertLabelsByAggregationPeriodtype(labels, aggregationPeriod),
    datasets,
  };
};
