import {
  IErrorModel,
  IListModelBase,
  IModelBase,
  RecordStatus,
  ServiceResponse,
  useDataLoader,
} from "@xala/common-services";
import { Choose, Empty, IChooseProps, Spin } from "@xala/common-ui";
import { LabeledValue } from "antd/lib/tree-select";
import React, { useMemo, useState } from "react";

export interface ILazyOption<T> {
  key?: string;
  label: string;
  value: string;
  item?: T;
}

export interface ILazyChooseProps<Candidate extends {}, Selected extends {}>
  extends Omit<IChooseProps, "onChange" | "value"> {
  loader: (
    search: string
  ) => Promise<ServiceResponse<IListModelBase<Candidate>, IErrorModel>>;
  loaderDeps?: any[];
  candidateToOption: (item: Candidate) => ILazyOption<Selected>;
  selectedToOption: (item: Selected) => ILazyOption<Selected>;
  onChange?: (value: Selected[] | Selected) => void;
  value?: Selected[];
  debounce?: number;
  multiple?: boolean;
}

export type ILazyChooseLoader<Candidate> = ILazyChooseProps<
  Candidate,
  {}
>["loader"];

export function LazyChoose<
  Candidate extends IModelBase,
  Selected extends IModelBase
>({
  loader,
  loaderDeps,
  candidateToOption,
  selectedToOption,
  onChange,
  multiple = true,
  debounce = 500,
  value,
  ...rest
}: ILazyChooseProps<Candidate, Selected>) {
  const [search, setSearch] = useState("");
  const itemsLoader = useDataLoader({
    loader: () => loader(search),
    debounce,
    deps: [search, ...(loaderDeps ?? [])],
  });

  const itemsOptions = useMemo(
    () =>
      itemsLoader.data
        ? itemsLoader.data.Entities.map((item) => {
            const opt = candidateToOption(item);
            return {
              key: opt.value,
              ...opt,
            };
          })
        : [],
    [itemsLoader.data]
  );

  const selectedOptions = useMemo(
    () =>
      multiple
        ? value?.map((item) => ({ item, ...selectedToOption(item) })) ?? []
        : [],
    [value, multiple]
  );

  const onSelect = (current: LabeledValue, { item }: any) => {
    const modifiedIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.value === current.value
    );
    const newValue = [...(value || [])];
    if (modifiedIndex < 0) {
      newValue.push({
        ...item,
        RecordStatus: RecordStatus.Inserted,
      });
    } else {
      newValue[modifiedIndex] = {
        ...newValue[modifiedIndex],
        RecordStatus: RecordStatus.Updated,
      };
    }
    onChange?.(newValue);
  };

  const onDeselect = (current: LabeledValue) => {
    const modifiedIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.value === current.value
    );
    const newValue = [...(value || [])];
    if (modifiedIndex >= 0) {
      if (
        selectedOptions[modifiedIndex].item.RecordStatus ===
        RecordStatus.Inserted
      ) {
        newValue.splice(modifiedIndex, 1);
      } else {
        newValue[modifiedIndex] = {
          ...newValue[modifiedIndex],
          RecordStatus: RecordStatus.Deleted,
        };
      }
    }
    onChange?.(newValue);
  };

  const visibleValue = selectedOptions.filter(
    ({ item }) => item.RecordStatus !== RecordStatus.Deleted
  );

  const loading = itemsLoader.loading || itemsLoader.debounceAwaiting;

  return (
    <Choose
      {...rest}
      mode={multiple ? "multiple" : undefined}
      onSearch={setSearch}
      onFocus={() => setSearch("")}
      loading={loading}
      value={multiple ? visibleValue : (value as any)}
      notFoundContent={
        <Spin spinning={loading}>
          <Empty style={{ margin: "8px" }} />
        </Spin>
      }
      optionFilterProp="label"
      onChange={multiple ? undefined : (onChange as any)}
      onSelect={multiple ? (onSelect as any) : undefined}
      onDeselect={multiple ? (onDeselect as any) : undefined}
      labelInValue
      options={itemsOptions}
    />
  );
}
