import {
  IAdvertisementBoardModel,
  AdvertisementBoardType,
} from "@xala/common-services";

export interface IAdvertisementGenerationHelperTranslations {
  HBBTV_FILL_IN_PHONE_NUMBER: string;
  HBBTV_FILL_IN_EMAIL_ADDRESS: string;
  HBBTV_CLOSE: string;
  HBBTV_NEXT: string;
  HBBTV_PREVIOUS: string;
  "HBBTV_CONFIRM/KEYBOARD": string;
  HBBTV_CORRECT_VALUE: string;
}

export class advertisementBlockGenerationHelper {
  public adBlockBoards: IAdvertisementBoardModel[];
  public translations: IAdvertisementGenerationHelperTranslations;
  public adBlockId: number;

  constructor(
    boards: IAdvertisementBoardModel[],
    translations: IAdvertisementGenerationHelperTranslations,
    adBlockId: number
  ) {
    this.adBlockBoards = boards;
    this.translations = translations;
    this.adBlockId = adBlockId;
  }

  public getHbbTemplate(): string {
    let blockContent: string = "";

    const boardTemplates = this.adBlockBoards
      .sort((a, b) => {
        return a.Sequence - b.Sequence;
      })
      .map((board) => this.getSingleBoardTemplate(board));
    blockContent = `<div class="adblock" id="adblock-${this.adBlockId}">
        ${boardTemplates.join("")}
      </div>`;

    return this.getHtmlDocument(blockContent);
  }

  private getSingleBoardTemplate(board: IAdvertisementBoardModel): string {
    let boardContent: string = "";
    let boardType: string = "";

    switch (board.BoardType) {
      case AdvertisementBoardType.Image:
        boardType = "image";
        boardContent = `<img class="board__image" src="${board.ContentUrl}"/>`;
        break;
      case AdvertisementBoardType.Video:
        boardType = "video";
        boardContent = `<div class="block__videoPlaceholder">${board.ContentUrl}</div>
            <div class="video__navigation">
                <div class="video__button video__button--play video__button--hidden"></div>
                <div class="video__button video__button--pause video__button--active"></div>
                <div class="video__button video__button--stop"></div>
            </div>`;
        break;
      case AdvertisementBoardType.QR:
        // not used, use images with qr code instead
        //boardContent= `<img src="${board.ContentUrl}" />`;
        break;
      case AdvertisementBoardType.LeadPhone:
        boardType = "lead board--leadPhone";
        boardContent = this.getLeadBoardTemplate(board);
        break;
      case AdvertisementBoardType.LeadEmail:
        boardType = "lead board--leadEmail";
        boardContent = this.getLeadBoardTemplate(board);
        break;
      default:
        boardContent = "";
    }

    return `<div id="board-${board.Id}" class="board board--${boardType}">${boardContent}</div>`;
  }

  private getLeadBoardTemplate(board: IAdvertisementBoardModel): string {
    let message = this.translations["HBBTV_FILL_IN_PHONE_NUMBER"];
    let placeholder = "123456789";
    const consentHeight = board.InputHeight ? board.InputHeight : 70;
    const consentColor = board.ConsentTextColor
      ? board.ConsentTextColor
      : "#ffffff";

    const inputStyles = `${
      board.InputWidth ? "width:" + board.InputWidth + "px;" : ""
    }
      ${
        board.InputHeight
          ? "height:" +
            board.InputHeight +
            "px; font-size:" +
            (board.InputHeight - 6) +
            "px; line-height: " +
            board.InputHeight +
            "px;"
          : ""
      }`;
    const titleStyle = `display:${board.ShowText ? "block;" : "none;"}
      ${board.TextColor ? "color: " + board.TextColor + ";" : "color: white;"}`;
    const containerStyle = `${
      typeof board.InputX === "number" ? "left: " + board.InputX + "px;" : ""
    }
      ${
        typeof board.InputY === "number" ? "top: " + board.InputY + "px;" : ""
      }`;
    const consentStyle = `color: ${consentColor}; border: 1px solid  ${consentColor};
      ${board.ShowText ? "" : "top: 0;"}
      ${board.InputHeight ? "height: " + consentHeight + "px;" : ""}`;
    const consentButtonStyle = `${
      board.InputHeight
        ? "height: " +
          consentHeight +
          "px;" +
          "line-height: " +
          consentHeight +
          "px;" +
          "width: " +
          consentHeight +
          "px;" +
          "font-size: " +
          consentHeight / 3 +
          "px;"
        : ""
    }`;
    const consentTextStyle = `${
      board.InputHeight
        ? "height: " +
          consentHeight +
          "px;" +
          "line-height: " +
          (consentHeight + 2) / 4 +
          "px;" +
          "width: " +
          (400 - consentHeight) +
          "px;" +
          "font-size: " +
          consentHeight / 4 +
          "px;"
        : ""
    }`;

    if (board.BoardType === AdvertisementBoardType.LeadEmail) {
      message = this.translations["HBBTV_FILL_IN_EMAIL_ADDRESS"];
      placeholder = "123456@domena.pl";
    }
    return `<img class="board__image" src="${board.ContentUrl}" />
            <div class="board__container" style="${containerStyle}">
              <h3 style="${titleStyle}">${message}</h3>
              <input class="board__input" placeholder="${placeholder}" style="${inputStyles}" type="text" readonly />
              <p class="board__message board__message--hidden"></p>
              <div class="consent" style="${consentStyle}">
                <span class="consent__button" style="${consentButtonStyle}">ok</span>
                <span class="consent__text" style="${consentTextStyle}"><p class="consent__paragraph">${board.ConsentText}</p></span>
              </div>
            </div>`;
  }

  private getHtmlDocument(content: string): string {
    return `<?xml version="1.0" encoding="utf-8" ?>
    <!DOCTYPE html PUBLIC "-//HbbTV//1.2.1//EN" "http://www.hbbtv.org/dtd/HbbTV-1.2.1.dtd">
    <html
      xmlns="http://www.w3.org/1999/xhtml"
      xml:lang="en"
      lang="en"
      style="
        width: 1280px;
        height: 720px;
        overflow: hidden;
        background: radial-gradient(
          at center center,
          rgb(68, 68, 68) 70%,
          rgb(0, 0, 0) 100%
        );
      "
      data-lt-installed="true"
    >
      <head>
        <title>The app</title>
        <meta
          http-equiv="Content-Type"
          content="application/vnd.hbbtv.xhtml+xml; charset=UTF-8"
        />
        <meta http-equiv="pragma" content="no-cache" />
        <link rel="stylesheet" href="./style.css" />
        <link rel="stylesheet" href="./lib/smartTvKeyboard/css/smart-tv-keyboard-light.min.css" />
        <script type="text/javascript" src="./scripts/keyCodes.js"></script>
        <script type="text/javascript" src="./scripts/baseService.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js"></script>
        <script type="text/javascript" src="./lib/smartTvKeyboard/smart.tv.keyboard.min.js"></script>
        <script type="text/javascript" src="./lib/smartTvKeyboard/layouts/en-boxed.js"></script>
        <script type="text/javascript" src="./lib/smartTvKeyboard/layouts/en.js"></script>
        <script type="text/javascript" src="./lib/smartTvKeyboard/layouts/num.js"></script>
        <script type="text/javascript" src="./lib/smartTvKeyboard/layouts/ru.js"></script>
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8LK1LJ7310"
        ></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-8LK1LJ7310");
        </script>
      </head>
      <body>
        <object
          id="oipfAppMan"
          type="application/oipfApplicationManager"
          style="width: 0; height: 0"
        ></object>
        <div id="wrapper">
            ${content}
        <div class="contBottomBox">
        <div class="buttons">
          <div class="buttons-left">
            <span class="btn btn-confirm navigation__element--confirm" id="navigation__element--confirm"></span>
            <span class="btn-desc navigation__element--confirm">
            ${this.translations["HBBTV_CONFIRM/KEYBOARD"]}</span>
            <span class="btn btn-yellow navigation__element--yellow" id="navigation__element--yellow"></span>
            <span class="btn-desc navigation__element--yellow">
            ${this.translations["HBBTV_CORRECT_VALUE"]}</span>
          </div>
          <div class="buttons-right">
            <span class="btn btn-red" id="navigation__element--close"></span></span>
            <span class="btn-desc">${this.translations["HBBTV_CLOSE"]}</span>
          </div>
        </div>
      </div>
    </div>
    <script type="text/javascript" src="./scripts/script.js"></script>
    </body>
    </html>
    `;
  }
}
