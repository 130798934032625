import React, { useCallback, useState } from "react";
import {
  AssetService,
  AssetType,
  IAssetModel,
  IAssetPeopleModel,
  LanguageService,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import {
  Button,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  Spin,
  TabPane,
  Tabs,
  useSearchParamsTabs,
  useSyncedState,
} from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import {
  AssetChildrenList,
  AssetContentList,
  AssetForm,
  AssetImages,
  AssetPriceDetails,
} from "../../components";
import { ROUTES } from "../../constants";
import { AssetContent } from "../AssetContent";
import {
  childFields,
  descriptionCommonFields,
  getSectionsFields,
  baseCommonFields,
} from "../AssetForm/AssetFormUtils";
import { AssetInAssetList } from "../AssetInAssetList";
import { AssetPackagesTransfer } from "../AssetPackagesTransfer";
import "./AssetDetails.scss";
import { useAssetDataPager } from "../../hooks";
import { AssetProperties } from "../../enums";
import { PlusOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../components";
import { DictionaryPeopleForm } from "../../../../modules/Dictionaries/components/DictionaryPeopleForm";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();
const languageService = new LanguageService().promisify();

const defaultPerson: IAssetPeopleModel = {
  Id: -1,
  FullName: "",
  RecordStatus: RecordStatus.Inserted,
};

export const AssetDetails: React.FC = () => {
  const id = parseInt(useParams<{ id: string }>()["id"]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [activeTabKey] = useSearchParamsTabs("DETAILS");
  const [dataPager, dataPagerLoading] = useAssetDataPager(id);
  const [showForm, setShowForm] = useState(false);

  const { data, loading, refresh } = useDataLoader({
    loader: () => assetService.getAsset(id),
    deps: [id],
  });

  const languageLoader = useDataLoader({
    loader: () => languageService.select(),
    deps: [],
  });

  const peopleLoader = useDataLoader({
    loader: (data: string) =>
      assetService.searchAssetPeople({
        PageNumber: 1,
        PageSize: 999,
        FullTextSearch: data,
        IncludeCount: true,
      }),
    deps: [],
  });

  const channelsLoader = useDataLoader({
    loader: () =>
      assetService.search({
        PageSize: 999,
        PageNumber: 1,
        IncludeCount: true,
        Types: [AssetType.Channel],
      }),
    deps: [],
  });

  const [asset, setAsset] = useSyncedState(() => data, [data]);

  const updateAsset = async (data: IAssetModel) => {
    const result = await assetService.updateAsset(data);
    if (result.ok) {
      setAsset(result.data);
      notificationService.success({
        message: t("UpdateAssetSuccess", "Asset successfully updated."),
      });
    } else {
      notificationService.error({
        message: t(
          "UpdateAssetFailure",
          "There was an error while updating asset."
        ),
        description: result.error.Message,
      });
    }
  };

  const [generalUpdateAsset, generalUpdateAssetState] = useServiceCaller(
    updateAsset,
    []
  );
  const [peopleUpdateAsset, peopleUpdateAssetState] = useServiceCaller(
    updateAsset,
    []
  );
  const [
    restrictionUpdateAsset,
    restrictionUpdateAssetState,
  ] = useServiceCaller(updateAsset, []);

  const [deleteAsset] = useServiceCaller(async () => {
    const result = await assetService.deleteAsset(asset!);
    if (result.ok) {
      notificationService.success({
        message: t("DeleteAssetSuccess", "Asset successfully deleted."),
      });
      history.push(ROUTES.ASSET_LIST);
    } else {
      notificationService.error({
        message: t(
          "DeleteAssetFailure",
          "There was an error while deleting asset."
        ),
        description: result.error?.Message,
      });
    }
  }, [asset]);

  const onDeleteClick = () => {
    if (!asset) {
      return;
    }

    Modal.confirm({
      title: t("Delete"),
      content: `Are you sure you want to delete "${asset?.Title}" asset?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: deleteAsset,
    });
  };

  const onBackClick = () => {
    if (location.state && location.state.hasOwnProperty("from")) {
      // use location from router state
      const state = location.state as { from: Location };
      history.push(state.from);
    } else {
      // use asset list location
      history.goBack();
    }
  };

  const [addNewPerson, isProcessing] = useServiceCaller(
    async (person: IAssetPeopleModel) => {
      const res = await assetService.addAssetPeople({
        ...person,
        RecordStatus: RecordStatus.Inserted,
      });
      if (res.ok) {
        notificationService.success({
          message: t("DICTIONARY_PEOPLE_INSERT_SUCCESS"),
        });
        await peopleLoader.refresh();
        personModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_PEOPLE_INSERT_FAILURE"),
          description: res.error?.Message,
        });
      }
    },
    []
  );

  const assetIs = (type: AssetType) => asset?.AssetTypeCode === type;

  const assetSubtitlesCodesArray = asset?.Subtitles?.split(", ");

  const assetSubtitlesArray = languageLoader.data?.filter((language) =>
    assetSubtitlesCodesArray?.includes(language.Code.toUpperCase())
  );

  const personModal = useCallback(() => {
    setShowForm((showForm) => !showForm);
  }, [showForm]);

  const renderTabDetails = () => {
    const sections = asset?.AssetTypeCode
      ? getSectionsFields(asset.AssetTypeCode)
      : null;

    return (
      <TabPane key="DETAILS" tab={t("Details", "Details")}>
        <SectionGrid style={{ maxWidth: "1200px" }}>
          {(sections?.general?.length || 0) > 0 && (
            <SectionGridItem
              header={"General"}
              processing={generalUpdateAssetState.processing}
            >
              <AssetForm
                key={`AssetForm-${asset?.Id}`}
                isEditMode={true}
                asset={asset}
                section="general"
                onSubmit={generalUpdateAsset}
                hiddenFields={[...descriptionCommonFields]}
                languages={languageLoader.data}
                assetSubtitlesArray={assetSubtitlesArray}
                channels={channelsLoader.data?.Entities}
              />
            </SectionGridItem>
          )}
          {(sections?.restriction?.length || 0) > 0 && (
            <SectionGridItem
              header={"Restriction"}
              processing={restrictionUpdateAssetState.processing}
            >
              <AssetForm
                key={`AssetForm-${asset?.Id}`}
                isEditMode={true}
                asset={asset}
                section="restriction"
                onSubmit={restrictionUpdateAsset}
              />
            </SectionGridItem>
          )}
          {(sections?.people?.length || 0) > 0 && (
            <SectionGridItem
              header={"People"}
              processing={peopleUpdateAssetState.processing}
            >
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<PlusOutlined />}
                onClick={personModal}
                title={t("DICTIONARY_PEOPLE_MODAL_BUTTON_NEW")}
              />
              <FormModal
                isLoading={!isProcessing}
                isVisible={showForm}
                isNewForm
                isDeleteButtonEnabled={false}
                createFormTitle={t("DICTIONARY_PEOPLE_MODAL_NEW")}
                modalClassName="DictionaryPeopleModal"
                submitFormName="DictionaryPeopleForm"
                onCloseModal={personModal}
              >
                <DictionaryPeopleForm
                  isProcessing={!isProcessing}
                  person={defaultPerson}
                  addNewPerson={addNewPerson}
                />
              </FormModal>
              <AssetForm
                key={`AssetForm-${asset?.Id}`}
                isEditMode={true}
                asset={asset}
                section="people"
                onSubmit={peopleUpdateAsset}
                people={peopleLoader.data}
              />
            </SectionGridItem>
          )}
        </SectionGrid>
      </TabPane>
    );
  };

  const renderTabDescription = () => {
    const hiddenFields = [
      ...baseCommonFields,
      ...childFields,
      AssetProperties.DurationMiliseconds,
      AssetProperties.YearText,
      AssetProperties.Tags,
      AssetProperties.Label,
      AssetProperties.Genre,
      AssetProperties.Nationality,
      AssetProperties.Captions,
      AssetProperties.Subtitles,
      AssetProperties.LanguageId,
      AssetProperties.Categories,
      AssetProperties.BCID,
      AssetProperties.ChannelName,
      AssetProperties.ChannelId,
      AssetProperties.RecordingPermission,
      AssetProperties.OrderRule,
    ];
    return (
      <TabPane key="Description" tab={t("Description", "Description")}>
        <SectionGrid style={{ maxWidth: "1200px" }}>
          <SectionGridItem processing={generalUpdateAssetState.processing}>
            <AssetForm
              key={`AssetForm-${asset?.Id}`}
              isEditMode={true}
              asset={asset}
              section="general"
              onSubmit={generalUpdateAsset}
              hiddenFields={hiddenFields}
            />
          </SectionGridItem>
        </SectionGrid>
      </TabPane>
    );
  };

  const renderTabImage = () => {
    return (
      <TabPane key="IMAGES" tab={t("Images", "Images")}>
        <SectionGrid>
          <SectionGridItem>
            <AssetImages
              key={`AssetImages-${asset?.Id}`}
              asset={asset!}
              refreshAsset={refresh}
            />
          </SectionGridItem>
        </SectionGrid>
      </TabPane>
    );
  };

  const renderTabContent = () => {
    if (assetIs(AssetType.Package)) {
      return null;
    }

    const view: React.ReactElement =
      process.env.REACT_APP_SINGLE_CONTENT_FOR_ASSET === "true" ? (
        <AssetContent key={`AssetContent-${asset?.Id}`} asset={asset} />
      ) : (
        <AssetContentList
          key={`AssetContent-${asset?.Id}`}
          asset={asset}
          refreshAsset={refresh}
        />
      );

    return (
      <TabPane key="CONTENT" tab={t("Content", "Content")}>
        <SectionGrid>
          <SectionGridItem>{view}</SectionGridItem>
        </SectionGrid>
      </TabPane>
    );
  };

  const renderTabSeasons = () => {
    if (assetIs(AssetType.Series) || assetIs(AssetType.Album)) {
      return (
        <TabPane key="SEASONS" tab={t("Seasons", "Seasons")}>
          <AssetChildrenList
            key={`AssetContent-${asset?.Id}`}
            title={t("Seasons List", "Seasons List")}
            asset={asset}
          />
        </TabPane>
      );
    }

    return null;
  };

  const renderTabEpisodes = () => {
    if (
      assetIs(AssetType.Season) &&
      asset?.ParentAssetTypeCode === AssetType.Series
    ) {
      return (
        <TabPane key="EPISODES" tab={t("Episodes", "Episodes")}>
          <AssetChildrenList
            key={`AssetContent-${asset?.Id}`}
            title={t("Episodes List", "Episodes List")}
            asset={asset}
          />
        </TabPane>
      );
    }
    return null;
  };

  const renderTabPodcasts = () => {
    if (
      assetIs(AssetType.Season) &&
      asset?.ParentAssetTypeCode === AssetType.Album
    ) {
      return (
        <TabPane key="PODCASTS" tab={t("Podcasts", "Podcasts")}>
          <AssetChildrenList
            key={`AssetContent-${asset?.Id}`}
            title={t("Podcasts List", "Podcasts List")}
            asset={asset}
          />
        </TabPane>
      );
    }
    return null;
  };

  const renderTabAssets = () => {
    if (assetIs(AssetType.Package)) {
      return (
        <TabPane key="ASSETS" tab={t("Assets", "Assets")}>
          <AssetInAssetList
            key={`AssetContent-${asset?.Id}`}
            title={t("Assets List", "Assets List")}
            asset={asset}
          />
        </TabPane>
      );
    }
  };

  const renderTabPrograms = () => {
    if (!assetIs(AssetType.Channel)) {
      return null;
    }

    return (
      <TabPane key="PROGRAMS" tab={t("Programs", "Programs")}>
        <AssetChildrenList
          key={`AssetContent-${asset?.Id}`}
          title={t("Programs List", "Programs List")}
          asset={asset}
        />
      </TabPane>
    );
  };

  const renderTabPackages = () => {
    if (assetIs(AssetType.Intro) || assetIs(AssetType.Package)) {
      return null;
    }

    return (
      <TabPane key="PACKAGES" tab={t("Packages", "Packages")}>
        <AssetPackagesTransfer />
      </TabPane>
    );
  };

  const renderTabPrices = () => {
    return (
      <TabPane key="PRICES" tab={t("Prices", "Prices")}>
        <AssetPriceDetails title={t("Prices")} asSection />
      </TabPane>
    );
  };

  return (
    <>
      <Spin spinning={loading || dataPagerLoading}>
        <PageContent>
          <PageHeader
            title={asset?.Title || t("VideoDetails", "Video details")}
            onBack={onBackClick}
            extra={
              <>
                {dataPager.enabled && (
                  <>
                    <Button
                      key="action-move-previous"
                      icon={<Icon type="arrow-left" />}
                      onClick={dataPager.movePrevious}
                      title="Move previous"
                      shape="circle"
                      disabled={!dataPager.movePreviousEnabled}
                    />
                    <Button
                      key="action-move-next"
                      icon={<Icon type="arrow-right" />}
                      title={t("DeleteAsset", "Move next")}
                      onClick={dataPager.moveNext}
                      shape="circle"
                      disabled={!dataPager.moveNextEnabled}
                    />
                  </>
                )}
                <Button
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  onClick={refresh}
                  title="Refresh data"
                  shape="circle"
                />
                <Button
                  danger
                  icon={<Icon type="delete" />}
                  title={t("DeleteAsset", "Delete asset")}
                  onClick={onDeleteClick}
                  shape="circle"
                />
              </>
            }
          />
          <Tabs activeKey={activeTabKey} destroyInactiveTabPane>
            {renderTabDetails()}
            {renderTabDescription()}
            {renderTabImage()}
            {renderTabContent()}
            {renderTabSeasons()}
            {renderTabEpisodes()}
            {renderTabPodcasts()}
            {renderTabPrograms()}
            {renderTabAssets()}
            {renderTabPackages()}
            {renderTabPrices()}
          </Tabs>
        </PageContent>
      </Spin>
    </>
  );
};
