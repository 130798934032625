import {
  ComponentType,
  IApplicationComponentModel,
  RecordStatus,
} from "@xala/common-services";
import { Empty } from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenContextProps } from "../../context";
import { DesignerModule } from "../../modules";

export interface IApplicationComponentPropertiesProps
  extends WithTranslation,
    WithScreenContextProps {
  isProcessingData: boolean;
}

export interface IApplicationComponentPropertiesState {}

export class ApplicationComponentProperties extends React.PureComponent<
  IApplicationComponentPropertiesProps,
  IApplicationComponentPropertiesState
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  onComponentChange = (component: IApplicationComponentModel) => {
    const { onComponentChange } = this.props;

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  _renderPropertiesComponent(component: IApplicationComponentModel) {
    switch (component.ComponentTypeCode) {
      case ComponentType.ApplicationHeaderItem:
        return (
          <DesignerModule.Components.ApplicationHeaderItemProperties
            key={`ApplicationHeaderItemProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.ApplicationMenuItem:
        return (
          <DesignerModule.Components.ApplicationMenuItemProperties
            key={`ApplicationMenuItemProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.ApplicationFooterItem:
        return (
          <DesignerModule.Components.ApplicationFooterItemProperties
            key={`ApplicationFooterItemProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.Epg:
        return (
          <DesignerModule.Components.EpgComponentProperties
            key={`EpgComponentProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.List:
        return (
          <DesignerModule.Components.ListComponentProperties
            key={`ListComponentProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.TextWidget:
        return (
          <DesignerModule.Components.TextWidgetComponentPoperties
            key={`TextWidgetComponentProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.SectionMenu:
        return (
          <DesignerModule.Components.ApplicationSectionMenuProperties
            key={`SectionMenuProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.Properties:
        return (
          <DesignerModule.Components.ApplicationFooterProperties
            key={`ApplicationFooterProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      case ComponentType.Banner:
        return (
          <DesignerModule.Components.ApplicationBannerProperties
            key={`BannerProperties-${component.Id}`}
            component={component}
            onComponentChange={this.onComponentChange}
          />
        );
      default:
        return <Empty>Not known component</Empty>;
    }
  }

  public render() {
    const { component } = this.props;

    if (!component || component.RecordStatus === RecordStatus.Deleted) {
      return <Empty>Select component</Empty>;
    }

    // Form: 'ApplicationComponentProperties'
    return <>{this._renderPropertiesComponent(component)}</>;
  }
}
