import { IProductModel } from "@xala/common-services";
import {
  AgeRestrictionTag,
  CardGridItem,
  Col,
  Icon,
  Link,
  Row,
  Text,
} from "@xala/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssetTypeTag } from "../../../Asset/components";
import { ROUTES as ASSET_ROUTES } from "../../../Asset/constants";
import { ROUTES } from "../../constants";

interface IProductCardProps {
  product: IProductModel;
}

export const ProductCard: React.FC<IProductCardProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <CardGridItem
      header={product.Title}
      footerLink={{
        href: `${ROUTES.PRODUCTS_DETAILS}/${product.Id}`,
        title: (
          <>
            {t("BUTTON_SEE_DETAILS")}
            <Icon style={{ marginLeft: "8px" }} type="double_right" />
          </>
        ),
      }}
    >
      <Row gutter={[16, 32]}>
        <Col xs={12}>
          <h4>{t("MODEL_TITLE")}</h4>
          <span>{product.Title}</span>
        </Col>
        <Col xs={12}>
          <h4>{t("MODEL_DESCRIPTION")}</h4>
          <Text ellipsis>{product.Description}</Text>
        </Col>
        <Col xs={12}>
          <h4>{t("MODEL_TYPE_CODE")}</h4>
          <AssetTypeTag type={product.TypeCode} />
        </Col>
        <Col xs={12}>
          <h4>{t("MODEL_AGE_RESTRICTION")}</h4>
          <AgeRestrictionTag age={product.AgeRestrictionFrom} />
        </Col>{" "}
        <Col xs={12}>
          <h4>{t("MODEL_METADATA_LINK")}</h4>
          <Link to={`${ASSET_ROUTES.ASSET_DETAILS}/${product.Id}`}>
            {t("COMMON_LINK")}
          </Link>
        </Col>
      </Row>
    </CardGridItem>
  );
};
