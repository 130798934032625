import { RecordStatus } from "@xala/common-services";
import {
  Col,
  ColorPicker,
  Dragger,
  Form,
  IColorResult,
  IFormValues,
  Input,
  PreviewImage,
  Row,
  Switch,
  TextArea,
  TextEditor,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { TextWidgetComponentPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./TextWidgetComponentPoperties.scss";

export interface ITextWidgetComponentPopertiesProps
  extends IComponentPropertiesBaseProps,
    WithTranslation {
  isProcessingData: boolean;
}

export interface ITextWidgetComponentPopertiesState
  extends IComponentPropertiesBaseState {
  isHTMLPreview: boolean;
}

const DEFAULT_FONT_COLOR = "#ffffff";
const DEFAULT_BACKGROUND_COLOR = "#000000";

export class TextWidgetComponentPoperties extends ComponentPropertiesBase<
  ITextWidgetComponentPopertiesProps,
  ITextWidgetComponentPopertiesState,
  TextWidgetComponentPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<ITextWidgetComponentPopertiesState> = {
    isHTMLPreview: false,
  };

  getPropertiesModel(): TextWidgetComponentPropertyModel {
    const { component } = this.props;
    const properties = new TextWidgetComponentPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onIsVisibleChange = () => {
    const { component, onComponentChange } = this.props;

    const updatedComponent = {
      ...component,
      IsVisible: !component.IsVisible,
      RecordStatus: RecordStatus.Updated,
    };

    if (onComponentChange) {
      onComponentChange(updatedComponent);
    }
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 4 },
        lg: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 20 },
        lg: { span: 22 },
      },
    };

    return (
      <Form.Item
        name="Title"
        label={t("Title")}
        initialValue={this.properties.Title}
        {...formItemLayout}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("Enter title")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public renderContentProperty = () => {
    const { t } = this.props;
    const { isHTMLPreview } = this.state;

    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
      },
    };

    return (
      <>
        <Form.Item>
          <Switch
            checkedChildren={t("HTML On", "HTML On")}
            unCheckedChildren={t("HTML Off", "HTML Off")}
            onChange={(e) => this.setState({ isHTMLPreview: e })}
            defaultChecked={isHTMLPreview}
          />
        </Form.Item>
        <Form.Item
          name="Content"
          key="Content"
          initialValue={this.properties.Content}
          {...formItemLayout}
        >
          {isHTMLPreview ? (
            <TextArea
              placeholder={t("ENTER_CONTENT_PLACEHOLDER")}
              autoSize={{ minRows: 11 }}
              onChange={(e) => {
                this.properties.Content = e.target.value;
                this.onPropertyChange("Content");
              }}
            />
          ) : (
            <TextEditor
              onChange={(value) => {
                this.properties.Content = value;
                this.onPropertyChange("Content");
              }}
            />
          )}
        </Form.Item>
      </>
    );
  };

  public renderBackgroundImageUrlProperty = () => {
    const { isProcessingData, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
        md: { span: 4 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 20 },
        lg: { span: 22 },
      },
    };

    return (
      <Form.Item
        name="ImageUrl"
        label={t("Image Url")}
        initialValue={this.properties.ImageUrl}
        {...formItemLayout}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("Enter image url")}
          onChange={(e) => {
            this.properties.ImageUrl = e.target.value;
            this.onPropertyChange("ImageUrl");
          }}
        />
      </Form.Item>
    );
  };

  public renderFileProperty = () => {
    const { t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        xs: { span: 30 },
        sm: { span: 30 },
        md: { span: 30 },
        lg: { span: 30 },
      },
    };

    return (
      <Form.Item
        key="FileValue"
        name="FileValue"
        valuePropName="FileValue"
        getValueFromEvent={this.onImageChange}
        {...formItemLayout}
      >
        <Dragger
          name="FileValue"
          accept="image/*"
          multiple={false}
          beforeUpload={this.beforeUpload}
          fileList={[]}
          withPreview={true}
          maxCount={1}
        >
          {(previewSrc: string) => {
            return (
              <>
                <p className="ant-upload-drag-icon">
                  <PreviewImage
                    src={previewSrc || this.properties.ImageUrl}
                    height={100}
                    width={100}
                    placeholderIconType={"inbox"}
                  />
                </p>
                <p className="ant-upload-text">
                  {t(
                    "DRAG_AND_DROP",
                    "Click or drag a file to this area to upload."
                  )}
                </p>
              </>
            );
          }}
        </Dragger>
      </Form.Item>
    );
  };

  public renderBackgroundColorProperty = () => {
    if (!this.properties.IsBrandingVisible) {
      return null;
    }
    const { t } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 16 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
    };

    return (
      <Form.Item
        name="BackgroundColor"
        label={t("Background Color")}
        initialValue={this.properties.BackgroundColor}
        {...formItemLayout}
      >
        <ColorPicker
          color={this.properties.BackgroundColor || DEFAULT_BACKGROUND_COLOR}
          onChange={(color: IColorResult) => {
            this.properties.BackgroundColor = color.hex;
            this.onPropertyChange("BackgroundColor");
          }}
          pickerStyle={{ left: "-164px", bottom: "34px" }}
        />
      </Form.Item>
    );
  };

  public renderFontColorProperty = () => {
    if (!this.properties.IsBrandingVisible) {
      return null;
    }

    const { t } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 12 },
      },
    };

    return (
      <Form.Item
        name="FontColor"
        label={t("Font Color")}
        initialValue={this.properties.FontColor}
        {...formItemLayout}
      >
        <ColorPicker
          color={this.properties.FontColor || DEFAULT_FONT_COLOR}
          onChange={(color: IColorResult) => {
            this.properties.FontColor = color.hex;
            this.onPropertyChange("FontColor");
          }}
          pickerStyle={{ bottom: "34px" }}
        />
      </Form.Item>
    );
  };

  public renderIsVisibleProperty = () => {
    const { t, component } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
        md: { span: 4 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
      },
    };

    return (
      <Form.Item name="IsVisible" label={t("IS_VISIBLE")} {...formItemLayout}>
        <Switch
          defaultChecked={component.IsVisible}
          onChange={() => this.onIsVisibleChange()}
        />
      </Form.Item>
    );
  };

  public renderIsBrandingVisibleProperty = () => {
    const { t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
        md: { span: 4 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
      },
    };

    return (
      <Form.Item
        name="IsBrandingVisible"
        label={t("IS_BRANDING_VISIBLE")}
        {...formItemLayout}
      >
        <Switch
          defaultChecked={this.properties.IsBrandingVisible}
          onChange={(e) => {
            this.properties.IsBrandingVisible = e;
            this.onPropertyChange("IsBrandingVisible");

            if (e === false) {
              this.deleteProperty("FontColor");
              this.deleteProperty("BackgroundColor");
            }

            if (e) {
              this.properties.FontColor = DEFAULT_FONT_COLOR;
              this.properties.BackgroundColor = DEFAULT_BACKGROUND_COLOR;
              this.onPropertyChange("FontColor");
              this.onPropertyChange("BackgroundColor");
            }
          }}
        />
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="TextWidgetComponentPoperties"
        id="ApplicationComponentPropertiesForm"
        className="TextWidgetComponentPoperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderContentProperty()}
            {this.renderBackgroundImageUrlProperty()}
            {this.renderFileProperty()}
            {this.renderIsVisibleProperty()}
            {this.renderIsBrandingVisibleProperty()}
            <Row gutter={20}>
              <Col span={12}>{this.renderFontColorProperty()}</Col>
              <Col span={12}>{this.renderBackgroundColorProperty()}</Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}
