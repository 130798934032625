import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import {
  CommonStore,
  IConsentModel,
  IErrorModel,
  RecordStatus,
} from "@xala/common-services";
import {
  Button,
  Checkbox,
  Icon,
  ITableColumnProps,
  ITablePaginationConfig,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  TableWithDraggableSorter,
} from "@xala/common-ui";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { DictionaryConsentForm } from "../DictionaryConsentForm";
import { FormModal } from "../../../../components/FormModal";
import "./DictionaryConsentList.scss";
import * as TranslationsModule from "../../../Translations";

const notificationService = NotificationService.getInstance();

export interface IDictionaryConsentListProps extends WithTranslation {
  actionType?: string;
  error?: IErrorModel;
  consents: IConsentModel[];
  isLoadingData: boolean;
  selectConsents: ActionCreator<Action>;
  updateConsent: ActionCreator<Action>;
  deleteConsent: ActionCreator<Action>;
}

interface IDictionaryConsentListState {
  isNewConsent: boolean;
  isOpenFormModal: boolean;
  consent: IConsentModel;
  paginationMinValue: number;
  paginationMaxValue: number;
  paginationPageSize: number;
  currentPage: number;
}

const defaultConsent: IConsentModel = {
  Id: -1,
  Code: "",
  Name: "",
  Description: "",
  Required: false,
  ContentUrl: "",
  Content: "",
  UpToDate: true,
  Sequence: 1,
  ContentType: "",
  ConsentVersion: 1,
};

export class DictionaryConsentList extends React.PureComponent<
  IDictionaryConsentListProps
> {
  public state: Readonly<IDictionaryConsentListState> = {
    isNewConsent: true,
    isOpenFormModal: false,
    consent: { ...defaultConsent },
    paginationMinValue: 0,
    paginationMaxValue: 10,
    paginationPageSize: 10,
    currentPage: 1,
  };

  public componentDidMount() {
    this.refreshData();
  }

  public componentDidUpdate(prevProps: IDictionaryConsentListProps) {
    const { actionType, error, t } = this.props;

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case CommonStore.Consts.INSERT_CONSENT_SUCCESS:
        this.onRefreshClick();
        this.closeModal();
        notificationService.success({
          message: t("AddConsentSuccess", "Consent added successfully."),
        });
        break;

      case CommonStore.Consts.INSERT_CONSENT_FAILURE:
        notificationService.error({
          message: t(
            "AddConsentFailure",
            "There was an error while creating consent."
          ),
          description: error?.Message,
        });
        break;

      case CommonStore.Consts.UPDATE_CONSENT_SUCCESS:
        this.onRefreshClick();
        this.closeModal();
        notificationService.success({
          message: t("UpdateConsentSuccess", "Consent updated successfully."),
        });
        break;

      case CommonStore.Consts.UPDATE_CONSENT_FAILURE:
        notificationService.error({
          message: t(
            "UpdateConsentFailure",
            "There was an error while updating consent."
          ),
          description: error?.Message,
        });
        break;

      case CommonStore.Consts.DELETE_CONSENT_SUCCESS:
        this.onRefreshClick();
        this.closeModal();
        notificationService.success({
          message: t("DeleteConsentSuccess", "Consent deleted successfully."),
        });
        break;

      case CommonStore.Consts.DELETE_CONSENT_FAILURE:
        notificationService.error({
          message: t(
            "DeleteConsentFailure",
            "There was an error while deleting consent."
          ),
          description: error?.Message,
        });
        break;

      default:
        break;
    }
  }

  public onRefreshClick = () => {
    this.refreshData();
  };

  public onDeleteConsent = () => {
    const { deleteConsent } = this.props;

    if (this.state.consent) {
      deleteConsent(this.state.consent);
    }
  };

  private refreshData() {
    const { selectConsents } = this.props;

    selectConsents();
  }

  private getColumnsProps(): Array<ITableColumnProps<IConsentModel>> {
    const { t } = this.props;

    return [
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
        align: "center",
      },
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        render: (text: any, row: IConsentModel) => {
          return (
            <a
              title={row.Code}
              className="imageUrl"
              onClick={() => {
                this.setState({
                  isNewConsent: false,
                  isOpenFormModal: true,
                  consent: row,
                });
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        key: "ConsentVersion",
        dataIndex: "ConsentVersion",
        title: t("MODEL_CONSENT_VERSION"),
        align: "center",
      },
      {
        key: "Name",
        dataIndex: "Name",
        className: "drag-visible",
        title: t("MODEL_NAME"),
      },
      {
        key: "NameTranslationKey",
        dataIndex: "NameTranslationKey",
        className: "drag-visible",
        title: t("MODEL_TRANSLATION_KEY"),
        render: (text: any, row: IConsentModel) => {
          return row.NameTranslationKey ? (
            <label>
              <Link
                to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${row.NameTranslationKey}`}
              >
                {row.NameTranslationKey}
              </Link>
            </label>
          ) : (
            ""
          );
        },
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
      },
      {
        key: "ContentUrl",
        dataIndex: "ContentUrl",
        title: t("MODEL_CONTENT_URL"),
      },
      {
        key: "Required",
        dataIndex: "Required",
        title: t("MODEL_REQUIRED"),
        render: (text, record) => (
          <Checkbox checked={record.Required} disabled={true} />
        ),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        render: (text, record) => (
          <Checkbox checked={record.UpToDate} disabled={true} />
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IConsentModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              this.props.deleteConsent(row);
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  }

  private getPaginationConfig(): ITablePaginationConfig {
    const { t, consents } = this.props;

    return {
      current: this.state.currentPage,
      total: consents.length,
      showTotal: (total, range) =>
        t("ITEMS_RANGE_LABEL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      showSizeChanger: true,
      defaultPageSize: this.state.paginationPageSize,
      defaultCurrent: 1,
      pageSizeOptions: ["10", "30", "50", "100"],
    };
  }

  private onPaginationChange = (value: number) => {
    if (value <= 1) {
      this.setState({
        currentPage: 1,
        paginationMinValue: 0,
        paginationMaxValue: this.state.paginationPageSize,
      });
    } else {
      this.setState({
        currentPage: value,
        paginationMinValue: this.state.paginationMaxValue,
        paginationMaxValue: value * this.state.paginationPageSize,
      });
    }
  };

  private onPaginationShowSizeChange = (current: number, size: number) => {
    this.setState({
      currentPage: 1,
      paginationMinValue: 0,
      paginationMaxValue: size,
      paginationPageSize: size,
    });
  };

  public onAddNewConsentClick = () => {
    this.setState({
      isNewConsent: true,
      isOpenFormModal: true,
      consent: { ...defaultConsent },
    });
  };

  onSuccessContentUploadModal = () => {
    this.onRefreshClick();
    this.closeModal();
  };

  public closeModal = () => {
    this.setState({ isOpenFormModal: false });
  };

  public onMoveRow = async (dragIndex: number, hoverIndex: number) => {
    const { consents, updateConsent } = this.props;

    const draggedConsent = consents[dragIndex];
    const hoveredConsent = consents[hoverIndex];

    const consentToUpdate: IConsentModel = {
      ...draggedConsent,
      Sequence: hoveredConsent.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedConsent.Sequence !== consentToUpdate.Sequence;
    const draggedToNewPosition = draggedConsent.Guid !== hoveredConsent.Guid;

    if (draggedToNewPosition && sequenceChanged) {
      await updateConsent(consentToUpdate);
    }
  };

  private getExtraButtons() {
    const { t } = this.props;

    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={this.onAddNewConsentClick}
          title={t("new", "Add new consent")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={this.onRefreshClick}
          title="Refresh data"
        />
      </>
    );
  }

  public render() {
    const { consents, isLoadingData, t } = this.props;
    let consentsToRender =
      consents.length > 0
        ? consents.slice(
            this.state.paginationMinValue,
            this.state.paginationMaxValue
          )
        : consents;

    return (
      <>
        <FormModal
          isLoading={isLoadingData}
          isVisible={this.state.isOpenFormModal}
          isNewForm={this.state.isNewConsent}
          isDeleteButtonEnabled={true}
          createFormTitle={t("AddConsent", "Add new consent")}
          editFormTitle={t("EditConsent", "Edit consent")}
          modalClassName="DictionaryConsentModal"
          submitFormName="DictionaryConsentForm"
          onCloseModal={this.closeModal}
          onDeleteButtonClick={this.onDeleteConsent}
        >
          <DictionaryConsentForm
            consent={this.state.consent}
            isNewConsent={this.state.isNewConsent}
            onSuccess={this.onSuccessContentUploadModal}
          />
        </FormModal>

        <PageContent
          footer={
            <Pagination
              {...this.getPaginationConfig()}
              onChange={this.onPaginationChange}
              onShowSizeChange={this.onPaginationShowSizeChange}
            />
          }
        >
          <PageHeader
            title={t("DictionaryConsentListTitle", "Consents")}
            extra={this.getExtraButtons()}
          />
          <TableWithDraggableSorter<IConsentModel>
            dragType="handler"
            columns={this.getColumnsProps()}
            dataSource={consentsToRender}
            loading={isLoadingData}
            pagination={false}
            onMoveRow={this.onMoveRow}
          />
        </PageContent>
      </>
    );
  }
}
