import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthStore } from "@xala/common-services";
import { AuthContainer } from "../AuthContainer";
import { ForgotPasswordForm } from "..";
import { ROUTES } from "../../../../constants";

export const ForgotPassword = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(AuthStore.Actions.clearError());
    },
    [dispatch]
  );

  return (
    <AuthContainer
      footerLink={{
        href: ROUTES.LOGIN,
        title: t("FORGOT_PASSWORD_BACK_TO_LOGIN_LINK"),
      }}
    >
      <ForgotPasswordForm />
    </AuthContainer>
  );
};
