import React, { useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import { deltaToHtml, convertHtmlToEditorContent } from "./utils";
import cx from "classnames";
import "./TextEditor.scss";

const Bold = Quill.import("formats/bold");
const Italic = Quill.import("formats/italic");

Bold.tagName = "b";
Italic.tagName = "i";

Quill.register("formats/bold", Bold);
Quill.register("formats/italic", Italic);

const colorPicker = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
];

export interface ITextEditorProps {
  value?: string;
  className?: string;
  onChange?: (model: string) => void;
  addImage?: boolean;
}

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: colorPicker,
      },
      {
        background: colorPicker,
      },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["clean"],
  ],
};

export const TextEditor = ({
  onChange,
  value,
  addImage,
  className,
}: ITextEditorProps) => {
  const onChangeHandler = useCallback(
    (_content, _delta, _source, editor) => {
      const delta = editor.getContents();
      const html = deltaToHtml(delta.ops);

      onChange?.(html);
    },
    [onChange]
  );

  const containerClassName = cx(className, "gm-text-editor");

  const editorModules = () => ({
    ...modules,
    toolbar: [...modules.toolbar],
  });

  let key = "defaultKey";

  if (value !== undefined) {
    key = "keyWithInitialValue";
  }

  return (
    <ReactQuill
      key={key}
      className={containerClassName}
      defaultValue={convertHtmlToEditorContent(value)}
      modules={editorModules()}
      onChange={onChangeHandler}
    />
  );
};
