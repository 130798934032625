import { IPaymentModel } from "@xala/common-services";
import {
  Col,
  DomainTag,
  Form,
  Link,
  Button,
  Row,
  PaymentStatusTag,
  SectionGrid,
  SectionGridItem,
} from "@xala/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES as ASSET_ROUTES } from "../../../Asset/constants";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export interface IPaymentForm {
  payment?: IPaymentModel;
}

export const PaymentForm: React.FC<IPaymentForm> = ({ payment }) => {
  const { t } = useTranslation();

  return (
    <SectionGrid style={{ maxWidth: "1100px" }}>
      <SectionGridItem header="General information">
        <Form name="PaymentForm" className="PaymentForm" {...formItemLayout}>
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              <Form.Item label={t("MODEL_PURCHASED_BY")}>
                <span>{payment?.UserFullName}</span>
              </Form.Item>

              <Form.Item label={t("MODEL_PRICE")}>
                <span>{payment?.TotalAmount}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_CURRENCY")}>
                <DomainTag
                  domain="currency"
                  noMargin
                  value={payment?.Currency}
                />
              </Form.Item>
              <Form.Item label={t("MODEL_TYPE_NAME")}>
                <DomainTag
                  domain="purchase-type"
                  noMargin
                  value={payment?.TypeDisplayName}
                />
              </Form.Item>

              <Form.Item label={t("MODEL_PAYMENT_STATUS")}>
                <PaymentStatusTag status={payment?.StatusCode} />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Link
                  to={`${ASSET_ROUTES.ASSET_DETAILS}/${payment?.AssetPayment?.AssetId}`}
                >
                  <Button style={{ float: "right" }}>
                    {t("SEE_PURCHASED_ASSET_BUTTON")}
                  </Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SectionGridItem>
      <SectionGridItem header="Transaction details">
        <Form name="PaymentForm" className="PaymentForm" {...formItemLayout}>
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              <Form.Item label={t("MODEL_PAYMENT_EMAIL")}>
                <span>{payment?.PaymentEmail}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_PAYMENT_METHOD")}>
                <span>{payment?.PaymentMethod}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_PAYMENT_KEY")}>
                <span>{payment?.PaymentKey}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_GUID")}>
                <span>{payment?.Guid}</span>
              </Form.Item>
              <Form.Item label={t("MODEL_DESCRIPTION")}>
                <span>{payment?.Description}</span>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SectionGridItem>
    </SectionGrid>
  );
};
