import {
  ApplicationConfigurationStore,
  IErrorModel,
} from "@xala/common-services";

interface IActionHandler {
  triggerListRefresh: () => void;
  notificationService: any;
  t: any;
  additionalHandlers?: any;
}

export const createActionHandler = ({
  triggerListRefresh,
  notificationService,
  t,
  additionalHandlers,
}: IActionHandler) => {
  const actionsHandlers: { [key: string]: any } = {
    [ApplicationConfigurationStore.Consts
      .SEARCH_APPLICATION_BRANDING_SUCCESS]: {},
    [ApplicationConfigurationStore.Consts
      .SEARCH_APPLICATION_BRANDING_FAILURE]: {},
    [ApplicationConfigurationStore.Consts
      .INSERT_APPLICATION_BRANDING_SUCCESS]: () => {
      notificationService.success({
        message: t("AddBrandingSuccess", "Branding added successfully."),
      });
      triggerListRefresh();
      additionalHandlers.closeModal();
    },
    [ApplicationConfigurationStore.Consts
      .UPDATE_APPLICATION_BRANDING_SUCCESS]: () => {
      notificationService.success({
        message: t("UpdateBrandingSuccess", "Branding updated successfully."),
      });
      triggerListRefresh();
      additionalHandlers.closeModal();
    },
    [ApplicationConfigurationStore.Consts
      .UPDATE_APPLICATION_BRANDING_FAILURE]: (
      error: IErrorModel | undefined
    ) => {
      notificationService.error({
        message: t(
          "UpdateBrandingFailure",
          "There was an error while updating branding."
        ),
        description: error?.Message,
      });
    },
    [ApplicationConfigurationStore.Consts
      .DELETE_APPLICATION_BRANDING_SUCCESS]: () => {
      notificationService.success({
        message: t("DeleteBrandingSuccess", "Branding deleted successfully."),
      });
      triggerListRefresh();
      additionalHandlers.closeModal();
    },
    [ApplicationConfigurationStore.Consts
      .DELETE_APPLICATION_BRANDING_FAILURE]: (
      error: IErrorModel | undefined
    ) => {
      notificationService.error({
        message: t(
          "DeleteBrandingFailure",
          "There was an error while deleting branding."
        ),
        description: error?.Message,
      });
    },
    default: () => {},
  };

  return (actionType: string, error: IErrorModel | undefined) => {
    try {
      actionsHandlers[actionType as string](error);
      if (typeof additionalHandlers[actionType] === "function") {
        additionalHandlers[actionType](error);
      }
    } catch (err) {
      actionsHandlers.default();
    }
  };
};
