import {
  ApplicationConfigurationStore,
  IApplicationScreenModel,
  IApplicationsScreensSearchFilterModel,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationConfigurationScreensList as ApplicationConfigurationScreensListDefinition } from "./ApplicationConfigurationScreensList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isLoadingData: state.applicationConfiguration.isLoadingData,
    actionType: state.applicationConfiguration.actionType,
    error: state.applicationConfiguration.error,
    screensTypes: state.applicationConfiguration.screensTypes,
    platforms: state.common.platforms,
    screens: state.applicationConfiguration.screens.Entities,
    locationSearch: state.router?.location.search,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteScreen: (screen: IApplicationScreenModel) => {
    return dispatch(ApplicationConfigurationStore.Actions.deleteScreen(screen));
  },
  getConfiguration: (id: number) => {
    return dispatch(ApplicationConfigurationStore.Actions.getConfiguration(id));
  },
  searchScreens: (filter: IApplicationsScreensSearchFilterModel) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.searchScreens(filter)
    );
  },
});

export const ApplicationConfigurationScreensList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationConfigurationScreensListDefinition));
