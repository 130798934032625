import * as React from "react";
import { ShakaPlayer } from "./ShakaPlayer";
import "./Player.scss";
import "shaka-player/dist/controls.css";

export interface IPlayerProps {
  contentUrl?: string;
  fill?: boolean;
}

const DEFAULT_CONTENT_URL =
  "https://docs.evostream.com/sample_content/assets/hls-bunny-166/playlist.m3u8";

export class Player extends React.Component<IPlayerProps> {
  static defaultProps = {
    fill: true,
  };

  private _player = new ShakaPlayer();
  private videoContainer: React.RefObject<HTMLDivElement> = React.createRef();
  private videoComponent: React.RefObject<HTMLVideoElement> = React.createRef();

  public componentDidMount = () => {
    const { contentUrl } = this.props;

    this._player.init(
      this.videoComponent.current as HTMLVideoElement,
      this.videoContainer.current as HTMLDivElement
    );
    this._player.load(contentUrl || DEFAULT_CONTENT_URL);
  };

  public onClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    // this._player.togglePlay();
  };

  public onDoubleClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    // this._player.toggleFullScreen();
  };

  public render() {
    const { fill } = this.props;
    return (
      <div className="video-player-container">
        <div
          data-shaka-player-container
          ref={this.videoContainer}
          className="video-container"
          onClick={this.onClick}
          onDoubleClick={this.onDoubleClick}
        >
          <video
            data-shaka-player
            ref={this.videoComponent}
            id="video"
            width={fill ? "100%" : "auto"}
            height={fill ? "100%" : "auto"}
            autoPlay={false}
          />
        </div>
      </div>
    );
  }
}
