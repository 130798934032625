import { AssetImageType } from "@xala/common-services";

export class assetImagesTypeHelper {
  static imageType() {
    return [
      {
        name: AssetImageType.Cover,
        suggestedResolution: { width: 480, height: 640 },
      },
      {
        name: AssetImageType.Frame,
        suggestedResolution: { width: 720, height: 405 },
      },
      {
        name: AssetImageType.Highlights,
        suggestedResolution: { width: 1920, height: 723 },
      },
      {
        name: AssetImageType.HeroLandscape,
        suggestedResolution: { width: 1820, height: 300 },
      },
      {
        name: AssetImageType.Round,
        suggestedResolution: { width: 141, height: 141 },
      },
      {
        name: AssetImageType.Square,
        suggestedResolution: { width: 234, height: 234 },
      },
    ];
  }
}

export const imageFooterSizeHelper = {
  name: "Footer",
  suggestedResolution: { width: 1920, height: 400 },
};

export const imageBannerSizeHelper = {
  name: "Banner",
  suggestedResolution: { width: 833, height: 397 },
};
