import React from "react";
import {
  useDataLoader,
  UserStreamsService,
  IUserModel,
} from "@xala/common-services";
import { useTranslation } from "react-i18next";
import { MediaChannelForm } from "../../../MediaChannel/components";

const userStreamsService = new UserStreamsService().promisify();

export interface IUserLiveManagementProps {
  user: IUserModel;
}

export const UserLiveManagement: React.FC<IUserLiveManagementProps> = ({
  user,
}) => {
  const { t } = useTranslation();

  const { data, loading, refresh } = useDataLoader({
    loader: () => userStreamsService.getChannel(user.Id),
    deps: [user],
  });

  return (
    <MediaChannelForm
      channel={data}
      loading={loading}
      refresh={refresh}
      title={t("USER_LIVE_MANAGEMENT_TITLE")}
      userId={user.Id}
    />
  );
};
