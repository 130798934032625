import { SectionGrid, SectionGridItem } from "@xala/common-ui";
import React from "react";
import { NotificationForm } from "../../NotificationForm";
import { ITabProps } from "./ITabProps";

export const TabContent = ({ notification, onSubmit }: ITabProps) => {
  return (
    <SectionGrid style={{ maxWidth: "1200px" }}>
      <SectionGridItem>
        <NotificationForm
          notification={notification}
          onSubmit={onSubmit}
          isEditMode
          hiddenFields={[
            "Name",
            "ScheduledOn",
            "Type",
            "RecipientType",
            "CreatorUserFullName",
            "Status",
            "Priority",
            "Provider",
            "UrlDescription",
            "UrlContent",
          ]}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
