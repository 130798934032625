import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationFooterItemProperties as ApplicationFooterItemPropertiesDefinition,
  IApplicationFooterItemPropertiesDispatchProps,
  IApplicationFooterItemPropertiesOwnProps,
  IApplicationFooterItemPropertiesStateProps,
} from "./ApplicationFooterItemProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationFooterItemPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationFooterItemPropertiesDispatchProps => ({});

export const ApplicationFooterItemProperties = connect<
  IApplicationFooterItemPropertiesStateProps,
  IApplicationFooterItemPropertiesDispatchProps,
  IApplicationFooterItemPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationFooterItemPropertiesDefinition));
