import React from "react";
import { Choose, ChooseOption, Form } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import {
  INotificationPriorityModel,
  NotificationPrioritiesService,
  useDataLoader,
} from "@xala/common-services";
import { INotificationField } from "./INotificationField";

const notificationPrioritiesService = new NotificationPrioritiesService().promisify();

export const PrioritiesField = ({
  notification,
  isEditMode,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();
  const notificationsPrioritiesLoader = useDataLoader({
    loader: () => notificationPrioritiesService.select(),
    deps: [],
  });

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item
      name="Priority"
      label={t("NOTIFICATION_PRIORITY_FIELD")}
      key="Priority"
      initialValue={isEditMode ? notification?.Priority || "" : ""}
    >
      <Choose
        placeholder={t("NOTIFICATION_PRIORITY_INPUT_FIELD")}
        key="Priority"
      >
        {notificationsPrioritiesLoader.data?.map(
          (type: INotificationPriorityModel) => (
            <ChooseOption key={type.Code} value={type.Code}>
              {type.DisplayName}
            </ChooseOption>
          )
        )}
      </Choose>
    </Form.Item>
  );
};
