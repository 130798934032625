import {
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  IApplicationComponentPropertyValueModel,
  RecordStatus,
} from "@xala/common-services";

export const getItemsPropertyValuesFromComponent = (
  component: IApplicationComponentModel
) => {
  return component.Properties
    ? component.Properties.find((component) => component.Name === "Items")
    : undefined;
};

export const getValuesFromItemsProperty = (
  property: IApplicationComponentPropertyModel
) => {
  return property.Values ? property.Values : [];
};

export const getUndeletedComponents = (
  components: IApplicationComponentPropertyValueModel[]
) => {
  return (
    components &&
    components
      .map((item) => item.Component)
      .filter((value) =>
        value ? value.RecordStatus !== RecordStatus.Deleted : undefined
      )
  );
};

export const sortComponentsBySequence = (
  components: IApplicationComponentPropertyValueModel[]
) => {
  return components
    ? components.sort((a, b) => {
        if (
          typeof a.ApplicationComponentPropertyValueSequence === "number" &&
          typeof b.ApplicationComponentPropertyValueSequence === "number"
        ) {
          return (
            a.ApplicationComponentPropertyValueSequence -
            b.ApplicationComponentPropertyValueSequence
          );
        }

        return 0;
      })
    : [];
};
