import { StorageService } from "@xala/common-services";
import React from "react";
import { TextWidgetComponentPropertyModel } from "../../models";

const storageService: StorageService = StorageService.getInstance();

export interface TextWidgetMediaComponentPreviewProps {
  properties?: TextWidgetComponentPropertyModel;
}

export const TextWidgetMediaComponentPreview = React.memo(
  ({ properties }: TextWidgetMediaComponentPreviewProps) => {
    if (!properties?.ImageUrl) {
      return null;
    }

    return <img alt="" src={properties.ImageUrl} />;
  }
);
