import { Dispatch } from "redux";
import { connect } from "react-redux";
import { DictionaryConsentForm as DictionaryConsentFormDefinition } from "./DictionaryConsentForm";
import {
  CommonStore,
  ICommonAppState,
  IConsentModel,
} from "@xala/common-services";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isProcessing: state.common.isProcessingData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateConsent: (consent: IConsentModel) => {
    return dispatch(CommonStore.Actions.updateConsent(consent));
  },
  insertConsent: (consent: IConsentModel) => {
    return dispatch(CommonStore.Actions.insertConsent(consent));
  },
});

export const DictionaryConsentForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionaryConsentFormDefinition);
