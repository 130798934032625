import {
  IApplicationComponentModel,
  RecordStatus,
} from "@xala/common-services";
import { FileHelper, IUploadChangeEvent } from "@xala/common-ui";
import { RcFile } from "antd/lib/upload";
import React from "react";
import { ComponentPropertyModel } from "../../models";

export interface IComponentPropertiesBaseProps {
  component: IApplicationComponentModel;
  onComponentChange?: (component: IApplicationComponentModel) => void;
}

export interface IComponentPropertiesBaseState {}

export abstract class ComponentPropertiesBase<
  TProps extends IComponentPropertiesBaseProps,
  TState extends IComponentPropertiesBaseState,
  TModel extends ComponentPropertyModel
> extends React.PureComponent<TProps, TState> {
  properties: TModel;

  constructor(props: TProps, context?: any) {
    super(props, context);

    this.properties = this.getPropertiesModel();
  }

  abstract getPropertiesModel(): TModel;

  onChange() {
    const { component, onComponentChange } = this.props;

    if (component.RecordStatus === RecordStatus.NoChange) {
      component.RecordStatus = RecordStatus.Updated;
    }

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  onPropertyChange(propertyName: string) {
    const { component, onComponentChange } = this.props;

    this.properties.onPropertyChange(propertyName, component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  deleteProperty(propertyName: string) {
    const { component, onComponentChange } = this.props;

    this.properties.deleteProperty(propertyName, component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  }

  beforeUpload = (file: RcFile) => {
    FileHelper.fileToBase64(file as File).then((base64image: string) => {
      if (this.properties.hasIconUrl()) {
        this.properties.IconUrl = base64image;
        this.onPropertyChange("IconUrl");
      }
      if (this.properties.hasBackgroundUrl()) {
        this.properties.BackgroundUrl = base64image;
        this.onPropertyChange("BackgroundUrl");
      }
      if (this.properties.hasImageUrl()) {
        this.properties.ImageUrl = base64image;
        this.onPropertyChange("ImageUrl");
      }
    });

    return true;
  };

  onImageChange = (e: IUploadChangeEvent) => {
    const { component } = this.props;

    if (e.fileList.length === 0) {
      return;
    }

    const files = [...e.fileList].slice(-1);

    component.Image = files[0].originFileObj;

    this.onChange();

    return e && e.file && e.file.originFileObj;
  };
}
