import {
  IErrorModel,
  IUserModel,
  UserService,
  UserStore,
  ProfileCode,
  UserHelper,
  IUserSettingsModel,
  IAssetModel,
  IAssetCategoryModel,
  AssetType,
  UserSettingsStore,
  IAssetSearchFilterModel,
  IAssetCategoriesSearchFilterModel,
} from "@xala/common-services";
import {
  Button,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  Spin,
  TabPane,
  Tabs,
  Tooltip,
} from "@xala/common-ui";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Action, ActionCreator } from "redux";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { UserAccountSettingsForm } from "../UserAccountSettingsForm";
import { UserPersonalInformationForm } from "../UserPersonalInformationForm";
import { UserPurchasedProducts } from "../UserPurchasedProducts";
import { UserResendConfirmationEmailByUserModal } from "../UserResendConfirmationEmailByUserModal";
import { UserResetPasswordModal } from "../UserResetPasswordModal";
import { UserLiveManagement } from "..";
import "./UserDetails.scss";

const notificationService = NotificationService.getInstance();
const userService = new UserService().promisify();

export interface IUserDetailsStateProps {
  actionType?: string;
  resetUserPasswordResult?: boolean;
  user?: IUserModel;
  isLoading: boolean;
  isProcessing: boolean;
  error?: IErrorModel;
  userSettings: IUserSettingsModel;
  categories: IAssetCategoryModel[];
  channels: IAssetModel[];
}

export interface IUserDetailsDispatchProps {
  getUser: ActionCreator<Action>;
  anonymizeUser: ActionCreator<UserStore.Types.IAnonymizeUserAction>;
  getUserSettings: ActionCreator<Action>;
  getCategories: ActionCreator<Action>;
  getChannels: ActionCreator<Action>;
}

export interface IUserDetailsOwnProps {
  viewType: "customers" | "administrators" | "all";
  customerUser?: boolean;
}

export interface IUserDetailsProps
  extends IUserDetailsStateProps,
    IUserDetailsDispatchProps,
    IUserDetailsOwnProps,
    WithTranslation,
    RouteComponentProps<{ id: string }> {}

export interface IUserDetailsState {
  activeTabKey: string;
  changePasswordModalVisible: boolean;
  emailModalVisible: boolean;
}

export class UserDetails extends React.Component<
  IUserDetailsProps,
  IUserDetailsState
> {
  static defaultProps: IUserDetailsOwnProps = {
    viewType: "all",
  };

  public state: Readonly<IUserDetailsState> = {
    activeTabKey: "PERSONAL_INFORMATION",
    changePasswordModalVisible: false,
    emailModalVisible: false,
  };

  public componentDidMount() {
    const { match } = this.props;

    const categoriesFilter: IAssetCategoriesSearchFilterModel = {
      PageSize: 999,
      PageNumber: 1,
      IncludeCount: true,
      UpToDate: true,
    };

    const channelsFilter: IAssetSearchFilterModel = {
      PageSize: 999,
      PageNumber: 1,
      IncludeCount: true,
      Types: [AssetType.Channel],
    };

    this.props.getUser(match.params.id);
    this.props.getUserSettings(match.params.id);
    this.props.getCategories(categoriesFilter);
    this.props.getChannels(channelsFilter);
  }

  public componentWillReceiveProps(nextProps: IUserDetailsProps) {
    const { actionType, t } = this.props;

    if (nextProps.actionType === actionType) {
      return;
    }

    switch (nextProps.actionType) {
      case UserStore.Consts.UPDATE_USER_FAILURE:
        return notificationService.error({
          message: t(
            "USER_UPDATE_PERSONAL_INFORMATION_FAILURE",
            "Update personal information failed"
          ),
          description: nextProps.error ? nextProps.error.Message : undefined,
        });
      case UserStore.Consts.UPDATE_USER_SUCCESS:
        this.onRefreshClick();
        return notificationService.success({
          message: t(
            "USER_UPDATE_PERSONAL_INFORMATION_SUCCESS",
            "Update personal information success"
          ),
        });
      case UserStore.Consts.CHANGE_USER_PASSWORD_SUCCESS:
        this.setState({ changePasswordModalVisible: false });

        if (nextProps.resetUserPasswordResult) {
          return notificationService.success({
            message: t(
              "USER_CHANGE_USER_PASSWORD_SUCCESS",
              "Reset password success"
            ),
          });
        }
        return notificationService.error({
          message: t(
            "USER_CHANGE_USER_PASSWORD_FAILURE",
            "Reset password failed"
          ),
          description: nextProps.error?.Message,
        });

      case UserStore.Consts.CHANGE_USER_PASSWORD_FAILURE:
        return notificationService.error({
          message: t(
            "USER_CHANGE_USER_PASSWORD_FAILURE",
            "Password change failed"
          ),
          description: nextProps.error ? nextProps.error.Message : undefined,
        });
      case UserStore.Consts.DELETE_USER_FAILURE:
      case UserStore.Consts.ANONYMIZE_USER_FAILURE:
        return notificationService.error({
          message: t("USER_DELETE_USER_FAILURE", "Delete user failed"),
          description: nextProps.error ? nextProps.error.Message : undefined,
        });
      case UserStore.Consts.DELETE_USER_SUCCESS:
      case UserStore.Consts.ANONYMIZE_USER_SUCCESS:
        notificationService.success({
          message: t("USER_DELETE_USER_SUCCESS", "Delete user success"),
          description: t(
            "USER_DELETE_USER_SUCCESS_INFO",
            "User will be still visible on users list but it's account is locked"
          ),
        });

        window.history.back();
        break;
      case UserSettingsStore.Consts.UPDATE_USER_SETTINGS_SUCCESS:
        this.onRefreshClick();
        return notificationService.success({
          message: t(
            "USER_UPDATE_ACCOUNT_SETTINGS_SUCCESS",
            "Update account setting success"
          ),
        });
      case UserSettingsStore.Consts.UPDATE_USER_SETTINGS_FAILURE:
        return notificationService.error({
          message: t(
            "USER_UPDATE_ACCOUNT_SETTINGS_FAILURE",
            "Update account setting failed"
          ),
          description: nextProps.error ? nextProps.error.Message : undefined,
        });
      default:
        break;
    }
  }

  private onTabClick = (key: string) => {
    this.setState({ activeTabKey: key });
  };

  private onDeleteClick = () => {
    const { t, user } = this.props;

    if (!user) {
      return;
    }

    Modal.confirm({
      title: t("USER_DETAILS_DELETE_ACTION_TITLE", "Delete user"),
      content: t(
        "USER_DETAILS_DELETE_ACTION_MESSAGE",
        `Are you sure to delete user ${user.FullName}?`,
        { userFullName: user.FullName }
      ),
      okText: t("BUTTON_OK", "OK"),
      cancelText: t("BUTTON_CANCEL", "Cancel"),
      onOk: this.performDelete,
    });
  };

  public performDelete = () => {
    const { anonymizeUser, user } = this.props;

    anonymizeUser(user);
  };

  private onConfirmEmailClick = () => {
    const { t, user } = this.props;

    if (!user) {
      return;
    }

    Modal.confirm({
      title: t(
        "USER_DETAILS_ACTION_CONFIRM_EMIAL_TITLE",
        "E-mail confirmation"
      ),
      content: t(
        "USER_DETAILS_ACTION_CONFIRM_EMIAL_MESSAGE",
        `Are you sure to confirm user ${user.FullName} e-mail?`,
        { userFullName: user.FullName }
      ),
      okText: t("BUTTON_OK", "OK"),
      cancelText: t("BUTTON_CANCEL", "Cancel"),
      onOk: this.performConfirmEmail,
    });
  };

  public performConfirmEmail = async () => {
    const { user, t, getUser } = this.props;

    if (!user?.Id) {
      return;
    }

    const result = await userService.confirmEmailByAdmin(user?.Id);

    if (result.ok) {
      notificationService.success({
        message: t(
          "USER_ACTION_CONFIRM_EMAIL_BY_ADMIN_SUCCESS",
          "User e-mail successfully confirmed."
        ),
      });
      getUser(user?.Id);
    } else {
      notificationService.error({
        message: t(
          "USER_ACTION_CONFIRM_EMAIL_BY_ADMIN_FAILURE",
          "There was an error while confirming user e-mail"
        ),
        description: result?.error?.Message,
      });
    }
  };

  public onRefreshClick = () => {
    const { match, getUser, getUserSettings } = this.props;

    getUser(match.params.id);
    getUserSettings(match.params.id);
  };

  private getBreadcrumbProps(): BreadcrumbProps {
    const { t, user, viewType } = this.props;
    const isCustomerView = viewType === "customers";
    const isAdministratorsView = viewType === "administrators";

    return generateBreadcrumb([
      {
        path: isCustomerView
          ? `${ROUTES.CUSTOMER_LIST}`
          : isAdministratorsView
          ? `${ROUTES.ADMINISTRATOR_LIST}`
          : `${ROUTES.USER_LIST}`,
        breadcrumbName: isCustomerView
          ? t("CUSTOMERS_LIST_TITLE")
          : isAdministratorsView
          ? t("ADMINISTRATORS_LIST_TITLE")
          : t("USERS_LIST_TITLE"),
      },
      {
        path: isCustomerView
          ? `${ROUTES.CUSTOMER_DETAILS}/${user?.Id}`
          : isAdministratorsView
          ? `${ROUTES.ADMINISTRATOR_DETAILS}/${user?.Id}`
          : `${ROUTES.USER_DETAILS}/${user?.Id}`,
        breadcrumbName: user?.FullName ?? this.getTitle(),
      },
    ]);
  }

  private getTitle() {
    const { t } = this.props;

    return t("USER_DETAILS_TAB_DETAILS_TITLE");
  }

  public render() {
    const {
      isLoading,
      isProcessing,
      t,
      user,
      userSettings,
      categories,
      channels,
    } = this.props;
    const { activeTabKey } = this.state;

    if (!user || userSettings.IsLoading) {
      return (
        <div className="UserDetails">
          <Spin
            spinning
            style={{ position: "absolute", top: "25%", left: "50%" }}
          />
        </div>
      );
    }

    const actions: React.ReactNode[] = [
      !user?.EmailConfirmed && !user.Locked && (
        <Button
          key="resend-confirmation-email"
          shape="circle"
          type="primary"
          icon={<Icon type="send" />}
          onClick={() => this.setState({ emailModalVisible: true })}
          title={t(
            "USER_DETAILS_TAB_RESEND_EMAIL_TITLE",
            "Resend confirmation email"
          )}
        />
      ),
      <Button
        key="change-password"
        shape="circle"
        type="primary"
        icon={<Icon type="key" />}
        onClick={() => this.setState({ changePasswordModalVisible: true })}
        title={t("USER_DETAILS_TAB_CHANGE_PASSWORD_TITLE")}
      />,
      <Button
        key="reload"
        shape="circle"
        icon={<Icon type="reload" />}
        onClick={this.onRefreshClick}
        title={t("BUTTON_REFRESH_TITLE")}
      />,
      <Button
        danger
        key="delete"
        shape="circle"
        icon={<Icon type="delete" />}
        onClick={this.onDeleteClick}
        disabled={user.Locked}
        title={t("BUTTON_DELETE")}
      />,
    ];

    if (!user?.EmailConfirmed && !user.Locked) {
      actions.push(
        <Tooltip
          key="confirm-email-tooltip"
          overlay={t(
            "USER_DETAILS_ACTION_CONFIRM_EMIAL_BUTTON_TITLE",
            "Confirm email"
          )}
        >
          <Button
            key="confirm-email"
            shape="circle"
            icon={<Icon type="check" />}
            onClick={this.onConfirmEmailClick}
            title={t(
              "USER_DETAILS_ACTION_CONFIRM_EMIAL_BUTTON_TITLE",
              "Confirm email"
            )}
          />
        </Tooltip>
      );
    }

    return (
      <div className="UserDetails">
        <Spin spinning={isLoading} />
        <PageContent>
          <PageHeader
            title={user.FullName || user.Email || this.getTitle()}
            className="UserDetails__PageHeader"
            onBack={() => window.history.back()}
            breadcrumb={this.getBreadcrumbProps()}
            extra={actions}
          />
          <Tabs defaultActiveKey={activeTabKey} onTabClick={this.onTabClick}>
            <TabPane key="ACCOUNT_SETTINGS" tab={this.getTitle()}>
              <SectionGrid style={{ maxWidth: "1200px" }}>
                <SectionGridItem
                  processing={isProcessing}
                  header={t(
                    "USER_PERSONAL_INFORMATION_FORM_TITLE",
                    "Personal information"
                  )}
                >
                  <UserPersonalInformationForm
                    user={user}
                    viewType={this.props.viewType}
                  />
                </SectionGridItem>
                <SectionGridItem
                  processing={isProcessing}
                  header={t(
                    "USER_DETAILS_TAB_ACCOUNT_SETTINGS_TITLE",
                    "Account Settings"
                  )}
                >
                  <UserAccountSettingsForm
                    userSettings={userSettings}
                    categories={categories}
                    channels={channels}
                  />
                </SectionGridItem>
              </SectionGrid>
            </TabPane>{" "}
            <TabPane
              key="PURCHASED_PRODUCTS"
              tab={t("USER_DETAILS_TAB_PURCHASED_PRODUCTS_TITLE")}
            >
              <SectionGrid>
                <SectionGridItem>
                  <UserPurchasedProducts user={user} />
                </SectionGridItem>
              </SectionGrid>
            </TabPane>
            {UserHelper.hasProfile(user, [ProfileCode.Creator]) && (
              <TabPane
                key="LIVE_MANAGEMENT"
                tab={t("USER_DETAILS_TAB_LIVE_MANAGEMENT")}
              >
                <UserLiveManagement user={user} />
              </TabPane>
            )}
          </Tabs>
        </PageContent>
        <UserResetPasswordModal
          user={user}
          isVisible={this.state.changePasswordModalVisible}
          onCancel={() => this.setState({ changePasswordModalVisible: false })}
        />
        <UserResendConfirmationEmailByUserModal
          visible={this.state.emailModalVisible}
          user={user}
          onCancel={() => this.setState({ emailModalVisible: false })}
          onSuccess={() => this.setState({ emailModalVisible: false })}
        />
      </div>
    );
  }
}
