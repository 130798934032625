import {
  RecordStatus,
  GuidHelper,
  useDataLoader,
  useServiceCaller,
  ICurrencyModel,
  CurrencyService,
} from "@xala/common-services";
import {
  Checkbox,
  ITableColumnProps,
  NotificationService,
  PageContent,
  PageHeader,
  TableWithDraggableSorter,
  Button,
} from "@xala/common-ui";
import { FormModal } from "../../../../components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { DictionaryCurrencyForm } from "../DictionaryCurrencyForm";

const notificationService = NotificationService.getInstance();
const currencyService = new CurrencyService().promisify();

export interface IDictionaryCurrencyListProps {}

const defaultCurrency: ICurrencyModel = {
  Id: -1,
  Code: "",
  Guid: "",
  Name: "",
  UpToDate: true,
  Sequence: 1,
  RecordStatus: RecordStatus.Inserted,
  MinPayment: 0,
};

export const DictionaryCurrencyList: React.FC<IDictionaryCurrencyListProps> = () => {
  const [showLanguageModal, setShowCurrencyModal] = useState(false);
  const [editableCurrency, setEditableCurrency] = useState<ICurrencyModel>(
    defaultCurrency
  );
  const { t } = useTranslation();

  const currencyLoader = useDataLoader({
    loader: () => currencyService.select(),
    deps: [],
  });

  const [insertCurrency, { processing: insertProcessing }] = useServiceCaller(
    async (currency: ICurrencyModel) => {
      const result = await currencyService.insert({
        ...currency,
        Guid: GuidHelper.newGuid(),
        RecordStatus: RecordStatus.Inserted,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_CURRENCY_INSERT_SUCCESS"),
        });
        await currencyLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_CURRENCY_INSERT_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [updateCurrency, { processing: updateProcessing }] = useServiceCaller(
    async (currency: ICurrencyModel) => {
      const result = await currencyService.update({
        ...currency,
        RecordStatus: RecordStatus.Updated,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_CURRENCY_UPDATE_SUCCESS"),
        });
        await currencyLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_CURRENCY_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [deleteCurrency, { processing: deleteProcessing }] = useServiceCaller(
    async (currency: ICurrencyModel) => {
      const result = await currencyService.delete({
        ...currency,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_CURRENCY_DELETE_SUCCESS"),
        });
        await currencyLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_CURRENCY_DELETE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const getColumnsProps = (): Array<ITableColumnProps<ICurrencyModel>> => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        render: (_: any, row: ICurrencyModel) => {
          return (
            <a
              title={row.Code}
              className="imageUrl"
              onClick={() => {
                setShowCurrencyModal(true);
                setEditableCurrency(row);
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        className: "drag-visible",
        title: t("MODEL_NAME"),
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
      },
      {
        key: "MinPayment",
        dataIndex: "MinPayment",
        title: t("MODEL_MIN_PAYMENT"),
        render: (_: any, row: ICurrencyModel) => row.MinPayment.toFixed(2),
      },
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        render: (_, record) => (
          <Checkbox checked={record.UpToDate} disabled={true} />
        ),
      },
    ];
  };

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedLanguage = currencyLoader.data?.[dragIndex];
    const hoveredLanguage = currencyLoader.data?.[hoverIndex];

    if (!draggedLanguage || !hoveredLanguage) {
      return;
    }

    const currencyToUpdate: ICurrencyModel = {
      ...draggedLanguage,
      Sequence: hoveredLanguage.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedLanguage.Sequence !== currencyToUpdate.Sequence;
    const draggedToNewPosition = draggedLanguage.Guid !== hoveredLanguage.Guid;

    if (draggedToNewPosition && sequenceChanged) {
      updateCurrency(currencyToUpdate);
    }
  };

  const onRefreshClick = () => {
    currencyLoader.refresh();
  };

  const onDeleteCurrency = () => {
    if (editableCurrency) {
      deleteCurrency(editableCurrency);
    }
  };

  const onAddNewCurrencyClick = () => {
    setShowCurrencyModal(true);
  };

  const closeModal = () => {
    setShowCurrencyModal(false);
    setEditableCurrency(defaultCurrency);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewCurrencyClick}
          title={t("DICTIONARY_CURRENCY_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("DICTIONARY_CURRENCY_REFRESH")}
        />
      </>
    );
  };

  return (
    <div className="DictionaryCurrencyList">
      <PageHeader
        title={t("DICTIONARY_CURRENCY_TITLE")}
        extra={getExtraButtons()}
      />
      <PageContent>
        <TableWithDraggableSorter<ICurrencyModel>
          dragType="handler"
          columns={getColumnsProps()}
          dataSource={currencyLoader.data}
          loading={currencyLoader.loading}
          pagination={false}
          onMoveRow={onMoveRow}
        />
      </PageContent>

      <FormModal
        isLoading={insertProcessing || updateProcessing || deleteProcessing}
        isVisible={showLanguageModal}
        isNewForm={editableCurrency.Id >= 0 ? false : true}
        isDeleteButtonEnabled={editableCurrency.Id >= 0 ? true : false}
        createFormTitle={t("DICTIONARY_CURRENCY_MODAL_NEW")}
        editFormTitle={t("DICTIONARY_CURRENCY_MODAL_EDIT")}
        modalClassName="DictionaryCurrencyModal"
        submitFormName="DictionaryCurrencyForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={onDeleteCurrency}
      >
        <DictionaryCurrencyForm
          currency={editableCurrency}
          insertCurrency={insertCurrency}
          updateCurrency={updateCurrency}
          isProcessing={
            insertProcessing || updateProcessing || deleteProcessing
          }
        />
      </FormModal>
    </div>
  );
};
