export enum ScreenType {
  Custom = "CUSTOM",
  ApplicationMenu = "APPLICATION_MENU",
  ApplicationHeader = "APPLICATION_HEADER",
  ApplicationFooter = "APPLICATION_FOOTER",
  Home = "HOME",
  Splash = "SPLASH",
  Login = "LOGIN",
  Help = "HELP",
  About = "ABOUT",
  MovieDetails = "MOVIE_DETAILS",
  SeriesDetails = "SERIES_DETAILS",
  LiveDetails = "LIVE_DETAILS",
  Profile = "PROFILE",
  Social = "SOCIAL",
  Messages = "MESSAGES",
  Settings = "SETTINGS",
  Search = "SEARCH",
  Notifications = "NOTIFICATIONS",
  Events = "EVENTS",
  Friends = "FRIENDS",
  Community = "COMMUNITY",
  Explore = "EXPLORE",
  CreatorProfiles = "CREATOR_PROFILES",
  Intro = "INTRO",
  Catalog = "CATALOG",
  EPG = "EPG",
  MyList = "MY_LIST",
  Category = "CATEGORY",
  CategoryDetails = "CATEGORY_DETAILS",
  Regulations = "REGULATIONS",
  PrivacyPolicy = "PRIVACY_POLICY",
}
