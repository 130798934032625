import {
  Button,
  IFormValues,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  TabPane,
  Tabs,
  useSearchParamsTabs,
} from "@xala/common-ui";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { TabGeneral, TabContent, TabRecipients } from "./tabs";
import { useHistory, useParams } from "react-router";
import {
  INotificationModel,
  INotificationSendModel,
  NotificationsService,
  RecordStatus,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import { FormInstance } from "antd/lib/form";

const userNotificationService = new NotificationsService().promisify();
const notificationService = NotificationService.getInstance();

export const NotificationDetails = () => {
  const { t } = useTranslation();
  const [activeTabKey] = useSearchParamsTabs("GENERAL");
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: notification, refresh: notificationRefresh } = useDataLoader({
    loader: () => userNotificationService.get(+id),
    deps: [id],
  });

  const [updateNotification] = useServiceCaller(
    async (
      data: INotificationModel,
      form: FormInstance<IFormValues>,
      isSended?: boolean
    ) => {
      const result = await userNotificationService.update({
        ...data,
        RecordStatus: RecordStatus.Updated,
        Id: +id,
        Guid: notification?.Guid,
      });
      if (result.ok) {
        notificationService.success({
          message: t("NOTIFICATION_UPDATE_SUCCESSFULLY"),
        });
      } else {
        notificationService.error({
          message: t("NOTIFICATION_UPDATE_FAILURE"),
        });
      }

      if (isSended) {
        const updatedNotification = await userNotificationService.get(+id);

        if (updatedNotification.ok && updatedNotification.data.RowVersion) {
          const sendData = {
            Id: updatedNotification.data.Id,
            RowVersion: updatedNotification.data.RowVersion,
          };
          await sendNotification(sendData, form);
        }
      } else {
        await notificationRefresh();
        form.resetFields();
      }
      return result.ok;
    },
    []
  );

  const [sendNotification] = useServiceCaller(
    async (data: INotificationSendModel, form: FormInstance<IFormValues>) => {
      const result = await userNotificationService.send({
        ...data,
      });
      if (result.ok) {
        notificationService.success({
          message: t("NOTIFICATION_SENT_SUCCESSFULLY"),
        });
      } else {
        notificationService.error({
          message: t("NOTIFICATION_SENT_FAILURE"),
        });
      }
      await notificationRefresh();
      form.resetFields();
      return result.ok;
    },
    []
  );

  const [deleteNotification] = useServiceCaller(
    async (data: INotificationModel) => {
      const result = await userNotificationService.delete(data);
      if (result.ok) {
        notificationService.success({
          message: t("NOTIFICATION_DELETE_SUCCESSFULLY"),
        });
        history.push(ROUTES.NOTIFICATION_LIST);
      } else {
        notificationService.error({
          message: t("NOTIFICATION_DELETE_FAILURE"),
        });
      }
      return result.ok;
    },
    []
  );

  const getBreadcrumbProps = (): BreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.NOTIFICATION_LIST}`,
        breadcrumbName: t("NOTIFICATIONS"),
      },
      {
        path: `${ROUTES.NOTIFICATION_DETAILS}/${id}`,
        breadcrumbName: notification?.Name ?? t("NOTIFICATIONS"),
      },
    ]);
  };

  const getExtraButtons = (
    <>
      <Button
        onClick={() => onDeleteClick()}
        title={t("NOTIFICATION_DELETE")}
        shape="circle"
        danger
        disabled={TimeHelper.isAfter(notification?.ScheduledOn || "")}
        icon={<Icon type="delete" />}
      />
    </>
  );

  const onDeleteClick = () => {
    if (!notification) {
      return;
    }

    Modal.confirm({
      title: t("Delete"),
      content: `Are you sure you want to delete "${notification?.Name}" notification?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => deleteNotification(notification),
    });
  };

  return (
    <PageContent>
      <PageHeader
        breadcrumb={getBreadcrumbProps()}
        title={t("NOTIFICATION_DETAILS")}
        extra={getExtraButtons}
      />
      <Tabs activeKey={activeTabKey} destroyInactiveTabPane={false}>
        <TabPane key="GENERAL" tab={t("NOTIFICATION_GENERAL")}>
          {notification && (
            <TabGeneral
              notification={notification}
              onSubmit={updateNotification}
            />
          )}
        </TabPane>
        <TabPane key="CONTENT" tab={t("NOTIFICATION_CONTENT")}>
          {notification && (
            <TabContent
              notification={notification}
              onSubmit={updateNotification}
            />
          )}
        </TabPane>

        <TabPane key="RECIPIENTS" tab={t("NOTIFICATION_RECIPIENTS")}>
          {notification && (
            <TabRecipients
              notification={notification}
              onSubmit={updateNotification}
            />
          )}
        </TabPane>
      </Tabs>
    </PageContent>
  );
};
