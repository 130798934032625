import React from "react";
import { noop } from "lodash";
import { Button, Popconfirm, Icon } from "@xala/common-ui";
import "./ActionButtons.scss";

import { useTranslation } from "react-i18next";

interface IActionButtonsProps {
  brandExist: boolean;
  onCancel: () => void;
  onDelete?: () => void;
}

export const ActionButtons: React.FC<IActionButtonsProps> = ({
  brandExist,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className="action-buttons-wrap">
      <Popconfirm
        title={t("Are you sure about deleting this branding?")}
        okText={t("Yes", "Yes")}
        cancelText={t("No", "No")}
        onCancel={noop}
        onConfirm={onDelete}
      >
        <Button
          shape="circle"
          disabled={!brandExist}
          danger={true}
          icon={<Icon type="delete" />}
          style={{ float: "left" }}
        />
      </Popconfirm>

      {brandExist ? (
        <Button key="cancel" type="link" onClick={onCancel}>
          {t("Cancel", "Cancel")}
        </Button>
      ) : (
        <Popconfirm
          title={t("All data entered will be lost. Continue?")}
          okText={t("Yes", "Yes")}
          cancelText={t("No", "No")}
          onCancel={noop}
          onConfirm={onCancel}
        >
          <Button key="cancel" type="link">
            {t("Cancel", "Cancel")}
          </Button>
        </Popconfirm>
      )}

      <Button type="primary" htmlType="submit">
        {t("Save", "Save")}
      </Button>
    </div>
  );
};
