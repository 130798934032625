import {
  IAssetCategoryModel,
  IAssetModel,
  IUserSettingsModel,
  RecordStatus,
  TownsStore,
  UserSettingsStore,
} from "@xala/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  IChooseLabeledValue,
  IFormValues,
  Row,
  useValidate,
} from "@xala/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAppState } from "@xala/studio/src/store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

export interface IUserAccountSettingsFormProps {
  userSettings: IUserSettingsModel;
  categories: IAssetCategoryModel[];
  channels: IAssetModel[];
}

interface IParamsType {
  id: string;
}

export const UserAccountSettingsForm = ({
  userSettings,
  categories,
  channels,
}: IUserAccountSettingsFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validate = useValidate();
  const { id } = useParams<IParamsType>();

  const towns = useSelector((state: IAppState) => state.towns.towns.Entities);

  useEffect(() => {
    dispatch(TownsStore.Actions.getTowns());
  }, []);

  const cityField = () => {
    const chooseOption = towns?.map((town) => (
      <ChooseOption key={town.Id} value={town.Id}>
        {town.Name}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="City"
        label={t("USER_ACCOUNT_FORM_CITY_LABEL", "City")}
        rules={validate.required({
          choiceField: true,
        })}
        initialValue={
          userSettings.Towns && {
            key: `${userSettings.Towns?.[0].Id}`,
            value: userSettings.Towns?.[0].Id,
            label: userSettings.Towns?.[0].Name,
          }
        }
      >
        <Choose
          placeholder={t(
            "USER_ACCOUNT_FORM_CITY_PLACEHOLDER",
            "Select a user city"
          )}
          labelInValue={true}
          children={chooseOption}
          showSearch
          optionFilterProp="children"
        />
      </Form.Item>
    );
  };

  const channelsField = () => {
    const fieldOptions: {
      initialValue?: IChooseLabeledValue[];
    } = {
      initialValue: userSettings.Channels?.map((channel) => {
        return {
          key: `${channel.Id}`,
          value: channel.Id,
          label: channel.Name,
        };
      }),
    };

    const chooseOption = channels.map((channel) => (
      <ChooseOption key={channel.Id} value={channel.Id}>
        {channel.Title}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="Channels"
        label={t("USER_ACCOUNT_FORM_Channels_LABEL", "Channels")}
        rules={validate.maxItems(2)}
        {...fieldOptions}
      >
        <Choose
          mode="multiple"
          labelInValue={true}
          optionFilterProp="children"
          placeholder={t(
            "USER_ACCOUNT_FORM_CHANNELS_PLACEHOLDER",
            "Select a user channels"
          )}
          children={chooseOption}
        />
      </Form.Item>
    );
  };

  const preferencesField = () => {
    const fieldOptions: {
      initialValue?: IChooseLabeledValue[];
    } = {
      initialValue: userSettings.Categories?.map((category) => {
        return {
          key: `${category.Id}`,
          value: category.Id,
          label: category.Name,
        };
      }),
    };

    const chooseOption = categories.map((category) => (
      <ChooseOption key={category.Id} value={category.Id}>
        {category.Name}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="Categories"
        label={t("USER_ACCOUNT_FORM_PREFERENCES_LABEL", "Preferences")}
        rules={validate.maxItems(5)}
        {...fieldOptions}
      >
        <Choose
          mode="multiple"
          placeholder={t(
            "USER_ACCOUNT_FORM_PREFERENCES_PLACEHOLDER",
            "Select a user preferences"
          )}
          optionFilterProp="children"
          labelInValue={true}
          children={chooseOption}
        />
      </Form.Item>
    );
  };

  const onFinish = (values: IFormValues) => {
    const filter: IUserSettingsModel = {
      UserId: Number(id),
      Towns: [{ Id: values.City.value }],
      Categories: values.Categories?.map((category: IChooseLabeledValue) => {
        return { Id: category.value };
      }),
      Channels: values.Channels?.map((channel: IChooseLabeledValue) => {
        return { Id: channel.value };
      }),
      RecordStatus: RecordStatus.Updated,
      RowVersion: userSettings.RowVersion,
    };

    return dispatch(UserSettingsStore.Actions.updateUserSettings(filter));
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 8 },
      lg: { span: 6 },
      xl: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 16 },
      lg: { span: 18 },
      xl: { span: 20 },
    },
  };

  return (
    <Form
      name="UserAccountSettingsForm"
      {...formItemLayout}
      onFinish={onFinish}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {cityField()}
          {channelsField()}
          {preferencesField()}
        </Col>
        <Col>
          <Form.Item wrapperCol={{ style: { textAlign: "right" } }}>
            <Button type="primary" htmlType="submit">
              {t("BUTTON_SAVE", "Save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
