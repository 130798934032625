import {
  Col,
  Dragger,
  Form,
  IFormValues,
  Input,
  InputNumber,
  PreviewImage,
  Row,
} from "@xala/common-ui";
import { Select } from "antd";
import React from "react";
import { WithTranslation } from "react-i18next";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ApplicationFooterPropertyModel } from "../../models/ApplicationFooterPropertyModel";
import { imageFooterSizeHelper } from "../../../../../Asset/helpers/assetImagesTypeHelper";

export interface IApplicationFooterPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IApplicationFooterPropertiesDispatchProps {}

export interface IApplicationFooterPropertiesOwnProps {}

export interface IApplicationFooterPropertiesProps
  extends IApplicationFooterPropertiesStateProps,
    IApplicationFooterPropertiesDispatchProps,
    IApplicationFooterPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationFooterPropertiesState
  extends IComponentPropertiesBaseState {
  translationsBrowserVisible: boolean;
}

export class ApplicationFooterProperties extends ComponentPropertiesBase<
  IApplicationFooterPropertiesProps,
  IApplicationFooterPropertiesState,
  ApplicationFooterPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IApplicationFooterPropertiesState> = {
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): ApplicationFooterPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationFooterPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public renderColumnsProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Columns"
        label={t("Columns")}
        initialValue={this.properties.Columns || 7}
      >
        <InputNumber
          disabled={isProcessingData}
          placeholder={t("Enter number of columns")}
          formatter={(value) => `${`${value}`?.replace?.(/[^\d]/, "") || 0}`}
          parser={(value?: string) => value?.replace(/[^\d]/, "") || 0}
          min={0}
          max={3600}
          onChange={(value) => {
            if (typeof value === "number") {
              this.properties.Columns = value;
              this.onPropertyChange("Columns");
            }
          }}
        />
      </Form.Item>
    );
  };

  public renderBackgroundUrlProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item name="BackgroundUrl" label={t("Background")}>
        <Input
          defaultValue={this.properties.BackgroundUrl}
          disabled={isProcessingData}
          placeholder={t("https://")}
          onChange={(e) => {
            this.properties.BackgroundUrl = e.target.value;
            this.onPropertyChange("BackgroundUrl");
          }}
        />
        <p>
          {t("SUGGESTED_RATIO_FOR_IMAGE", {
            width: imageFooterSizeHelper.suggestedResolution.width,
            height: imageFooterSizeHelper.suggestedResolution.height,
          })}
        </p>
      </Form.Item>
    );
  };

  public renderFileProperty = () => {
    const { t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form.Item
        key="FileValue"
        name="FileValue"
        valuePropName="FileValue"
        getValueFromEvent={this.onImageChange}
        {...formItemLayout}
        style={{ justifyContent: "flex-end" }}
      >
        <Dragger
          name="FileValue"
          accept="image/*"
          multiple={false}
          beforeUpload={this.beforeUpload}
          fileList={[]}
          withPreview={true}
          maxCount={1}
        >
          {(previewSrc: string) => {
            return (
              <>
                <p className="ant-upload-drag-icon">
                  <PreviewImage
                    src={previewSrc || this.properties.BackgroundUrl}
                    height={60}
                    width={288}
                    placeholderIconType={"inbox"}
                  />
                </p>
                <p className="ant-upload-text">
                  {t(
                    "DRAG_AND_DROP",
                    "Click or drag a file to this area to upload."
                  )}
                </p>
              </>
            );
          }}
        </Dragger>
      </Form.Item>
    );
  };

  public renderItemsAlignmentProperty = () => {
    const { isProcessingData, t } = this.props;

    const ItemsAlignmentOptions = [
      { Code: "justify", Name: "justify center" },
      { Code: "fit_to_content", Name: "fit to content" },
    ];

    return (
      <Form.Item
        name="ItemsAlignment"
        label={t("ItemsAlignment")}
        initialValue={this.properties.ItemsAlignment}
      >
        <Select
          disabled={isProcessingData}
          placeholder={t("ItemsAlignment", "ItemsAlignment")}
          onSelect={(value: string) => {
            this.properties.ItemsAlignment = value;
            this.onPropertyChange("ItemsAlignment");
          }}
        >
          {ItemsAlignmentOptions.map((options) => (
            <Select.Option key={options.Code} value={options.Code}>
              {options.Name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ApplicationFooterProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderColumnsProperty()}
            {this.renderBackgroundUrlProperty()}
            {this.renderFileProperty()}
            {this.renderItemsAlignmentProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
