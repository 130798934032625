import React, { memo, useMemo } from "react";
import {
  IAnalyticsSalesRevenueFilterModel,
  TimeHelper,
} from "@xala/common-services";
import { Button, DatePicker, Icon, PageHeader } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { SalesRevenueAggregationTypeSelect } from "../SalesRevenueAggregationTypeSelect";
import "./SalesRevenueHeader.scss";
import { SalesRevenueCreatorSelect } from "../SalesRevenueCreatorSelect";
import { PaymentAssetSelect } from "../../PaymentAssetSelect";

interface ISalesRevenueHeaderProps {
  filter: IAnalyticsSalesRevenueFilterModel;
  setFilter: <T>(
    property: keyof IAnalyticsSalesRevenueFilterModel,
    value: T
  ) => void;
  refreshData: () => void;
  resetFilter: () => void;
}

export const SalesRevenueHeader = memo(
  ({
    filter,
    setFilter,
    refreshData,
    resetFilter,
  }: ISalesRevenueHeaderProps) => {
    const { t } = useTranslation();

    const dateFrom = useMemo(() => {
      if (filter.PaymentDateFrom) {
        return TimeHelper.toUTCString(filter.PaymentDateFrom);
      }
      return null;
    }, [filter.PaymentDateFrom]);

    const dateTo = useMemo(() => {
      if (filter.PaymentDateTo) {
        return TimeHelper.toUTCString(filter.PaymentDateTo);
      }
      return null;
    }, [filter.PaymentDateTo]);

    const period = useMemo(() => filter.AggregationPeriod, [
      filter.AggregationPeriod,
    ]);

    return (
      <PageHeader
        title={t("SALES_REVENUE_TITLE")}
        extra={
          <div className="SalesRevenueHeader__container">
            <div className="SalesRevenueHeader__container-row">
              <SalesRevenueAggregationTypeSelect
                value={period}
                onChange={(val) => setFilter("AggregationPeriod", val)}
              />
              <DatePicker
                showTime={false}
                placeholder={t("SALES_REVENUE_DATE_FROM")}
                style={{ width: "100%" }}
                onChange={(val) =>
                  val &&
                  setFilter(
                    "PaymentDateFrom",
                    TimeHelper.toUTCString(val.toDate())
                  )
                }
                value={dateFrom as any}
              />
              <DatePicker
                showTime={false}
                placeholder={t("SALES_REVENUE_DATE_TO")}
                style={{ width: "100%" }}
                value={dateTo as any}
                onChange={(val) =>
                  val &&
                  setFilter(
                    "PaymentDateTo",
                    TimeHelper.toUTCString(val.toDate())
                  )
                }
              />
              <Button
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={refreshData}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                shape="circle"
                icon={<Icon type="clear" />}
                onClick={resetFilter}
                title={t("BUTTON_RESET")}
              />
            </div>
            <div className="SalesRevenueHeader__container-row">
              <SalesRevenueCreatorSelect
                filter={filter}
                onChange={(val) => setFilter("CreatorUsers", val)}
              />
            </div>
            <div className="SalesRevenueHeader__container-row">
              <PaymentAssetSelect
                onChange={(val) => setFilter("Assets", val)}
              />
            </div>
          </div>
        }
      />
    );
  }
);
