export enum AssetProperties {
  AssetTypeCode = "AssetTypeCode",
  ParentAssetId = "ParentAssetId",
  OrderInParent = "OrderInParent",
  Title = "Title",
  AssetAgeRestrictionValueMin = "AssetAgeRestrictionValueMin",
  IsDraft = "IsDraft",
  Categories = "Categories",
  LanguageId = "LanguageId",
  Subtitles = "Subtitles",
  Description = "Description",
  ShortDescription = "ShortDescription",
  ShortDescriptionForm = "ShortDescriptionForm",
  LongDescription = "LongDescription",
  IsFree = "IsFree",
  Captions = "Captions",
  Director = "Director",
  Presenter = "Presenter",
  Editor = "Editor",
  Cast = "Cast",
  Producer = "Producer",
  Writer = "Writer",
  DurationMiliseconds = "DurationMiliseconds",
  YearText = "YearText",
  AvailableDate = "AvailableDate",
  StartEndDates = "StartEndDates",
  CreatorUserId = "CreatorUserId",
  CreationDate = "CreationDate",
  LastModificationDate = "LastModificationDate",
  Tags = "Tags",
  AdvertisingTags = "AdvertisingTags",
  ContextTags = "ContextTags",
  Label = "Label",
  Genre = "Genre",
  Nationality = "Nationality",
  BCID = "BCID",
  ChannelName = "ChannelName",
  ChannelId = "ChannelId",
  StreamingPermission = "StreamingPermission",
  RecordingPermission = "RecordingPermission",
  EntityId = "EntityId",
  AllowUnregistered = "AllowUnregistered",
  AssetDatesVisible = "AssetDatesVisible",
  EventDateTime = "EventDateTime",
  OrderRule = "OrderRule",
}
