import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IAssetModel,
  IAssetListModel,
  EntityListModel,
  PaginationModel,
  UploadFileInfoModel,
  IUploadFileInfoHeaderModel,
  AssetInCollectionModel,
  IAssetCollectionSearchFilterModel,
  IAssetTypeModel,
  IAssetAgeRestrictionModel,
  IAssetCategoriesListModel,
  IAssetCategoriesSearchFilterModel,
  IAssetPeopleListModel,
  IAssetPeopleSearchFilterModel,
  IAssetCoversModel,
  IAssetImageModel,
  IAssetImageTypeModel,
  IAssetContentTypeModel,
  IAssetContentModel,
  IAssetContentStreamTypeModel,
  IAssetPeopleModel,
  IInitMultipartUploadFileModel,
  IUploadMultipartFileInfoModel,
  ICompleteMultipartUploadFileModel,
  IAbortMultipartUploadFileModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";
import { UploadType } from "../../enums";
import { IAssetSourceEntities } from "../../models/Asset/IAssetSourceEntities";

export class AssetService extends SimpleServiceBase<
  IAssetModel,
  IAssetListModel,
  number,
  IAssetCollectionSearchFilterModel
> {
  get url(): string {
    return "/Assets";
  }

  public getAssetList = (
    data: IAssetModel,
    pagination: PaginationModel
  ): Observable<EntityListModel<IAssetModel>> =>
    new Observable(
      (observer: Observer<EntityListModel<IAssetModel>>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: pagination,
          },
          data,
          method: HTTP_METHOD.POST,
          url: `/Assets/Search`,
        })
    );

  public getAsset = (id: number): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id },
          },
          method: HTTP_METHOD.GET,
          url: `/Assets/Get`,
        })
    );

  public createAsset = (data: IAssetModel): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Assets/Insert`,
        })
    );

  public updateAsset = (data: IAssetModel): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/Assets/Update`,
        })
    );

  public deleteAsset = (data: IAssetModel): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Assets/Delete`,
        })
    );

  public updateAssetContentUrl = (
    id: number,
    contentUrl: string,
    contentStatusCode: string = "READY"
  ): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id, contentUrl, contentStatusCode },
          },
          method: HTTP_METHOD.POST,
          url: `/Assets/UpdateContentUrl`,
        })
    );

  public getSourceEntities = (): Observable<IAssetSourceEntities[]> =>
    new Observable(
      (observer: Observer<IAssetSourceEntities[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/Assets/GetSourceSystemEntityMappings`,
        })
    );

  public getAssetContentUploadUrl = (
    assetId: number
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/Assets/GetContentUploadUrl?id=${assetId}`,
        })
    );

  public rebuildCache = (): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.PUT,
          url: `${this.url}/RebuildCache`,
        })
    );

  public synchronizeEPG = (): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.PUT,
          url: `${this.url}/SynchronizeEpg`,
        })
    );

  public getContentMultipartUploadFileInfo = (
    data: IInitMultipartUploadFileModel
  ): Observable<IUploadMultipartFileInfoModel> =>
    new Observable(
      (observer: Observer<IUploadMultipartFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/AssetContents/InitMultipartUploadFile`,
        })
    );

  public completeMultipartUploadFile = (
    data: ICompleteMultipartUploadFileModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/AssetContents/CompleteMultipartUploadFile`,
        })
    );

  public abortMultipartUploadFile = (
    data: IAbortMultipartUploadFileModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/AssetContents/AbortMultipartUploadFile`,
        })
    );

  public uploadAssetContentFile = (
    uploadInfo: UploadFileInfoModel,
    file: File,
    onProgress: (e: { percent: number }) => void
  ): Observable<UploadFileInfoModel> =>
    new Observable((observer: Observer<UploadFileInfoModel>) => {
      const _headers =
        uploadInfo.Headers &&
        uploadInfo.Headers.reduce(
          (
            headers: Record<string, string>,
            header: IUploadFileInfoHeaderModel
          ) => {
            headers[header.Key] = header.Value;
            return headers;
          },
          {}
        );

      return new AxiosSubscriber(observer, {
        axiosConfig: {
          headers: {
            ..._headers,
            Authorization: "",
            "Content-Type": file.type,
          },
          onUploadProgress: (e: ProgressEvent) =>
            onProgress({ percent: (e.loaded / e.total) * 100 }),
        },
        data: file,
        method: uploadInfo.Method,
        url: uploadInfo.Url,
      });
    });

  public getUploadFileInfo = (
    assetId: number
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/AssetImages/GetUploadFileInfo?assetId=${assetId}`,
        })
    );

  public getContentUploadUrl = (
    assetId: number
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/Assets/GetContentUploadUrl?id=${assetId}`,
        })
    );

  public updateAssetImage = (data: IAssetImageModel): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: "/AssetImages/Update",
        })
    );

  public deleteAssetImage = (data: IAssetImageModel): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetImages/Delete",
        })
    );

  public addAssetImage = (data: IAssetImageModel): Observable<IAssetModel> =>
    new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetImages/Insert",
        })
    );

  public addAssetContent = (
    data: IAssetContentModel
  ): Observable<IAssetContentModel> =>
    new Observable(
      (observer: Observer<IAssetContentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetContents/Insert",
        })
    );

  public updateAssetContent = (
    data: IAssetContentModel
  ): Observable<IAssetContentModel> =>
    new Observable(
      (observer: Observer<IAssetContentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: "/AssetContents/Update",
        })
    );

  public deleteAssetContent = (
    data: IAssetContentModel
  ): Observable<IAssetContentModel> =>
    new Observable(
      (observer: Observer<IAssetContentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetContents/Delete",
        })
    );

  public getAssetContentUploadFileInfo = (
    assetId: number,
    assetContentGuid: string,
    uploadType: UploadType
  ): Observable<UploadFileInfoModel> =>
    new Observable(
      (observer: Observer<UploadFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              assetId,
              assetContentGuid,
              uploadType,
            },
          },
          method: HTTP_METHOD.GET,
          url: "/AssetContents/GetUploadFileInfo",
        })
    );

  public searchAssetCollection = (
    pageSize: number = 10,
    pageNumber: number = 1,
    filter?: IAssetCollectionSearchFilterModel
  ): Observable<IAssetCollectionSearchFilterModel> =>
    new Observable(
      (observer: Observer<IAssetCollectionSearchFilterModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              pageSize,
              pageNumber,
              includeCount: true,
            },
          },
          data: filter,
          method: HTTP_METHOD.POST,
          url: "/AssetsInCollections/Search",
        })
    );

  public createAssetCollection = (data: any): Observable<any> =>
    new Observable(
      (observer: Observer<any>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetsCollections/Insert/",
        })
    );

  public saveAssetsInCollection = (
    data: AssetInCollectionModel[]
  ): Observable<AssetInCollectionModel[]> =>
    new Observable(
      (observer: Observer<AssetInCollectionModel[]>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: "/AssetsInCollections/SaveCollection",
        })
    );

  public getAssetTypes = (): Observable<IAssetTypeModel[]> =>
    new Observable(
      (observer: Observer<IAssetTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: "/AssetTypes/Select",
        })
    );

  public getAssetImageTypes = (): Observable<IAssetImageTypeModel[]> =>
    new Observable(
      (observer: Observer<IAssetImageTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: "/AssetImageTypes/Select",
        })
    );

  public getAssetContentTypes = (): Observable<IAssetContentTypeModel[]> =>
    new Observable(
      (observer: Observer<IAssetContentTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: "/AssetContentTypes/Select",
        })
    );

  public getAssetContentStreamTypes = (): Observable<
    IAssetContentStreamTypeModel[]
  > =>
    new Observable(
      (observer: Observer<IAssetContentStreamTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: "/AssetContentStreamTypes/Select",
        })
    );

  public getAssetAgeRestriction = (): Observable<IAssetAgeRestrictionModel[]> =>
    new Observable(
      (observer: Observer<IAssetAgeRestrictionModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: "/AssetAgeRestrictions/Select",
        })
    );

  public selectAssetParent = (id: number): Observable<IAssetModel[]> =>
    new Observable(
      (observer: Observer<IAssetModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.POST,
          url: "/Assets/SelectParent",
        })
    );

  public searchAssetCategories = (
    filter: IAssetCategoriesSearchFilterModel | undefined
  ): Observable<IAssetCategoriesListModel> =>
    new Observable(
      (observer: Observer<IAssetCategoriesListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: "/AssetCategories/Search",
        })
    );

  public searchAssetPeople = (
    filter?: IAssetPeopleSearchFilterModel
  ): Observable<IAssetPeopleListModel> =>
    new Observable(
      (observer: Observer<IAssetPeopleListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: "/AssetPeople/Search",
        })
    );

  public addAssetPeople = (
    data: IAssetPeopleModel
  ): Observable<IAssetPeopleModel> =>
    new Observable(
      (observer: Observer<IAssetPeopleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetPeople/Insert",
        })
    );

  public deleteAssetPeople = (
    data: IAssetPeopleModel
  ): Observable<IAssetPeopleModel> =>
    new Observable(
      (observer: Observer<IAssetPeopleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: "/AssetPeople/Delete",
        })
    );

  public updateAssetPeople = (
    data: IAssetPeopleModel
  ): Observable<IAssetPeopleModel> =>
    new Observable(
      (observer: Observer<IAssetPeopleModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: "/AssetPeople/Update",
        })
    );

  public downloadCoversAndFrames = (): Observable<IAssetCoversModel> =>
    new Observable(
      (observer: Observer<IAssetCoversModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: "/Assets/DownloadCoversAndFrames",
        })
    );
}
