import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { IErrorModel } from "../../../models";
import { ICommonAppState } from "../../../store";
import {
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaModel,
  IMediaPlayInfoModel,
} from "../models";
import { MediaService } from "../services";
import {
  getMediaCategoriesFailure,
  getMediaCategoriesSuccess,
  getMediaFailure,
  getMediaListFailure,
  getMediaListForEpgFailure,
  getMediaListForEpgSuccess,
  getMediaListSuccess,
  getMediaPlayInfoFailure,
  getMediaPlayInfoSuccess,
  getMediaSuccess,
  searchMediaFailure,
  searchMediaInMediaFailure,
  searchMediaInMediaSuccess,
  searchMediaSuccess,
  selectMediaPurchaseOffersFailure,
  selectMediaPurchaseOffersSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IGetMediaAction,
  IGetMediaCategoriesAction,
  IGetMediaListAction,
  IGetMediaListForEpgAction,
  IGetMediaPlayInfoAction,
  ISearchMediaAction,
  ISearchMediaInMediaAction,
  ISelectMediaPurchaseOffersAction,
} from "./types";

const mediaService: MediaService = new MediaService();

const getMediaEpic = (
  action$: ActionsObservable<IGetMediaAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA),
    switchMap((action: IGetMediaAction) =>
      mediaService.getMedia(action.options).pipe(
        map((data: IMediaModel) => {
          return getMediaSuccess(data);
        }),
        catchError((error: IErrorModel) =>
          of(getMediaFailure(action.options.MediaId, error))
        )
      )
    )
  );

const getMediaPlayInfoEpic = (
  action$: ActionsObservable<IGetMediaPlayInfoAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_PLAY_INFO),
    switchMap((action: IGetMediaPlayInfoAction) =>
      mediaService.getMediaPlayInfo(action.options).pipe(
        map((data: IMediaPlayInfoModel) => {
          return getMediaPlayInfoSuccess(action.options, data);
        }),
        catchError((error: IErrorModel) =>
          of(getMediaPlayInfoFailure(action.options, error))
        )
      )
    )
  );

const searchMediaEpic = (
  action$: ActionsObservable<ISearchMediaAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_MEDIA),
    switchMap((action: ISearchMediaAction) =>
      mediaService.searchMedia(action.filter).pipe(
        map((data: IMediaListModel) => {
          data.Filter = action.filter;

          return searchMediaSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchMediaFailure(error)))
      )
    )
  );

export const searchMediaInMediaEpic = (
  action$: ActionsObservable<ISearchMediaInMediaAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_MEDIA_IN_MEDIA),
    switchMap((action: ISearchMediaInMediaAction) =>
      mediaService.searchMediaInMedia(action.filter).pipe(
        map((data: IMediaListModel) => {
          data.Filter = action.filter;

          return searchMediaInMediaSuccess(action.filter, data);
        }),
        catchError((error: IErrorModel) =>
          of(searchMediaInMediaFailure(action.filter, error))
        )
      )
    )
  );

const getMediaListEpic = (
  action$: ActionsObservable<IGetMediaListAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_LIST),
    switchMap((action: IGetMediaListAction) =>
      mediaService.getMediaList(action.options).pipe(
        map((data: IMediaListModel) => {
          data.Filter = action.options;

          return getMediaListSuccess(action.options.MediaListId, data);
        }),
        catchError((error: IErrorModel) =>
          of(getMediaListFailure(action.options.MediaListId, error))
        )
      )
    )
  );

export const getMediaListForEpgEpic = (
  action$: ActionsObservable<IGetMediaListForEpgAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_LIST_FOR_EPG),
    mergeMap((action: IGetMediaListForEpgAction) =>
      mediaService.getMediaList(action.options).pipe(
        map((data: IMediaListModel) => {
          data.Filter = action.options;

          return getMediaListForEpgSuccess(
            action.options.MediaListId,
            action.options,
            data
          );
        }),
        catchError((error: IErrorModel) =>
          of(
            getMediaListForEpgFailure(
              action.options.MediaListId,
              action.options,
              error
            )
          )
        )
      )
    )
  );

const getMediaCategoriesEpic = (
  action$: ActionsObservable<IGetMediaCategoriesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_CATEGORIES),
    switchMap((action: IGetMediaCategoriesAction) =>
      mediaService.getMediaCategories().pipe(
        map((data: IMediaCategoryListModel) => {
          return getMediaCategoriesSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getMediaCategoriesFailure(error)))
      )
    )
  );

export const selectMediaPurchaseOffersEpic = (
  action$: ActionsObservable<ISelectMediaPurchaseOffersAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_MEDIA_PURCHASE_OFFERS),
    switchMap((action: ISelectMediaPurchaseOffersAction) =>
      mediaService.selectMediaPurchaseOffers(action.mediaId).pipe(
        map((data) => {
          return selectMediaPurchaseOffersSuccess(action.mediaId, data);
        }),
        catchError((error: IErrorModel) =>
          of(selectMediaPurchaseOffersFailure(action.mediaId, error))
        )
      )
    )
  );

export const mediaEpics = [
  getMediaEpic,
  getMediaPlayInfoEpic,
  searchMediaEpic,
  searchMediaInMediaEpic,
  getMediaListEpic,
  getMediaListForEpgEpic,
  getMediaCategoriesEpic,
  selectMediaPurchaseOffersEpic,
];
