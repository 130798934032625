import { UserHelper as CommonUserHelper } from "@xala/common-services";
import { Tag } from "@xala/common-ui";
import React from "react";

export class UserHelper extends CommonUserHelper {
  static getTag(userFullName?: string): React.ReactElement | null {
    if (!userFullName) {
      return null;
    }

    const color = UserHelper.getColor(userFullName);

    return <Tag color={color}>{userFullName}</Tag>;
  }
}
