import React from "react";
import {
  Orientation,
  OrientationHelper as CommonOrientationHelper,
} from "@xala/common-services";
import { ChooseOption } from "@xala/common-ui";

export class OrientationHelper extends CommonOrientationHelper {
  static getOptions(): React.ReactElement[] {
    return [
      <ChooseOption value={Orientation.Default}>
        {OrientationHelper.getDescription(Orientation.Default)}
      </ChooseOption>,
      <ChooseOption value={Orientation.Vertical}>
        {OrientationHelper.getDescription(Orientation.Vertical)}
      </ChooseOption>,
      <ChooseOption value={Orientation.Horizontal}>
        {OrientationHelper.getDescription(Orientation.Horizontal)}
      </ChooseOption>,
      <ChooseOption value={Orientation.Grid}>
        {OrientationHelper.getDescription(Orientation.Grid)}
      </ChooseOption>,
    ];
  }
}
