import {
  IAssetCollectionModel,
  IAssetCollectionTypesModel,
  RecordStatus,
} from "@xala/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Input,
  InputNumber,
  RadioGroup,
  required,
  Row,
  Spin,
  TextArea,
  useSendable,
} from "@xala/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IAssetCollectionFormProps {
  assetCollection?: IAssetCollectionModel;
  assetCollectionTypes?: IAssetCollectionTypesModel[];
  onSubmit: (collection: IAssetCollectionModel) => Promise<boolean>;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    xl: { span: 18 },
  },
};

const formItemLayoutModal = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: { offset: 0, span: 24 },
    sm: { offset: 8, span: 16 },
    lg: { offset: 6, span: 18 },
  },
};

export const AssetCollectionForm: React.FC<IAssetCollectionFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const { onSubmit, assetCollection, assetCollectionTypes } = props;
  const isEditMode = assetCollection?.RecordStatus !== RecordStatus.Inserted;
  const [form] = Form.useForm();
  const sendable = useSendable();

  const formLayout = isEditMode ? formItemLayout : formItemLayoutModal;

  const onFinish = async (values: IFormValues) => {
    const data: IAssetCollectionModel = {
      ...assetCollection,
      CollectionType: values.CollectionType,
      Id: assetCollection?.Id ?? -1,
      Name: values.Name,
      Description: values.Description,
      CacheDataInterval: parseInt(values.CacheDataInterval) || 0,
    };

    const ok = await onSubmit(data);
    if (ok) {
      sendable.resetSendable();
    }

    form.resetFields();
  };

  const renderNameField = () => (
    <Form.Item
      name="Name"
      label={t("Name", "Name")}
      initialValue={isEditMode ? assetCollection?.Name : ""}
      rules={[required()]}
    >
      <Input placeholder={t("EnterName", "Enter name")} />
    </Form.Item>
  );

  const renderDescriptionField = () => (
    <Form.Item
      name="Description"
      label={t("Description", "Description")}
      initialValue={isEditMode ? assetCollection?.Description : ""}
    >
      <TextArea
        maxLength={250}
        placeholder={t("EnterDescription", "Enter description")}
      />
    </Form.Item>
  );

  const renderCacheDataIntervalField = () => (
    <Form.Item
      name="CacheDataInterval"
      label={t("PLAY_LIST_CACHE_RETENTION_LABEL")}
      initialValue={assetCollection?.CacheDataInterval ?? 0}
    >
      <InputNumber
        formatter={(value) => `${`${value}`?.replace?.(/[^\d]/, "") || 0} s`}
        parser={(value?: string) => value?.replace(/[^\d]/, "") || 0}
        min={0}
        max={3600}
        defaultValue={0}
        placeholder={t(
          "EnterCacheDataInterval",
          "Enter cache data interval [seconds]"
        )}
      />
    </Form.Item>
  );

  const renderCollectionTypeField = () => {
    if (!assetCollectionTypes) {
      return null;
    }

    return (
      <Form.Item
        name="CollectionType"
        label={t("DEFINE_COLLECTION")}
        key="CollectionType"
        initialValue={assetCollection?.CollectionType}
      >
        <RadioGroup
          buttons
          data={assetCollectionTypes.map(({ DisplayName, Code }) => ({
            name: t(DisplayName),
            label: t(DisplayName),
            value: Code,
          }))}
        />
      </Form.Item>
    );
  };

  if (!assetCollection) {
    return <Spin />;
  }

  return (
    <Form
      id="AssetCollectionForm"
      name="AssetCollectionForm"
      {...formLayout}
      onFinish={onFinish}
      onFieldsChange={sendable.onFieldsChange}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderNameField()}
          {renderDescriptionField()}
          {renderCacheDataIntervalField()}
          {renderCollectionTypeField()}
        </Col>
        {isEditMode && (
          <Col>
            <Form.Item {...tailFormItemLayout}>
              <Button
                disabled={!sendable.sendable}
                style={{ float: "right" }}
                type="primary"
                htmlType="submit"
              >
                {t("SAVE", "Save")}
              </Button>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};
