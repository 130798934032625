import { CellType, IAssetModel } from "@xala/common-services";
import React, { useState } from "react";
import { ListItemCoverComponentPreview } from "./ListItemCoverComponentPreview";
import { ListItemFrameComponentPreview } from "./ListItemFrameComponentPreview";
import { ListItemHighlightComponentPreview } from "./ListItemHighlightComponentPreview";

export interface IListItemComponentPreviewProps {
  asset?: IAssetModel;
  cellType?: CellType;
  index?: number;
  isHovered?: boolean;
  isPlaceholder: boolean;
  placeholderSrc?: string;
  style?: React.CSSProperties;
  width: number;
}

/**
 * Component to generate current cell in horizontal list. For example a film from category list
 * @exports ListItemComponentPreview [default]
 */
export const ListItemComponentPreview: React.FC<IListItemComponentPreviewProps> = (
  props
) => {
  const { asset, cellType, index, width, isPlaceholder = false } = props;
  const [isHovered, setIsHovered] = useState(false);
  const mouseOver = () => setIsHovered(true);
  const mouseOut = () => setIsHovered(false);
  let cell;

  switch (cellType) {
    case CellType.Highlights:
      cell = (
        <ListItemHighlightComponentPreview
          asset={asset}
          index={index}
          isPlaceholder={isPlaceholder}
          isHovered={isHovered}
          width={width}
        />
      );
      break;
    case CellType.Cover:
      cell = (
        <ListItemCoverComponentPreview
          asset={asset}
          index={index}
          isPlaceholder={isPlaceholder}
          isHovered={isHovered}
          width={width}
        />
      );
      break;
    default:
      cell = (
        <ListItemFrameComponentPreview
          asset={asset}
          index={index}
          isPlaceholder={isPlaceholder}
          isHovered={isHovered}
          width={width}
        />
      );
      break;
  }

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        cursor: "pointer",
      }}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      {cell}
    </div>
  );
};
