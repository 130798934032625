import {
  AssetCollectionService,
  IAssetCollectionModel,
  RecordStatus,
  useServiceCaller,
} from "@xala/common-services";
import { Button, NotificationService } from "@xala/common-ui";
import { FormModal } from "../../../../components";
import { push } from "connected-react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../constants/routes";
import { AssetCollectionForm } from "../AssetCollectionForm";
import "./AddAssetCollectionModal.scss";

export interface IAddAssetCollectionModalProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess?: () => void;
}

const notificationService = NotificationService.getInstance();
const assetCollectionService = new AssetCollectionService().promisify();

const newAssetCollection: IAssetCollectionModel = {
  Id: -1,
  RecordStatus: RecordStatus.Inserted,
};

export const AddAssetCollectionModal: React.FC<IAddAssetCollectionModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { visible, onSuccess, onCancel } = props;

  const [insertAssetCollection, { processing }] = useServiceCaller(
    async (data: IAssetCollectionModel) => {
      const result = await assetCollectionService.insert(data);
      if (result.ok) {
        onSuccess?.();
        notificationService.success({
          message: t(
            "AddPlaylistCollectionSuccess",
            "Playlist collection successfully created."
          ),
        });
        dispatch(push(`${ROUTES.ASSET_COLLECTION_DETAILS}/${result.data.Id}`));
      } else {
        notificationService.error({
          message: t(
            "AddPlaylistCollectionFailure",
            "There was an error while creating playlist collection."
          ),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  return (
    <FormModal
      isLoading={processing}
      isVisible={visible}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t("ADD_NEW_PLAY_LIST", "Add new playlist")}
      editFormTitle={t("ADD_NEW_PLAY_LIST", "Add new playlist")}
      modalClassName="AddAssetCollectionModal"
      submitFormName="AssetCollectionForm"
      onCloseModal={onCancel}
      onDeleteButtonClick={() => {}}
    >
      <AssetCollectionForm
        assetCollection={newAssetCollection}
        onSubmit={insertAssetCollection}
      />
    </FormModal>
  );
};
