declare var shaka: any;

export class ShakaPlayer {
  private _player: any;
  private _ui: any;
  private _videoContainerEl: HTMLDivElement | undefined;
  private _videoEl: HTMLMediaElement | undefined;

  public init = (
    videoEl: HTMLMediaElement,
    videoContainerEl: HTMLDivElement
  ) => {
    shaka.polyfill.installAll();

    this._videoContainerEl = videoContainerEl;
    this._videoEl = videoEl;

    if (this._videoEl && shaka.Player.isBrowserSupported()) {
      this.initPlayer();
    } else {
      console.error("Browser not supported!");
    }
  };

  public initPlayer = () => {
    this._player = new shaka.Player(this._videoEl);
    this._ui = new shaka.ui.Overlay(
      this._player,
      this._videoContainerEl,
      this._videoEl
    );
    this._ui.a.Ea.button.type = "button";
    (window as any).player = this._player;

    this._player.addEventListener("error", this.onErrorEvent);
  };

  public load = (ContentUrl: any) => {
    this._player
      .load(ContentUrl)
      .then(function () {
        console.log("The video has now been loaded!");
      })
      .catch(this.onError);
  };

  private onErrorEvent = (event: any) => {
    this.onError(event.detail);
  };

  private onError = (error: any) => {
    console.error("Error code", error.code, "object", error);
  };
}
