import React from "react";
import { useDataLoader, PaymentsService } from "@xala/common-services";
import { Button, Icon, PageContent, PageHeader, Spin } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { PaymentForm } from "../PaymentForm";

const paymentsService = new PaymentsService().promisify();

export const PaymentDetails: React.FC = () => {
  const id = useParams<{ id: string }>()["id"];
  const { t } = useTranslation();
  const history = useHistory();

  const { data, loading, refresh } = useDataLoader({
    loader: () =>
      /^d+$/g.test(id)
        ? paymentsService.get(+id)
        : paymentsService.getByKey(id),
    deps: [id],
  });

  return (
    <Spin spinning={loading}>
      <PageContent>
        <PageHeader
          title={t("PAYMENT_DETAILS")}
          onBack={history.goBack}
          extra={
            <>
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={refresh}
                title="Refresh data"
                shape="circle"
              />
            </>
          }
        />
        <PaymentForm payment={data} />
      </PageContent>
    </Spin>
  );
};
