import { IAdvertisementBoardTypeModel } from "@xala/common-services";
import {
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  PageContent,
  PageHeader,
  Table,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import "./AdvertisementBoardsTypesList.scss";

export interface IAdvertisementBoardsTypesListProps extends WithTranslation {
  boardsTypes: IAdvertisementBoardTypeModel[];
  isLoadingData: boolean;
  selectBoardsTypes: ActionCreator<Action>;
}

interface IAdvertisementBoardsTypesListState {
  filters?: ITableFilter;
}

export class AdvertisementBoardsTypesList extends React.PureComponent<
  IAdvertisementBoardsTypesListProps,
  IAdvertisementBoardsTypesListState
> {
  public state: Readonly<IAdvertisementBoardsTypesListState> = {};

  public componentDidMount() {
    const { selectBoardsTypes } = this.props;

    selectBoardsTypes();
  }

  private getColumnsProps(): Array<
    ITableColumnProps<IAdvertisementBoardTypeModel>
  > {
    const { t } = this.props;
    const { filters } = this.state;

    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("CODE"),
        filteredValue: filters?.Code,
        onFilter: (value, record) => record.Code.includes(`${value}`),
      },
      {
        key: "Name",
        dataIndex: "Name",
        title: t("NAME"),
        filteredValue: filters?.Name,
        onFilter: (value, record) => record.DisplayName.includes(`${value}`),
      },
    ];
  }

  public onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    this.setState({ filters });
  };

  public render() {
    const { boardsTypes, isLoadingData, t } = this.props;

    const columns = this.getColumnsProps();

    return (
      <PageContent>
        <PageHeader title={t("ADVERTISEMENT_BOARDS_TYPES_LIST")} />
        <Table<IAdvertisementBoardTypeModel>
          rowKey={(row) => row.Code}
          columns={columns}
          dataSource={boardsTypes}
          loading={isLoadingData}
          pagination={false}
          onChange={this.onTableChange}
        />
      </PageContent>
    );
  }
}
