import React from "react";
import { ProductsService, useDataLoader } from "@xala/common-services";
import {
  Button,
  Icon,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
  useSearchParamsTabs,
} from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { ProductForm } from "../ProductForm";
import { ProductRelatedProductsList } from "../ProductRelatedProductsList";
import "./ProductDetails.scss";

const productsService = new ProductsService().promisify();

export const ProductDetails: React.FC = () => {
  const id = parseInt(useParams<{ id: string }>().id, 10);
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useSearchParamsTabs("DETAILS");

  const { data: product, loading, refresh } = useDataLoader({
    loader: () => productsService.get(id),
    deps: [id],
  });

  return (
    <>
      <Spin spinning={loading}>
        <PageContent>
          <PageHeader
            title={
              product?.Title || t("PRODUCT_DETAILS_TITLE", "Product details")
            }
            onBack={history.goBack}
            extra={
              <>
                <Button
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  onClick={refresh}
                  title="Refresh data"
                  shape="circle"
                />
              </>
            }
          />
          <Tabs
            activeKey={activeTabKey}
            destroyInactiveTabPane
            onTabClick={setActiveTabKey}
          >
            <TabPane key="DETAILS" tab={t("Details", "Details")}>
              <ProductForm product={product} />
            </TabPane>
            <TabPane
              key="RELATED_PRODUCTS"
              tab={t("RELATED_PRODUCTS", "Related products")}
            >
              {product && <ProductRelatedProductsList product={product} />}
            </TabPane>
          </Tabs>
        </PageContent>
      </Spin>
    </>
  );
};
