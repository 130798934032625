import {
  ApplicationConfigurationStore,
  IApplicationConfigurationModel,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenContext } from "../../context";
import { ApplicationFooterProperties as ApplicationFooterPropertiesDefinition } from "./ApplicationFooterProperties";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.applicationConfiguration.actionType,
    isProcessingData: state.applicationConfiguration.isProcessingData,
    error: state.applicationConfiguration.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateConfiguration: (data: IApplicationConfigurationModel) => {
    return dispatch(
      ApplicationConfigurationStore.Actions.updateConfiguration(data)
    );
  },
});

export const ApplicationFooterProperties = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withScreenContext(ApplicationFooterPropertiesDefinition)));
