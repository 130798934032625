export const SEARCH_APPLICATION_CONFIGURATION =
  "SEARCH_APPLICATION_CONFIGURATION";
export const SEARCH_APPLICATION_CONFIGURATION_SUCCESS =
  "SEARCH_APPLICATION_CONFIGURATION_SUCCESS";
export const SEARCH_APPLICATION_CONFIGURATION_FAILURE =
  "SEARCH_APPLICATION_CONFIGURATION_FAILURE";

export const GET_APPLICATION_CONFIGURATION = "GET_APPLICATION_CONFIGURATION";
export const GET_APPLICATION_CONFIGURATION_SUCCESS =
  "GET_APPLICATION_CONFIGURATION_SUCCESS";
export const GET_APPLICATION_CONFIGURATION_FAILURE =
  "GET_APPLICATION_CONFIGURATION_FAILURE";

export const DELETE_APPLICATION_CONFIGURATION =
  "DELETE_APPLICATION_CONFIGURATION";
export const DELETE_APPLICATION_CONFIGURATION_SUCCESS =
  "DELETE_APPLICATION_CONFIGURATION_SUCCESS";
export const DELETE_APPLICATION_CONFIGURATION_FAILURE =
  "DELETE_APPLICATION_CONFIGURATION_FAILURE";

export const UPDATE_APPLICATION_CONFIGURATION =
  "UPDATE_APPLICATION_CONFIGURATION";
export const UPDATE_APPLICATION_CONFIGURATION_SUCCESS =
  "UPDATE_APPLICATION_CONFIGURATION_SUCCESS";
export const UPDATE_APPLICATION_CONFIGURATION_FAILURE =
  "UPDATE_APPLICATION_CONFIGURATION_FAILURE";

export const PUBLISH_APPLICATION_CONFIGURATION =
  "PUBLISH_APPLICATION_CONFIGURATION";
export const PUBLISH_APPLICATION_CONFIGURATION_SUCCESS =
  "PUBLISH_APPLICATION_CONFIGURATION_SUCCESS";
export const PUBLISH_APPLICATION_CONFIGURATION_FAILURE =
  "PUBLISH_APPLICATION_CONFIGURATION_FAILURE";

export const SAVE_APPLICATION_CONFIGURATION = "SAVE_APPLICATION_CONFIGURATION";
export const SAVE_APPLICATION_CONFIGURATION_SUCCESS =
  "SAVE_APPLICATION_CONFIGURATION_SUCCESS";
export const SAVE_APPLICATION_CONFIGURATION_FAILURE =
  "SAVE_APPLICATION_CONFIGURATION_FAILURE";

export const GET_APPLICATION_SCREEN = "GET_APPLICATION_SCREEN";
export const GET_APPLICATION_SCREEN_SUCCESS = "GET_APPLICATION_SCREEN_SUCCESS";
export const GET_APPLICATION_SCREEN_FAILURE = "GET_APPLICATION_SCREEN_FAILURE";

export const SEARCH_APPLICATION_SCREEN = "SEARCH_APPLICATION_SCREEN ";
export const SEARCH_APPLICATION_SCREEN_SUCCESS =
  "SEARCH_APPLICATION_SCREEN_SUCCESS";
export const SEARCH_APPLICATION_SCREEN_FAILURE =
  "SEARCH_APPLICATION_SCREEN_FAILURE";

export const BROWSE_APPLICATION_SCREEN = "BROWSE_APPLICATION_SCREEN";
export const BROWSE_APPLICATION_SCREEN_SUCCESS =
  "BROWSE_APPLICATION_SCREEN_SUCCESS";
export const BROWSE_APPLICATION_SCREEN_FAILURE =
  "BROWSE_APPLICATION_SCREEN_FAILURE";
export const BROWSE_APPLICATION_SCREEN_CANCEL =
  "BROWSE_APPLICATION_SCREEN_CANCEL";

export const INSERT_APPLICATION_SCREEN = "INSERT_APPLICATION_SCREEN";
export const INSERT_APPLICATION_SCREEN_SUCCESS =
  "INSERT_APPLICATION_SCREEN_SUCCESS";
export const INSERT_APPLICATION_SCREEN_FAILURE =
  "INSERT_APPLICATION_SCREEN_FAILURE";

export const UPDATE_APPLICATION_SCREEN = "UPDATE_APPLICATION_SCREEN";
export const UPDATE_APPLICATION_SCREEN_SUCCESS =
  "UPDATE_APPLICATION_SCREEN_SUCCESS";
export const UPDATE_APPLICATION_SCREEN_FAILURE =
  "UPDATE_APPLICATION_SCREEN_FAILURE";

export const DELETE_APPLICATION_SCREEN = "DELETE_APPLICATION_SCREEN";
export const DELETE_APPLICATION_SCREEN_SUCCESS =
  "DELETE_APPLICATION_SCREEN_SUCCESS";
export const DELETE_APPLICATION_SCREEN_FAILURE =
  "DELETE_APPLICATION_SCREEN_FAILURE";

export const SELECT_APPLICATION_SCREEN_TYPE = "SELECT_APPLICATION_SCREEN_TYPE";
export const SELECT_APPLICATION_SCREEN_TYPE_SUCCESS =
  "SELECT_APPLICATION_SCREEN_TYPE_SUCCESS";
export const SELECT_APPLICATION_SCREEN_TYPE_FAILURE =
  "SELECT_APPLICATION_SCREEN_TYPE_FAILURE";

export const SELECT_APPLICATION_COMPONENT_TYPE =
  "SELECT_APPLICATION_COMPONENT_TYPE";
export const SELECT_APPLICATION_COMPONENT_TYPE_SUCCESS =
  "SELECT_APPLICATION_COMPONENT_TYPE_SUCCESS";
export const SELECT_APPLICATION_COMPONENT_TYPE_FAILURE =
  "SELECT_APPLICATION_COMPONENT_TYPE_FAILURE";

export const GET_DATA_SOURCE = "GET_DATA_SOURCE";
export const GET_DATA_SOURCE_SUCCESS = "GET_DATA_SOURCE_SUCCESS";
export const GET_DATA_SOURCE_FAILURE = "GET_DATA_SOURCE_FAILURE";
export const GET_DATA_SOURCE_CANCEL = "GET_DATA_SOURCE_CANCEL";

// Branding

export const GET_APPLICATION_BRANDING = "GET_APPLICATION_BRANDING";
export const GET_APPLICATION_BRANDING_SUCCESS =
  "GET_APPLICATION_BRANDING_SUCCESS";
export const GET_APPLICATION_BRANDING_FAILURE =
  "GET_APPLICATION_BRANDING_FAILURE";

export const GET_APPLICATION_DEFAULT_BRANDING =
  "GET_APPLICATION_DEFAULT_BRANDING";
export const GET_APPLICATION_DEFAULT_BRANDING_SUCCESS =
  "GET_APPLICATION_DEFAULT_BRANDING_SUCCESS";
export const GET_APPLICATION_DEFAULT_BRANDING_FAILURE =
  "GET_APPLICATION_DEFAULT_BRANDING_FAILURE";

export const SELECT_KEYS_APPLICATION_BRANDING =
  "SELECT_KEYS_APPLICATION_BRANDING";
export const SELECT_KEYS_APPLICATION_BRANDING_SUCCESS =
  "SELECT_KEYS_APPLICATION_BRANDING_SUCCESS";
export const SELECT_KEYS_APPLICATION_BRANDING_FAILURE =
  "SELECT_KEYS_APPLICATION_BRANDING_FAILURE";

export const SEARCH_APPLICATION_BRANDING = "SEARCH_APPLICATION_BRANDING";
export const SEARCH_APPLICATION_BRANDING_SUCCESS =
  "SEARCH_APPLICATION_BRANDING_SUCCESS";
export const SEARCH_APPLICATION_BRANDING_FAILURE =
  "SEARCH_APPLICATION_BRANDING_FAILURE";

export const INSERT_APPLICATION_BRANDING = "INSERT_APPLICATION_BRANDING";
export const INSERT_APPLICATION_BRANDING_SUCCESS =
  "INSERT_APPLICATION_BRANDING_SUCCESS";
export const INSERT_APPLICATION_BRANDING_FAILURE =
  "INSERT_APPLICATION_BRANDING_FAILURE";

export const UPDATE_APPLICATION_BRANDING = "UPDATE_APPLICATION_BRANDING";
export const UPDATE_APPLICATION_BRANDING_SUCCESS =
  "UPDATE_APPLICATION_BRANDING_SUCCESS";
export const UPDATE_APPLICATION_BRANDING_FAILURE =
  "UPDATE_APPLICATION_BRANDING_FAILURE";

export const DELETE_APPLICATION_BRANDING = "DELETE_APPLICATION_BRANDING";
export const DELETE_APPLICATION_BRANDING_SUCCESS =
  "DELETE_APPLICATION_BRANDING_SUCCESS";
export const DELETE_APPLICATION_BRANDING_FAILURE =
  "DELETE_APPLICATION_BRANDING_FAILURE";

export const UPLOAD_BRANDING_IMAGE = "UPLOAD_BRANDING_IMAGE";
export const UPLOAD_BRANDING_IMAGE_SUCCESS = "UPLOAD_BRANDING_IMAGE_SUCCESS";
export const UPLOAD_BRANDING_IMAGE_FAILURE = "UPLOAD_BRANDING_IMAGE_FAILURE";

// Branding end

export const GET_IMAGE_UPLOAD_FILE_INFO = "GET_IMAGE_UPLOAD_FILE_INFO";
export const GET_IMAGE_UPLOAD_FILE_INFO_SUCCESS =
  "GET_IMAGE_UPLOAD_FILE_INFO_SUCCESS";
export const GET_IMAGE_UPLOAD_FILE_INFO_FAILURE =
  "GET_IMAGE_UPLOAD_FILE_INFO_FAILURE";

export const IMPORT_TRANSLATIONS = "IMPORT_TRANSLATIONS";
export const IMPORT_TRANSLATIONS_SUCCESS = "IMPORT_TRANSLATIONS_SUCCESS";
export const IMPORT_TRANSLATIONS_FAILURE = "IMPORT_TRANSLATIONS_FAILURE";

export const EXPORT_TRANSLATIONS = "EXPORT_TRANSLATIONS";
export const EXPORT_TRANSLATIONS_SUCCESS = "EXPORT_TRANSLATIONS_SUCCESS";
export const EXPORT_TRANSLATIONS_FAILURE = "EXPORT_TRANSLATIONS_FAILURE";

export const REBUILD_CACHE = "REBUILD_CACHE";
export const REBUILD_CACHE_SUCCESS = "REBUILD_CACHE_SUCCESS";
export const REBUILD_CACHE_FAILURE = "REBUILD_CACHE_FAILURE";

export const SYNCHRONIZE_EPG = "SYNCHRONIZE_EPG";
export const SYNCHRONIZE_EPG_SUCCESS = "SYNCHRONIZE_EPG_SUCCESS";
export const SYNCHRONIZE_EPG_FAILURE = "SYNCHRONIZE_EPG_FAILURE";
