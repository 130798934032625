import {
  AssetStore,
  IAssetModel,
  ICommonAppState,
  IStateModel,
  UploadFileInfoModel,
} from "@xala/common-services";
import {
  Alert,
  Button,
  Col,
  Dragger,
  Form,
  FormFieldRule,
  Icon,
  IFormValues,
  Input,
  IUploadChangeEvent,
  IUploadFile,
  NotificationService,
  required,
  Row,
  urlValid,
} from "@xala/common-ui";
import { noop, takeRight } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Player } from "../../../../components";
import "./AssetContent.scss";

const notificationService = NotificationService.getInstance();

const assetSelector = (state: ICommonAppState) => state.asset;

export interface IAssetContentProps {
  asset?: IAssetModel;
}

export interface IAssetContentState {
  contentFile?: File;
  contentUrl?: string;
  fileList: IUploadFile[];
}

export const AssetContent: React.FC<IAssetContentProps> = ({ asset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { action, uploadContentUrl } = useSelector(assetSelector);
  const [processingData, setProcessingData] = useState<boolean>(false);
  const [state, setState] = useState<IAssetContentState>({
    contentFile: undefined,
    contentUrl: asset?.ContentUrl,
    fileList: [],
  });
  const [form] = Form.useForm();
  form.setFieldsValue({ FormItemRenderPath: state.contentUrl ?? "" });

  const assetContentStatusCode = asset?.ContentStatusCode || "READY";

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 6 },
      lg: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 18 },
      lg: { span: 12 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
      md: {
        span: 18,
        offset: 6,
      },
      lg: {
        span: 21,
        offset: 3,
      },
    },
  };

  const getAssetContentUploadPath = useCallback(
    () => dispatch(AssetStore.Actions.getContentUploadUrl(asset?.Id!)),
    [dispatch]
  );

  const uploadAssetVideo = useCallback(
    (
      fileUploadInfo: UploadFileInfoModel,
      file: File,
      onProgress: any,
      onSuccess: () => void
    ) =>
      dispatch(
        AssetStore.Actions.uploadAssetContentFile(
          fileUploadInfo,
          file!,
          onProgress,
          onSuccess
        )
      ),
    [dispatch]
  );

  const updateAsset = useCallback(
    (data: IAssetModel, callback?: () => void) => {
      return dispatch(AssetStore.Actions.updateAsset(data, callback));
    },
    [dispatch]
  );

  const updateAssetContent = useCallback(
    (id: number, contentUrl?: string, contentFile?: File) => {
      return dispatch(
        AssetStore.Actions.updateAssetContentUrl(id, contentUrl, contentFile)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    switch (action?.type) {
      case "GET_CONTENT_UPLOAD_URL":
        setProcessingData(true);
        break;
      case "GET_CONTENT_UPLOAD_URL_SUCCESS":
        uploadAssetVideo(
          uploadContentUrl.data!,
          state.contentFile!,
          noop,
          noop
        );
        break;
      case "GET_CONTENT_UPLOAD_URL_FAILURE":
        notificationService.error({
          message: t(
            "GetContentUploadUrlFailure",
            "There was an error while getting video upload url."
          ),
          description: action?.error?.Message,
        });
        break;
      case "UPLOAD_ASSET_CONTENT_FILE_SUCCESS":
        notificationService.success({
          message: t(
            "UpdateVideoSuccess",
            "Video has been successfully uploaded."
          ),
        });
        setState({
          ...state,
          fileList: [],
          contentUrl: asset?.ContentUrl,
          contentFile: undefined,
        });
        break;
      case "UPLOAD_ASSET_CONTENT_FILE_FAILURE":
        notificationService.error({
          message: t(
            "UpdateAssetFailure",
            "There was an error while uploading video."
          ),
          description: action?.error?.Message,
        });
        setProcessingData(false);
        break;
      case "UPDATE_ASSET":
        setProcessingData(true);
        break;
      case "UPDATE_ASSET_SUCCESS":
      case "UPDATE_ASSET_FAILURE":
        setProcessingData(false);
        break;
      case AssetStore.Consts.UPDATE_ASSET_CONTENT_URL_SUCCESS:
        notificationService.success({
          message: t(
            "UPDATE_ASSET_CONTENT_SUCCESS",
            "Video has been successfully uploaded."
          ),
        });
        setState({
          ...state,
          fileList: [],
          contentUrl: asset?.ContentUrl,
          contentFile: undefined,
        });
        break;
      case AssetStore.Consts.UPDATE_ASSET_CONTENT_URL_FAILURE:
        notificationService.error({
          message: t(
            "UPDATE_ASSET_CONTENT_FAILURE",
            "There was an error while uploading video."
          ),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const onVideoChange = (e: IUploadChangeEvent) => {
    const { fileList } = e;
    const video = takeRight(fileList);
    let contentFile = state.contentFile;

    if (video?.length > 0) {
      video[0].status = "done";
    } else {
      contentFile = undefined;
    }

    setState({
      ...state,
      fileList: video,
      contentFile: contentFile,
    });
  };

  const beforeUpload = (file: File) => {
    setState({
      ...state,
      contentUrl: file ? file.name : state.contentUrl,
      contentFile: file,
    });

    return true;
  };

  const renderPathField = () => {
    const { contentFile } = state;
    const rules: FormFieldRule[] = [required()];
    const disabled =
      !!contentFile ||
      assetContentStatusCode === "QUEUED" ||
      assetContentStatusCode === "PROCESSING";

    if (!contentFile) {
      rules.push(urlValid());
    }

    return (
      <Form.Item name="FormItemRenderPath" rules={rules}>
        <Input
          onChange={(e) => {
            setState({
              ...state,
              contentUrl: e.target.value,
              contentFile: undefined,
            });
          }}
          style={{ marginBottom: 10 }}
          className="bottom-margin"
          placeholder={t("EnterVideoPath", "Enter video path")}
          disabled={disabled}
        />
      </Form.Item>
    );
  };

  const renderUpload = () => {
    const isSeries = asset?.AssetTypeCode == "LIVE";

    return (
      <Dragger
        className="AssetContent__Dragger"
        name="Upload"
        multiple={false}
        showUploadList={{
          showRemoveIcon: true,
          showPreviewIcon: false,
          showDownloadIcon: false,
        }}
        accept="video/*"
        beforeUpload={beforeUpload}
        fileList={state.fileList}
        onChange={onVideoChange}
        disabled={
          processingData ||
          isSeries ||
          assetContentStatusCode === "QUEUED" ||
          assetContentStatusCode === "PROCESSING"
        }
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    );
  };

  const renderAlert = () => {
    switch (asset?.ContentStatusCode) {
      case "QUEUED":
      case "PROCESSING":
        return (
          <Alert
            showIcon
            type="warning"
            message="Video is transcoding. Please wait..."
          />
        );
      case "ERROR":
        return (
          <Alert
            showIcon
            type="error"
            message="Transcoding video failed. Please try again."
          />
        );
      default:
        return null;
    }
  };

  const onFinish = (values: IFormValues) => {
    const { contentUrl, contentFile } = state;

    if (!asset || (!contentUrl && !contentFile)) {
      return;
    }

    updateAssetContent(asset.Id, contentUrl, contentFile);
  };

  return (
    <div className="AssetContent">
      <Form
        form={form}
        name="AssetContentForm"
        className="UserPersonalInformationForm"
        onFinish={onFinish}
      >
        <Form.Item>
          <h1>{t("ASSET_CONTENT_FORM_TITLE", "Content")}</h1>
          {renderAlert()}
        </Form.Item>
        {renderPathField()}
        <Form.Item>
          <Row gutter={16} justify="space-between">
            <Col span={12}>{renderUpload()}</Col>
            <Col span={12}>
              <Player contentUrl={state.contentUrl} />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item style={{ paddingTop: "32px" }} shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              loading={processingData}
              disabled={
                assetContentStatusCode === "QUEUED" ||
                assetContentStatusCode === "PROCESSING" ||
                !form.isFieldsTouched(true) ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }
            >
              {t("BUTTON_SAVE", "Save")}
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
