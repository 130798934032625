import { assign, cloneDeep } from "lodash";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mapTo, switchMap } from "rxjs/operators";
import { IErrorModel } from "../../../models";
import { ICommonAppState } from "../../../store";
import { IConfigurationModel } from "../models";
import { ConfigurationService } from "../services";
import {
  getConfiguration,
  getConfigurationForUserFailure,
  getConfigurationForUserSuccess,
  saveConfiguration,
  saveConfigurationFailure,
  saveConfigurationSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IGetConfigurationForUserAction,
  ISaveConfigurationAction,
  ISaveConfigurationSuccessAction,
  IUpdateConfigurationScreenAction,
} from "./types";

const configurationService = new ConfigurationService();

const getConfigurationForUserEpic = (
  action$: ActionsObservable<IGetConfigurationForUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_CONFIGURATION_FOR_USER),
    switchMap((action: IGetConfigurationForUserAction) =>
      configurationService.getConfigurationForUser(action.userId).pipe(
        map((data) => getConfigurationForUserSuccess(action.userId, data)),
        catchError((error: IErrorModel) =>
          of(getConfigurationForUserFailure(error))
        )
      )
    )
  );

const saveConfigurationEpic = (
  action$: ActionsObservable<ISaveConfigurationAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SAVE_CONFIGURATION),
    switchMap((action: ISaveConfigurationAction) =>
      configurationService.saveConfiguration(action.data).pipe(
        map((config: IConfigurationModel) => {
          action.onSuccess && action.onSuccess();
          return saveConfigurationSuccess(config);
        }),
        catchError((error: IErrorModel) => of(saveConfigurationFailure(error)))
      )
    )
  );

const saveConfigurationSuccessEpic = (
  action$: ActionsObservable<ISaveConfigurationSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.SAVE_CONFIGURATION_SUCCESS),
    mapTo(getConfiguration())
  );

const updateConfigurationScreenEpic = (
  action$: ActionsObservable<IUpdateConfigurationScreenAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_CONFIGURATION_SCREEN),
    map((action: IUpdateConfigurationScreenAction) => {
      const newConfig =
        cloneDeep(state.value.configuration.configuration) || {};

      if (
        action.screenId &&
        newConfig.Screens &&
        newConfig.Screens[action.screenId]
      ) {
        assign(newConfig.Screens[action.screenId], action.data);
      }

      return saveConfiguration(newConfig, action.onSuccess);
    })
  );

export const configurationEpics = [
  getConfigurationForUserEpic,
  saveConfigurationEpic,
  saveConfigurationSuccessEpic,
  updateConfigurationScreenEpic,
];
