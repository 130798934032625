import dayjs, { UnitType } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import { TimestampModel } from "../models";

dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

export class TimeHelper {
  public static getCurrentDate(): Date {
    const date = dayjs().startOf("day");

    return date.toDate();
  }

  public static getDate(value: Date | number | string): Date {
    const date = dayjs(value).startOf("day");

    return date.toDate();
  }

  public static getCurrentDateTime(): Date {
    return dayjs().toDate();
  }

  public static getDateTime(value: Date | number | string): Date {
    const date = dayjs(value);

    return date.toDate();
  }

  public static getDateWithOffset(
    value: Date | number | string,
    offset: number,
    offsetUnit: dayjs.OpUnitType
  ): Date {
    const date = dayjs(value).add(offset, offsetUnit);

    return date.toDate();
  }

  public static getCurrentDateKey(format?: string) {
    return dayjs().format(format);
  }

  public static formatDuration(duration: number | undefined) {
    let durationText: string[] = [];

    if (duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = Math.floor((duration % 3600) % 60);

      durationText = [
        `${hours}`.padStart(2, "0"),
        `${minutes}`.padStart(2, "0"),
        `${seconds}`.padStart(2, "0"),
      ];
    }

    return durationText.join(":");
  }

  public static formatDurationMilliseconds(duration: number | undefined) {
    if (duration) {
      const seconds = Math.floor(duration / 1000);
      const milliseconds = `${duration % seconds}`.padStart(3, "0");

      return this.formatDuration(seconds) + `.${milliseconds}`;
    }

    return "";
  }

  public static formatDurationHuman(duration: number | undefined) {
    let durationText = "";

    if (duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);

      if (hours) {
        durationText += `${hours}h `;
      }

      if (minutes) {
        durationText += `${minutes}min`;
      }
    }

    return durationText.trim();
  }

  public static toString(value: Date | number | string): string {
    return dayjs(value).format();
  }

  public static toUTCString(value: Date | number | string): string {
    return dayjs(value).utc().format();
  }

  public static format(
    value: Date | number | string,
    stringWithTokens: string = "YYYY-MM-DD HH:mm:ss"
  ): string {
    return dayjs(value).format(stringWithTokens);
  }

  public static parse(value: Date | number | string) {
    return dayjs(value);
  }

  static fromNow(value: Date | number | string): string {
    return dayjs(value).fromNow();
  }

  public static formatPlayTimeFromSeconds(value: number) {
    var sec_num = Math.round(value);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    return TimeHelper.formatPlayTime(hours, minutes, seconds);
  }

  public static formatPlayTime(
    hours: number,
    minutes: number,
    seconds: number
  ) {
    let result = "";

    if (hours > 0) {
      result += `${hours < 10 ? 0 : ""}${hours}:`;
    }

    result += `${minutes < 10 ? 0 : ""}${minutes}:`;
    result += `${seconds < 10 ? 0 : ""}${seconds}`;

    return result;
  }

  public static toPlaybackTime(timestamp: TimestampModel) {
    let result: number = 0;

    if (timestamp.Hour) {
      result += timestamp.Hour * 3600;
    }

    if (timestamp.Minute) {
      result += timestamp.Minute * 60;
    }

    if (timestamp.Second) {
      result += timestamp.Second;
    }

    return result;
  }

  public static toTimestamp(seconds: number = 0): TimestampModel {
    return {
      Second: Math.floor((seconds % 3600) % 60),
      Minute: Math.floor((seconds % 3600) / 60),
      Hour: Math.floor(seconds / 3600),
    };
  }

  public static compareIsAfter(
    dateToCheck: string | dayjs.Dayjs,
    dateToCompare: string | dayjs.Dayjs,
    unit?: UnitType
  ) {
    return dayjs(dateToCheck).isAfter(dateToCompare, unit);
  }

  public static compareIsBefore(
    dateToCheck: string | dayjs.Dayjs,
    dateToCompare: string | dayjs.Dayjs,
    unit?: UnitType
  ) {
    return dayjs(dateToCheck).isBefore(dateToCompare, unit);
  }

  public static isAfter(date: string | dayjs.Dayjs, unit?: UnitType) {
    return dayjs().isAfter(date, unit);
  }

  public static isBefore(date: string | dayjs.Dayjs, unit?: UnitType) {
    return dayjs().isBefore(date, unit);
  }

  public static fromDurationMilliseconds(milliseconds: number): Date {
    const startMilliseconds = dayjs().startOf("day").valueOf();

    return dayjs(startMilliseconds + milliseconds).toDate();
  }

  public static toDurationMilliseconds(date: Date): number {
    const startMilliseconds = dayjs(date).clone().startOf("date").valueOf();
    const endMilliseconds = dayjs(date).valueOf();

    return endMilliseconds - startMilliseconds;
  }

  public static getCurrentTimezoneTime() {
    const offset = new Date().getTimezoneOffset() * 60000;
    return new Date(Date.now() - offset).toISOString();
  }
}
