import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IErrorModel } from "../../models";
import { IAssetSourceEntities } from "../../models/Asset/IAssetSourceEntities";
import { AssetService } from "../../services";
import { ICommonAppState } from "../index";
import { getSourceEntitiesFailure, getSourceEntitiesSuccess } from "./actions";
import { GET_SOURCE_ENTITIES, IGetSourceEntitiesAction } from "./types";

const assetService: AssetService = new AssetService();

const getSourceEntitiesEpic = (
  action$: ActionsObservable<IGetSourceEntitiesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(GET_SOURCE_ENTITIES),
    switchMap((action) =>
      assetService.getSourceEntities().pipe(
        map((data) => getSourceEntitiesSuccess(data)),
        catchError((error: IErrorModel) => of(getSourceEntitiesFailure(error)))
      )
    )
  );

export const sourceEntitiesEpics = [getSourceEntitiesEpic];
