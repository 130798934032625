import { IAdvertisementBlockModel, IStateModel } from "@xala/common-services";

export class advertisementBlockStatusHelper {
  static isBlockArchived = (
    advertisementBlock: IStateModel<IAdvertisementBlockModel>
  ) => {
    return advertisementBlock.Data?.Status == "ARCHIVAL";
  };

  static isBlockPublished = (
    advertisementBlock: IStateModel<IAdvertisementBlockModel>
  ) => {
    return advertisementBlock.Data?.Status == "PUBLISHED";
  };

  static isEditable = (
    advertisementBlock: IStateModel<IAdvertisementBlockModel>
  ) => {
    return (
      advertisementBlock.Data &&
      !advertisementBlock.IsProcessing &&
      !advertisementBlockStatusHelper.isBlockPublished(advertisementBlock) &&
      !advertisementBlockStatusHelper.isBlockArchived(advertisementBlock)
    );
  };
}
