// tslint:disable:object-literal-sort-keys
import {
  AssetQualityService,
  AssetStore,
  ContentStatus,
  IAssetContentModel,
  IAssetModel,
  IAssetSearchFilterModel,
  ICommonAppState,
  IRestrictedContentCookieModel,
  RecordStatus,
  useDataLoader,
  useExternalSources,
} from "@xala/common-services";
import * as Consts from "@xala/common-services/src/store/asset/consts";
import {
  Button,
  Heading,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Modal,
  NotificationService,
  Popconfirm,
  setTableColumnSearchProps,
  Spin,
  Table,
  Tag,
  useIntervalEffect,
} from "@xala/common-ui";
import Cookies, { CookieAttributes } from "js-cookie";
import {
  EditExternalAsset,
  EditType,
} from "../../../../components/EditExternalAsset/EditExternalAsset";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormModal, Player } from "../../../../components";
import { ContentStatusHelper } from "../../../../helpers";
import { AssetContentPathModal } from "../AssetContentPathModal";
import { AssetContentUploadModal } from "../AssetContentUploadModal";
import { AssetExternalIdUploadModal } from "../AssetExternalIdUploadModal";
import "./AssetContentList.scss";

const notificationService = NotificationService.getInstance();
const assetQualityService = new AssetQualityService().promisify();

const assetSelector = (state: ICommonAppState) => state.asset;
type ColumnValue = string | number | boolean;

const AUTO_REFRESH_INTERVAL = 10_000;
const STATUSES_REQUIRING_UPDATE: ContentStatus[] = [
  ContentStatus.Processing,
  ContentStatus.Queued,
];

interface IAssetContentListProps {
  asset?: IAssetModel;
  refreshAsset: () => void;
}

const URL_VALIDATE = "https://";

export const AssetContentList: React.FC<IAssetContentListProps> = (props) => {
  const { asset, refreshAsset } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sources = useExternalSources();

  const assetQualityLoader = useDataLoader({
    loader: () => assetQualityService.getAssetQuality(),
    deps: [],
  });

  const [refreshInterval, setRefreshInterval] = useIntervalEffect(refreshAsset);
  const [stateFilter, setStateFilter] = useState<IAssetSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
  });
  const [editableContent, setEditableContent] = useState<IAssetContentModel>(
    {}
  );
  const [previewContentUrl, setPreviewContentUrl] = useState("");
  const [showContentPreviewModal, setShowContentPreviewModal] = useState(false);
  const [showContentPathModal, setShowContentPathModal] = useState(false);
  const [showContentUploadModal, setShowContentUploadModal] = useState(false);
  const [showExternalIdUploadModal, setShowExternalIdUploadModal] = useState(
    false
  );

  const {
    assetContentTypes,
    assetContentStreamTypes,
    action,
    isProcessing,
  } = useSelector(assetSelector);

  const getAssetContentTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetContentTypes()),
    [dispatch]
  );
  const getAssetContentStreamTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetContentStreamTypes()),
    [dispatch]
  );
  const deleteAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.deleteAssetContent(data)),
    [dispatch]
  );

  const cleanupAfterActions = () => {
    setShowContentPathModal(false);
    setShowContentUploadModal(false);
    setShowExternalIdUploadModal(false);
    setEditableContent({});
    refreshAsset();
  };

  useEffect(() => {
    getAssetContentTypes();
    getAssetContentStreamTypes();
  }, []);

  useEffect(() => {
    const shouldRefresh = asset?.Contents?.some(({ ContentStatusCode }) =>
      STATUSES_REQUIRING_UPDATE.includes(ContentStatusCode as ContentStatus)
    );

    if (shouldRefresh) {
      if (refreshInterval === 0) {
        setRefreshInterval(AUTO_REFRESH_INTERVAL);
      }
    } else {
      setRefreshInterval(0);
    }
  }, [asset?.Contents]);

  useEffect(() => {
    switch (action?.type) {
      case Consts.ADD_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notificationService.success({
          message: t(
            "ADD_ASSET_CONTENT_SUCCESS",
            "The asset content has been successfully created."
          ),
        });
        break;
      case Consts.ADD_ASSET_CONTENT_FAILURE:
        notificationService.error({
          message: t(
            "ADD_ASSET_CONTENT_FAILURE",
            "There was an error while creating the asset content."
          ),
          description: action?.error?.Message,
        });
        break;
      case Consts.UPDATE_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notificationService.success({
          message: t(
            "UPDATE_ASSET_CONTENT_SUCCESS",
            "The asset content has been successfully updated."
          ),
        });
        break;
      case Consts.UPDATE_ASSET_CONTENT_FAILURE:
        notificationService.error({
          message: t(
            "UPDATE_ASSET_CONTENT_FAILURE",
            "There was an error while updating the asset content."
          ),
          description: action?.error?.Message,
        });
        break;
      case Consts.DELETE_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notificationService.success({
          message: t(
            "DELETE_ASSET_CONTENT_SUCCESS",
            "The asset content has been successfully deleted."
          ),
        });
        break;
      case Consts.DELETE_ASSET_CONTENT_FAILURE:
        notificationService.error({
          message: t(
            "DELETE_ASSET_CONTENT_FAILURE",
            "There was an error while deleting the asset content."
          ),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const getColumnsProps = (): Array<ITableColumnProps<IAssetContentModel>> => {
    return [
      {
        key: "ContentTypeDisplayName",
        dataIndex: "ContentTypeDisplayName",
        title: t("ContentTypeDisplayName", "Content type"),
        width: "150px",
        align: "center",
        filters: assetContentTypes?.data?.map((type) => ({
          text: type.DisplayName,
          value: type.Code,
        })),
        filteredValue: stateFilter.AssetContentTypeDisplayNames || null,
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.ContentTypeCode!.includes(`${value}`),
        render: (value, record: IAssetContentModel) => {
          if (!record.Url?.includes(URL_VALIDATE)) {
            return;
          }
          return <Tag colorRotate={value}>{value}</Tag>;
        },
      },
      {
        key: "StreamTypeDisplayName",
        dataIndex: "StreamTypeDisplayName",
        title: t("StreamTypeDisplayName", "Stream type"),
        width: "150px",
        align: "center",
        filters: assetContentStreamTypes?.data?.map((type) => ({
          text: type.DisplayName,
          value: type.Code,
        })),
        filteredValue: stateFilter.AssetStreamTypeDisplayNames || null,
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.StreamTypeCode!.includes(`${value}`),
        render: (value, record: IAssetContentModel) => (
          <Tag colorRotate={value}>{record.StreamTypeDisplayName}</Tag>
        ),
      },
      {
        key: "Url",
        dataIndex: "Url",
        ellipsis: true,
        title: t("Url", "Url / External ID"),
        ...setTableColumnSearchProps("Url / ExternalID"),
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.Url
            ? record.Url!.includes(`${value}`)
            : record.ExternalId!.includes(`${value}`),
        filteredValue: stateFilter.Url ? [stateFilter.Url] : null,
        render: (_, record: IAssetContentModel) => {
          return (
            <span
              title={record.Url}
              className="AssetContentList__TableColumn__Url"
              onClick={() => {
                setEditableContent(record);
                if (!record.Url?.includes(URL_VALIDATE)) {
                  setShowExternalIdUploadModal(true);
                } else {
                  setShowContentPathModal(true);
                }
              }}
            >
              {record.Url}
            </span>
          );
        },
      },
      {
        key: "ContentStatusDisplayName",
        dataIndex: "ContentStatusDisplayName",
        title: t("ContentStatusDisplayName", "Status"),
        width: "150px",
        filters: ContentStatusHelper.getFilterOptions(),
        filteredValue: stateFilter.AssetContentStatusDisplayNames || null,
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.ContentStatusCode!.includes(`${value}`),
        align: "center",
        render: (_, row: IAssetContentModel) => {
          return ContentStatusHelper.getTag(row.ContentStatusCode);
        },
      },
      {
        key: "ContentQualityDisplayName",
        dataIndex: "ContentQualityDisplayName",
        title: t("ContentQualityDisplayName", "Quality"),
        width: "150px",
        align: "center",
        render: (_, record: IAssetContentModel) => {
          if (!record.Url?.includes(URL_VALIDATE)) {
            return;
          }
          const assetQuality = assetQualityLoader.data?.find(
            (quality) => quality.Code === record.QualityCode
          );
          return (
            <Tag>
              {t(assetQuality?.TranslationKey || "", assetQuality?.DisplayName)}
            </Tag>
          );
        },
      },
      {
        key: "Preview",
        dataIndex: "Preview",
        title: t("Preview", "Preview"),
        width: "100px",
        ellipsis: true,
        align: "center",
        render: (_, record: IAssetContentModel) => {
          switch (record.ContentStatusCode) {
            case ContentStatus.Ready:
              return (
                <Button
                  shape="circle"
                  type="text"
                  icon={<Icon type="PlayCircle" style={{ fontSize: "24px" }} />}
                  onClick={() => onOpenContentPreviewModal(record)}
                />
              );
            case ContentStatus.Queued:
            case ContentStatus.Processing:
              return <Spin />;
            default:
              return null;
          }
        },
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "100px",
        title: t("AssetActionCode", "Actions"),
        render: (_, assetContent: IAssetContentModel) => (
          <div className="asset-table__actions">
            <EditExternalAsset
              asset={assetContent}
              externalSource={assetContent.ExternalSource}
              assetSourceEntities={sources}
              type={EditType.AssetDetails}
            />
            <Popconfirm
              title={t(
                "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
                "Are you sure you want to delete element?"
              )}
              onConfirm={(e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                deleteAssetContent({ ...assetContent, RecordStatus: RecordStatus.Deleted });
              }}
              okText={t("BUTTON_YES", "Yes")}
              cancelText={t("BUTTON_NO", "No")}
            >
              <Button
                danger={true}
                icon={<Icon type="delete" />}
                title={t("DELETE_ELEMENT", "Delete element")}
              />
            </Popconfirm>
          </div>
        ),
      },
    ];
  };

  const onOpenContentPreviewModal = (assetContent: IAssetContentModel) => {
    if (assetContent.Cookies && assetContent.Cookies.length > 0) {
      assetContent.Cookies.forEach((item: IRestrictedContentCookieModel) => {
        Cookies.remove(item.Name);
        const options: CookieAttributes = {
          path: item.Path,
          domain: item.Domain,
          secure: item.IsSecure,
          sameSite: item.SameSite,
        };
        if (item.Expires) {
          options.expires = new Date(item.Expires);
        }
        Cookies.set(item.Name, item.Value || "", options);
      });
    }

    setShowContentPreviewModal(true);
    setPreviewContentUrl(assetContent.Url!);
  };

  const onOpenContentPathModal = () => {
    setShowContentPathModal(true);
  };

  const onOpenContentUploadModal = () => {
    setShowContentUploadModal(true);
  };

  const onOpenExternalIdUploadModal = () => {
    setShowExternalIdUploadModal(true);
  };

  const onCancelContentPreviewModal = () => {
    setShowContentPreviewModal(false);
    setPreviewContentUrl("");
    Modal.destroyAll();
  };

  const onCancelContentPathModal = () => {
    setShowContentPathModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onCancelContentUploadModal = () => {
    setShowContentUploadModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onCancelExternalIdUploadModal = () => {
    setShowExternalIdUploadModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onSuccessContentUploadModal = () => {
    refreshAsset();
    setShowContentUploadModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onDeleteAssetContent = () => {
    deleteAssetContent({ ...editableContent, RecordStatus: RecordStatus.Deleted });
    Modal.destroyAll();
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const filter: IAssetSearchFilterModel = { ...stateFilter };
    filter.PageNumber = pagination.current!;
    filter.PageSize = pagination.pageSize;

    filter.Url =
      filters.Url && filters.Url.length ? `${filters.Url[0]}` : undefined;

    filter.AssetContentTypeDisplayNames = filters.ContentTypeDisplayName?.length
      ? filters.ContentTypeDisplayName.map((row) => `${row}`)
      : undefined;

    filter.AssetContentStatusDisplayNames = filters.ContentStatusDisplayName
      ?.length
      ? filters.ContentStatusDisplayName.map((row) => `${row}`)
      : undefined;

    filter.AssetStreamTypeDisplayNames = filters.StreamTypeDisplayName?.length
      ? filters.StreamTypeDisplayName.map((row) => `${row}`)
      : undefined;

    setStateFilter(filter);
  };

  const onClearFiltersClick = () => {
    setStateFilter({});
  };

  return (
    <div className="AssetContentList">
      <Heading
        title={t("ContentList", "Content list")}
        actions={
          <>
            <Button
              key="clear-filters"
              shape="circle"
              icon={<Icon type="clear" />}
              onClick={onClearFiltersClick}
              title="Clear filters"
            />
            <Button
              key="add"
              type="primary"
              shape="circle"
              icon={<Icon type="link" />}
              onClick={onOpenContentPathModal}
              title="Add video URL"
            />
            <Button
              key="upload"
              type="primary"
              shape="circle"
              icon={<Icon type="cloud-upload" />}
              onClick={onOpenContentUploadModal}
              title="Add video file"
            />
            <Button
              key="addExternalId"
              type="primary"
              shape="circle"
              icon={<Icon type="plus" />}
              onClick={onOpenExternalIdUploadModal}
              title="Add external asset"
            />
          </>
        }
      />
      <Table<IAssetContentModel>
        columns={getColumnsProps()}
        dataSource={asset?.Contents}
        rowKey="Id"
        loading={isProcessing}
        pagination={false}
        onChange={onTableChange}
      />
      <Modal
        visible={showContentPreviewModal}
        footer={null}
        onCancel={onCancelContentPreviewModal}
        preview
      >
        {showContentPreviewModal && (
          <Player contentUrl={previewContentUrl} fill={false} />
        )}
      </Modal>
      <FormModal
        isVisible={showContentPathModal}
        isLoading={isProcessing}
        isNewForm={editableContent.Id ? false : true}
        isDeleteButtonEnabled={editableContent.Id ? true : false}
        createFormTitle={t("ASSET_CONTENT_ADD_MODAL_TITLE")}
        editFormTitle={t("ASSET_CONTENT_EDIT_MODAL_TITLE")}
        modalClassName="AssetContentPathForm"
        submitFormName="AssetContentPathForm"
        onCloseModal={onCancelContentPathModal}
        onDeleteButtonClick={onDeleteAssetContent}
      >
        <AssetContentPathModal
          asset={asset}
          assetContent={editableContent}
          assetQuality={assetQualityLoader.data}
        />
      </FormModal>
      <AssetContentUploadModal
        visible={showContentUploadModal}
        asset={asset}
        assetContent={editableContent}
        onCancel={onCancelContentUploadModal}
        onSuccess={onSuccessContentUploadModal}
      />
      <FormModal
        isVisible={showExternalIdUploadModal}
        isLoading={isProcessing}
        isNewForm={!editableContent?.Id}
        isDeleteButtonEnabled={true}
        createFormTitle={t("ASSET_CONTENT_ADD_MODAL_TITLE")}
        editFormTitle={t("ASSET_CONTENT_EDIT_MODAL_TITLE")}
        modalClassName="AssetContentModal"
        submitFormName="AssetExternalIdUploadForm"
        onCloseModal={onCancelExternalIdUploadModal}
        onDeleteButtonClick={onDeleteAssetContent}
      >
        <AssetExternalIdUploadModal
          asset={asset}
          assetContent={editableContent}
        />
      </FormModal>
    </div>
  );
};
