import React from "react";
import {
  PlatformType,
  PlatformTypeHelper as CommonPlatformTypeHelper,
} from "@xala/common-services";
import { Tag, IChooseTagProps } from "@xala/common-ui";

export class PlatformTypeHelper extends CommonPlatformTypeHelper {
  static getTag(value: PlatformType): React.ReactElement {
    let color: string = PlatformTypeHelper.getColor(value);

    return (
      <Tag key={`${value}`} color={color}>
        {" "}
        {PlatformTypeHelper.getDescription(value)}
      </Tag>
    );
  }

  static getTagOption = (props: IChooseTagProps): React.ReactElement => {
    const { label, value, closable, onClose } = props;
    const platform = PlatformTypeHelper.getValue(value as string);

    return (
      <Tag
        color={PlatformTypeHelper.getColor(platform)}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
}
