import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  AdvertisementBlockAddModal as AdvertisementBlockAddModalDefinition,
  IAdvertisementBlockAddModalDispatchProps,
  IAdvertisementBlockAddModalOwnProps,
  IAdvertisementBlockAddModalStateProps,
} from "./AdvertisementBlockAddModal";

const mapStateToProps = (
  state: ICommonAppState,
  ownProps: IAdvertisementBlockAddModalOwnProps
): IAdvertisementBlockAddModalStateProps => {
  return {
    actionType: state.advertisement.actionType,
    error: state.advertisement.error,
    isProcessingData: state.advertisement.isProcessingData,
    temporaryAdvertisementBlock:
      state.advertisement.temporaryAdvertisementBlock,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export const AdvertisementBlockAddModal = connect<
  IAdvertisementBlockAddModalStateProps,
  IAdvertisementBlockAddModalDispatchProps,
  IAdvertisementBlockAddModalOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBlockAddModalDefinition));
