import React from "react";
import {
  Col,
  Form,
  IFormValues,
  Input,
  InputNumber,
  Row,
  Spin,
} from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetAgeRestrictionModel } from "@xala/common-services";

export interface IDictionaryageRestrictionFormProps {
  ageRestriction: IAssetAgeRestrictionModel;
  isProcessing: boolean;
  insertAgeRestriction: (ageRestriction: IAssetAgeRestrictionModel) => void;
}

export const DictionaryAgeRestrictionForm = ({
  ageRestriction,
  isProcessing,
  insertAgeRestriction,
}: IDictionaryageRestrictionFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (values: IFormValues) => {
    const data: IAssetAgeRestrictionModel = {
      ...ageRestriction,
      ...values,
    };

    insertAgeRestriction(data);
  };

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryAgeRestrictionForm"
        className="DictionaryAgeRestrictionForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item name="ValueMin" label={t("Value", "Value")}>
          <InputNumber
            parser={(value?: string) => value?.replace(/[^\d]/, "") || 0}
            defaultValue={0}
            min={0}
            max={99}
            placeholder={t("Value", "Value")}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
};
