import {
  DeleteOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  AssetStreamsService,
  CreatorStreamState,
  ICreatorStreamModel,
  UserStreamsService,
  useServiceCaller,
} from "@xala/common-services";
import {
  Button,
  Form,
  Link,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  Spin,
  Text,
} from "@xala/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Player } from "../../../../components";
import { MediaChannelStateTag } from "../../../MediaChannel/components";
import { ROUTES as UserRoutes } from "../../../User/constants";
import "./MediaChannelForm.scss";

const notificationService = NotificationService.getInstance();
const userStreamsService = new UserStreamsService().promisify();
const assetStreamsService = new AssetStreamsService().promisify();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 6 },
    xl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 16 },
    lg: { span: 18 },
    xl: { span: 20 },
  },
};

export interface IMediaChannelFormProps {
  channel?: ICreatorStreamModel;
  loading: boolean;
  refresh?: () => Promise<void>;
  title: string;
  userId?: number;
  onBack?: (e: React.MouseEvent<HTMLDivElement> | null) => void;
}

export const MediaChannelForm: React.FC<IMediaChannelFormProps> = ({
  channel,
  loading,
  refresh,
  title,
  userId,
  onBack,
}) => {
  const { t } = useTranslation();
  const [showContentPreviewModal, setShowContentPreviewModal] = useState(false);
  const [previewContentUrl, setPreviewContentUrl] = useState("");

  const [
    createOrUpdateChannel,
    createOrUpdateChannelState,
  ] = useServiceCaller(async () => {
    if (userId) {
      const result = await userStreamsService.createOrUpdateUserLiveChannel({
        UserId: userId,
      });

      if (result.ok) {
        notificationService.success({
          message: t("CREATE_CHANNEL_SUCCESS"),
        });
        refresh?.();
      } else {
        notificationService.error({
          message: t("CREATE_CHANNEL_FAILURE"),
          description: result?.error?.Message,
        });
      }
    }
  }, [userId]);

  const [deleteAsset] = useServiceCaller(async () => {
    if (userId) {
      const result = await userStreamsService.deleteUserLiveChannel({
        UserId: userId,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DELETE_CHANNEL_SUCCESS"),
        });

        if (onBack) {
          onBack(null);
        } else {
          refresh?.();
        }
      } else {
        notificationService.error({
          message: t("DELETE_CHANNEL_FAILURE"),
          description: result.error?.Message,
        });
      }
    }
  }, [userId]);

  const [startChannel, startChannelState] = useServiceCaller(async () => {
    if (userId) {
      const result = await assetStreamsService.start(userId);

      if (result.ok) {
        notificationService.success({
          message: t("START_CHANNEL_SUCCESS"),
        });
        refresh?.();
      } else {
        notificationService.error({
          message: t("START_CHANNEL_FAILURE"),
          description: result?.error?.Message,
        });
      }
    }
  }, [userId]);

  const [stopChannel, stopChannelState] = useServiceCaller(async () => {
    if (userId) {
      const result = await assetStreamsService.stop(userId);

      if (result.ok) {
        notificationService.success({
          message: t("STOP_CHANNEL_SUCCESS"),
        });
        refresh?.();
      } else {
        notificationService.error({
          message: t("STOP_CHANNEL_FAILURE"),
          description: result?.error?.Message,
        });
      }
    }
  }, [userId]);

  const onStartClick = () => {
    Modal.confirm({
      title: t("START_CHANNEL_TITLE"),
      content: t("START_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_START"),
      cancelText: t("COMMON_CANCEL"),
      onOk: startChannel,
    });
  };

  const onStopClick = () => {
    Modal.confirm({
      title: t("STOP_CHANNEL_TITLE"),
      content: t("STOP_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_STOP"),
      cancelText: t("COMMON_CANCEL"),
      onOk: stopChannel,
    });
  };

  const onAddClick = () => {
    Modal.confirm({
      title: t("CREATE_CHANNEL_TITLE"),
      content: t("CREATE_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_CREATE"),
      cancelText: t("COMMON_CANCEL"),
      onOk: createOrUpdateChannel,
    });
  };

  const onDeleteClick = () => {
    Modal.confirm({
      title: t("DELETE_CHANNEL_TITLE"),
      content: t("DELETE_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_DELETE"),
      cancelText: t("COMMON_CANCEL"),
      onOk: deleteAsset,
    });
  };

  const onOpenContentPreviewModal = (contentPreview: string) => {
    setPreviewContentUrl(contentPreview);
    setShowContentPreviewModal(true);
  };

  const onCancelContentPreviewModal = () => {
    setPreviewContentUrl("");
    setShowContentPreviewModal(false);
  };

  const isCreatorStreamIdle = channel?.State === CreatorStreamState.Idle;
  const isCreatorStreamRunning = channel?.State === CreatorStreamState.Running;

  const globalLoading =
    loading ||
    createOrUpdateChannelState.processing ||
    startChannelState.processing ||
    stopChannelState.processing;

  return (
    <PageContent className="MediaChannelForm">
      <Spin spinning={globalLoading}>
        <PageHeader
          title={title}
          onBack={onBack}
          extra={
            <>
              {isCreatorStreamIdle && (
                <Button
                  icon={<PlayCircleOutlined />}
                  onClick={onStartClick}
                  title={t("BUTTON_START_TITLE")}
                >
                  {t("BUTTON_START_TITLE")}
                </Button>
              )}
              {isCreatorStreamRunning && (
                <Button
                  icon={<StopOutlined />}
                  onClick={onStopClick}
                  title={t("BUTTON_STOP_TITLE")}
                >
                  {t("BUTTON_STOP_TITLE")}
                </Button>
              )}
              {channel && refresh && (
                <Button
                  shape="circle"
                  icon={<ReloadOutlined />}
                  onClick={refresh}
                  title={t("BUTTON_REFRESH_TITLE")}
                />
              )}
              {channel && (
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title={t("DELETE_CHANNEL_BUTTON")}
                  onClick={onDeleteClick}
                  shape="circle"
                />
              )}
              {!channel && !loading && (
                <Button
                  key="add"
                  shape="circle"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={onAddClick}
                  title={t("BUTTON_NEW_CHANNEL")}
                />
              )}
            </>
          }
        />

        {!channel && !loading && (
          <SectionGridItem>
            {t("MEDIA_CHANNEL_FORM_INPUT_NO_CHANNEL")}
          </SectionGridItem>
        )}

        {channel && (
          <SectionGrid style={{ maxWidth: "1200px" }}>
            <SectionGridItem header={t("MEDIA_CHANNEL_FORM_CHANNEL")}>
              <Form {...formItemLayout}>
                <Form.Item label="Type">{channel?.Type}</Form.Item>
                <Form.Item label="State">
                  <MediaChannelStateTag state={channel?.State} />
                </Form.Item>
                <Form.Item label="User">
                  <Link to={`${UserRoutes.USER_DETAILS}/${channel.UserId}`}>
                    {channel.UserFullName}
                  </Link>
                </Form.Item>
              </Form>
            </SectionGridItem>

            {channel?.InputEndpoints?.map((input, index) => (
              <SectionGridItem
                key={index}
                header={t("MEDIA_CHANNEL_FORM_INPUT")}
              >
                <Form {...formItemLayout}>
                  {Object.entries(input).map(([key, value]) => (
                    <Form.Item key={key} label={key}>
                      <Text copyable>{value}</Text>
                    </Form.Item>
                  ))}
                </Form>
              </SectionGridItem>
            ))}

            {channel?.OutputEndpoints.map((output, index) => (
              <SectionGridItem
                key={index}
                header={t("MEDIA_CHANNEL_FORM_OUTPUT")}
              >
                <Form {...formItemLayout}>
                  {Object.entries(output).map(([key, value]) => (
                    <Form.Item key={key} label={key}>
                      <Text copyable>{value}</Text>
                    </Form.Item>
                  ))}

                  <Form.Item label=" " colon={false}>
                    <Button
                      disabled={!isCreatorStreamRunning}
                      icon={<PlayCircleOutlined />}
                      title={t("MEDIA_CHANNEL_FORM_PLAY")}
                      onClick={() => onOpenContentPreviewModal(output.Url)}
                    >
                      {t("MEDIA_CHANNEL_FORM_PLAY")}
                    </Button>
                  </Form.Item>
                </Form>

                {isCreatorStreamRunning && <Player contentUrl={output.Url} />}
              </SectionGridItem>
            ))}
          </SectionGrid>
        )}
      </Spin>
      <Modal
        visible={showContentPreviewModal}
        footer={null}
        onCancel={onCancelContentPreviewModal}
        preview
      >
        {showContentPreviewModal && (
          <Player contentUrl={previewContentUrl} fill={false} />
        )}
      </Modal>
    </PageContent>
  );
};
