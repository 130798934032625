import {
  ApplicationConfigurationStore,
  ICommonAppState,
} from "@xala/common-services";
import {
  Button,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
} from "@xala/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationConfigurationBrandingThemesList } from "../ApplicationConfigurationBrandingThemesList";
import { ApplicationConfigurationDetailsForm } from "../ApplicationConfigurationDetailsForm";
import { ApplicationConfigurationScreensList } from "../ApplicationConfigurationScreensList";
import { TranslationsList } from "../../../Translations/components/TranslationsList";
import "./ApplicationConfigurationDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

const notificationService = NotificationService.getInstance();

export const ApplicationConfigurationDetails = () => {
  const [activeTabKey, setActiveTabKey] = useState(
    new URLSearchParams(location.search).get("tab") ?? "DETAILS"
  );
  const action = useSelector(
    (state: ICommonAppState) => state.applicationConfiguration.action
  );
  const configuration = useSelector(
    (state: ICommonAppState) => state.applicationConfiguration.configuration
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(ApplicationConfigurationStore.Actions.getConfiguration(+id));
  }, [id]);

  useEffect(() => {
    switch (action?.type) {
      case ApplicationConfigurationStore.Consts
        .PUBLISH_APPLICATION_CONFIGURATION_SUCCESS:
        notificationService.success({
          message: t(
            "APPLICATION_CONFIGURATION__PUBLISH_SUCCESS",
            "Application configuration was published successfully."
          ),
        });
        dispatch(ApplicationConfigurationStore.Actions.getConfiguration(+id));
        break;
      case ApplicationConfigurationStore.Consts
        .PUBLISH_APPLICATION_CONFIGURATION_FAILURE:
        notificationService.error({
          description: configuration?.Error?.Message,
          message: t(
            "APPLICATION_CONFIGURATION__PUBLISH_FAILED",
            "Publication application configuration failed."
          ),
        });
        break;
      case ApplicationConfigurationStore.Consts
        .UPDATE_APPLICATION_CONFIGURATION_FAILURE:
        notificationService.error({
          message: "Update application configuration failed",
          description: configuration?.Error?.Message,
        });
        break;
      case ApplicationConfigurationStore.Consts
        .UPDATE_APPLICATION_CONFIGURATION_SUCCESS:
        notificationService.success({
          message: "Update application configuration success",
        });
        break;
      case ApplicationConfigurationStore.Consts.REBUILD_CACHE_SUCCESS:
        notificationService.success({
          message: t("REBUILD_CACHE__SUCCESS"),
        });
        break;
      case ApplicationConfigurationStore.Consts.REBUILD_CACHE_FAILURE:
        notificationService.error({
          message: t("REBUILD_CACHE__FAILED"),
        });
        break;
      case ApplicationConfigurationStore.Consts.SYNCHRONIZE_EPG_SUCCESS:
        notificationService.success({
          message: t("SYNCHRONIZE_EPG__SUCCESS"),
        });
        break;
      case ApplicationConfigurationStore.Consts.SYNCHRONIZE_EPG_FAILURE:
        notificationService.error({
          message: t("SYNCHRONIZE_EPG__FAILED"),
        });
        break;
    }
  }, [action?.type]);

  const onTabClick = (key: string) => {
    setActiveTabKey(key);
  };

  const onRefreshClick = () => {
    dispatch(ApplicationConfigurationStore.Actions.getConfiguration(+id));
  };

  const onPublishClick = () => {
    if (!configuration?.Data) {
      return;
    }

    Modal.confirm({
      title: t("Publish configuration"),
      content: `Are you sure to publish configuration "${configuration?.Data.Name}"?`,
      okText: "Publish",
      cancelText: "Cancel",
      onOk: onPublish,
    });
  };

  const onPublish = () => {
    if (configuration?.Data && configuration?.Data?.RowVersion) {
      dispatch(
        ApplicationConfigurationStore.Actions.publishConfiguration(
          configuration.Data.Id,
          configuration.Data.RowVersion
        )
      );
    }
  };

  const onRebuildCacheClick = () => {
    dispatch(ApplicationConfigurationStore.Actions.rebuildCache());
  };

  const onSynchronizeEPGClick = () => {
    dispatch(ApplicationConfigurationStore.Actions.synchronizeEPG());
  };

  return (
    <div className="ApplicationConfigurationDetails">
      <Spin
        spinning={
          configuration.IsProcessing ||
          action?.type === ApplicationConfigurationStore.Consts.REBUILD_CACHE ||
          action?.type === ApplicationConfigurationStore.Consts.SYNCHRONIZE_EPG
        }
      >
        <PageContent>
          <PageHeader
            title={configuration?.Data?.Name}
            onBack={() => window.history.back()}
            extra={
              <>
                <Button
                  type="primary"
                  className="synchronize-epg-btn"
                  key="action-synchronize-epg"
                  title="Synchronize EPG"
                  loading={
                    action?.type ===
                    ApplicationConfigurationStore.Consts.SYNCHRONIZE_EPG
                  }
                  disabled={
                    action?.type ===
                    ApplicationConfigurationStore.Consts.SYNCHRONIZE_EPG
                  }
                  onClick={onSynchronizeEPGClick}
                >
                  {t("BUTTON__SYNCHRONIZE_EPG")}
                </Button>
                <Button
                  type="primary"
                  className="rebuild-cache-btn"
                  key="action-rebuild-cache"
                  title="Rebuild cache"
                  loading={
                    action?.type ===
                    ApplicationConfigurationStore.Consts.REBUILD_CACHE
                  }
                  disabled={
                    action?.type ===
                    ApplicationConfigurationStore.Consts.REBUILD_CACHE
                  }
                  onClick={onRebuildCacheClick}
                >
                  {t("BUTTON__REBUILD_CACHE")}
                </Button>
                <Button
                  type="primary"
                  key="action-save"
                  icon={<Icon type="upload" />}
                  title="Publish configuration"
                  loading={configuration.IsProcessing}
                  disabled={configuration.IsProcessing}
                  onClick={onPublishClick}
                >
                  {t("BUTTON_PUBLISH", "PUBLISH")}
                </Button>
                <Button
                  shape="circle"
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  onClick={onRefreshClick}
                  title="Refresh data"
                />
              </>
            }
          ></PageHeader>
          <Tabs
            defaultActiveKey={activeTabKey}
            destroyInactiveTabPane
            onTabClick={onTabClick}
          >
            <TabPane key="DETAILS" tab={t("details", "Details")}>
              <ApplicationConfigurationDetailsForm
                key={`ApplicationConfigurationDetailsForm-${configuration?.Data?.Id}`}
                configuration={configuration}
                isActive={activeTabKey === "DETAILS"}
              />
            </TabPane>
            <TabPane key="BRANDING" tab={t("branding", "Branding")}>
              <ApplicationConfigurationBrandingThemesList
                configurationId={configuration?.Data?.Id}
              />
            </TabPane>
            <TabPane key="SCREENS" tab={t("screens", "Screens")}>
              <ApplicationConfigurationScreensList
                key={`ApplicationConfigurationScreensList-${configuration?.Data?.Id}`}
                configurationId={configuration?.Data?.Id}
              />
            </TabPane>
            {/* INFO: Tab will be enabled when functionalities will be ready.
                  <TabPane
                    key="USERS_SCREENS"
                    tab={t("users_screens", "Users screens")}
                  >
                    <ApplicationsUsersScreensList
                      key={`ApplicationsUsersScreensList-${configuration?.Data?.Id}`}
                      configurationId={configuration?.Data?.Id}
                    />
                  </TabPane> */}
            <TabPane
              key="CUSTOM_TRANSLATIONS"
              tab={t("CUSTOM_TRANSLATIONS", "Translations")}
            >
              <TranslationsList />
            </TabPane>
          </Tabs>
        </PageContent>
      </Spin>
    </div>
  );
};
