import { PaymentStatus } from "@xala/common-services";
import React from "react";
import { Tag } from "../Tag";

interface PaymentStatusTagProps {
  status?: PaymentStatus;
}

export const PaymentStatusTag: React.FC<PaymentStatusTagProps> = ({
  status,
}) => {
  let color;

  switch (status) {
    case PaymentStatus.Completed:
      color = "#418841";
      break;
    default:
      color = "#787878";
  }

  return <Tag color={color}>{status || "?"}</Tag>;
};
