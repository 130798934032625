import {
  ComponentType,
  ComponentTypeHelper,
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationScreenModel,
  PlatformType,
  RecordStatus,
  ScreenType,
} from "@xala/common-services";
import { Content, Icon, Layout, Popconfirm, Sider } from "@xala/common-ui";
import React from "react";
import GridLayout, { Layout as LayoutProps } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { WithTranslation } from "react-i18next";
import "react-resizable/css/styles.css";
import { WithScreenContextProps } from "../../../../context";
import { DesignerProvider, IDesignerContext } from "../../context";
import {
  ComponentLayoutPropertyModel,
  LayoutOptionsModel,
  ListComponentPropertyModel,
  TextWidgetComponentPropertyModel,
  TopFriendsWidgetComponentPropertyModel,
  ApplicationFooterItemComponentPropertyModel,
} from "../../models";
import { ApplicationHeaderPreview } from "../ApplicationHeaderPreview";
import { ApplicationMenuPreview } from "../ApplicationMenuPreview";
import { DeviceFrame } from "../DeviceFrame";
import { ListComponentPreview } from "../ListComponentPreview";
import { TextWidgetComponentPreview } from "../TextWidgetComponentPreview";
import { ApplicationFooterItemComponentPreview } from "../ApplicationFooterItemComponentPreview";
import "./ScreenDesigner.scss";

function getInitialLayoutOptions(
  screen?: IApplicationScreenModel
): LayoutOptionsModel {
  const layoutOptions: LayoutOptionsModel = {
    Platform: PlatformType.Any,
    Zoom: 0.5,
    RowHeight: 30,
    RowWidth: 1280,
    CurrentPositionY: 0,
    CurrentPositionX: 0,
  };

  if (screen && screen.Platforms && screen.Platforms.length > 0) {
    const platformCode = screen.Platforms[0].PlatformCode;

    switch (platformCode) {
      case PlatformType.iOSPhone:
        layoutOptions.Platform = platformCode;
        layoutOptions.RowWidth = 375;
        layoutOptions.Zoom = 1;

        break;
      case PlatformType.AndroidPhone:
        layoutOptions.Platform = platformCode;
        layoutOptions.RowWidth = 360;
        layoutOptions.Zoom = 1;
        break;
      case PlatformType.AndroidTablet:
      case PlatformType.iPad:
        layoutOptions.Platform = platformCode;
        layoutOptions.RowWidth = 510;
        layoutOptions.Zoom = 1;
        break;
      case PlatformType.AndroidTV:
      case PlatformType.AppleTV:
      case PlatformType.Tizen:
      case PlatformType.WebOS:
        layoutOptions.Platform = platformCode;
        layoutOptions.RowWidth = 600;
        layoutOptions.Zoom = 1;
        break;
      case PlatformType.Web:
        layoutOptions.Platform = platformCode;
        layoutOptions.RowWidth = 1280;
        layoutOptions.Zoom = 0.5;
        break;
    }
  }

  return layoutOptions;
}

export interface IScreenDesignerProps
  extends WithTranslation,
    WithScreenContextProps {}

export interface IScreenDesignerState {
  layoutOptions: LayoutOptionsModel;
  menuCollapsed: boolean;
}

export class ScreenDesigner extends React.Component<
  IScreenDesignerProps,
  IScreenDesignerState
> {
  public state: Readonly<IScreenDesignerState> = {
    layoutOptions: getInitialLayoutOptions(this.props.screen),
    menuCollapsed: false,
  };

  private _isMounted = false;

  public componentDidMount() {
    this._isMounted = true;
  }

  public onLayoutChange = (layouts: LayoutProps[]) => {
    const { screen, onComponentsChange } = this.props;
    const { layoutOptions } = this.state;

    if (!this._isMounted || !screen || !onComponentsChange) {
      return;
    }

    if (!screen.Components) {
      screen.Components = [];
    }

    const changedComponents = [];

    for (let component of screen.Components) {
      if (!component.Layouts) {
        component.Layouts = [];
      }

      let componentLayout = component.Layouts.find(
        (row: IApplicationComponentLayoutModel) =>
          row.PlatformCode === layoutOptions.Platform
      );
      const layout = layouts.find(
        (row: LayoutProps) => row.i === `${component.Id}`
      );

      if (layout && componentLayout) {
        if (
          layout.w !== componentLayout.Width ||
          layout.h !== componentLayout.Height ||
          layout.x !== componentLayout.PositionX ||
          layout.y !== componentLayout.PositionY
        ) {
          componentLayout.Width = layout.w;
          componentLayout.Height = layout.h;
          componentLayout.PositionX = layout.x;
          componentLayout.PositionY = layout.y;

          if (componentLayout.RecordStatus === RecordStatus.NoChange) {
            componentLayout.RecordStatus = RecordStatus.Updated;
          }

          changedComponents.push(component);
        }
      }
    }

    if (changedComponents.length) {
      onComponentsChange(changedComponents);
    }
  };

  public onComponentSelect = (componentId: number) => {
    const { screen, onComponentSelect } = this.props;

    if (screen && screen.Components && onComponentSelect) {
      const component = screen.Components.find(
        (row: IApplicationComponentModel) => row.Id === componentId
      );

      onComponentSelect(component);
    }
  };

  public onComponentDelete = (componentId: number) => {
    const { onComponentDelete } = this.props;

    if (onComponentDelete) {
      onComponentDelete(componentId);
    }
  };
  public renderApplicationHeader = () => {
    const { screen } = this.props;

    if (!screen || screen?.ScreenTypeCode !== ScreenType.ApplicationHeader) {
      return null;
    }

    return <ApplicationHeaderPreview applicationHeaderScreen={screen} />;
  };

  public renderApplicationMenu = () => {
    const { screen } = this.props;

    if (!screen || screen?.ScreenTypeCode !== ScreenType.ApplicationMenu) {
      return null;
    }

    return <ApplicationMenuPreview applicationMenuScreen={screen} />;
  };

  private onMenuCollapse = (collapsed: boolean) => {
    this.setState({ menuCollapsed: collapsed });

    // Hack to recalculate widget grid on sidebar collapse
    for (let t = 0; t <= 300; t += 100) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, t);
    }
  };

  private makeOxyMoveForNextComponent = (
    componentTypeCode: ComponentType,
    layoutOptions: LayoutOptionsModel,
    layout: LayoutProps
  ) => {
    switch (componentTypeCode) {
      case ComponentType.ApplicationFooterItem:
        layoutOptions.CurrentPositionX =
          (layoutOptions.CurrentPositionX < layout.x
            ? layout.y
            : layoutOptions.CurrentPositionX) + layout.w;
        break;
      default:
        layoutOptions.CurrentPositionY =
          (layoutOptions.CurrentPositionY < layout.y
            ? layout.y
            : layoutOptions.CurrentPositionY) + layout.h;
        break;
    }
  };

  public renderComponents(): {
    layouts: LayoutProps[];
    components: React.ReactNode[];
  } {
    const { screen, component, t } = this.props;
    const { layoutOptions } = this.state;

    const componentsView: React.ReactNode[] = [];
    const layouts: LayoutProps[] = [];

    if (
      !screen ||
      screen.ScreenTypeCode === ScreenType.ApplicationHeader ||
      screen.ScreenTypeCode === ScreenType.ApplicationMenu
    ) {
      return {
        layouts,
        components: componentsView,
      };
    }

    layoutOptions.CurrentPositionY = 0;
    layoutOptions.CurrentPositionX = 0;

    if (screen.Components) {
      for (let screenComponent of screen.Components) {
        if (screenComponent.RecordStatus === RecordStatus.Deleted) {
          continue;
        }

        const componentOptionsView: React.ReactNode[] = [];
        let componentView: React.ReactNode;
        let componentLayout: ComponentLayoutPropertyModel = {
          PositionX: layoutOptions.CurrentPositionX,
          PositionY: layoutOptions.CurrentPositionY,
          Width: 12,
          Height: 2,
        };

        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        switch (screenComponent.ComponentTypeCode) {
          case ComponentType.TextWidget:
            const textWidgetProperties = new TextWidgetComponentPropertyModel(
              screenComponent
            );
            componentLayout = textWidgetProperties.getLayoutProperty(
              screenComponent,
              layoutOptions
            );

            componentView = (
              <TextWidgetComponentPreview
                key={screenComponent.Guid}
                component={screenComponent}
                properties={textWidgetProperties}
              />
            );
            break;
          case ComponentType.TopFriendsWidget:
            const topFriendsWidgetProperties = new TopFriendsWidgetComponentPropertyModel(
              screenComponent
            );
            componentLayout = topFriendsWidgetProperties.getLayoutProperty(
              screenComponent,
              layoutOptions
            );

            //componentView = (
            //  <ListComponentPreview
            //    key={screenComponent.Id}
            //    component={screenComponent}
            //    properties={textWidgetProperties}
            //  />);
            break;
          case ComponentType.List:
            const listProperties = new ListComponentPropertyModel(
              screenComponent
            );
            componentLayout = listProperties.getLayoutProperty(
              screenComponent,
              layoutOptions
            );

            componentView = (
              <ListComponentPreview
                key={screenComponent.Guid}
                component={screenComponent}
                properties={listProperties}
              />
            );
            break;
          case ComponentType.ApplicationFooterItem:
            const footerProperties = new ApplicationFooterItemComponentPropertyModel(
              screenComponent
            );
            componentLayout = footerProperties.getLayoutProperty(
              screenComponent,
              layoutOptions
            );

            componentView = (
              <ApplicationFooterItemComponentPreview
                key={screenComponent.Guid}
                component={screenComponent}
                properties={footerProperties}
              />
            );
            break;
          default:
            break;
        }

        const layout: LayoutProps = {
          i: `${screenComponent.Id}`,
          x: componentLayout.PositionX,
          y: componentLayout.PositionY,
          w: componentLayout.Width,
          h: componentLayout.Height,
          static: componentLayout.Static,
          isResizable: componentLayout.IsResizable,
          isDraggable: componentLayout.IsDraggable,
        };

        layouts.push(layout);
        this.makeOxyMoveForNextComponent(
          screenComponent.ComponentTypeCode,
          layoutOptions,
          layout
        );

        componentOptionsView.push(
          <div
            key={`remove-action-${screenComponent.Guid}`}
            className="Component--remove"
            title={t("DELETE_COMPONENT", "Delete component")}
          >
            <Popconfirm
              title={t(`Are you sure you want to delete commponent?`)}
              onConfirm={(e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                this.onComponentDelete(screenComponent.Id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>
        );

        if (layout.isDraggable) {
          componentOptionsView.push(
            <div
              key={`drag-action-${screenComponent.Guid}`}
              className="Component--drag"
            >
              <Icon type="drag" />
            </div>
          );
        }

        componentsView.push(
          <div
            key={layout.i}
            style={style}
            className="Component"
            onClick={() => this.onComponentSelect(screenComponent.Id)}
          >
            <div className="Component--content">
              <div className="Component--options">{componentOptionsView}</div>
              <div className="Component--preview">{componentView}</div>
            </div>
          </div>
        );
      }
    }

    return {
      layouts,
      components: componentsView,
    };
  }

  public renderDeviceLayout = () => {
    const { layoutOptions, menuCollapsed } = this.state;
    const { screen } = this.props;
    const { layouts, components } = this.renderComponents();

    switch (layoutOptions.Platform) {
      case PlatformType.AndroidPhone:
      case PlatformType.AndroidTablet:
      case PlatformType.iOSPhone:
      case PlatformType.iPad:
        return (
          <React.Fragment>
            {this.renderApplicationHeader()}
            <React.Fragment>
              <GridLayout
                key={`device-content-${layoutOptions.Platform}`}
                margin={[10, 10]}
                className="Component--grid"
                compactType="vertical"
                cols={12}
                draggableHandle=".Component--drag"
                rowHeight={layoutOptions.RowHeight}
                width={layoutOptions.RowWidth}
                layout={layouts}
                onLayoutChange={this.onLayoutChange}
              >
                {components}
              </GridLayout>
            </React.Fragment>
            {this.renderApplicationMenu()}
          </React.Fragment>
        );
      case PlatformType.Web:
        const siderWidth =
          screen?.ScreenTypeCode === ScreenType.ApplicationMenu
            ? menuCollapsed
              ? 80
              : 224
            : 0;

        return (
          <Layout
            style={{
              height: "100%",
              background: "none",
            }}
          >
            <Sider
              collapsible={true}
              collapsed={menuCollapsed}
              defaultCollapsed={menuCollapsed}
              breakpoint="lg"
              width={siderWidth}
              onCollapse={this.onMenuCollapse}
            >
              {this.renderApplicationMenu()}
            </Sider>
            <Content style={{ marginLeft: siderWidth }}>
              <GridLayout
                key={`device-content-${layoutOptions.Platform}`}
                margin={[10, 10]}
                className="Component--grid"
                compactType="vertical"
                cols={12}
                draggableHandle=".Component--drag"
                rowHeight={layoutOptions.RowHeight}
                width={layoutOptions.RowWidth}
                layout={layouts}
                onLayoutChange={this.onLayoutChange}
              >
                {components}
              </GridLayout>
            </Content>
          </Layout>
        );
      default:
        return (
          <React.Fragment>
            <GridLayout
              key={`device-content-${layoutOptions.Platform}`}
              margin={[10, 10]}
              className="Component--grid"
              compactType="vertical"
              cols={12}
              draggableHandle=".Component--drag"
              rowHeight={layoutOptions.RowHeight}
              width={layoutOptions.RowWidth}
              layout={layouts}
              onLayoutChange={this.onLayoutChange}
            >
              {components}
            </GridLayout>
          </React.Fragment>
        );
    }
  };

  public render() {
    const { screen, t } = this.props;
    const { layoutOptions } = this.state;

    if (!screen) {
      return null;
    }

    const applicationScreenDesignerContext: IDesignerContext = {
      layoutOptions,
      onLayoutOptionsChange: (nextLayoutOptions: LayoutOptionsModel) => {
        const { layoutOptions } = this.state;

        switch (nextLayoutOptions.Platform) {
          case PlatformType.iOSPhone:
            nextLayoutOptions.RowWidth = 375;

            if (layoutOptions.Platform !== nextLayoutOptions.Platform) {
              nextLayoutOptions.Zoom = 1;
            }
            break;
          case PlatformType.AndroidPhone:
            nextLayoutOptions.RowWidth = 360;

            if (layoutOptions.Platform !== nextLayoutOptions.Platform) {
              nextLayoutOptions.Zoom = 1;
            }
            break;
          case PlatformType.AndroidTablet:
          case PlatformType.iPad:
            nextLayoutOptions.RowWidth = 510;

            if (layoutOptions.Platform !== nextLayoutOptions.Platform) {
              nextLayoutOptions.Zoom = 1;
            }
            break;
          case PlatformType.AndroidTV:
          case PlatformType.AppleTV:
          case PlatformType.Tizen:
          case PlatformType.WebOS:
            nextLayoutOptions.RowWidth = 600;

            if (layoutOptions.Platform !== nextLayoutOptions.Platform) {
              nextLayoutOptions.Zoom = 1;
            }
            break;
          default:
            nextLayoutOptions.RowWidth = 1280;

            if (layoutOptions.Platform !== nextLayoutOptions.Platform) {
              nextLayoutOptions.Zoom = 0.5;
            }
            break;
        }

        this.setState({ layoutOptions: nextLayoutOptions });
      },
    };

    return (
      <div className="ScreenDesigner">
        <DesignerProvider value={applicationScreenDesignerContext}>
          <DeviceFrame>{this.renderDeviceLayout()}</DeviceFrame>
        </DesignerProvider>
      </div>
    );
  }
}
