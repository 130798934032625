import {
  AdvertisementStore,
  IAdvertisementBlockModel,
  ICommonAppState,
  IStateModel,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AdvertisementBlockDetailsForm as AdvertisementBlockDetailsFormDefinition } from "./AdvertisementBlockDetailsForm";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    actionType: state.advertisement.actionType,
    isProcessingData: state.advertisement.isProcessingData,
    error: state.advertisement.error,
    advertisementBlock: state.advertisement.advertisementBlock,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTemporaryAdvertisementBlock: (
    data: IStateModel<IAdvertisementBlockModel>
  ) => {
    return dispatch(
      AdvertisementStore.Actions.updateTemporaryAdvertisementBlock(data)
    );
  },
});

export const AdvertisementBlockDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdvertisementBlockDetailsFormDefinition));
