import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import {
  IApplicationScreenModel,
  IApplicationsScreensListModel,
  IApplicationsScreensSearchFilterModel,
} from "../../models";
import { IApplicationScreenCopyModel } from "../../models/ApplicationConfiguration/IApplicationScreenCopyModel";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class ApplicationScreenService extends SimpleServiceBase<
  IApplicationScreenModel,
  IApplicationsScreensListModel,
  number,
  IApplicationsScreensSearchFilterModel
> {
  get url(): string {
    return "/ApplicationsScreens";
  }

  public copy = (
    asset: IApplicationScreenCopyModel
  ): Observable<IApplicationScreenCopyModel> =>
    new Observable(
      (observer: Observer<IApplicationScreenCopyModel>) =>
        new AxiosSubscriber(observer, {
          data: asset,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Copy`,
        })
    );
}
