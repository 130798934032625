import React from "react";
import { useTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";

import {
  IApplicationModel,
  IErrorModel,
  ILanguageModel,
  IPlatformModel,
  IResourceModel,
} from "@xala/common-services";
import { Col, Input, Row, Spin, Form, IFormValues } from "@xala/common-ui";
import "./TranslationDetailsForm.scss";
import { Select } from "antd";

export interface ITranslationDetailsFormProps {
  actionType?: string;
  languages: ILanguageModel[];
  platforms: IPlatformModel[];
  applications: IApplicationModel[];
  isProcessingData?: boolean;
  error?: IErrorModel;
  updateResource: ActionCreator<Action>;
  insertResource: ActionCreator<Action>;
  isNewTranslation: boolean;
  translation: IResourceModel;
}

export const TranslationDetailsForm: React.FC<ITranslationDetailsFormProps> = (
  props
) => {
  const {
    translation,
    languages,
    platforms,
    applications,
    isNewTranslation,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  };

  const onSubmit = async (values: IFormValues) => {
    const { updateResource, insertResource } = props;

    if (isNewTranslation) {
      await insertResource(values);
    } else {
      await updateResource(values);
    }

    form.resetFields();
  };

  return (
    <Spin spinning={props.isProcessingData}>
      <Form
        id="TranslationDetailsForm"
        form={form}
        {...formItemLayout}
        onFinish={onSubmit}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="ResourceKey"
              label={t("key", "Key")}
              initialValue={translation.ResourceKey || null}
            >
              <Input
                placeholder={t("Key", "Key")}
                disabled={!isNewTranslation || !!translation.ResourceKey}
              />
            </Form.Item>

            <Form.Item
              name="LanguageCode"
              label={t("language", "Language")}
              initialValue={translation.LanguageCode || null}
              rules={[
                {
                  message: t("please_provide_value", "Please provide value"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Select
                placeholder={t("key", "Key")}
                disabled={!isNewTranslation}
              >
                {languages.map((language) => (
                  <Select.Option key={language.Code} value={language.Code}>
                    {language.Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="PlatformCode"
              label={t("platform", "Platform")}
              initialValue={translation.PlatformCode || null}
              rules={[
                {
                  message: t("please_provide_value", "Please provide value"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Select
                placeholder={t("platform", "Platform")}
                disabled={!isNewTranslation}
              >
                {platforms.map((platform) => (
                  <Select.Option key={platform.Code} value={platform.Code}>
                    {platform.DisplayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="ApplicationKey"
              label={t("application", "Application")}
              initialValue={translation.ApplicationKey || null}
              rules={[
                {
                  message: t("please_provide_value", "Please provide value"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Select
                placeholder={t("application", "Application")}
                disabled={!isNewTranslation}
              >
                {applications.map((application) => (
                  <Select.Option key={application.Key} value={application.Key}>
                    {application.DisplayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="ResourceValue"
              label={t("value", "Value")}
              initialValue={translation.ResourceValue}
              rules={[
                {
                  message: t("please_provide_value", "Please provide value"),
                  required: isNewTranslation,
                },
              ]}
            >
              <Input placeholder={t("value", "Value")} />
            </Form.Item>

            <Form.Item
              name="UrlLayoutDefinition"
              label={t("url", "Url")}
              initialValue={translation.UrlLayoutDefinition}
            >
              <Input placeholder={t("value", "Value")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
