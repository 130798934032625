import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AssetPriceService,
  AssetPurchasePeriodTypeService,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Table,
  Tag,
  DomainTag,
} from "@xala/common-ui";
import { ROUTES } from "../../constants";
import { FormModal } from "../../../../components/FormModal";
import { AssetPriceForm } from "../AssetPriceForm";
import { useHistory } from "react-router";

const notificationService = NotificationService.getInstance();
const assetPriceService = new AssetPriceService().promisify();
const purchasePeriodTypeService = new AssetPurchasePeriodTypeService().promisify();

export const defaultAssetPrice: IAssetPriceModel = {
  Id: -1,
  AssetId: -1,
  AssetPurchasePeriodTypeCode: "",
  Price: 0.1,
  CurrencyId: 1,
  AvailableFrom: "",
};

export const AssetPriceList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [priceFormVisible, setPriceFormVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<IAssetPriceSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
  });

  const [createAssetPrice, createAssetPriceState] = useServiceCaller(
    async (data: IAssetPriceModel) => {
      const result = await assetPriceService.insert(data);

      if (result.ok) {
        setPriceFormVisible(false);
        history.push(`${ROUTES.ASSET_PRICE_DETAILS}/${data.AssetId}`);

        notificationService.success({
          message: t("CREATE_ASSET_PRICE_SUCCESS"),
        });
      } else {
        notificationService.error({
          message: t("CREATE_ASSET_PRICE_FAILURE"),
          description: result?.error?.Message,
        });
      }
    },
    []
  );

  const purchasePeriodTypeLoader = useDataLoader({
    loader: () => purchasePeriodTypeService.select(),
    deps: [],
  });

  const assetPriceLoader = useDataLoader({
    loader: () => assetPriceService.search(filter),
    onError: (error) => {
      notificationService.error({
        message: t("DATA_LOADING_FAILURE"),
        description: error?.Message,
      });
    },
    deps: [filter],
  });

  const pagination: ITablePaginationConfig = {
    current: filter.PageNumber,
    defaultPageSize: 10,
    pageSize: filter.PageSize,
    pageSizeOptions: ["10", "30", "50", "100"],
    showSizeChanger: true,
    showTotal: (total, range) =>
      t("ITEMS_RANGE_LABEL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    total: assetPriceLoader.data?.TotalCount ?? 0,
    onChange: (page: number, pageSize?: number) => {
      setFilter({
        ...filter,
        PageNumber: page,
        PageSize: pageSize ?? filter.PageSize,
      });
    },
  };

  const columns: Array<ITableColumnProps<IAssetPriceModel>> = [
    {
      key: "AssetTitle",
      dataIndex: "AssetTitle",
      title: t("MODEL_TITLE"),
      render: (text: any, row: IAssetPriceModel) => {
        return (
          <Link to={`${ROUTES.ASSET_PRICE_DETAILS}/${row.AssetId}`}>
            {row.AssetTitle}
          </Link>
        );
      },
    },
    {
      key: "Price",
      dataIndex: "Price",
      title: t("MODEL_PRICE"),
      width: "120px",
      align: "center",
    },
    {
      key: "CurrencyCode",
      dataIndex: "CurrencyCode",
      title: t("MODEL_CURRENCY"),
      width: "120px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) => (
        <DomainTag domain="currency" noMargin value={row.CurrencyCode} />
      ),
    },
    {
      key: "AssetPurchasePeriodTypeCode",
      dataIndex: "AssetPurchasePeriodTypeCode",
      title: t("MODEL_ASSET_PURCHASE_PERIOD_NAME"),
      width: "250px",
      align: "center",
      filters: purchasePeriodTypeLoader.data?.map((purchasePeriodType) => ({
        text: purchasePeriodType.DisplayName,
        value: purchasePeriodType.Code,
      })),
      filteredValue: filter.AssetPurchasePeriodTypes || null,
      render: (text: any, row: IAssetPriceModel) => (
        <DomainTag
          domain="purchase-period"
          noMargin
          colorRotate={row.AssetPurchasePeriodTypeCode}
          value={row.AssetPurchasePeriodTypeName}
        />
      ),
    },
    {
      key: "AvailableFrom",
      dataIndex: "AvailableFrom",
      title: t("MODEL_AVAILABLE_FROM"),
      width: "200px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) =>
        row.AvailableFrom ? TimeHelper.format(row.AvailableFrom) : null,
    },
    {
      key: "AvailableTo",
      dataIndex: "AvailableTo",
      title: t("MODEL_AVAILABLE_TO"),
      width: "200px",
      align: "center",
      render: (text: any, row: IAssetPriceModel) =>
        row.AvailableTo ? TimeHelper.format(row.AvailableTo) : null,
    },
  ];

  const onSearch = (value: string) =>
    setFilter({ ...filter, FullTextSearch: value || undefined });

  const onRefreshClick = () => setFilter({ ...filter });

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    setFilter({
      ...filter,
      AssetPurchasePeriodTypes: filters.AssetPurchasePeriodTypeCode?.length
        ? filters.AssetPurchasePeriodTypeCode.map((row) => `${row}`)
        : undefined,
    });
  };

  return (
    <PageContent footer={<Pagination {...pagination} />}>
      <PageHeader
        title={t("ASSET_PRICES_LIST_TITLE")}
        extra={
          <>
            <InputSearch
              placeholder={t("SEARCH_PLACEHOLDER")}
              defaultValue={assetPriceLoader.data?.Filter?.FullTextSearch}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              shape="circle"
              type="primary"
              icon={<Icon type="plus" />}
              onClick={() => {
                setPriceFormVisible(true);
              }}
              disabled={
                assetPriceLoader.loading || createAssetPriceState.processing
              }
              title={t("BUTTON_ADD")}
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={onRefreshClick}
              title={t("BUTTON_REFRESH_TITLE")}
            />
          </>
        }
      />
      <Table<IAssetPriceModel>
        rowKey="Id"
        columns={columns}
        dataSource={assetPriceLoader.data?.Entities}
        loading={assetPriceLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />

      <FormModal
        isLoading={assetPriceLoader.loading}
        isVisible={priceFormVisible}
        isNewForm={true}
        isDeleteButtonEnabled={true}
        createFormTitle={t("ADD_ASSET_PRICE_MODAL_TITLE")}
        editFormTitle={t("EDIT_ASSET_PRICE_MODAL_TITLE")}
        modalClassName="AssetPriceModal"
        submitFormName="AssetPriceForm"
        onCloseModal={() => {
          setPriceFormVisible(false);
        }}
        onDeleteButtonClick={() => {}}
      >
        <AssetPriceForm
          assetPrice={{ ...defaultAssetPrice }}
          onCreate={createAssetPrice}
        />
      </FormModal>
    </PageContent>
  );
};
