import {
  IChannelsForTownModel,
  ITownModel,
  ITownWithChannelsModel,
} from "@xala/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  IChooseLabeledValue,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
  useValidate,
} from "@xala/common-ui";
import { IAppState } from "@xala/studio/src/store";
import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IDictionaryTownFormProps {
  town: ITownWithChannelsModel;
  isProcessing?: boolean;
  updateOrAddNewTown: (
    town: ITownModel,
    channels: IChannelsForTownModel[]
  ) => void;
}

export const DictionaryTownForm = ({
  town,
  updateOrAddNewTown,
  isProcessing,
}: IDictionaryTownFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const validate = useValidate();
  const channels = useSelector(
    (state: IAppState) => state.asset.assets.Entities
  );

  const onFinish = async (values: IFormValues) => {
    const townPayload: ITownModel = {
      ...town.Town,
      Code: values.Code,
      Name: values.Name,
    };

    const channelsPayload = values.Channels.map((el: IChooseLabeledValue) => {
      return { ChannelId: el.value };
    });

    updateOrAddNewTown(townPayload, channelsPayload);
  };

  const fieldOptions: {
    initialValue?: IChooseLabeledValue[];
  } = {
    initialValue: town.Channels?.map((channel) => {
      return {
        key: `${channel.ChannelId}`,
        value: channel.ChannelId,
        label: channel.ChannelName,
      };
    }),
  };

  const chooseOption = channels.map((channel) => (
    <ChooseOption key={channel.Id} value={channel.Id}>
      {channel.Title}
    </ChooseOption>
  ));

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryTownForm"
        className="DictionaryTownForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Code"
              label={t("CLASS_LIST_TABLE_CODE_COLUMN")}
              rules={[required()]}
              initialValue={town.Town.Code}
            >
              <Input placeholder={t("DICTIONARY_TOWN_CODE_PLACEHOLDER")} />
            </Form.Item>
            <Form.Item
              name="Name"
              label={t("CLASS_LIST_TABLE_NAME_COLUMN")}
              rules={[required()]}
              initialValue={town.Town.Name}
            >
              <Input placeholder={t("DICTIONARY_TOWN_NAME_PLACEHOLDER")} />
            </Form.Item>
            <Form.Item
              name="Channels"
              label={t("USER_ACCOUNT_FORM_Channels_LABEL", "Channels")}
              rules={[
                ...validate.required({
                  choiceField: true,
                }),
                ...validate.maxItems(2),
              ]}
              {...fieldOptions}
            >
              <Choose
                mode="multiple"
                labelInValue={true}
                optionFilterProp="children"
                placeholder={t("DICTIONARY_TOWN_CHANNELS_PLACEHOLDER")}
                children={chooseOption}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
