import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationBannerItemProperties as ApplicationBannerItemPropertiesDefinition,
  IApplicationBannerItemPropertiesDispatchProps,
  IApplicationBannerItemPropertiesOwnProps,
  IApplicationBannerItemPropertiesStateProps,
} from "./ApplicationBannerItemProperties";

const mapStateToProps = (
  state: ICommonAppState
): IApplicationBannerItemPropertiesStateProps => {
  return {
    isProcessingData: state.applicationConfiguration.isProcessingData,
    categories: state.asset.assetCategories,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationBannerItemPropertiesDispatchProps => ({});

export const ApplicationBannerItemProperties = connect<
  IApplicationBannerItemPropertiesStateProps,
  IApplicationBannerItemPropertiesDispatchProps,
  IApplicationBannerItemPropertiesOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationBannerItemPropertiesDefinition));
