export enum CellType {
  Default = "DEFAULT",
  Highlights = "HIGHLIGHTS",
  HeroLandscape = "LANDSCAPE",
  Cover = "COVER",
  Frame = "FRAME",
  Promo = "PROMO",
  Button = "BUTTON",
  Square = "SQUARE",
  Round = "ROUND",
  Channel = "CHANNEL",
}
