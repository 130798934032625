import { DatePicker as AntDatePicker } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import AntMoment from "antd/node_modules/moment";

import "./DatePickerPager.scss";

export declare type IDatePickerValue = AntMoment.Moment;

export enum DatePickerPagerType {
  Days = "days",
  Months = "months",
  Years = "years",
}

export declare type IDatePickerPagerProps = {
  defaultValue?: AntMoment.Moment | Date | string | number;
  pager?: DatePickerPagerType;
  bordered?: boolean;
  onChange?: (value: Date | undefined) => void;
};

export const DatePickerPager: React.FC<IDatePickerPagerProps> = ({
  pager = DatePickerPagerType.Days,
  bordered = false,
  defaultValue,
  onChange,
}) => {
  const innerDefaultValue = defaultValue ? AntMoment(defaultValue) : undefined;
  const [innerValue, setInnerValue] = useState<IDatePickerValue | undefined>(
    innerDefaultValue
  );

  const setValue = (value: IDatePickerValue | null) => {
    setInnerValue(value ? value : undefined);
    onChange?.(value ? value.toDate() : undefined);
  };

  const datePicker = (
    <AntDatePicker
      value={innerValue}
      bordered={bordered}
      defaultValue={innerDefaultValue}
      onChange={(value) => setValue(value)}
    />
  );

  return (
    <div className="date-picker-pager">
      <div
        className="pager-icon left"
        onClick={() => {
          if (innerValue) {
            const newValue = AntMoment(innerValue).add(-1, pager);
            setValue(newValue);
          }
        }}
      >
        <LeftOutlined />
      </div>
      <div className="pager-date-picker-container">{datePicker}</div>
      <div
        className="pager-icon"
        onClick={() => {
          if (innerValue) {
            const newValue = AntMoment(innerValue).add(1, pager);
            setValue(newValue);
          }
        }}
      >
        <RightOutlined />
      </div>
    </div>
  );
};
