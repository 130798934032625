import { IAdvertisementBlockModel, IStateModel } from "@xala/common-services";
import { required, Col, Form, Input, Row } from "@xala/common-ui";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { WithTranslation } from "react-i18next";
import { advertisementBlockStatusHelper as utils } from "../../helpers";
import "./AdvertisementBlockDetailsForm.scss";

export interface IAdvertisementBlockDetailsFormProps extends WithTranslation {
  actionType?: string;
  advertisementBlock: IStateModel<IAdvertisementBlockModel>;
  isActive: boolean;
  updateTemporaryAdvertisementBlock: any;
}

export class AdvertisementBlockDetailsForm extends React.PureComponent<
  IAdvertisementBlockDetailsFormProps
> {
  public static defaultProps = {
    isActive: false,
  };

  public renderDescriptionField = () => {
    const { t, advertisementBlock } = this.props;

    return (
      <Form.Item
        name="Description"
        label={t("DESCRIPTION")}
        initialValue={advertisementBlock.Data?.Description}
      >
        <TextArea
          rows={5}
          disabled={!utils.isEditable(advertisementBlock)}
          placeholder={t("ENTER_DESCRIPTION")}
          value={advertisementBlock.Data?.Description}
          onChange={this.onUpdateDescription}
        />
      </Form.Item>
    );
  };

  public onUpdateName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { updateTemporaryAdvertisementBlock } = this.props;
    const block = this.props.advertisementBlock;
    if (block.Data) {
      block.Data.Name = e.currentTarget.value;
      updateTemporaryAdvertisementBlock(block);
    }
  };

  public onUpdateDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const { updateTemporaryAdvertisementBlock } = this.props;
    const block = this.props.advertisementBlock;
    if (block.Data) {
      block.Data.Description = e.currentTarget.value;
      updateTemporaryAdvertisementBlock(block);
    }
  };

  public renderNameField = () => {
    const { t, advertisementBlock } = this.props;

    return (
      <Form.Item
        name="Name"
        label={t("NAME")}
        initialValue={advertisementBlock.Data?.Name}
        rules={[required()]}
      >
        <Input
          disabled={!utils.isEditable(advertisementBlock)}
          placeholder={t("ENTER_NAME")}
          value={advertisementBlock.Data?.Name}
          onChange={this.onUpdateName}
        />
      </Form.Item>
    );
  };

  public render() {
    const { advertisementBlock, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
        lg: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 18 },
        lg: { span: 12 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
        md: {
          span: 18,
          offset: 6,
        },
        lg: {
          span: 21,
          offset: 3,
        },
      },
    };

    return (
      <Form
        name="AdvertisementBlockDetailsForm"
        {...formItemLayout}
        className="AdvertisementBlockDetailsForm"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item {...tailFormItemLayout}>
              <h1>{t("GENERAL_INFORMATION")}</h1>
            </Form.Item>
            {this.renderNameField()}
            {this.renderDescriptionField()}

            <Form.Item {...tailFormItemLayout}>
              <h4>{t("URL")}</h4>
              {advertisementBlock.Data?.Url}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <h4>{t("CREATION_DATE")}</h4>
              {advertisementBlock.Data?.Created}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <h4>{t("CREATED_BY")}</h4>
              {advertisementBlock.Data?.CreatedByFullName}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <h4>{t("ID")}</h4>
              {advertisementBlock.Data?.Id}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <h4>{t("LAST_MODIFICATION_DATE")}</h4>
              {advertisementBlock.Data?.Modified}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <h4>{t("MODIFIED_BY")}</h4>
              {advertisementBlock.Data?.ModifiedByFullName}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <h4>{t("STATUS")}</h4>
              {advertisementBlock.Data?.Status}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
