import {
  ApplicationConfigurationModule,
  AssetModule,
  DictionariesModule,
  PaymentModule,
  ProductModule,
  TranslationsModule,
  UserModule,
  MediaChannelModule,
  NotificationModule,
} from "@xala/common-cms";
import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import { BooleanHelper } from "../../helpers";
import i18n from "../../i18n";
import "../../sass/App.scss";
import {
  ChangeForgottenPassword,
  ForgotPassword,
  ForgotPasswordSuccess,
  Login,
} from "../Auth";
import { AnimatedBackground } from "../Auth/components/AuthContainer/AnimatedBackground";
import { AuthenticatedRoute } from "../AuthenticatedRoute";
import { Dashboard } from "../Dashboard";

interface IProps {
  history: History;
}

const featureFlags = {
  mediaChannelsEnabled: BooleanHelper.toBool(
    process.env.REACT_APP_FEATURE_FLAG_MEDIA_CHANNELS,
    true
  ),
};

const HOME = ROUTES.DASHBOARD;
export class App extends React.Component<IProps> {
  public render() {
    const { history } = this.props;

    return (
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <Suspense fallback="Loading">
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  const path = HOME;
                  return <Redirect to={path} />;
                }}
              />

              {/*
               Common route for all auth pages is needed to not reset
               background animation when navigating between these routes
               */}
              <Route
                path={[
                  ROUTES.LOGIN,
                  ROUTES.RESET_PASSWORD,
                  ROUTES.RESET_PASSWORD_SUCCESS,
                  ROUTES.AUTH_RESET_PASSWORD,
                ]}
              >
                <AnimatedBackground />

                <Route path={ROUTES.LOGIN} component={Login} />

                <Route
                  path={ROUTES.RESET_PASSWORD}
                  component={ForgotPassword}
                />

                <Route
                  path={ROUTES.RESET_PASSWORD_SUCCESS}
                  component={ForgotPasswordSuccess}
                />

                <Route
                  path={ROUTES.AUTH_RESET_PASSWORD}
                  component={ChangeForgottenPassword}
                />
              </Route>

              <AuthenticatedRoute
                exact
                path={ROUTES.DASHBOARD}
                component={Dashboard}
              />

              <AuthenticatedRoute
                exact
                path={`${UserModule.ROUTES.USER_DETAILS}/:id`}
                component={UserModule.Components.UserDetails}
              />

              <AuthenticatedRoute
                exact
                path={UserModule.ROUTES.CUSTOMER_LIST}
                component={UserModule.Components.CustomersList}
              />

              <AuthenticatedRoute
                exact
                path={`${UserModule.ROUTES.CUSTOMER_DETAILS}/:id`}
                component={UserModule.Components.CustomerDetails}
              />

              <AuthenticatedRoute
                exact
                path={UserModule.ROUTES.ADMINISTRATOR_LIST}
                component={UserModule.Components.AdministratorsList}
              />

              <AuthenticatedRoute
                exact
                path={`${UserModule.ROUTES.ADMINISTRATOR_DETAILS}/:id`}
                component={UserModule.Components.AdministratorDetails}
              />

              <Redirect
                exact
                path={ApplicationConfigurationModule.ROUTES.CONFIGURATION_BASE}
                to={ApplicationConfigurationModule.ROUTES.CONFIGURATION_LIST}
              />

              <AuthenticatedRoute
                exact
                path={ApplicationConfigurationModule.ROUTES.CONFIGURATION_LIST}
                component={
                  ApplicationConfigurationModule.Components
                    .ApplicationsConfigurationsList
                }
              />

              <AuthenticatedRoute
                exact
                path={`${ApplicationConfigurationModule.ROUTES.CONFIGURATION_DETAILS}/:id`}
                component={
                  ApplicationConfigurationModule.Components
                    .ApplicationConfigurationDetails
                }
              />

              <AuthenticatedRoute
                exact
                path={`${ApplicationConfigurationModule.ROUTES.CONFIGURATION_SCREEN_DETAILS}/:id`}
                component={
                  ApplicationConfigurationModule.Components
                    .ApplicationScreenDetails
                }
              />

              <AuthenticatedRoute
                exact
                path={
                  ApplicationConfigurationModule.ROUTES
                    .CONFIGURATION_SCREEN_TYPES_LIST
                }
                component={
                  ApplicationConfigurationModule.Components
                    .ApplicationScreensTypesList
                }
              />

              <AuthenticatedRoute
                exact
                path={
                  ApplicationConfigurationModule.ROUTES
                    .CONFIGURATION_COMPONENT_TYPES_LIST
                }
                component={
                  ApplicationConfigurationModule.Components
                    .ApplicationComponentsTypesList
                }
              />

              <Redirect
                exact
                path={AssetModule.ROUTES.ASSET_BASE}
                to={AssetModule.ROUTES.ASSET_LIST}
              />

              <AuthenticatedRoute
                exact
                path={[
                  AssetModule.ROUTES.ASSET_LIST,
                  `${AssetModule.ROUTES.ASSET_LIST}/:type`,
                ]}
                component={AssetModule.Components.AssetsList}
              />

              <AuthenticatedRoute
                exact
                path={`${AssetModule.ROUTES.ASSET_DETAILS}/:id`}
                component={AssetModule.Components.AssetDetails}
              />

              <AuthenticatedRoute
                exact
                path={AssetModule.ROUTES.ASSET_COLLECTION_LIST}
                component={AssetModule.Components.AssetsCollectionsList}
              />

              <AuthenticatedRoute
                exact
                path={`${AssetModule.ROUTES.ASSET_COLLECTION_DETAILS}/:id`}
                component={AssetModule.Components.AssetCollectionDetails}
              />

              <AuthenticatedRoute
                exact
                path={`${AssetModule.ROUTES.ASSET_CATEGORIES}`}
                component={AssetModule.Components.AssetCategoryList}
              />

              <AuthenticatedRoute
                exact
                path={`${AssetModule.ROUTES.ASSET_PRICE_LIST}`}
                component={AssetModule.Components.AssetPriceList}
              />

              <AuthenticatedRoute
                exact
                path={`${AssetModule.ROUTES.ASSET_PRICE_DETAILS}/:id`}
                component={AssetModule.Components.AssetPriceDetails}
              />

              <AuthenticatedRoute
                exact
                path={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/:resourceKey`}
                component={TranslationsModule.Components.TranslationDetails}
              />

              <Redirect
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_BASE}
                to={DictionariesModule.ROUTES.DICTIONARY_APPLICATION_LIST}
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_APPLICATION_LIST}
                component={
                  DictionariesModule.Components.DictionaryApplicationList
                }
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_LANGUAGE_LIST}
                component={DictionariesModule.Components.DictionaryLanguageList}
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_CURRENCY_LIST}
                component={DictionariesModule.Components.DictionaryCurrencyList}
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_PEOPLE}
                component={DictionariesModule.Components.DictionaryPeople}
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_PLATFORM_LIST}
                component={DictionariesModule.Components.DictionaryPlatformList}
              />

              <AuthenticatedRoute
                exact
                path={
                  DictionariesModule.ROUTES
                    .DICTIONARY_VIDEO_AGE_RESTRICTION_LIST
                }
                component={
                  DictionariesModule.Components.DictionaryAgeRestrictionList
                }
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_CONSENT_LIST}
                component={DictionariesModule.Components.ConsentList}
              />

              <AuthenticatedRoute
                exact
                path={
                  DictionariesModule.ROUTES
                    .DICTIONARY_ASSET_PURCHASE_PERIOD_TYPE_LIST
                }
                component={
                  DictionariesModule.Components
                    .DictionaryAssetPurchasePeriodTypeList
                }
              />

              <AuthenticatedRoute
                exact
                path={DictionariesModule.ROUTES.DICTIONARY_TOWNS_LIST}
                component={DictionariesModule.Components.DictionaryTownsList}
              />

              <AuthenticatedRoute
                exact
                path={`${NotificationModule.ROUTES.NOTIFICATION_DETAILS}/:id`}
                component={NotificationModule.Components.NotificationDetails}
              />

              <AuthenticatedRoute
                exact
                path={NotificationModule.ROUTES.NOTIFICATION_LIST}
                component={NotificationModule.Components.NotificationList}
              />

              <AuthenticatedRoute
                exact
                path={ProductModule.ROUTES.PRODUCTS_LIST}
                component={ProductModule.Components.ProductsList}
              />

              <AuthenticatedRoute
                exact
                path={`${ProductModule.ROUTES.PRODUCTS_DETAILS}/:id`}
                component={ProductModule.Components.ProductDetails}
              />

              <AuthenticatedRoute
                exact
                path={`${PaymentModule.ROUTES.PAYMENT_LIST}`}
                component={PaymentModule.Components.PaymentList}
              />

              <AuthenticatedRoute
                exact
                path={`${PaymentModule.ROUTES.SALES_REVENUE}`}
                component={PaymentModule.Components.SalesRevenue}
              />

              <AuthenticatedRoute
                exact
                path={`${PaymentModule.ROUTES.PAYMENT_DETAILS}/:id`}
                component={PaymentModule.Components.PaymentDetails}
              />

              {featureFlags.mediaChannelsEnabled && (
                <AuthenticatedRoute
                  exact
                  path={`${MediaChannelModule.ROUTES.CHANNEL_LIST}`}
                  component={MediaChannelModule.Components.MediaChannelList}
                />
              )}

              {featureFlags.mediaChannelsEnabled && (
                <AuthenticatedRoute
                  exact
                  path={`${MediaChannelModule.ROUTES.CHANNEL_DETAILS}/:id`}
                  component={MediaChannelModule.Components.MediaChannelDetails}
                />
              )}
            </Switch>
          </Suspense>
        </ConnectedRouter>
      </I18nextProvider>
    );
  }
}
