import {
  ComponentTypeHelper,
  IApplicationComponentModel,
  IApplicationScreenModel,
} from "@xala/common-services";
import {
  Avatar,
  Col,
  Dropdown,
  Icon,
  Menu,
  MenuItem,
  Row,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenContextProps } from "../../../../context";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationHeaderItemPropertyModel } from "../../models";
import { ApplicationHeaderItemPreview } from "../ApplicationHeaderItemPreview";
import "./ApplicationHeaderPreview.scss";

export interface IApplicationHeaderPreviewStateProps {}

export interface IApplicationHeaderPreviewDispatchProps {}

export interface IApplicationHeaderPreviewOwnProps {
  applicationHeaderScreen: IApplicationScreenModel;
}

export interface IApplicationHeaderPreviewProps
  extends IApplicationHeaderPreviewStateProps,
    IApplicationHeaderPreviewDispatchProps,
    IApplicationHeaderPreviewOwnProps,
    WithTranslation,
    WithScreenDesignerContextProps,
    WithScreenContextProps {}

export interface IApplicationHeaderPreviewState {}

export class ApplicationHeaderPreview extends React.PureComponent<
  IApplicationHeaderPreviewProps,
  IApplicationHeaderPreviewState
> {
  public static defaultProps = {};

  public state: Readonly<IApplicationHeaderPreviewState> = {};

  public onComponentSelect = (componentId: number) => {
    const { applicationHeaderScreen, screen, onComponentSelect } = this.props;

    if (
      screen &&
      screen.Id === applicationHeaderScreen.Id &&
      screen.Components &&
      onComponentSelect
    ) {
      const component = screen.Components.find(
        (row: IApplicationComponentModel) => row.Id === componentId
      );

      onComponentSelect(component);
    }
  };

  public renderApplicationHeaderItems = () => {
    const { applicationHeaderScreen, screen, component, t } = this.props;

    const itemsView: React.ReactNode[] = [];
    const moreMenuItemsView: React.ReactNode[] = [];
    const moreMenuView: React.ReactNode = (
      <Dropdown
        key="dropdown"
        placement="bottomRight"
        trigger={["click"]}
        overlay={<Menu>{moreMenuItemsView}</Menu>}
      >
        <Icon
          type="more"
          className="ApplicationHeaderPreview__Items--Dropdown--Icon"
        />
      </Dropdown>
    );

    if (applicationHeaderScreen.Components) {
      for (let i = 0; i < applicationHeaderScreen.Components.length; i++) {
        const screenComponent = applicationHeaderScreen.Components[i];

        const properties = new ApplicationHeaderItemPropertyModel(
          screenComponent
        );

        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        if (i < 2) {
          itemsView.push(
            <div
              key={screenComponent.Id}
              style={style}
              className="Component"
              onClick={() => this.onComponentSelect(screenComponent.Id)}
            >
              <div className="Component--content">
                <div className="Component--options"></div>
                <div className="Component--preview">
                  <ApplicationHeaderItemPreview
                    key={screenComponent.Id}
                    component={screenComponent}
                    properties={properties}
                  />
                </div>
              </div>
            </div>
          );
        }

        if (i === 2) {
          itemsView.push(moreMenuView);
        }

        if (i >= 2) {
          moreMenuItemsView.push(
            <div
              key={screenComponent.Id}
              style={style}
              className="Component"
              onClick={() => this.onComponentSelect(screenComponent.Id)}
            >
              <div className="Component--content">
                <div className="Component--options"></div>
                <div className="Component--preview">
                  <MenuItem>
                    <ApplicationHeaderItemPreview
                      key={screenComponent.Id}
                      component={screenComponent}
                      properties={properties}
                      withTitle={true}
                    />
                  </MenuItem>
                </div>
              </div>
            </div>
          );
        }
      }
    }

    return itemsView;
  };

  public render() {
    const { t } = this.props;

    return (
      <div className="ApplicationHeaderPreview">
        <Row align="middle" direction="row" wrap="nowrap" gutter={[8, 8]}>
          <Col>
            <Avatar
              icon="user"
              className="ApplicationHeaderPreview__Avatar"
            ></Avatar>
          </Col>
          <Col style={{ width: "100%" }}>
            <h3 className="ApplicationHeaderPreview__Title">{t("App Name")}</h3>
          </Col>
          <Col className="ApplicationHeaderPreview__Items">
            {this.renderApplicationHeaderItems()}
          </Col>
        </Row>
      </div>
    );
  }
}
