import { useDataLoader, AssetService } from "@xala/common-services";
import { NotificationService } from "@xala/common-ui";
import { useTranslation } from "react-i18next";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

export const useAssetTypesDataProvider = () => {
  const { t } = useTranslation();
  const { data, loading } = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    onError: (error) =>
      notificationService.error({
        message: error.Title,
        description: error.Message,
      }),
    debounce: 500,
    deps: [],
  });
  return {
    assets:
      data?.map((asset) => ({
        key: asset.Code,
        value: asset.Code,
        label: t(asset.TranslationKey),
      })) || [],
    loading,
  };
};
