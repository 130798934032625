import { AssetStore, IAssetCategoryModel, RecordStatus } from "@xala/common-services";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssetCategoryForm } from "../AssetCategoryForm";
import { useDispatch } from "react-redux";
import { FormModal } from "../../../../components";

interface AssetCategoryModalProps {
  loading: boolean;
  visible: boolean;
  isNewCategory: boolean;
  selectedCategory: IAssetCategoryModel;
  closeModal: () => void;
}

export const AssetCategoryModal: React.FC<AssetCategoryModalProps> = (
  props
) => {
  const {
    visible,
    isNewCategory,
    selectedCategory,
    closeModal,
    loading,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDeleteAssetCategory = () => {
    dispatch(AssetStore.Actions.deleteAssetCategory({ ...selectedCategory, RecordStatus: RecordStatus.Deleted }));
  };

  return (
    <FormModal
      createFormTitle={t("AddCategory", "Add new category")}
      editFormTitle={t("EditCategory", "Edit category")}
      modalClassName="AssetCategoryForm"
      submitFormName="AssetCategoryForm"
      isLoading={loading}
      isVisible={visible}
      isNewForm={isNewCategory}
      isDeleteButtonEnabled={!isNewCategory}
      onCloseModal={() => closeModal()}
      onDeleteButtonClick={onDeleteAssetCategory}
    >
      <AssetCategoryForm
        category={selectedCategory}
        isNewCategory={isNewCategory}
      />
    </FormModal>
  );
};
