import {
  AdvertisementStore,
  IAdvertisementBlockModel,
  IErrorModel,
  IStateModel,
} from "@xala/common-services";
import {
  Button,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  Spin,
  TabPane,
  Tabs,
} from "@xala/common-ui";
import { ROUTES } from "../../constants";
import {
  advertisementBlockGenerationHelper,
  advertisementBlockStatusHelper as utils,
} from "../../helpers";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ActionCreator } from "redux";
import { AdvertisementBlockDetailsForm } from "../AdvertisementBlockDetailsForm";
import { AdvertisementBoardsList } from "../AdvertisementBoardsList";
import "./AdvertisementBlockDetails.scss";

const notificationService = NotificationService.getInstance();

export interface IAdvertisementBlockDetailsStateProps extends WithTranslation {
  actionType?: string;
  advertisementBlock: IStateModel<IAdvertisementBlockModel>;
  error?: IErrorModel;
}

export interface IAdvertisementBlockDetailsDispatchProps {
  getAdvertisementBlock: ActionCreator<
    AdvertisementStore.Types.IGetAdvertisementBlockAction
  >;
  updateAdvertisementBlockWithDetails: ActionCreator<
    AdvertisementStore.Types.IUpdateAdvertisementBlockWithDetailsAction
  >;
  deleteAdvertisementBlocks: any;
  publishAdvertisementBlock: ActionCreator<
    AdvertisementStore.Types.IPublishAdvertisementBlockAction
  >;
  archiveAdvertisementBlock: ActionCreator<
    AdvertisementStore.Types.IArchiveAdvertisementBlockAction
  >;
  restoreAdvertisementBlock: ActionCreator<
    AdvertisementStore.Types.IRestoreAdvertisementBlockAction
  >;
}

export interface IAdvertisementBlockDetailsOwnProps {}

export interface IAdvertisementBlockDetailsProps
  extends IAdvertisementBlockDetailsStateProps,
    IAdvertisementBlockDetailsDispatchProps,
    IAdvertisementBlockDetailsOwnProps,
    WithTranslation,
    RouteComponentProps<{ id: string }> {}

export interface IAdvertisementBlockDetailsState {
  activeTabKey: string;
  shouldExitAfterSave: boolean;
}

export class AdvertisementBlockDetails extends React.Component<
  IAdvertisementBlockDetailsProps,
  IAdvertisementBlockDetailsState
> {
  public state: Readonly<IAdvertisementBlockDetailsState> = {
    activeTabKey:
      new URLSearchParams(this.props.location.search).get("tab") ?? "DETAILS",
    shouldExitAfterSave: false,
  };

  public componentDidMount() {
    const { getAdvertisementBlock, match } = this.props;

    getAdvertisementBlock(match.params.id);
  }

  componentDidUpdate(prevProps: IAdvertisementBlockDetailsProps) {
    const { actionType, error, t } = this.props;

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case AdvertisementStore.Consts
        .UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_FAILURE:
        notificationService.error({
          message: t("SAVE_FAILURE"),
          description: error ? error.Message : undefined,
        });
        if (this.state.shouldExitAfterSave) {
          window.history.back();
        }
        break;
      case AdvertisementStore.Consts
        .UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_SUCCESS:
        notificationService.success({
          message: t("SAVE_SUCCESS"),
        });
        if (this.state.shouldExitAfterSave) {
          window.history.back();
        }
        break;
      case AdvertisementStore.Consts.DELETE_ADVERTISEMENT_BLOCK_FAILURE:
        return notificationService.error({
          message: t("DELETE_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case AdvertisementStore.Consts.DELETE_ADVERTISEMENT_BLOCK_SUCCESS:
        this.props.history.push(ROUTES.ADVERTISEMENT_BLOCK_LIST);
        return notificationService.success({
          message: t("DELETE_SUCCESS"),
        });
      case AdvertisementStore.Consts.PUBLISH_ADVERTISEMENT_BLOCK_FAILURE:
        return notificationService.error({
          message: t("PUBLISH_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case AdvertisementStore.Consts.PUBLISH_ADVERTISEMENT_BLOCK_SUCCESS:
        return notificationService.success({
          message: t("PUBLISH_SUCCESS"),
        });
      case AdvertisementStore.Consts.ARCHIVE_ADVERTISEMENT_BLOCK_FAILURE:
        return notificationService.error({
          message: t("ARCHIVE_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case AdvertisementStore.Consts.ARCHIVE_ADVERTISEMENT_BLOCK_SUCCESS:
        return notificationService.success({
          message: t("ARCHIVE_SUCCESS"),
        });
      case AdvertisementStore.Consts.RESTORE_ADVERTISEMENT_BLOCK_FAILURE:
        return notificationService.error({
          message: t("RESTORE_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case AdvertisementStore.Consts.RESTORE_ADVERTISEMENT_BLOCK_SUCCESS:
        return notificationService.success({
          message: t("RESTORE_SUCCESS"),
        });
      default:
        break;
    }
  }

  private onTabClick = (key: string) => {
    this.setState({ activeTabKey: key });
  };

  onRefreshClick = () => {
    const { getAdvertisementBlock, match } = this.props;

    getAdvertisementBlock(match.params.id);
  };
  onPublishClick = () => {
    const { publishAdvertisementBlock, advertisementBlock } = this.props;
    const { t } = this.props;

    const boards = advertisementBlock.Data?.Boards;
    const AdBlockId = advertisementBlock.Data?.Id;
    let html = "<html><body></body></html>";

    if (boards && AdBlockId) {
      const translations = {
        HBBTV_FILL_IN_PHONE_NUMBER: t("HBBTV_FILL_IN_PHONE_NUMBER"),
        HBBTV_FILL_IN_EMAIL_ADDRESS: t("HBBTV_FILL_IN_EMAIL_ADDRESS"),
        HBBTV_CLOSE: t("HBBTV_CLOSE"),
        HBBTV_NEXT: t("HBBTV_NEXT"),
        HBBTV_PREVIOUS: t("HBBTV_PREVIOUS"),
        "HBBTV_CONFIRM/KEYBOARD": t("HBBTV_CONFIRM/KEYBOARD"),
        HBBTV_CORRECT_VALUE: t("HBBTV_CORRECT_VALUE"),
      };
      const generationHelper = new advertisementBlockGenerationHelper(
        boards,
        translations,
        AdBlockId
      );
      html = generationHelper.getHbbTemplate();
    }

    publishAdvertisementBlock(advertisementBlock.Data, html);
  };

  onArchiveClick = () => {
    const { archiveAdvertisementBlock, advertisementBlock } = this.props;

    archiveAdvertisementBlock(advertisementBlock.Data);
  };

  onRestoreClick = () => {
    const { restoreAdvertisementBlock, advertisementBlock } = this.props;

    restoreAdvertisementBlock(advertisementBlock.Data);
  };

  onSaveClick = () => {
    const {
      updateAdvertisementBlockWithDetails,
      advertisementBlock,
    } = this.props;

    updateAdvertisementBlockWithDetails(advertisementBlock);
  };

  public onDeleteClick = () => {
    const { advertisementBlock, t } = this.props;

    Modal.confirm({
      title: t("DELETE_ADVERTISEMENT_BLOCK"),
      content: t("BLOCK_DETAILS_DELETE_ACTION_MESSAGE", {
        advertisementBlockName: advertisementBlock.Data?.Name,
      }),
      okText: t("OK"),
      cancelText: t("CANCEL"),
      onOk: this.onConfirmDelete,
    });
  };

  public onConfirmDelete = () => {
    const { advertisementBlock, deleteAdvertisementBlocks } = this.props;

    deleteAdvertisementBlocks(advertisementBlock.Data);
  };

  renderActionSave = () => {
    const { advertisementBlock, t } = this.props;

    if (!utils.isEditable(advertisementBlock)) {
      return null;
    }

    return (
      <Button
        key="action-save"
        icon={<Icon type="save" />}
        title={t("SAVE_DATA")}
        type="primary"
        htmlType="submit"
        loading={advertisementBlock.IsProcessing}
        disabled={advertisementBlock.IsProcessing}
        onClick={this.onSaveClick}
      >
        {t("BUTTON_SAVE")}
      </Button>
    );
  };

  renderActionPublish = () => {
    const { advertisementBlock, t } = this.props;

    if (!utils.isEditable(advertisementBlock)) {
      return null;
    }

    return (
      <Button
        key="action-publish"
        icon={<Icon type="play-square" />}
        title={t("PUBLISH_DATA")}
        type="primary"
        htmlType="submit"
        loading={advertisementBlock.IsProcessing}
        onClick={this.onPublishClick}
      >
        {t("BUTTON_PUBLISH")}
      </Button>
    );
  };

  renderActionArchive = () => {
    const { advertisementBlock, t } = this.props;

    if (
      !advertisementBlock.Data ||
      advertisementBlock.IsProcessing ||
      !utils.isBlockPublished(advertisementBlock)
    ) {
      return null;
    }

    return (
      <Button
        key="action-archive"
        icon={<Icon type="eye-invisible" />}
        title={t("ARCHIVE_DATA")}
        loading={advertisementBlock.IsProcessing}
        onClick={this.onArchiveClick}
      >
        {t("BUTTON_ARCHIVE")}
      </Button>
    );
  };

  renderActionRestore = () => {
    const { advertisementBlock, t } = this.props;

    if (
      !advertisementBlock.Data ||
      advertisementBlock.IsProcessing ||
      !utils.isBlockArchived(advertisementBlock)
    ) {
      return null;
    }

    return (
      <Button
        key="action-restore"
        icon={<Icon type="eye" />}
        title={t("RESTORE_DATA")}
        loading={advertisementBlock.IsProcessing}
        onClick={this.onRestoreClick}
      >
        {t("BUTTON_RESTORE")}
      </Button>
    );
  };

  renderActionDelete = () => {
    const { advertisementBlock, t } = this.props;

    if (!utils.isEditable(advertisementBlock)) {
      return null;
    }

    return (
      <Button
        key="action-delete"
        icon={<Icon type="delete" />}
        title={t("DELETE_DATA")}
        loading={advertisementBlock.IsProcessing}
        onClick={this.onDeleteClick}
      >
        {t("BUTTON_DELETE")}
      </Button>
    );
  };

  onScreenExit = () => {
    const { advertisementBlock, t } = this.props;

    if (!utils.isEditable(advertisementBlock)) {
      return window.history.back();
    }

    Modal.confirm({
      title: "",
      content: t("DETAILS_EXIT_ACTION_MESSAGE"),
      okText: t("SAVE"),
      cancelText: t("CANCEL"),
      onOk: this.saveAndExit,
      onCancel: () => {
        window.history.back();
      },
    });
  };

  saveAndExit = () => {
    this.setState({ shouldExitAfterSave: true });
    this.onSaveClick();
  };

  public render() {
    const { advertisementBlock, t } = this.props;
    const { activeTabKey } = this.state;

    return (
      <div className="AdvertisementBlockDetails">
        <Spin spinning={advertisementBlock.IsProcessing}>
          <PageHeader
            title={advertisementBlock.Data?.Name}
            onBack={this.onScreenExit}
            extra={
              <>
                {this.renderActionSave()}
                {this.renderActionPublish()}
                {this.renderActionArchive()}
                {this.renderActionRestore()}
                {this.renderActionDelete()}
                <Button
                  key="action-reload"
                  icon={<Icon type="reload" />}
                  title={t("REFRESH_DATA")}
                  onClick={this.onRefreshClick}
                />
              </>
            }
          ></PageHeader>
          <PageContent>
            <Tabs
              defaultActiveKey={activeTabKey}
              tabPosition="left"
              tabBarStyle={{ textAlign: "left", minWidth: "180px" }}
              onTabClick={this.onTabClick}
            >
              <TabPane key="DETAILS" tab={t("DETAILS")}>
                <AdvertisementBlockDetailsForm
                  key={`AdvertisementBlockDetailsForm-${advertisementBlock.Data?.Id}`}
                  isActive={activeTabKey === "DETAILS"}
                />
              </TabPane>
              <TabPane
                key="ADVERTISEMENT_BOARDS"
                tab={t("ADVERTISEMENT_BOARDS")}
              >
                <AdvertisementBoardsList
                  key={`AdvertisementBoardsList-${advertisementBlock.Data?.Id}`}
                  advertisementBlockId={advertisementBlock.Data?.Id}
                />
              </TabPane>
            </Tabs>
          </PageContent>
        </Spin>
      </div>
    );
  }
}
