import {
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  CommonStore,
  IApplicationModel,
  IErrorModel,
  ILanguageModel,
  IPlatformModel,
  IResourceModel,
  IResourcesListModel,
  IResourcesSearchFilterModel,
  PlatformType,
} from "@xala/common-services";
import {
  Button,
  Dropdown,
  IPaginationProps,
  ITableColumnProps,
  ITableFilter,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  NotificationService,
  PageContent,
  PageHeader,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  Tag,
} from "@xala/common-ui";
import { Pagination } from "antd";
import { first } from "lodash";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Action, ActionCreator } from "redux";
import { TranslationDetailsModal } from "../TranslationDetailsModal";
import "./TranslationDetails.scss";

const notificationService = NotificationService.getInstance();

export interface ITranslationDetailsProps
  extends WithTranslation,
    RouteComponentProps<{ resourceKey: string }> {
  actionType?: string;
  resourceKey: string;
  resources: IResourcesListModel;
  languages: ILanguageModel[];
  platforms: IPlatformModel[];
  applications: IApplicationModel[];
  isLoadingData: boolean;
  goToDetails: ActionCreator<Action>;
  selectLanguages: ActionCreator<Action>;
  selectResource: ActionCreator<Action>;
  selectApplications: ActionCreator<Action>;
  selectPlatforms: ActionCreator<Action>;
  deleteResourcesByKey: ActionCreator<Action>;
  error?: IErrorModel;
}

interface ITranslationDetailsState {
  translation: IResourceModel;
  isOpenFormModal: boolean;
  isNewTranslation: boolean;
  pageSize: number;
  pageNumber: number;
  filters: IResourcesSearchFilterModel;
}

export class TranslationDetails extends React.PureComponent<
  ITranslationDetailsProps,
  ITranslationDetailsState
> {
  public resourceKey = this.props.match.params.resourceKey;

  private emptyTranslation: IResourceModel = {
    ApplicationKey: "",
    LanguageCode: "",
    LanguageName: "",
    OriginalValue: "",
    PlatformCode: PlatformType.Any,
    ResourceKey: "",
    ResourceValue: "",
  };

  public state: Readonly<ITranslationDetailsState> = {
    filters: {},
    isNewTranslation: false,
    isOpenFormModal: false,
    pageNumber: 1,
    pageSize: 10,
    translation: { ...this.emptyTranslation },
  };

  public componentDidMount() {
    const {
      languages,
      selectResource,
      selectLanguages,
      applications,
      selectApplications,
      platforms,
      selectPlatforms,
    } = this.props;

    selectResource(this.resourceKey);

    if (!languages || languages.length === 0) {
      selectLanguages();
    }
    if (!applications || applications.length === 0) {
      selectApplications();
    }
    if (!platforms || platforms.length === 0) {
      selectPlatforms();
    }
  }

  public componentWillReceiveProps(nextProps: ITranslationDetailsProps) {
    const { actionType } = this.props;

    if (nextProps.actionType === actionType) {
      return;
    }

    switch (nextProps.actionType) {
      case CommonStore.Consts.INSERT_RESOURCE_SUCCESS:
        this.onRefreshClick();
        this.closeModal();
        notificationService.success({
          message: "Insert resource success",
        });
        break;
      case CommonStore.Consts.INSERT_RESOURCE_FAILURE:
        notificationService.error({
          description: nextProps.error ? nextProps.error.Message : undefined,
          message: "Insert resource failed",
        });
        break;
      case CommonStore.Consts.UPDATE_RESOURCE_SUCCESS:
        this.onRefreshClick();
        this.closeModal();
        notificationService.success({
          message: "Update resource success",
        });
        break;
      case CommonStore.Consts.UPDATE_RESOURCE_FAILURE:
        notificationService.error({
          description: nextProps.error ? nextProps.error.Message : undefined,
          message: "Update resource failed",
        });
        break;
      case CommonStore.Consts.DELETE_RESOURCE_SUCCESS:
        this.onRefreshClick();
        this.closeModal();
        notificationService.success({
          message: "Delete resource success",
        });
        break;
      case CommonStore.Consts.DELETE_RESOURCE_FAILURE:
        notificationService.error({
          description: nextProps.error ? nextProps.error.Message : undefined,
          message: "Delete resource failed",
        });
        break;
      case CommonStore.Consts.DELETE_RESOURCES_BY_KEY_SUCCESS:
        window.history.back();
        notificationService.success({
          message: "Delete resource by key success",
        });
        break;
      case CommonStore.Consts.DELETE_RESOURCES_BY_KEY_FAILURE:
        notificationService.error({
          description: nextProps.error ? nextProps.error.Message : undefined,
          message: "Delete resource by key failed",
        });
        break;
      default:
        break;
    }
  }

  private onAdditionalOptionsMenuItemClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "OPTION_CLEAR_FILTERS":
        this.onClearFiltersClick();
        break;
    }
  };

  public onRefreshClick = () => {
    this.props.selectResource(this.resourceKey);
  };

  public onClearFiltersClick = () => {
    this.setState({ filters: {} });
  };

  public onAddNewTranslationClick = () => {
    this.setState({
      isNewTranslation: true,
      isOpenFormModal: true,
      translation: { ...this.emptyTranslation, ResourceKey: this.resourceKey },
    });
  };

  public closeModal = () => this.setState({ isOpenFormModal: false });

  private getColumnsProps = (): Array<ITableColumnProps<IResourceModel>> => {
    const { t, applications, platforms, languages } = this.props;
    const { filters } = this.state;

    return [
      {
        className: "TranslationDetails__Table-column-value",
        dataIndex: "ResourceValue",
        filteredValue: filters.ResourceValue ? [filters.ResourceValue] : null,
        title: t("ResourceValue", "Translation Value"),
        ...setTableColumnSearchProps("ResourceValue"),
        onFilter: (value, record) =>
          record.ResourceValue.toLowerCase().includes(
            value.toString().toLowerCase()
          ),
        render: (text: any, row: IResourceModel) => {
          return (
            <p
              title={row.ResourceValue}
              className="imageUrl"
              onClick={() => {
                this.setState({
                  isNewTranslation: false,
                  isOpenFormModal: true,
                  translation: row,
                });
              }}
            >
              {row.ResourceValue}
            </p>
          );
        },
      },
      {
        dataIndex: "ApplicationKey",
        filteredValue: filters.ApplicationKeys || null,
        filters: applications.map((application) => ({
          text: application.DisplayName,
          value: application.Key,
        })),
        onFilter: (value, record) =>
          record.ApplicationKey.includes(value as string),
        title: t("Application", "Application"),
        render: (text: any, row: IResourceModel, index: number) => {
          return (
            <Tag color={row.ApplicationKey ? "#f50" : "#1890ff"}>
              {row.ApplicationKey}
            </Tag>
          );
        },
      },
      {
        dataIndex: "LanguageCode",
        title: t("Language", "Language"),
        filters: languages.map((language) => ({
          text: language.Name,
          value: language.Code,
        })),
        filteredValue: filters.LanguageCodes || null,
        onFilter: (value, record) =>
          record.LanguageCode.includes(value as string),
        render: (text: any, row: IResourceModel, index: number) => {
          return (
            <Tag color={row.LanguageCode ? "#f50" : "#1890ff"}>
              {row.LanguageCode}
            </Tag>
          );
        },
      },
      {
        dataIndex: "PlatformCode",
        filteredValue: filters.PlatformCodes || null,
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        onFilter: (value, record) =>
          record.PlatformCode.includes(value as string),
        title: t("Platform", "Platform"),
        render: (text: any, row: IResourceModel, index: number) => {
          return (
            <Tag color={row.PlatformCode ? "#1890ff" : "#f50"}>
              {row.PlatformCode}
            </Tag>
          );
        },
      },
      {
        className: "TranslationDetails__Table-column-layout-definition-url",
        dataIndex: "UrlLayoutDefinition",
        title: t("UrlLayoutDefinition", "Layout Definition Url"),
        render: (text: any, row: IResourceModel, index: number) => {
          return row.UrlLayoutDefinition ? (
            <a href={row.UrlLayoutDefinition} target="_blank">
              {row.UrlLayoutDefinition}
            </a>
          ) : null;
        },
      },
    ];
  };

  private rowKey = (row: IResourceModel, index?: number) =>
    `${this.resourceKey}${row.ApplicationKey}${row.PlatformCode}${row.LanguageCode}`;

  public onSubmitTranslation = () => {
    this.setState({
      isOpenFormModal: false,
      translation: { ...this.emptyTranslation, ResourceKey: this.resourceKey },
    });

    this.props.selectResource(this.resourceKey);
  };

  public onTableChange = (
    pagination: IPaginationProps,
    filters: ITableFilter
  ) => {
    const filter: IResourcesSearchFilterModel = { ...this.state.filters };
    filter.ResourceValue = filters.ResourceValue?.length
      ? `${first(filters.ResourceValue)}`
      : undefined;
    filter.ApplicationKeys = filters.ApplicationKey?.length
      ? filters.ApplicationKey.map((row) => `${row}`)
      : undefined;
    filter.LanguageCodes = filters.LanguageCode?.length
      ? filters.LanguageCode.map((row) => `${row}`)
      : undefined;
    filter.PlatformCodes = filters.PlatformCode?.length
      ? filters.PlatformCode.map((row) => `${row}`)
      : undefined;

    this.setState({
      filters: filter,
      pageNumber: pagination.current!,
      pageSize: pagination.pageSize!,
    });
  };

  public deleteResourcesByKey = () => {
    const { t } = this.props;

    return (
      <Popconfirm
        title="Are you sure you want to delete multiple translations?"
        onConfirm={() => this.props.deleteResourcesByKey(this.resourceKey)}
        okText="Yes"
        cancelText="No"
      >
        <Button
          danger={true}
          shape="circle"
          icon={<DeleteOutlined />}
          title={t("delete_all", "Delete all")}
        />
      </Popconfirm>
    );
  };

  public render() {
    const { resources, isLoadingData, t } = this.props;
    const pagination: IPaginationProps = {
      current: this.state.pageNumber,
      defaultPageSize: 10,
      pageSize: this.state.pageSize,
      pageSizeOptions: ["10", "30", "50", "100"],
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]} - ${range[1]} of ${total} items`,
      total: resources.TotalCount,
    };
    const columns = this.getColumnsProps();

    return (
      <div className="TranslationDetails">
        <PageContent footer={<Pagination {...pagination} />}>
          <PageHeader
            title={this.resourceKey}
            onBack={() => window.history.back()}
            extra={
              <>
                <Button
                  key="new"
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={this.onAddNewTranslationClick}
                  title={t("new", "New")}
                />
                {this.deleteResourcesByKey()}
                <Button
                  key="reload"
                  shape="circle"
                  icon={<ReloadOutlined />}
                  onClick={this.onRefreshClick}
                  title="Refresh data"
                />
                <Dropdown
                  key="dropdown"
                  overlay={
                    <Menu onClick={this.onAdditionalOptionsMenuItemClick}>
                      <MenuItem key="OPTION_CLEAR_FILTERS">
                        Clear filters
                      </MenuItem>
                    </Menu>
                  }
                >
                  <Button icon={<MoreOutlined />} />
                </Dropdown>
              </>
            }
          />
          <Table<IResourceModel>
            columns={columns}
            dataSource={resources.Entities}
            loading={isLoadingData}
            pagination={false}
            rowKey={this.rowKey}
            onChange={this.onTableChange}
          />

          <TranslationDetailsModal
            isOpen={this.state.isOpenFormModal}
            translation={this.state.translation}
            isNewTranslation={this.state.isNewTranslation}
            closeModal={this.closeModal}
          />
        </PageContent>
      </div>
    );
  }
}
