import React from "react";
import {
  ScreenType,
  ScreenTypeHelper as CommonScreenTypeHelper,
} from "@xala/common-services";
import { ChooseOption, Tag, IChooseTagProps } from "@xala/common-ui";

export class ScreenTypeHelper extends CommonScreenTypeHelper {
  static getTag(value?: ScreenType): React.ReactElement {
    let color: string = ScreenTypeHelper.getColor(value);

    return <Tag color={color}>{ScreenTypeHelper.getDescription(value)}</Tag>;
  }

  static getOptions(): React.ReactElement[] {
    return [
      <ChooseOption key="CUSTOM" value={ScreenType.Custom}>
        {ScreenTypeHelper.getDescription(ScreenType.Custom)}
      </ChooseOption>,
      <ChooseOption
        key="APPLICATION_HEADER"
        value={ScreenType.ApplicationHeader}
      >
        {ScreenTypeHelper.getDescription(ScreenType.ApplicationHeader)}
      </ChooseOption>,
      <ChooseOption key="APPLICATION_MENU" value={ScreenType.ApplicationMenu}>
        {ScreenTypeHelper.getDescription(ScreenType.ApplicationMenu)}
      </ChooseOption>,
      <ChooseOption key="HOME" value={ScreenType.Home}>
        {ScreenTypeHelper.getDescription(ScreenType.Home)}
      </ChooseOption>,
      <ChooseOption key="SPLASH" value={ScreenType.Splash}>
        {ScreenTypeHelper.getDescription(ScreenType.Splash)}
      </ChooseOption>,
      <ChooseOption key="LOGIN" value={ScreenType.Login}>
        {ScreenTypeHelper.getDescription(ScreenType.Login)}
      </ChooseOption>,
      <ChooseOption key="HELP" value={ScreenType.Help}>
        {ScreenTypeHelper.getDescription(ScreenType.Help)}
      </ChooseOption>,
      <ChooseOption key="ABOUT" value={ScreenType.About}>
        {ScreenTypeHelper.getDescription(ScreenType.About)}
      </ChooseOption>,
      <ChooseOption key="MOVIE_DETAILS" value={ScreenType.MovieDetails}>
        {ScreenTypeHelper.getDescription(ScreenType.MovieDetails)}
      </ChooseOption>,
      <ChooseOption key="SERIES_DETAILS" value={ScreenType.SeriesDetails}>
        {ScreenTypeHelper.getDescription(ScreenType.SeriesDetails)}
      </ChooseOption>,
      <ChooseOption key="LIVE_DETAILS" value={ScreenType.LiveDetails}>
        {ScreenTypeHelper.getDescription(ScreenType.LiveDetails)}
      </ChooseOption>,
      <ChooseOption key="PROFILE" value={ScreenType.Profile}>
        {ScreenTypeHelper.getDescription(ScreenType.Profile)}
      </ChooseOption>,
      <ChooseOption key="SOCIAL" value={ScreenType.Social}>
        {ScreenTypeHelper.getDescription(ScreenType.Social)}
      </ChooseOption>,
      <ChooseOption key="MESSAGES" value={ScreenType.Messages}>
        {ScreenTypeHelper.getDescription(ScreenType.Messages)}
      </ChooseOption>,
      <ChooseOption key="SETTINGS" value={ScreenType.Settings}>
        {ScreenTypeHelper.getDescription(ScreenType.Settings)}
      </ChooseOption>,
      <ChooseOption key="SEARCH" value={ScreenType.Search}>
        {ScreenTypeHelper.getDescription(ScreenType.Search)}
      </ChooseOption>,
      <ChooseOption key="NOTIFICATIONS" value={ScreenType.Notifications}>
        {ScreenTypeHelper.getDescription(ScreenType.Notifications)}
      </ChooseOption>,
      <ChooseOption key="EVENTS" value={ScreenType.Events}>
        {ScreenTypeHelper.getDescription(ScreenType.Events)}
      </ChooseOption>,
      <ChooseOption key="FRIENDS" value={ScreenType.Friends}>
        {ScreenTypeHelper.getDescription(ScreenType.Friends)}
      </ChooseOption>,
      <ChooseOption key="COMMUNITY" value={ScreenType.Community}>
        {ScreenTypeHelper.getDescription(ScreenType.Community)}
      </ChooseOption>,
      <ChooseOption key="EXPLORE" value={ScreenType.Explore}>
        {ScreenTypeHelper.getDescription(ScreenType.Explore)}
      </ChooseOption>,
      <ChooseOption key="CREATOR_PROFILES" value={ScreenType.CreatorProfiles}>
        {ScreenTypeHelper.getDescription(ScreenType.CreatorProfiles)}
      </ChooseOption>,
    ];
  }

  static getTagOption = (props: IChooseTagProps): React.ReactElement => {
    const { label, value, closable, onClose } = props;
    const screenType = ScreenTypeHelper.getValue(value as string);

    return (
      <Tag
        color={ScreenTypeHelper.getColor(screenType)}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
}
