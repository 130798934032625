import {
  AssetStore,
  AssetType,
  ContentStatus,
  IAssetContentModel,
  IAssetModel,
  IAssetQualityModel,
  ICommonAppState,
  RecordStatus,
} from "@xala/common-services";
import {
  Choose,
  ChooseOption,
  Form,
  IFormValues,
  Input,
  required,
  urlValid,
} from "@xala/common-ui";
import { assign } from "lodash";
import { ContentSource } from "../../enums";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const assetSelector = (state: ICommonAppState) => state.asset;

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

interface IAssetContentPathModalProps {
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
  assetQuality?: IAssetQualityModel[];
}

export const AssetContentPathModal: React.FC<IAssetContentPathModalProps> = (
  props
) => {
  const { asset, assetContent, assetQuality } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { assetContentTypes, assetContentStreamTypes } = useSelector(
    assetSelector
  );
  const [form] = Form.useForm();
  const [contentTypeState, setContentTypeState] = useState<string>();
  const [contentStreamTypeState, setContentStreamTypeState] = useState<
    string
  >();

  const contentExistsForSteamType =
    contentTypeState &&
    contentStreamTypeState &&
    (asset?.Contents?.find(
      (content) =>
        content.ContentTypeCode == contentTypeState &&
        content.StreamTypeCode == contentStreamTypeState
    ) ??
      false);

  const addAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.addAssetContent(data)),
    [dispatch]
  );

  const updateAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.updateAssetContent(data)),
    [dispatch]
  );

  const validateContentTypeUniqueness = (
    _rule: any,
    value: string | number,
    callback: (error?: string) => void
  ) => {
    return contentExistsForSteamType
      ? callback(t("CONTENT_TYPE_EXISTS_FOR_STREAM_TYPE"))
      : callback();
  };

  useEffect(() => {
    if (contentTypeState && contentStreamTypeState) {
      form.validateFields(["AssetContentTypeCode"]);
    }
  }, [contentTypeState, contentStreamTypeState]);

  const onFinish = (values: IFormValues) => {
    const {
      AssetContentTypeCode,
      AssetContentPath,
      AssetContentStreamTypeCode,
    } = values;
    const payload: IAssetContentModel = assign({}, assetContent, {
      AssetId: asset?.Id,
      ContentTypeCode: AssetContentTypeCode,
      ContentStatusCode: ContentStatus.Ready,
      StreamTypeCode: AssetContentStreamTypeCode,
      Path: AssetContentPath,
      QualityCode: values.QualityCode?.value,
      UrlSource: ContentSource.Internal,
    });

    if (assetContent?.Id) {
      updateAssetContent({ ...payload, RecordStatus: RecordStatus.Updated });
    } else {
      addAssetContent({ ...payload, RecordStatus: RecordStatus.Inserted });
    }
  };

  const renderContentTypeField = () => {
    return (
      <Form.Item
        name="AssetContentTypeCode"
        label={t("ASSET_CONTENT_TYPE_LABEL")}
        key="contentType"
        initialValue={assetContent?.ContentTypeCode}
        rules={[required(), { validator: validateContentTypeUniqueness }]}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={assetContentTypes.isFetching}
          onChange={(value) => setContentTypeState(value as string)}
        >
          {assetContentTypes.data?.map((assetContentType) => (
            <ChooseOption
              key={assetContentType.Code}
              value={assetContentType.Code}
            >
              {assetContentType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderContentStreamTypeField = () => {
    return (
      <Form.Item
        name="AssetContentStreamTypeCode"
        label={t("ASSET_CONTENT_STREAM_TYPE_LABEL")}
        key="streamType"
        initialValue={assetContent?.StreamTypeCode}
        rules={[required()]}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_TYPE_PLACEHOLDER")}
          loading={assetContentStreamTypes.isFetching}
          disabled={assetContentStreamTypes.isFetching}
          onChange={(value) => setContentStreamTypeState(value as string)}
        >
          {assetContentStreamTypes.data?.map((assetContentStreamType) => (
            <ChooseOption
              key={assetContentStreamType.Code}
              value={assetContentStreamType.Code}
            >
              {assetContentStreamType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderPathField = () => {
    return (
      <Form.Item
        name="AssetContentPath"
        label={t("ASSET_CONTENT_URL_LABEL")}
        key="path"
        initialValue={assetContent?.Url}
        rules={[urlValid(), required()]}
      >
        <Input placeholder={t("ASSET_CONTENT_URL_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderQualityCodeField = () => {
    if (
      asset?.AssetTypeCode === AssetType.Album ||
      asset?.AssetTypeCode === AssetType.Podcast
    ) {
      return null;
    }
    const initialAssetQuality = assetQuality?.find(
      (element) => element.Code === assetContent?.QualityCode
    );

    const chooseOptions = assetQuality?.map((quality) => (
      <ChooseOption key={quality.Code} value={quality.Code}>
        {t(quality.TranslationKey, quality.DisplayName)}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="QualityCode"
        label={t("ASSET_CONTENT_QUALITY_CODE_LABEL")}
        key="qualityCode"
        initialValue={
          initialAssetQuality && {
            key: initialAssetQuality.Code,
            value: initialAssetQuality.Code,
            label: initialAssetQuality.DisplayName,
          }
        }
      >
        <Choose
          placeholder={t("ASSET_CONTENT_QUALITY_CODE_PLACEHOLDER")}
          children={chooseOptions}
          labelInValue={true}
        />
      </Form.Item>
    );
  };

  return (
    <div className="AssetContentModal">
      <Form
        form={form}
        name="AssetContentPathForm"
        className="AssetContentForm"
        onFinish={onFinish}
        {...formLayout}
      >
        {renderContentStreamTypeField()}
        {renderContentTypeField()}
        {renderPathField()}
        {renderQualityCodeField()}
      </Form>
    </div>
  );
};
