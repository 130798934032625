import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  InputPassword,
  Form,
  IFormValues,
  useValidate,
} from "@xala/common-ui";
import { AuthStore, IAuthState, isFieldError } from "@xala/common-services";
import { IAppState } from "../../../../store";

interface ILoginFormValues extends IFormValues {
  Password?: string;
  RepeatPassword?: string;
}

interface ChangeForgottenPasswordFormProps {
  token: string;
  email: string;
}

export const ChangeForgottenPasswordForm: React.FC<ChangeForgottenPasswordFormProps> = ({
  token,
  email,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const validate = useValidate();

  const onFinish = useCallback(
    (values: ILoginFormValues) => {
      if (email && values.Password) {
        dispatch(
          AuthStore.Actions.resetForgotPassword({
            Email: email,
            Password: values.Password,
            Token: token,
          })
        );
      }
    },
    [dispatch, email, token]
  );

  const { isFetching, error } = useSelector<IAppState, IAuthState>(
    (state) => state.auth
  );
  useEffect(() => {
    if (isFieldError(error)) {
      form.setFields([{ name: "Password", errors: error.Password }]);
    }
  }, [error, form]);

  return (
    <Form
      form={form}
      name="ChangeForgottenPasswordForm"
      onFinish={onFinish}
      labelCol={{ span: 9 }}
    >
      <Form.Item
        name="Password"
        label={t("PASSWORD_LABEL")}
        rules={[...validate.required(), ...validate.password()]}
      >
        <InputPassword
          visibilityToggle={false}
          placeholder={t("PASSWORD_PLACEHOLDER")}
        />
      </Form.Item>
      <Form.Item
        name="RepeatPassword"
        label={t("CONFIRM_PASSWORD_LABEL")}
        rules={[
          ...validate.required(),
          ...validate.passwordConfirm(form, "Password"),
        ]}
      >
        <InputPassword
          visibilityToggle={false}
          placeholder={t("CONFIRM_PASSWORD_PLACEHOLDER")}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: "right" }}>
        <Button
          loading={isFetching}
          type="primary"
          htmlType="submit"
          block={false}
        >
          {t("RESET")}
        </Button>
      </Form.Item>
    </Form>
  );
};
