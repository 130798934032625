import {
  Button,
  Col,
  Dragger,
  Form,
  Icon,
  IFormValues,
  Input,
  PreviewImage,
  Row,
  Switch,
} from "@xala/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationSectionMenuPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ApplicationSectionMenuItemsModal } from "../../../../components/ApplicationSectionMenuItemsModal/ApplicationSectionMenuItemsModal";
import { RecordStatus } from "@xala/common-services";

export interface IApplicationSectionMenuPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IApplicationSectionMenuPropertiesDispatchProps {}

export interface IApplicationSectionMenuPropertiesOwnProps {}

export interface IApplicationSectionMenuPropertiesProps
  extends IApplicationSectionMenuPropertiesStateProps,
    IApplicationSectionMenuPropertiesDispatchProps,
    IApplicationSectionMenuPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationSectionMenuPropertiesState
  extends IComponentPropertiesBaseState {
  itemsModalVisible: boolean;
  isItemsPropertyCreated: boolean;
  fileToBase64: string | null;
}

export class ApplicationSectionMenuProperties extends ComponentPropertiesBase<
  IApplicationSectionMenuPropertiesProps,
  IApplicationSectionMenuPropertiesState,
  ApplicationSectionMenuPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
    itemsBrowserVisible: false,
  };

  public state: Readonly<IApplicationSectionMenuPropertiesState> = {
    itemsModalVisible: false,
    isItemsPropertyCreated: false,
    fileToBase64: null,
  };

  getPropertiesModel(): ApplicationSectionMenuPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationSectionMenuPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    component.Name = values.Name;
  };

  public onIsVisibleChange = () => {
    const { component, onComponentChange } = this.props;

    const updatedComponent = {
      ...component,
      IsVisible: !component.IsVisible,
      RecordStatus: RecordStatus.Updated,
    };

    if (onComponentChange) {
      onComponentChange(updatedComponent);
    }
  };

  public onItemsModalCancel = () => {
    this.setState({ itemsModalVisible: false });
  };

  public onItemsPropertyCreate = () => {
    this.setState({ isItemsPropertyCreated: true });
  };

  private checkIfPropertyAlreadyExists = this.props?.component?.Properties?.find(
    (property) => property.Name === "Items"
  );

  public renderItemsProperty = () => {
    const { component, t } = this.props;
    const { itemsModalVisible } = this.state;
    return (
      <Form.Item
        name="Items"
        label={t("Items")}
        initialValue={this.properties.Items}
      >
        <Row gutter={8}>
          <Col span={18}>
            {itemsModalVisible && (
              <ApplicationSectionMenuItemsModal
                onItemsModalCancel={this.onItemsModalCancel}
                modalVisible={itemsModalVisible}
              />
            )}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ itemsModalVisible: true });

                if (
                  !this.state.isItemsPropertyCreated &&
                  !this.checkIfPropertyAlreadyExists
                ) {
                  this.properties.Items = [];
                  this.onPropertyChange("Items");
                  this.onItemsPropertyCreate();
                }
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderBackgroundUrlProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="IconUrl"
        label={t("Background Url")}
        initialValue={this.properties.BackgroundUrl}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("https://")}
          onChange={(e) => {
            this.properties.BackgroundUrl = e.target.value;
            this.onPropertyChange("BackgroundUrl");
          }}
        />
      </Form.Item>
    );
  };

  public renderFileProperty = () => {
    const { t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form.Item
        key="FileValue"
        name="FileValue"
        valuePropName="FileValue"
        getValueFromEvent={this.onImageChange}
        {...formItemLayout}
        style={{ justifyContent: "flex-end" }}
      >
        <Dragger
          name="FileValue"
          accept="image/*"
          multiple={false}
          beforeUpload={this.beforeUpload}
          fileList={[]}
          withPreview={true}
          maxCount={1}
        >
          {(previewSrc: string) => {
            return (
              <>
                <p className="ant-upload-drag-icon">
                  <PreviewImage
                    src={previewSrc || this.properties.BackgroundUrl}
                    height={30}
                    width={330}
                    placeholderIconType={"inbox"}
                  />
                </p>
                <p className="ant-upload-text">
                  {t(
                    "DRAG_AND_DROP",
                    "Click or drag a file to this area to upload."
                  )}
                </p>
              </>
            );
          }}
        </Dragger>
      </Form.Item>
    );
  };

  public renderIsVisibleProperty = () => {
    const { t, component } = this.props;

    return (
      <Form.Item name="IsVisible" label={t("IS_VISIBLE")}>
        <Switch
          defaultChecked={component.IsVisible}
          onChange={() => this.onIsVisibleChange()}
        />
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };
    const { component, t } = this.props;

    return (
      <Form
        name="ApplicationMenuItemProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderItemsProperty()}
            {this.renderBackgroundUrlProperty()}
            {this.renderFileProperty()}
            {this.renderIsVisibleProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
