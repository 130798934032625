import {
  RecordStatus,
  IApplicationConfigurationBrandingModel,
} from "@xala/common-services";

interface IStringKeyAndValues {
  [key: string]: string;
}

export const allowedFontSizes = [
  "8",
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "18",
  "20",
  "24",
];

export const fontKey2NameMap: IStringKeyAndValues = {
  ABRADE: "Abrade, sans-serif",
  ARIAL: "Arial",
  MONTSERRAT: "Montserrat",
  TAHOMA: "Tahoma",
  TIMES_NEW_ROMAN: "Times New Roman",
  VERDANA: "Verdana",
  ROBOTO: "Roboto",
  BR_SONOMA: "BR Sonoma",
};

export const allowedFonts = Object.keys(fontKey2NameMap);

export const defaultBrandingValuesByType: IStringKeyAndValues = {
  colorDark: "#000000",
  colorLight: "#ffffff",
  fontFamily: fontKey2NameMap.ABRADE,
  fontSize: "16",
  COLOR: "#000000",
  URL: "",
};

export const defaultBrandingValues: IStringKeyAndValues = {
  AppBackgroundColor: defaultBrandingValuesByType.colorDark,
  AppCellsBackgroundColor: defaultBrandingValuesByType.colorLight,
  AppFontFamily: defaultBrandingValuesByType.fontFamily,
  AppFontSize: defaultBrandingValuesByType.fontSize,
  WebFooterLogoUrl: "",
};

export const getDefaultValByKeyOrType = ({
  Key,
  Type,
}: {
  Key: string;
  Type: string;
}): string =>
  defaultBrandingValues[Key] || defaultBrandingValuesByType[Type] || "";

export const brandingBoilerPlate = {
  AppBackgroundColor: "",
  AppCellsBackgroundColor: "",
  AppFontFamily: "",
  AppFontSize: 16,
  AppPrimaryColor: "",
  AppPrimaryTextColor: "",
  AppSecondaryColor: "",
  AppSecondaryTextColor: "",
  ApplicationConfigurationId: 0,
  FooterBackgroundColor: "",
  FooterLinkColor: "",
  FooterLinkHoverColor: "",
  HeaderBackgroundColor: "",
  HeaderLinkColor: "",
  HeaderLinkHoverColor: "",
  Id: -1,
  IsDefault: false,
  MobileFooterLogoUrl: "",
  MobileHeaderLogoUrl: "",
  Name: "",
  RecordStatus: RecordStatus.Inserted,
  Sequence: 0,
  Values: [],
  WebFooterLogoUrl: "",
  WebHeaderLogoUrl: "",
  LoginImageUrl: "",
  RegisterImageUrl: "",
};

export const createBranding = ({
  ApplicationConfigurationId,
}: {
  ApplicationConfigurationId: number;
}): IApplicationConfigurationBrandingModel => {
  return {
    ...brandingBoilerPlate,
    ApplicationConfigurationId,
  };
};

export const type2LabelMapping: { [key: string]: any } = {
  FONT: "FONT TYPE",
  NUMBER: "FONT SIZE",
  URL: "IMAGES",
};

export const type2Label = (type: string) => {
  try {
    return type2LabelMapping[type];
  } catch (e) {
    return type;
  }
};
