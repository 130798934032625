import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  IAssetCollectionCopyModel,
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  IAssetListModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class AssetCollectionService extends SimpleServiceBase<
  IAssetCollectionModel,
  IAssetCollectionListModel,
  number,
  IAssetCollectionSearchFilterModel
> {
  get url(): string {
    return "/AssetsCollections";
  }

  public searchAssetsByAssetCollectionId = (
    filter: IAssetCollectionSearchFilterModel
  ): Observable<IAssetListModel> =>
    new Observable(
      (observer: Observer<IAssetListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: { id: filter.Id },
          },
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/SearchAssetsByAssetCollectionId`,
        })
    );

  public copy = (
    asset: IAssetCollectionCopyModel
  ): Observable<IAssetCollectionCopyModel> =>
    new Observable(
      (observer: Observer<IAssetCollectionCopyModel>) =>
        new AxiosSubscriber(observer, {
          data: asset,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Copy`,
        })
    );
}
