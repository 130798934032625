export const ROUTES = {
  DICTIONARY_BASE: "/dictionaries",
  DICTIONARY_APPLICATION_LIST: "/dictionary/applications",
  DICTIONARY_LANGUAGE_LIST: "/dictionary/languages",
  DICTIONARY_CURRENCY_LIST: "/dictionary/currency",
  DICTIONARY_PLATFORM_LIST: "/dictionary/platforms",
  DICTIONARY_VIDEO_AGE_RESTRICTION_LIST: "/dictionary/video-age-restrictions",
  DICTIONARY_CONSENT_LIST: "/dictionary/consents",
  DICTIONARY_ASSET_PURCHASE_PERIOD_TYPE_LIST:
    "/dictionary/asset-purchase-period-types",
  DICTIONARY_PEOPLE: "/dictionary/people",
  DICTIONARY_TOWNS_LIST: "/dictionary/towns",
};
