import {
  AssetCategoryModel,
  AssetCategoryService,
  AssetModel,
  AssetService,
  AssetStatusType,
  AssetStore,
  AssetType,
  IAssetCategoryModel,
  IAssetModel,
  IAssetPeopleListModel,
  IAssetPeopleModel,
  IAssetTypeModel,
  ICommonAppState,
  ILanguageModel,
  IPersonInAssetModel,
  IUserModel,
  LanguageService,
  PersonInAssetType,
  StreamType,
  TimeHelper,
  useDataLoader,
} from "@xala/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  ChooseValue,
  Col,
  DatePicker,
  Form,
  IChooseLabeledValue,
  Icon,
  IDatePickerMoment,
  IFormValues,
  Input,
  InputNumber,
  Link,
  RadioGroup,
  required,
  Row,
  Switch,
  TextArea,
  TimePicker,
  useSendable,
  useSyncedState,
  useValidate,
} from "@xala/common-ui";
import { get, pick } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ILazyChooseLoader, LazyChoose } from "../../../../components";
import { ROUTES as USER_ROUTES } from "../../../User/constants";
import { UserBrowserModal } from "../../../User/components/UserBrowserModal";
import { ROUTES } from "../../constants";
import "./AssetForm.scss";
import {
  AssetDescriptionMode,
  commonFields,
  FieldType,
  formLayouts,
  getFieldNamesByParams,
  getSectionsFields,
  ISection,
} from "./AssetFormUtils";
import { AssetProperties, OrderRules } from "../../enums";
import { Select } from "antd";

const assetCategoryService = new AssetCategoryService().promisify();
const assetService = new AssetService().promisify();
const languageService = new LanguageService().promisify();

const assetSelector = (state: ICommonAppState) => state.asset;

const inferAssetTypeFromParent = (parent?: IAssetModel) => {
  switch (parent?.AssetTypeCode) {
    case AssetType.Series:
      return AssetType.Season;
    case AssetType.Season:
      if (parent.ParentAssetTypeCode === AssetType.Album) {
        return AssetType.Podcast;
      }
      return AssetType.Episode;
    case AssetType.Channel:
      return AssetType.Program;
    case AssetType.Premiere:
      return AssetType.Premiere;
    case AssetType.Album:
      return AssetType.Season;
    default:
      return undefined;
  }
};

const inferParentAssetType = (type?: AssetType) => {
  switch (type) {
    case AssetType.Season:
      return [AssetType.Series, AssetType.Album];
    case AssetType.Episode:
      return [AssetType.Season];
    case AssetType.Program:
      return [AssetType.Channel];
    case AssetType.Podcast:
      return [AssetType.Season];
    default:
      return undefined;
  }
};

const getFirstAvailableAssetType = (availableAssets?: IAssetTypeModel[]) => {
  if (availableAssets && availableAssets.length > 0) {
    return availableAssets[0].Code;
  }
};

export const STATUS_ARRAY = [
  { Name: AssetStatusType.Publish, Id: 0, Value: false },
  { Name: AssetStatusType.Draft, Id: 1, Value: true },
];

const ORDER_RULES = [
  { id: 0, value: OrderRules.Ascending, label: "MODEL_ORDER_RULE_ASCENDING" },
  { id: 1, value: OrderRules.Descending, label: "MODEL_ORDER_RULE_DESCENDING" },
];

export interface IAssetForm {
  isEditMode: boolean;
  asset?: IAssetModel;
  parent?: IAssetModel;
  section?: ISection;
  availableAssets?: IAssetTypeModel[];
  hiddenFields?: FieldType[];
  onSubmit?: (data: IAssetModel) => void;
  languages?: ILanguageModel[];
  assetSubtitlesArray?: ILanguageModel[];
  people?: IAssetPeopleListModel;
  channels?: IAssetModel[];
  childrenAssetsTotalCount?: number;
  childrenAssetsListForm?: boolean;
}

export const AssetForm: React.FC<IAssetForm> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: assetId } = useParams<{ id: string }>();
  const {
    isEditMode,
    asset,
    parent: propParent,
    section,
    availableAssets,
    hiddenFields,
    languages,
    assetSubtitlesArray,
    people,
    channels,
    childrenAssetsTotalCount,
    childrenAssetsListForm,
  } = props;

  const languageLoader = useDataLoader({
    loader: () => languageService.select(),
    deps: [],
  });

  const [userBrowserVisible, setUserBrowserVisible] = useState(false);
  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: number;
    FullName?: string;
  }>(
    () => ({
      Id: asset?.CreatorUserId,
      FullName: asset?.CreatorUserFullName,
    }),
    [asset]
  );

  const { data: persistentParent } = useDataLoader({
    loader: () =>
      asset?.ParentAssetId
        ? assetService.getAsset(asset?.ParentAssetId)
        : undefined,
    deps: [asset?.ParentAssetId],
  });

  const parent = propParent || persistentParent;
  const [typeState, setTypeState] = useState<AssetType | undefined>(
    asset?.AssetTypeCode ||
      inferAssetTypeFromParent(parent) ||
      getFirstAvailableAssetType(availableAssets)
  );
  const { onFieldsChange, resetSendable, setDirty, sendable } = useSendable();

  const formFieldNames = useMemo(() => {
    let fields = commonFields;
    if (typeState) {
      fields = section
        ? getSectionsFields(typeState)[section]
        : getFieldNamesByParams(typeState);
    }

    return fields;
  }, [section, typeState, isEditMode]);

  const assetFields = useMemo(
    () => [
      ...formFieldNames.flatMap((name): (keyof AssetModel)[] => {
        switch (name) {
          case AssetProperties.Director:
          case AssetProperties.Editor:
          case AssetProperties.Presenter:
          case AssetProperties.Cast:
          case AssetProperties.Producer:
          case AssetProperties.Writer:
            return ["People"];
          case AssetProperties.DurationMiliseconds:
            return ["DurationSeconds"];
          case AssetProperties.AvailableDate:
            return ["AvailableFrom", "AvailableTo"];
          case AssetProperties.StartEndDates:
            return ["StartDateTime", "EndDateTime"];
          case AssetProperties.CreatorUserId:
            return ["CreatorUserId", "CreatorUserFullName"];
          default:
            return [name];
        }
      }),
      "Payment",
      "ParentAssetId",
    ],
    [formFieldNames]
  );

  const { assetTypes, assetAgeRestrictions } = useSelector(assetSelector);
  const [form] = Form.useForm();
  const { compareIsBefore, compareIsAfter } = useValidate();

  const {
    data: assetTypesData = [],
    isFetching: assetTypesDataLoading,
  } = assetTypes;
  const {
    data: assetAgeRestrictionsData = [],
    isFetching: assetAgeRestrictionsDataLoading,
  } = assetAgeRestrictions;

  let parentId: number | undefined = undefined;
  let parentTitle: string | undefined = undefined;

  if (asset && asset.ParentAssetId) {
    parentId = asset.ParentAssetId;
    parentTitle = asset.ParentAssetTitle;
  } else if (parent) {
    parentId = parent.Id;
    parentTitle = parent.Title;
  }

  const getAssetTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetTypes()),
    [dispatch]
  );

  const getAssetAgeRestrictions = useCallback(
    () => dispatch(AssetStore.Actions.getAssetAgeRestrictions()),
    [dispatch]
  );

  const selectAssetParent = useCallback(
    (id: number) => dispatch(AssetStore.Actions.selectAssetParent(id)),
    [dispatch]
  );

  useEffect(() => {
    getAssetTypes();
    getAssetAgeRestrictions();
    if (isEditMode) {
      selectAssetParent(Number.parseInt(assetId));
    }
  }, [getAssetTypes, getAssetAgeRestrictions, selectAssetParent]);

  const formLayout = isEditMode
    ? formLayouts.formItem
    : formLayouts.modalFormItem;

  useEffect(() => {
    resetSendable();
    form.resetFields();
  }, [JSON.stringify(pick(asset, assetFields))]);

  const onFinish = async (values: IFormValues) => {
    const savedItems: IFormValues = {};

    const parserMapper: IFormValues = {
      AssetTypeCode: values.AssetTypeCode,
      Title: values.Title,
      ShortDescription: values.ShortDescription,
      LongDescription: values.LongDescription,
      AssetAgeRestrictionValueMin: values.AssetAgeRestrictionValueMin,
      Categories: values.Categories,
      LanguageId: values.LanguageId?.value,
      Subtitles: values.Subtitles?.map(
        (subtitle: IChooseLabeledValue) =>
          typeof subtitle.value === "string" && subtitle.value.toUpperCase()
      ).join(", "),
      IsDraft:
        values.IsDraft?.value === 1
          ? true
          : values.IsDraft?.value === 0
          ? false
          : undefined,
      CreatorUserId: userCreator.Id,
      CreatorUserFullName: userCreator.FullName,
      People: [
        ...(values.Director || []),
        ...(values.Editor || []),
        ...(values.Presenter || []),
        ...(values.Cast || []),
        ...(values.Producer || []),
        ...(values.Writer || []),
      ],
      OrderInParent: values.OrderInParent
        ? values.OrderInParent
        : values.ParentAssetId?.value
        ? 1
        : undefined,
      DurationMiliseconds: values.DurationMiliseconds
        ? TimeHelper.toDurationMilliseconds(values.DurationMiliseconds)
        : undefined,
      YearText: values.YearText,
      AvailableFrom: values.AvailableFrom
        ? values.AvailableFrom.toISOString()
        : null,
      AvailableTo: values.AvailableTo ? values.AvailableTo.toISOString() : null,
      StartDateTime: values.StartDateTime
        ? values.StartDateTime.toISOString()
        : null,
      EndDateTime: values.EndDateTime ? values.EndDateTime.toISOString() : null,
      ParentAssetId: values.ParentAssetId?.value
        ? +values.ParentAssetId?.value
        : parent
        ? parent.Id
        : undefined,
      IsFree: values?.IsFree === false ? values.IsFree : true,
      Captions: values.Captions,
      Tags: values.Tags,
      AdvertisingTags: values.AdvertisingTags,
      ContextTags: values.ContextTags,
      Label: values.Label,
      Genre: values.Genre,
      Nationality: values.Nationality,
      BCID: values?.BCID,
      ChannelName: values?.ChannelName,
      ChannelId: values?.ChannelId,
      StreamingPermission: values?.StreamingPermission,
      RecordingPermission: values?.RecordingPermission,
      EntityId: values?.EntityId?.value,
      AllowUnregistered: values?.AllowUnregistered,
      AssetDatesVisible: values?.AssetDatesVisible,
      EventDateTime: values?.EventDateTime,
      OrderRule: values?.OrderRule?.value,
    };

    assetFields
      .filter((item) => parserMapper[item] !== undefined)
      .forEach((item) => (savedItems[item] = parserMapper[item]));

    const assetDetails: IAssetModel = {
      Id: -1,
      ...asset,
      ...savedItems,
    };

    props.onSubmit?.(assetDetails);
  };

  const handleTypeChange = (value: ChooseValue) => {
    setTypeState(value as AssetType);
  };

  const renderTypeField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.AssetTypeCode)) {
      return;
    }

    return (
      <Form.Item
        name="AssetTypeCode"
        label={t("Type", "Type")}
        key="type"
        initialValue={typeState}
        rules={[required()]}
      >
        <Choose
          placeholder={t("Type", "Enter type")}
          loading={assetTypesDataLoading}
          disabled={isEditMode || !!parent}
          onChange={handleTypeChange}
        >
          {assetTypesData.map((assetType: IAssetTypeModel) => (
            <ChooseOption key={assetType.Code} value={assetType.Code}>
              {assetType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderCreationDateField = () => {
    if (
      !isEditMode ||
      (hiddenFields && hiddenFields.includes(AssetProperties.CreationDate))
    ) {
      return;
    }

    return (
      <Form.Item
        name="CreationDate"
        label={t("CREATION_DATE_LABEL")}
        key="creationDate"
        initialValue={
          asset?.CreationDate && TimeHelper.format(asset.CreationDate)
        }
      >
        <Input disabled />
      </Form.Item>
    );
  };

  const renderLastModificationDate = () => {
    if (
      !isEditMode ||
      (hiddenFields &&
        hiddenFields.includes(AssetProperties.LastModificationDate))
    ) {
      return;
    }

    return (
      <Form.Item
        name="LastModificationDate"
        label={t("LAST_MODIFICATION_DATE_LABEL")}
        key="lastModificationDate"
        initialValue={
          asset?.LastModificationDate &&
          TimeHelper.format(asset.LastModificationDate)
        }
      >
        <Input disabled />
      </Form.Item>
    );
  };

  const renderLanguagesField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.LanguageId)) {
      return;
    }

    const assetsLanguage = languages?.find(
      (language) => language.Id === asset?.LanguageId
    );

    const chooseOption = languageLoader.data?.map((language) => (
      <ChooseOption key={language.Id} value={language.Id}>
        {language.Name}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="LanguageId"
        label={t("Language", "Language")}
        key="language"
        rules={[{ required: true }]}
        initialValue={
          assetsLanguage && {
            key: `${assetsLanguage?.Id}`,
            value: assetsLanguage?.Id,
            label: assetsLanguage?.Name,
          }
        }
      >
        <Choose
          placeholder={t("Language", "Enter language")}
          loading={assetTypesDataLoading}
          labelInValue={true}
          children={chooseOption}
        />
      </Form.Item>
    );
  };

  const renderSubtitlesField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.Subtitles)) ||
      !isEditMode
    ) {
      return;
    }

    const fieldOptions: {
      initialValue?: IChooseLabeledValue[];
    } = {
      initialValue: assetSubtitlesArray?.map((subtitle) => {
        return {
          key: subtitle.Code,
          value: subtitle.Code,
          label: subtitle.Name,
        };
      }),
    };

    const chooseOptions = languageLoader.data?.map((language) => (
      <ChooseOption key={language.Code} value={language.Code}>
        {language.Name}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="Subtitles"
        label={t("Subtitles", "Subtitles")}
        key="subtitles"
        {...fieldOptions}
      >
        <Choose
          placeholder={t("Subtitles", "Enter subtitles")}
          loading={assetTypesDataLoading}
          mode="multiple"
          children={chooseOptions}
          labelInValue={true}
        />
      </Form.Item>
    );
  };

  const renderParentField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.ParentAssetId)) {
      return;
    }

    const parentType =
      (asset?.ParentAssetTypeCode && [asset?.ParentAssetTypeCode]) ||
      inferParentAssetType(typeState);
    if (isEditMode && parent) {
      return (
        <Form.Item label={t("Parent", "Parent")} key="Parent">
          {parent.ParentAssetTitle && parent.ParentAssetId && (
            <>
              <Link to={`${ROUTES.ASSET_DETAILS}/${parent.ParentAssetId}`}>
                {parent.ParentAssetTitle}
              </Link>
              <span> / </span>
            </>
          )}
          <Link to={`${ROUTES.ASSET_DETAILS}/${parentId}`}>{parentTitle}</Link>
        </Form.Item>
      );
    }

    const loadCandidates: ILazyChooseLoader<IAssetModel> = async (
      search: string
    ) => {
      // When assigning season parent to episode we want to search by series
      // for better UX, since series have non unique titles (like Season 1, Season 2)
      if (typeState === AssetType.Episode) {
        const params = {
          PageNumber: 1,
          PageSize: 20,
          Types: [AssetType.Series],
          Title: search || undefined,
          IncludeChildAssets: true,
        };
        const result = await assetService.search(params);
        if (!result.ok) {
          return result;
        }
        // flatmap series' children assets to mimic searching for seasons
        return {
          ok: true,
          data: {
            Entities: result.data.Entities.flatMap(
              ({ Assets }) => Assets || []
            ),
            TotalCount: -1,
          },
        };
      }
      if (typeState === AssetType.Podcast) {
        const params = {
          PageNumber: 1,
          PageSize: 20,
          Types: [AssetType.Album],
          Title: search || undefined,
          IncludeChildAssets: true,
        };
        const result = await assetService.search(params);
        if (!result.ok) {
          return result;
        }
        return {
          ok: true,
          data: {
            Entities: result.data.Entities.flatMap(
              ({ Assets }) => Assets || []
            ),
            TotalCount: -1,
          },
        };
      }
      // normal flow for other types of assets
      return assetService.search({
        PageNumber: 1,
        PageSize: 100,
        Types: parentType && [...parentType],
        Title: search || undefined,
      });
    };

    if (!isEditMode) {
      return (
        <Form.Item
          name="ParentAssetId"
          label={t("Parent", "Parent")}
          key="Parent"
          initialValue={
            parentId && {
              key: parentId,
              label: parentTitle,
              value: parentId,
            }
          }
          rules={[{ required: true }]}
        >
          <LazyChoose<IAssetModel, IAssetModel>
            disabled={!!parent}
            multiple={false}
            showSearch
            placeholder={t("Parent", "Enter parent")}
            loader={loadCandidates}
            loaderDeps={[typeState]}
            candidateToOption={(item) => ({
              label: item.ParentAssetTitle
                ? `${item.ParentAssetTitle} / ${item.Title}`
                : item.Title!,
              value: `${item.Id}`,
              item,
            })}
            selectedToOption={(item) => ({
              label: item.Title!,
              value: `${item.Id!}`,
              item,
            })}
          />
        </Form.Item>
      );
    }
    return null;
  };

  const renderOrderInParentField = () => {
    if (
      (childrenAssetsTotalCount === undefined && childrenAssetsListForm) ||
      (hiddenFields && hiddenFields.includes(AssetProperties.OrderInParent))
    ) {
      return;
    }

    let orderInParent = undefined;
    if (asset) {
      orderInParent = asset?.OrderInParent;
    } else if (parent) {
      let currentChildrenCount = 0;
      if (childrenAssetsTotalCount && childrenAssetsTotalCount > 0) {
        currentChildrenCount = childrenAssetsTotalCount;
      }
      orderInParent = currentChildrenCount + 1;
    }

    if ((isEditMode && parent) || !isEditMode) {
      return (
        <Form.Item
          name="OrderInParent"
          label={t("ORDER", "Order")}
          key="OrderInParent"
          initialValue={orderInParent}
          rules={[
            {
              required: !(
                typeState === AssetType.Podcast ||
                typeState === AssetType.Program
              ),
            },
          ]}
        >
          <InputNumber
            min={1}
            placeholder={t("Enter number", "Enter number")}
          />
        </Form.Item>
      );
    }

    return null;
  };

  const renderCategoriesField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Categories)) {
      return;
    }

    return (
      <Form.Item
        name="Categories"
        label={t("Categories", "Categories")}
        key="Categories"
        initialValue={(isEditMode && asset?.Categories) || []}
        rules={[
          {
            required:
              typeState === AssetType.Video ||
              typeState === AssetType.Channel ||
              typeState === AssetType.Program ||
              typeState === AssetType.Album
                ? true
                : false,
          },
        ]}
      >
        <LazyChoose<IAssetCategoryModel, AssetCategoryModel>
          loader={(search: string) =>
            assetCategoryService.search({
              PageNumber: 1,
              PageSize: 999,
              FullTextSearch: search,
              UpToDate: true,
            })
          }
          candidateToOption={(item) => ({
            label: item.Name,
            value: `${item.Id}`,
            item: {
              AssetId: asset?.Id,
              AssetCategoryId: item.Id,
              AssetCategoryName: item.Name,
              AssetCategoryCode: item.Code,
              RowVersion: item.RowVersion,
            },
          })}
          selectedToOption={(item) => ({
            label: item.AssetCategoryName!,
            value: `${item.AssetCategoryId!}`,
            item,
          })}
        />
      </Form.Item>
    );
  };

  const renderPeopleField = (
    name: string,
    label: string,
    roleCode: PersonInAssetType
  ) => {
    if (!isEditMode) {
      return null;
    }
    return (
      <Form.Item
        name={name}
        label={label}
        key={name}
        initialValue={
          (isEditMode &&
            asset?.People?.filter?.(
              (p) => p.PersonInAssetRoleCode === roleCode
            )) ||
          []
        }
      >
        <LazyChoose<IAssetPeopleModel, IPersonInAssetModel>
          loader={(search: string) =>
            assetService.searchAssetPeople({
              PageNumber: 1,
              PageSize: 999,
              FullTextSearch: search,
            })
          }
          loaderDeps={[people]}
          candidateToOption={(item) => ({
            label: item.FullName,
            value: `${item.Id}`,
            item: {
              AssetId: asset?.Id,
              AssetPersonFullName: item.FullName,
              AssetPersonId: item.Id,
              PersonInAssetRoleCode: roleCode,
              RowVersion: item.RowVersion,
            },
          })}
          selectedToOption={(item) => ({
            label: item.AssetPersonFullName!,
            value: `${item.AssetPersonId}`,
            item,
          })}
        />
      </Form.Item>
    );
  };

  const renderPeopleDirectorField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Director)) {
      return;
    }
    return renderPeopleField(
      "Director",
      t("Director"),
      PersonInAssetType.Director
    );
  };

  const renderPeopleEditorField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Editor)) {
      return;
    }
    return renderPeopleField("Editor", t("Editor"), PersonInAssetType.Editor);
  };

  const renderPeoplePresenterField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Presenter)) {
      return;
    }
    return renderPeopleField(
      "Presenter",
      t("Presenter"),
      PersonInAssetType.Presenter
    );
  };

  const renderPeopleCastField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Cast)) {
      return;
    }
    return renderPeopleField("Cast", t("Cast"), PersonInAssetType.Cast);
  };

  const renderPeopleProducerField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Producer)) {
      return;
    }
    return renderPeopleField(
      "Producer",
      t("Producer"),
      PersonInAssetType.Producer
    );
  };

  const renderPeopleWriterField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Writer)) {
      return;
    }
    return renderPeopleField("Writer", t("Writer"), PersonInAssetType.Writer);
  };

  const renderAgeRestrictionField = () => {
    if (
      hiddenFields &&
      hiddenFields.includes(AssetProperties.AssetAgeRestrictionValueMin)
    ) {
      return;
    }

    return (
      <Form.Item
        name="AssetAgeRestrictionValueMin"
        label={t("AgeRestriction", "Age restriction")}
        key="ageRestriction"
        initialValue={
          isEditMode ? get(asset, "AssetAgeRestrictionValueMin") : null
        }
        rules={[{ required: true }]}
      >
        <Choose
          placeholder={t("AgeRestriction", "Enter age restriction")}
          loading={assetAgeRestrictionsDataLoading}
          disabled={assetAgeRestrictionsDataLoading}
        >
          {assetAgeRestrictionsData.map((assetAgeRestriction) => (
            <ChooseOption
              key={assetAgeRestriction.ValueMin}
              value={assetAgeRestriction.ValueMin}
            >
              {assetAgeRestriction.ValueMin}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderStatusField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.IsDraft)) {
      return;
    }

    const status = STATUS_ARRAY.find(
      (element) => element.Value === asset?.IsDraft
    );

    const chooseOption = STATUS_ARRAY.map((element) => (
      <ChooseOption key={element.Id} value={element.Id}>
        {element.Name}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="IsDraft"
        label={t("Status", "Status")}
        key="status"
        rules={[{ required: true }]}
        initialValue={
          status && {
            key: `${status?.Id}`,
            value: status?.Id,
            label: status?.Name,
          }
        }
      >
        <Choose
          placeholder={t("Status", "Enter status")}
          children={chooseOption}
          labelInValue={true}
        />
      </Form.Item>
    );
  };

  const descriptionEditorModeOptions: AssetDescriptionMode[] = [
    {
      name: "Long",
      label: "Long description",
      value: "LongDescription",
      maxLength: 65000,
      initialValue: asset?.LongDescription,
    },
    {
      name: "Short",
      label: "Short description",
      value: "ShortDescription",
      maxLength: 200,
      initialValue: asset?.ShortDescription,
    },
  ];

  const [descriptionEditorMode, setDescriptionEditorMode] = useState(
    "LongDescription"
  );

  const onDescriptionEditorModeChange = (e: any) => {
    setDescriptionEditorMode(e.target.value);
  };

  const renderDescriptionField = () => {
    if (
      !isEditMode ||
      (hiddenFields && hiddenFields.includes(AssetProperties.Description))
    ) {
      return;
    }

    return (
      <Form.Item label={t("Description", "Description")}>
        <Form.Item>
          <RadioGroup
            buttons
            defaultValue={descriptionEditorMode}
            data={descriptionEditorModeOptions}
            onChange={onDescriptionEditorModeChange}
          />
        </Form.Item>
        {descriptionEditorModeOptions.map((mode) => (
          <Form.Item
            name={mode.value}
            key={mode.value}
            initialValue={isEditMode ? mode.initialValue || "" : ""}
            hidden={mode.value !== descriptionEditorMode}
            rules={[
              {
                max: mode.maxLength,
                message: `"${mode.label}" cannot be longer that ${mode.maxLength} characters`,
              },
            ]}
          >
            <TextArea rows={7}></TextArea>
          </Form.Item>
        ))}
      </Form.Item>
    );
  };

  const renderAvailableDateFields = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.AvailableDate)) {
      return;
    }

    return (
      <React.Fragment key="AvailableDate">
        <Form.Item
          name="AvailableFrom"
          label={t("MODEL_AVAILABLE_FROM", "Available From")}
          key="AvailableFrom"
          dependencies={["StartDateTime"]}
          initialValue={
            isEditMode && asset?.AvailableFrom
              ? TimeHelper.parse(asset?.AvailableFrom)
              : undefined
          }
          rules={[
            ...compareIsBefore(
              form,
              "AvailableFrom",
              "AvailableTo",
              "AVAILABLE_START_DATE_MESSAGE"
            ),
            { required: true },
          ]}
        >
          <DatePicker
            showTime={true}
            placeholder={t("SelectDateFrom", "Select a start date and time")}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="AvailableTo"
          label={t("AvailableTo", "Available To")}
          key="AvailableTo"
          initialValue={
            isEditMode && asset?.AvailableTo
              ? TimeHelper.parse(asset?.AvailableTo)
              : undefined
          }
          rules={[
            ...compareIsAfter(
              form,
              "AvailableFrom",
              "AvailableTo",
              "AVAILABLE_END_DATE_MESSAGE"
            ),
            { required: true },
          ]}
        >
          <DatePicker
            showTime={true}
            placeholder={t("SelectDateTo", "Select an end date and time")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderStartEndDatesFields = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.StartEndDates)) {
      return;
    }

    return (
      <React.Fragment key="StartEndDates">
        <Form.Item
          name="StartDateTime"
          label={t("MODEL_START_DATE_TIME", "Start Date")}
          key="StartDateTime"
          validateFirst
          dependencies={["AvailableFrom"]}
          initialValue={
            isEditMode && asset?.StartDateTime
              ? TimeHelper.parse(asset?.StartDateTime)
              : undefined
          }
          rules={[
            { required: true },
            ...compareIsAfter(
              form,
              "AvailableFrom",
              "StartDateTime",
              "START_DATE_AFTER_AVAILABLE_FROM_MESSAGE"
            ),
            ...compareIsBefore(
              form,
              "StartDateTime",
              "EndDateTime",
              "START_DATE_TIME_MESSAGE"
            ),
          ]}
        >
          <DatePicker
            showTime={true}
            placeholder={t("SelectStartDate", "Select a start date and time")}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="EndDateTime"
          label={t("MODEL_END_DATE_TIME", "End Date")}
          key="EndDateTime"
          initialValue={
            isEditMode && asset?.EndDateTime
              ? TimeHelper.parse(asset?.EndDateTime)
              : undefined
          }
          rules={[
            { required: true },
            ...compareIsAfter(
              form,
              "StartDateTime",
              "EndDateTime",
              "END_DATE_TIME_MESSAGE"
            ),
            ...compareIsAfter(
              form,
              "EndDateTime",
              "AvailableTo",
              "END_DATE_TIME_AFTER_AVAILABLE_TO_MESSAGE"
            ),
          ]}
        >
          <DatePicker
            showTime={true}
            placeholder={t("SelectEndDate", "Select an end date and time")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderTitleField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.Title)) {
      return;
    }

    return (
      <Form.Item
        name="Title"
        label={t("Title", "Title")}
        key="title"
        initialValue={isEditMode ? asset?.Title || "" : ""}
        rules={[required()]}
      >
        <Input placeholder={t("Title", `Enter a title`)} />
      </Form.Item>
    );
  };

  const renderTagsField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.Tags)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="Tags"
        label={t("TAGS_LABEL")}
        key="Tags"
        initialValue={asset?.Tags || []}
      >
        <Select mode="tags" placeholder={t("TAGS_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderAdvertisingTagsField = () => {
    if (
      (hiddenFields &&
        hiddenFields.includes(AssetProperties.AdvertisingTags)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="AdvertisingTags"
        label={t("Advertising Tags", "Advertising Tags")}
        key="advertisingTags"
        initialValue={asset?.AdvertisingTags || []}
      >
        <Select
          mode="tags"
          placeholder={t("AdvertisingTags", `Enter a advertising tags`)}
        />
      </Form.Item>
    );
  };

  const renderContextTagsField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.ContextTags)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="ContextTags"
        label={t("CONTEXT_TAGS_LABEL")}
        key="contextTags"
        initialValue={asset?.ContextTags}
      >
        <Input placeholder={t("CONTEXT_TAGS_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderLabelField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.Label)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="Label"
        label={t("Label", "Label")}
        key="label"
        initialValue={asset?.Label}
      >
        <Input placeholder={t("Label", `Enter a label`)} />
      </Form.Item>
    );
  };

  const renderGenreField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.Genre)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="Genre"
        label={t("GENRE_LABEL")}
        key="Genre"
        initialValue={asset?.Genre}
      >
        <Input placeholder={t("GENRE_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderNationalityField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.Nationality)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="Nationality"
        label={t("Nationality", "Nationality")}
        key="nationality"
        initialValue={asset?.Nationality}
      >
        <Input placeholder={t("Nationality", `Enter a nationality`)} />
      </Form.Item>
    );
  };

  const renderShortDescriptionField = () => {
    if (
      hiddenFields &&
      hiddenFields.includes(AssetProperties.ShortDescriptionForm)
    ) {
      return;
    }

    return (
      <Form.Item
        name="ShortDescription"
        label={t("ShortDescription", "Short Description")}
        key="shortDescriptionForm"
        initialValue={isEditMode ? asset?.ShortDescription || "" : ""}
        rules={[
          {
            max: 100,
            message: t(
              "SHORT_DESCRIPTION_MAXIMUM_LENGTH",
              `Short description cannot be longer that 100 characters`
            ),
          },
          required(),
        ]}
      >
        <TextArea
          placeholder={t("ShortDescription", `Enter a short description`)}
        />
      </Form.Item>
    );
  };

  const renderIsFreeField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.IsFree)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item key="IsFree" wrapperCol={{ xs: 24 }} dependencies={["IsFree"]}>
        {({ getFieldValue }) => {
          const isFree = getFieldValue("IsFree");

          return (
            <>
              <Form.Item
                name="IsFree"
                key="IsFree"
                label={t("IsFree", "Is free")}
                valuePropName="checked"
                initialValue={asset?.IsFree ?? false}
                {...formLayout}
              >
                <Switch />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    );
  };

  const renderCaptionsField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.Captions)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="Captions"
        key="Captions"
        label={t("Captions", "Captions")}
        valuePropName="checked"
        initialValue={asset?.Captions ?? false}
      >
        <Switch />
      </Form.Item>
    );
  };

  const handleDurationChange = (date: IDatePickerMoment | null) => {
    if (asset) {
      if (date) {
        asset.DurationMiliseconds =
          date.millisecond() +
          1000 * (date.second() + 60 * (date.minute() + 60 * date.hour()));
      } else {
        asset.DurationMiliseconds = undefined;
      }
    }
  };

  const renderDurationField = () => {
    if (
      !isEditMode ||
      (hiddenFields &&
        hiddenFields.includes(AssetProperties.DurationMiliseconds))
    ) {
      return;
    }

    const durationMiliseconds = asset?.Contents?.find(
      (c) => c.StreamTypeCode === StreamType.Main
    )?.DurationMiliseconds;

    return (
      <Form.Item
        name="DurationMiliseconds"
        label={t("Duration", "Duration")}
        key="duration"
        initialValue={
          isEditMode
            ? TimeHelper.parse(
                TimeHelper.fromDurationMilliseconds(
                  asset?.DurationMiliseconds || durationMiliseconds || 0
                )
              )
            : undefined
        }
      >
        <TimePicker
          placeholder={t("SelectDuration", "Select a duration")}
          popupClassName="hide-now-btn"
          style={{ width: "100%" }}
          onChange={handleDurationChange}
        />
      </Form.Item>
    );
  };

  const renderYearTextField = () => {
    if (
      !isEditMode ||
      (hiddenFields && hiddenFields.includes(AssetProperties.YearText))
    ) {
      return null;
    }

    return (
      <Form.Item
        name="YearText"
        label={t("Year", "Year")}
        key="yearText"
        initialValue={asset?.YearText && asset.YearText}
      >
        <Input placeholder={t("Year", `Enter a year`)} />
      </Form.Item>
    );
  };

  const onUserSelect = (row: IUserModel) => {
    setUserCreator({ Id: row.Id, FullName: row.FullName });
    setUserBrowserVisible(false);
    setDirty();
  };

  const onUserClear = () => {
    setUserCreator({ Id: undefined, FullName: undefined });
    setDirty();
  };

  const renderCreatorUserIdField = () => {
    if (!isEditMode) {
      return;
    }

    let creatorUserIdView: React.ReactElement = userCreator.Id ? (
      <Link to={`${USER_ROUTES.USER_DETAILS}/${userCreator.Id}`}>
        {userCreator.FullName}
      </Link>
    ) : (
      t("COMMON_UNDEFINED")
    );

    return (
      <Form.Item
        key="CreatorUserId"
        name="CreatorUserId"
        label={t("MODEL_CREATOR")}
      >
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item>{creatorUserIdView}</Form.Item>
            <UserBrowserModal
              profiles={["CREATOR"]}
              visible={userBrowserVisible}
              onCancel={() => setUserBrowserVisible(false)}
              onSelect={onUserSelect}
            />
          </Col>
          <Col style={{ textAlign: "right" }}>
            {userCreator.Id && (
              <Button
                icon={<Icon type="delete" />}
                onClick={onUserClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => setUserBrowserVisible(true)}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderBCIDField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.BCID)) {
      return;
    }

    return (
      <Form.Item
        name="BCID"
        label={t("BCID_LABEL", "BCID")}
        key="BCID"
        initialValue={asset?.BCID}
      >
        <Input placeholder={t("BCID_PLACEHOLDER", `Enter a BCID`)} />
      </Form.Item>
    );
  };

  const renderChannelName = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.ChannelName)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="ChannelName"
        label={t("CHANNEL_NAME_LABEL")}
        key="channelName"
        initialValue={asset?.ChannelName}
      >
        <Input placeholder={t("CHANNEL_NAME_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderChannelId = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.ChannelId)) ||
      !isEditMode
    ) {
      return;
    }

    return (
      <Form.Item
        name="ChannelId"
        label={t("CHANNEL_ID_LABEL")}
        key="channelId"
        initialValue={asset?.ChannelId}
      >
        <Input placeholder={t("CHANNEL_ID_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const renderStreamingPermission = () => {
    if (
      hiddenFields &&
      hiddenFields.includes(AssetProperties.StreamingPermission)
    ) {
      return;
    }

    return (
      <Form.Item
        name="StreamingPermission"
        key="StreamingPermission"
        label={t("STREAMING_PERMISSION_LABEL")}
        valuePropName="checked"
        initialValue={asset?.StreamingPermission ?? true}
        {...formLayout}
      >
        <Switch />
      </Form.Item>
    );
  };

  const renderRecordingPermission = () => {
    if (
      hiddenFields &&
      hiddenFields.includes(AssetProperties.RecordingPermission)
    ) {
      return;
    }

    return (
      <Form.Item
        name="RecordingPermission"
        key="RecordingPermission"
        label={t("RECORDING_PERMISSION_LABEL")}
        valuePropName="checked"
        initialValue={asset?.RecordingPermission ?? true}
        {...formLayout}
      >
        <Switch />
      </Form.Item>
    );
  };

  const renderAllowUnregistered = () => {
    if (
      !isEditMode ||
      (hiddenFields && hiddenFields.includes(AssetProperties.AllowUnregistered))
    ) {
      return;
    }

    return (
      <Form.Item
        name="AllowUnregistered"
        key="AllowUnregistered"
        label={t("ALLOW_UNREGISTERED_LABEL")}
        valuePropName="checked"
        initialValue={asset?.AllowUnregistered ?? false}
        {...formLayout}
      >
        <Switch />
      </Form.Item>
    );
  };

  const renderAssetDatesVisible = () => {
    if (
      !isEditMode ||
      (hiddenFields && hiddenFields.includes(AssetProperties.AssetDatesVisible))
    ) {
      return;
    }

    return (
      <Form.Item
        name="AssetDatesVisible"
        key="AssetDatesVisible"
        label={t("ASSET_DATES_VISIBLE_LABEL")}
        valuePropName="checked"
        initialValue={asset?.AssetDatesVisible ?? false}
        {...formLayout}
      >
        <Switch />
      </Form.Item>
    );
  };

  const renderEntityIdField = () => {
    if (
      (hiddenFields && hiddenFields.includes(AssetProperties.EntityId)) ||
      !channels
    ) {
      return;
    }

    const initialEntity = channels.find((el) => el.Id === asset?.EntityId);
    const fieldOptions: {
      initialValue?: IChooseLabeledValue;
    } = {
      initialValue: initialEntity && {
        key: `${initialEntity.Id}`,
        value: initialEntity.Id,
        label: initialEntity.Title,
      },
    };
    const chooseOption = channels?.map((channel) => (
      <ChooseOption key={channel.Id} value={channel.Id}>
        {channel.Title}
      </ChooseOption>
    ));

    return (
      <Form.Item
        name="EntityId"
        key="EntityId"
        label={t("ENTITAT_LABEL")}
        {...fieldOptions}
        {...formLayout}
      >
        <Choose
          labelInValue={true}
          optionFilterProp="children"
          placeholder={t("ENTITAT_PLACEHOLDER")}
          children={chooseOption}
        />
      </Form.Item>
    );
  };

  const renderEventDateTimeField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.EventDateTime)) {
      return;
    }

    return (
      <Form.Item
        name="EventDateTime"
        label={t("MODEL_EVENT_DATE_TIME")}
        key="EventDateTime"
        initialValue={
          isEditMode && asset?.EventDateTime
            ? TimeHelper.parse(asset?.EventDateTime)
            : undefined
        }
      >
        <DatePicker
          showTime={true}
          placeholder={t("SELECT_EVENT_DATE")}
          style={{ width: "100%" }}
        />
      </Form.Item>
    );
  };

  const renderOrderRuleField = () => {
    if (hiddenFields && hiddenFields.includes(AssetProperties.OrderRule)) {
      return;
    }

    const chooseOption = ORDER_RULES.map(({ id, value, label }) => (
      <ChooseOption key={`${id}`} value={value}>
        {t(label)}
      </ChooseOption>
    ));

    const iniitailValue = ORDER_RULES.find(
      (el) => el.value === asset?.OrderRule
    );

    return (
      <Form.Item
        name="OrderRule"
        label={t("MODEL_ORDER_RULE")}
        key="OrderRule"
        initialValue={
          iniitailValue && {
            key: `${iniitailValue.id}`,
            value: iniitailValue.value,
            label: t(iniitailValue.label),
          }
        }
      >
        <Choose
          labelInValue={true}
          optionFilterProp="children"
          placeholder={t("MODEL_ORDER_RULE_PLACEHOLDER")}
          children={chooseOption}
        />
      </Form.Item>
    );
  };

  const formFields = {
    CreationDate: renderCreationDateField,
    LastModificationDate: renderLastModificationDate,
    AssetTypeCode: renderTypeField,
    LanguageId: renderLanguagesField,
    Subtitles: renderSubtitlesField,
    ParentAssetId: renderParentField,
    OrderInParent: renderOrderInParentField,
    Title: renderTitleField,
    ShortDescriptionForm: renderShortDescriptionField,
    CreatorUserId: renderCreatorUserIdField,
    Categories: renderCategoriesField,
    Editor: renderPeopleEditorField,
    Director: renderPeopleDirectorField,
    Presenter: renderPeoplePresenterField,
    Cast: renderPeopleCastField,
    Producer: renderPeopleProducerField,
    Writer: renderPeopleWriterField,
    AssetAgeRestrictionValueMin: renderAgeRestrictionField,
    IsDraft: renderStatusField,
    Description: renderDescriptionField,
    DurationMiliseconds: renderDurationField,
    YearText: renderYearTextField,
    AvailableDate: renderAvailableDateFields,
    StartEndDates: renderStartEndDatesFields,
    IsFree: renderIsFreeField,
    Tags: renderTagsField,
    AdvertisingTags: renderAdvertisingTagsField,
    ContextTags: renderContextTagsField,
    Captions: renderCaptionsField,
    Label: renderLabelField,
    Genre: renderGenreField,
    Nationality: renderNationalityField,
    BCID: renderBCIDField,
    ChannelName: renderChannelName,
    ChannelId: renderChannelId,
    StreamingPermission: renderStreamingPermission,
    RecordingPermission: renderRecordingPermission,
    EntityId: renderEntityIdField,
    AllowUnregistered: renderAllowUnregistered,
    AssetDatesVisible: renderAssetDatesVisible,
    EventDateTime: renderEventDateTimeField,
    OrderRule: renderOrderRuleField,
  };

  const renderFields = () => {
    return formFieldNames.map((field: string) => {
      const formField = get(formFields, field, undefined);
      return formField ? formField() : null;
    });
  };

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `AssetForm-${section}` : "AssetForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="AssetForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderFields()}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE", "Save")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
