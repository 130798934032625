import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import { AxiosSubscriber } from "../AxiosSubscriber";
import {
  ICompleteMultipartUploadConsentModel,
  IConsentModel,
  IInitMultipartUploadConsentModel,
  IUploadMultipartFileInfoModel,
  IAbortMultipartUploadConsentModel,
} from "../../models";

export class ConsentService {
  get url(): string {
    return "/Consents";
  }

  public select = (): Observable<IConsentModel[]> =>
    new Observable(
      (observer: Observer<IConsentModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );

  public insert = (data: IConsentModel): Observable<IConsentModel> =>
    new Observable(
      (observer: Observer<IConsentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public update = (data: IConsentModel): Observable<IConsentModel> =>
    new Observable(
      (observer: Observer<IConsentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public delete = (data: IConsentModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Delete`,
        })
    );

  public getContentMultipartUploadConsentInfo = (
    data: IInitMultipartUploadConsentModel
  ): Observable<IUploadMultipartFileInfoModel> =>
    new Observable(
      (observer: Observer<IUploadMultipartFileInfoModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Consents/InitMultipartUploadFile`,
        })
    );

  public completeMultipartUploadConsent = (
    data: ICompleteMultipartUploadConsentModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Consents/CompleteMultipartUploadFile`,
        })
    );

  public abortMultipartUploadConsent = (
    data: IAbortMultipartUploadConsentModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Consents/AbortMultipartUploadFile`,
        })
    );
}
