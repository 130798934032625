import {
  AssetService,
  IAssetListModel,
  IAssetSearchFilterModel,
  IErrorModel,
  IListModelBase,
  ISearchFilterModelBase,
} from "@xala/common-services";
import { NotificationService } from "@xala/common-ui";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ROUTES } from "../../../constants";

const assetService = new AssetService();
const notificationService = NotificationService.getInstance();

export interface IAssetDataPagerSource {
  Entities: number[];
  TotalCount: number;
  Filter?: IAssetSearchFilterModel;
}

export interface IAssetDataPager {
  enabled: boolean;
  movePreviousEnabled: boolean;
  moveNextEnabled: boolean;
  moveNext: () => void;
  movePrevious: () => void;
}

export const createAssetDataPagerSource = (
  assetList?: IAssetListModel,
  assetFilter?: IAssetSearchFilterModel
): IAssetDataPagerSource | undefined => {
  return useMemo(() => {
    const filter: IAssetSearchFilterModel = assetFilter
      ? assetFilter
      : {
          PageNumber: 1,
          PageSize: 10,
        };
    if (assetList && assetList.Entities && assetList.Entities.length > 0) {
      return {
        Filter: filter,
        Entities: assetList.Entities.map((row) => row.Id),
        TotalCount: assetList.TotalCount,
      };
    }
    return undefined;
  }, [assetList, assetFilter]);
};

export const useAssetDataPager = (id: number): [IAssetDataPager, boolean] => {
  const { t } = useTranslation();
  const location = useLocation();
  const { replace } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const dataPagerSource = useMemo<IAssetDataPagerSource | undefined>(() => {
    if (location.state && location.state.hasOwnProperty("dataPagerSource")) {
      const state = location.state as {
        dataPagerSource: IAssetDataPagerSource;
      };
      return state.dataPagerSource;
    }
    return undefined;
  }, [location, id]);

  const onMovePrevious = useCallback(async () => {
    if (
      dataPagerSource &&
      dataPagerSource.Filter &&
      dataPagerSource.Entities &&
      dataPagerSource.Entities.length > 0
    ) {
      const index = dataPagerSource.Entities.findIndex((rowId) => rowId == id);
      if (index > 0) {
        const newLocation = {
          ...location,
          pathname: `${ROUTES.ASSET_DETAILS}/${
            dataPagerSource.Entities[index - 1]
          }`,
        };
        replace(newLocation);
      } else if (index === 0) {
        const pageNumber = dataPagerSource.Filter.PageNumber || 1;
        const filter = {
          ...dataPagerSource.Filter,
          PageNumber: pageNumber - 1,
        };
        try {
          setLoading(true);
          const assets = await assetService.search(filter).toPromise();

          if (assets.Entities && assets.Entities.length > 0) {
            const newDataPager = {
              Filter: filter,
              Entities: assets.Entities.map((row) => row.Id),
              TotalCount: assets.TotalCount,
            };
            const newLocation = {
              ...location,
              pathname: `${ROUTES.ASSET_DETAILS}/${
                newDataPager.Entities[newDataPager.Entities.length - 1]
              }`,
              state: {
                ...location.state,
                dataPagerSource: newDataPager,
              },
            };
            replace(newLocation);
            setLoading(false);
          }
        } catch (error) {
          const err = error as IErrorModel;
          notificationService.error({
            message: t(
              "LOADING_DATA_ERROR_MESSAGE",
              "There was an error while loading data."
            ),
            description: err?.Message,
          });
          setLoading(false);
        }
      }
    }
  }, [id]);
  const onMoveNext = useCallback(async () => {
    if (
      dataPagerSource &&
      dataPagerSource.Filter &&
      dataPagerSource.Entities &&
      dataPagerSource.Entities.length > 0
    ) {
      const index = dataPagerSource.Entities.findIndex((rowId) => rowId == id);

      if (index >= 0 && index < dataPagerSource.Entities.length - 1) {
        const newLocation = {
          ...location,
          pathname: `${ROUTES.ASSET_DETAILS}/${
            dataPagerSource.Entities[index + 1]
          }`,
        };
        replace(newLocation);
      } else if (index === dataPagerSource.Entities.length - 1) {
        const pageNumber = dataPagerSource.Filter.PageNumber || 1;
        const filter = {
          ...dataPagerSource.Filter,
          PageNumber: pageNumber + 1,
        };
        try {
          setLoading(true);
          const assets = await assetService.search(filter).toPromise();

          if (assets.Entities && assets.Entities.length > 0) {
            const newDataPager = {
              Filter: filter,
              Entities: assets.Entities.map((row) => row.Id),
              TotalCount: assets.TotalCount,
            };
            const newLocation = {
              ...location,
              pathname: `${ROUTES.ASSET_DETAILS}/${newDataPager.Entities[0]}`,
              state: {
                ...location.state,
                dataPagerSource: newDataPager,
              },
            };
            replace(newLocation);
            setLoading(false);
          }
        } catch (error) {
          const err = error as IErrorModel;
          notificationService.error({
            message: t(
              "LOADING_DATA_ERROR_MESSAGE",
              "There was an error while loading data."
            ),
            description: err?.Message,
          });
          setLoading(false);
        }
      }
    }
  }, [id]);

  const assetDataPager = useMemo(() => {
    const pager = {
      enabled: false,
      movePreviousEnabled: false,
      moveNextEnabled: false,
      moveNext: onMoveNext,
      movePrevious: onMovePrevious,
    };

    if (dataPagerSource) {
      pager.enabled = true;

      if (
        dataPagerSource.Filter &&
        dataPagerSource.Entities &&
        dataPagerSource.Entities.length > 0
      ) {
        const pageSize =
          dataPagerSource.Filter.PageSize || dataPagerSource.Entities.length;
        const pageNumber = dataPagerSource.Filter.PageNumber || 1;
        const index = dataPagerSource.Entities.findIndex(
          (rowId) => rowId == id
        );
        const entireIndex = pageSize * (pageNumber - 1) + index;

        pager.movePreviousEnabled = entireIndex > 0;
        pager.moveNextEnabled =
          entireIndex >= 0 && entireIndex < dataPagerSource.TotalCount - 1;
      }
    }

    return pager;
  }, [id, dataPagerSource]);

  return [assetDataPager, loading];
};
