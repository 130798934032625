import {
  Gender,
  INTERNAL_USERS_PROFILE_CODES,
  IUserModel,
  IUserProfileModel,
  RecordStatus,
  TimeHelper,
  UserStore,
} from "@xala/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  DatePicker,
  Form,
  FormFieldRule,
  IChooseLabeledValue,
  IChooseValue,
  IDaggerChangeEvent,
  IFormValues,
  Input,
  Row,
  Switch,
  useValidate,
} from "@xala/common-ui";
import { IAppState } from "@xala/studio/src/store";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserAvatarDragger } from "../UserAvatarDragger";
import "./UserPersonalInformationForm.scss";

export interface IUserPersonalInformationFormProps {
  user: IUserModel;
  viewType: "customers" | "administrators" | "all";
}

export const UserPersonalInformationForm = ({
  user,
  viewType,
}: IUserPersonalInformationFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate();

  const [userProfiles, setUserProfiles] = useState<IUserProfileModel[]>(
    user?.Profiles ? user.Profiles : []
  );

  const [lockoutEnd, setLockoutEnd] = useState(
    user?.LockoutEnd
      ? TimeHelper.parse(TimeHelper.format(user?.LockoutEnd))
      : undefined
  );

  const profiles = useSelector((state: IAppState) => state.user.profiles);

  useEffect(() => {
    dispatch(UserStore.Actions.selectProfiles());
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 8 },
      lg: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 16 },
      lg: { span: 20 },
    },
  };

  const onFinish = (values: IFormValues) => {
    user.FirstName = values.FirstName;
    user.Surname = values.Surname;
    user.Initials = values.Initials;
    user.FullName = `${values.FirstName} ${values.Surname}`;
    user.DateOfBirth = values.Birthday?.toISOString();
    user.GenderCode = values.Gender;
    user.LockoutEnd = lockoutEnd?.toISOString();
    user.Locked = values?.Locked;
    user.Profiles = userProfiles;
    user.Origin = values.Origin;

    if (values.AvatarFile) {
      user.AvatarFile = values.AvatarFile;
    }

    return dispatch(UserStore.Actions.updateUser(user));
  };

  const onAvatarImageChange = (e: IDaggerChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const renderAvatarField = () => {
    return (
      <Form.Item
        name="AvatarFile"
        getValueFromEvent={onAvatarImageChange}
        valuePropName="file"
        style={{ textAlign: "center" }}
        wrapperCol={{ span: 24 }}
      >
        <UserAvatarDragger name="Avatar" src={user.AvatarUrl} />
      </Form.Item>
    );
  };

  const renderNameField = () => {
    return (
      <Form.Item
        name="FirstName"
        label={t("USER_ACCOUNT_FORM_NAME_LABEL", "Name")}
        initialValue={user.FirstName}
        rules={validate.required()}
      >
        <Input
          placeholder={t("USER_ACCOUNT_FORM_NAME_PLACEHOLDER", "Enter name")}
        />
      </Form.Item>
    );
  };

  const renderSurnameField = () => {
    return (
      <Form.Item
        name="Surname"
        initialValue={user.Surname}
        label={t("USER_ACCOUNT_FORM_SURNAME_LABEL", "Surname")}
        rules={validate.required()}
      >
        <Input
          placeholder={t(
            "USER_ACCOUNT_FORM_SURNAME_PLACEHOLDER",
            "Enter surname"
          )}
        />
      </Form.Item>
    );
  };

  const renderEmailField = () => {
    return (
      <Form.Item
        name="Email"
        label={t("USER_ACCOUNT_FORM_EMAIL_LABEL", "E-mail")}
        rules={(validate.email(), validate.required())}
        initialValue={user.Email}
      >
        <Input
          addonBefore="@"
          placeholder={t("USER_ACCOUNT_FORM_EMAIL_PLACEHOLDER", "Enter e-mail")}
        />
      </Form.Item>
    );
  };

  const renderBirthdayField = () => {
    const date = user.DateOfBirth && TimeHelper.parse(user.DateOfBirth);

    return (
      <Form.Item
        name="Birthday"
        label={t("USER_ACCOUNT_FORM_BIRTHDAY_LABEL", "Birthday")}
        initialValue={date}
      >
        <DatePicker />
      </Form.Item>
    );
  };

  const renderGenderField = () => {
    return (
      <Form.Item
        name="Gender"
        label={t("USER_ACCOUNT_FORM_GENDER_LABEL", "Gender")}
        initialValue={user.GenderCode}
      >
        <Choose
          placeholder={t(
            "USER_ACCOUNT_FORM_GENDER_PLACEHOLDER",
            "Select a user gender"
          )}
        >
          {Object.keys(Gender).map((gender, index) => (
            <ChooseOption key={index} value={gender}>
              {gender}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const onProfileSelect = (value: IChooseValue) => {
    // @ts-ignore
    const { key, label } = value;
    const index = userProfiles.findIndex((row) => row.ProfileId === +key);

    if (index >= 0) {
      const profile = userProfiles[index];
      profile.RecordStatus = RecordStatus.NoChange;
    } else {
      const profile: IUserProfileModel = {
        UserId: user?.Id ?? -1,
        ProfileId: key,
        ProfileName: label,
        RecordStatus: RecordStatus.Inserted,
      };

      userProfiles.push(profile);
    }

    setUserProfiles(userProfiles);
  };

  const onProfileDeselect = (value: IChooseValue) => {
    // @ts-ignore
    const { key } = value;
    const index = userProfiles.findIndex((row) => row.ProfileId === +key);

    if (index >= 0) {
      const profile = userProfiles[index];

      switch (profile.RecordStatus) {
        case RecordStatus.NoChange:
          profile.RecordStatus = RecordStatus.Deleted;
          break;
        case RecordStatus.Inserted:
          userProfiles.splice(index, 1);
          break;
        default:
          profile.RecordStatus = RecordStatus.Deleted;
          break;
      }
    }

    setUserProfiles(userProfiles);
  };

  const renderProfileField = () => {
    const fieldOptions: {
      initialValue?: IChooseLabeledValue[];
      rules: FormFieldRule[];
    } = {
      rules: [
        {
          required: true,
          message: t(
            "USER_ACCOUNT_FORM_REQUIRE_VALIDATION_MESSAGE",
            "Please select user profile"
          ),
        },
      ],
      initialValue: userProfiles
        .filter((row) => row.RecordStatus !== RecordStatus.Deleted)
        .map((row) => {
          return {
            key: `${row.ProfileId}`,
            value: `${row.ProfileId}`,
            label: row.ProfileName,
          };
        }),
    };

    const options: React.ReactElement[] = [];
    const profilesOptions = profiles.Entities.filter(({ Code }) =>
      viewType === "customers"
        ? !INTERNAL_USERS_PROFILE_CODES.includes(Code)
        : true
    ).filter(
      (p) =>
        userProfiles.findIndex(
          (up) =>
            up.ProfileId === p.Id && up.RecordStatus !== RecordStatus.Deleted
        ) < 0
    );

    for (const profile of profilesOptions) {
      options.push(
        <ChooseOption value={profile.Id}>{profile.Name}</ChooseOption>
      );
    }

    return (
      <Form.Item
        name="ProfileId"
        label={t("USER_ACCOUNT_FORM_PROFILE_LABEL", "Profile")}
        {...fieldOptions}
      >
        <Choose
          mode="multiple"
          labelInValue={true}
          defaultActiveFirstOption={false}
          filterOption={false}
          placeholder={t(
            "USER_ACCOUNT_FORM_PROFILE_PLACEHOLDER",
            "Select a user profile"
          )}
          children={options}
          onSelect={onProfileSelect}
          onDeselect={onProfileDeselect}
        />
      </Form.Item>
    );
  };

  const renderOriginField = () => {
    return (
      <Form.Item
        name="Origin"
        label={t("USER_ACCOUNT_FORM_ORIGIN")}
        initialValue={user.Origin}
      >
        <Input />
      </Form.Item>
    );
  };

  const renderLastLoginDateField = () => {
    const date = user.LastLoginDate && TimeHelper.format(user.LastLoginDate);

    return (
      <Form.Item
        name="LastLoginDate"
        label={t("USER_ACCOUNT_FORM_LAST_LOGIN_DATE_LABEL")}
        initialValue={date}
      >
        <Input disabled />
      </Form.Item>
    );
  };

  const onChangeLock = (locked: boolean) => {
    setLockoutEnd(
      locked ? lockoutEnd || DEFAULT_USER_ACCOUNT_LOCKOUT_END : lockoutEnd
    );
  };

  const renderLockedField = () => {
    return (
      <Form.Item
        name="Locked"
        label={t("USER_ACCOUNT_FORM_LOCKED_LABEL", "Locked")}
        valuePropName="checked"
        initialValue={user?.Locked}
      >
        <Switch onChange={onChangeLock} />
      </Form.Item>
    );
  };

  const renderLockoutEndField = () => {
    if (!lockoutEnd) {
      return null;
    }

    return (
      <Form.Item
        name="LockoutEnd"
        label={t("USER_ACCOUNT_FORM_LOCKOUT_END_LABEL", "Lockout End")}
        initialValue={lockoutEnd}
      >
        <DatePicker
          showTime
          disabled
          placeholder={t("LockoutEnd", "Lockout End")}
          style={{ width: "100%" }}
        />
      </Form.Item>
    );
  };

  return (
    <Form
      name="UserPersonalInformationForm"
      {...formItemLayout}
      className="UserPersonalInformationForm"
      onFinish={onFinish}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderAvatarField()}
          {renderNameField()}
          {renderSurnameField()}
          {renderEmailField()}
          {renderBirthdayField()}
          {renderGenderField()}
          {renderProfileField()}
          {renderOriginField()}
          {renderLastLoginDateField()}
          {renderLockedField()}
          {renderLockoutEndField()}
        </Col>
        <Col>
          <Form.Item wrapperCol={{ style: { textAlign: "right" } }}>
            <Button type="primary" htmlType="submit">
              {t("BUTTON_SAVE", "Save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const DEFAULT_USER_ACCOUNT_LOCKOUT_END = TimeHelper.parse(
  TimeHelper.getDateWithOffset(TimeHelper.getCurrentDate(), 200, "year")
);
