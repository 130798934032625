import {
  AssetStore,
  IAssetModel,
  IAssetSearchFilterModel,
  ICommonAppState,
} from "@xala/common-services";
import {
  Button,
  Dropdown,
  Heading,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  NotificationService,
  setTableColumnSearchProps,
  Table,
  Tag,
} from "@xala/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const notificationService = NotificationService.getInstance();

const assetSelector = (state: ICommonAppState) => state.asset;

export const AddAssetToAssetCollection: React.FC<{
  addAssetToCollection: (asset: IAssetModel) => void;
  assetsIds: number[];
}> = ({ addAssetToCollection, assetsIds }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [stateAssetsIds, setStateAssetsIds] = useState<number[]>(assetsIds);
  const { assetsBrowser, action, assetTypes, assetInCollection } = useSelector(
    assetSelector
  );
  const { data: assetTypesData = [] } = assetTypes;
  const assetsBrowserFilter: IAssetSearchFilterModel = {
    ...assetsBrowser.Filter,
    PageSize: assetsBrowser.Filter?.PageSize ?? 10,
    PageNumber: assetsBrowser.Filter?.PageNumber ?? 1,
    IncludeCount: true,
  };

  const searchAssets = useCallback(
    (filter: IAssetSearchFilterModel) =>
      dispatch(AssetStore.Actions.browseAssets(filter)),
    [dispatch]
  );
  const getAssetTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetTypes()),
    [dispatch]
  );
  const getAssetAgeRestrictions = useCallback(
    () => dispatch(AssetStore.Actions.getAssetAgeRestrictions()),
    [dispatch]
  );

  useEffect(() => {
    setStateAssetsIds(assetsIds);
  }, [assetsIds]);

  useEffect(() => {
    searchAssets(assetsBrowserFilter);
    getAssetTypes();
    getAssetAgeRestrictions();
  }, []);

  useEffect(() => {
    switch (action?.type) {
      case "ADD_ASSET_IN_COLLECTION_SUCCESS":
        if (assetInCollection) {
          setStateAssetsIds([...stateAssetsIds, assetInCollection.AssetId]);
        }

        notificationService.success({
          message: t(
            "AddAssetToCollectionSuccess",
            "Asset successfully added to collection."
          ),
        });
        break;

      case "ADD_ASSET_IN_COLLECTION_FAILURE":
        notificationService.error({
          message: t(
            "AddAssetToCollectionFailure",
            "There was an error while adding asset to collection."
          ),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action, assetInCollection]);

  const onAdditionalOptionsMenuItemClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "OPTION_CLEAR_FILTERS":
        onClearFiltersClick();
        break;
    }
  };

  const _additionalOptionsMenu: React.ReactElement = (
    <Menu onClick={onAdditionalOptionsMenuItemClick}>
      <MenuItem key="OPTION_CLEAR_FILTERS">Clear filters</MenuItem>
    </Menu>
  );

  const onRefreshClick = () => searchAssets(assetsBrowserFilter);

  const onClearFiltersClick = () => {
    searchAssets({
      PageSize: 10,
      PageNumber: 1,
      IncludeCount: true,
    });
  };

  const getColumnsProps = (): Array<ITableColumnProps<IAssetModel>> => {
    return [
      {
        key: "Title",
        dataIndex: "Title",
        title: t("Title", "Title"),
        filteredValue: assetsBrowserFilter.Title
          ? [assetsBrowserFilter.Title]
          : null,
        ...setTableColumnSearchProps("Title"),
      },
      {
        key: "AssetTypeCode",
        dataIndex: "AssetTypeCode",
        title: t("AssetTypeCode", "Asset Type"),
        filters: assetTypesData.map((assetType) => ({
          text: assetType.DisplayName,
          value: assetType.Code,
        })),
        filteredValue: assetsBrowserFilter.Types || null,
        render: (text: any, row: IAssetModel) => {
          return (
            <Tag color={row.AssetTypeCode ? "#f50" : "#1890ff"}>
              {row.AssetTypeCode}
            </Tag>
          );
        },
      },
      {
        key: "AssetAgeRestrictionValueMin",
        dataIndex: "AssetAgeRestrictionValueMin",
        title: t("MinAge", "Minimum Age"),
        width: "250px",
      },
      {
        key: "Action",
        dataIndex: "Action",
        title: t("Action", "Action"),
        width: "150px",
        render: (text: any, row: IAssetModel) => {
          const actions: React.ReactNode[] = [];

          if (!stateAssetsIds.includes(row.Id || -1)) {
            actions.push(
              <Button
                key="add"
                icon={<Icon type="plus" />}
                onClick={() => addAssetToCollection(row)}
              >
                Add
              </Button>
            );
          }

          return actions;
        },
      },
    ];
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    assetsBrowserFilter.Title = filters.Title?.length
      ? `${filters.Title[0]}`
      : undefined;
    assetsBrowserFilter.Types = filters.AssetTypeCode?.length
      ? filters.AssetTypeCode.map((row) => `${row}`)
      : undefined;
    assetsBrowserFilter.PageSize = pagination.pageSize;
    assetsBrowserFilter.PageNumber = pagination.current;

    searchAssets(assetsBrowserFilter);
  };

  const onSearch = (value: string) => {
    assetsBrowserFilter.FullTextSearch = value;
    assetsBrowserFilter.PageNumber = 1;

    searchAssets(assetsBrowserFilter);
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: assetsBrowserFilter.PageSize,
    total: assetsBrowser.TotalCount,
    current: assetsBrowserFilter.PageNumber,
    showTotal: (total, range) =>
      t("ITEMS_RANGE_LABEL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };

  const columns = getColumnsProps();

  return (
    <>
      <Heading
        title={t("Assets")}
        actions={
          <>
            <InputSearch
              key="search"
              placeholder="Search"
              defaultValue={assetsBrowserFilter.FullTextSearch}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              key="reload"
              icon={<Icon type="reload" />}
              onClick={onRefreshClick}
              title="Refresh data"
            />
            <Dropdown overlay={_additionalOptionsMenu} key="dropdown">
              <Button icon={<Icon type="more" />} />
            </Dropdown>
          </>
        }
      />
      <Table<IAssetModel>
        columns={columns}
        rowKey="Guid"
        dataSource={assetsBrowser.Entities}
        loading={assetsBrowser.IsLoading}
        pagination={tablePagination}
        onChange={onTableChange}
      />
    </>
  );
};
