import { ICommonAppState } from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withScreenContext } from "../../../../context";
import { ScreenDesigner as ScreenDesignerDefinition } from "./ScreenDesigner";

const mapStateToProps = (state: ICommonAppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export const ScreenDesigner = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withScreenContext(ScreenDesignerDefinition)));
