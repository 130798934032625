import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { IAssetAgeRestrictionModel } from "../../models";
import { PromisifiableBase } from "..";

export class AgeRestrictionService extends PromisifiableBase {
  get url(): string {
    return "/AssetAgeRestrictions";
  }

  public select = (): Observable<IAssetAgeRestrictionModel[]> =>
    new Observable(
      (observer: Observer<IAssetAgeRestrictionModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );

  public insert = (
    data: IAssetAgeRestrictionModel
  ): Observable<IAssetAgeRestrictionModel> =>
    new Observable(
      (observer: Observer<IAssetAgeRestrictionModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public delete = (data: IAssetAgeRestrictionModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            data,
          },
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete`,
        })
    );
}
