import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import {
  AgeRestrictionModel,
  IChangePasswordModel,
  ICreatorsListModel,
  IResendConfirmationByUserModel,
  IResendConfirmationModel,
  IResetPasswordModel,
  ISearchFilterModelBase,
  IUserBrandingModel,
  IUserBrandingSettingsModel,
  IUserModel,
  IUserRequestOptionsModel,
  IUsersListModel,
  IUsersSearchFilterModel,
  IUserProductModel,
  IUnlockUserModel,
  ILockUserModel,
} from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { SimpleServiceBase } from "../Base";

export class UserService extends SimpleServiceBase<
  IUserModel,
  IUsersListModel,
  number,
  IUsersSearchFilterModel
> {
  public get url(): string {
    return "/Users";
  }

  public getDetails = (
    id: number,
    options: IUserRequestOptionsModel = {}
  ): Observable<IUserModel> =>
    new Observable(
      (observer: Observer<IUserModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          data: options,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Get`,
        })
    );

  public delete = (data: IUserModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id: data.Id,
            },
          },
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/Delete`,
        })
    );

  public anonymize = (data: IUserModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id: data.Id,
            },
          },
          method: HTTP_METHOD.PUT,
          url: `${this.url}/AnonymizeUser`,
        })
    );

  public getProfile = (
    options: IUserRequestOptionsModel = {}
  ): Observable<IUserModel> =>
    new Observable(
      (observer: Observer<IUserModel>) =>
        new AxiosSubscriber(observer, {
          data: options,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetProfile`,
        })
    );

  public getProfileDetails = (
    options: IUserRequestOptionsModel = {}
  ): Observable<IUserModel> =>
    new Observable(
      (observer: Observer<IUserModel>) =>
        new AxiosSubscriber(observer, {
          data: options,
          method: HTTP_METHOD.POST,
          url: `${this.url}/GetProfile`,
        })
    );

  public getPublicProfile = (userId: number): Observable<IUserModel> =>
    new Observable(
      (observer: Observer<IUserModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              userId,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetPublicProfile`,
        })
    );

  public updateProfile = (data: IUserModel): Observable<IUserModel> =>
    new Observable(
      (observer: Observer<IUserModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/UpdateProfile`,
        })
    );

  public changePassword = (data: IChangePasswordModel): Observable<boolean> =>
    new Observable(
      (observer: Observer<boolean>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/ChangePassword`,
        })
    );

  public getBrandingSettings = (): Observable<IUserBrandingSettingsModel> =>
    new Observable(
      (observer: Observer<IUserBrandingSettingsModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/UserBranding/GetBrandingSettings`,
        })
    );

  public saveBranding = (
    data: IUserBrandingModel
  ): Observable<IUserBrandingModel> =>
    new Observable(
      (observer: Observer<IUserBrandingModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `/UserBranding/Save`,
        })
    );

  public getFamilyMembers = (): Observable<IUserModel[]> =>
    new Observable(
      (observer: Observer<IUserModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/UserChildren/Select`,
        })
    );

  public addFamilyMember = (data: any): Observable<IUserModel> =>
    new Observable(
      (observer: Observer<IUserModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `/Authorization/RegisterChild`,
        })
    );

  public getAgeRestrictions = (): Observable<AgeRestrictionModel[]> =>
    new Observable(
      (observer: Observer<AgeRestrictionModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/RestrictionLevels/Select`,
        })
    );

  public resetPassword = (data: IResetPasswordModel): Observable<boolean> =>
    new Observable(
      (observer: Observer<boolean>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/ResetPassword`,
        })
    );

  public browseCreators = (
    filter: ISearchFilterModelBase
  ): Observable<ICreatorsListModel> =>
    new Observable(
      (observer: Observer<ICreatorsListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/BrowseCreators`,
        })
    );

  public resendConfirmationEmails = (
    data: IResendConfirmationModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/ResendConfirmationEmails`,
        })
    );

  public resendConfirmationEmailByUser = (
    data: IResendConfirmationByUserModel
  ): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: data,
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/ResendConfirmationEmailByUser`,
        })
    );

  public confirmEmailByAdmin = (id: number): Observable<boolean> =>
    new Observable(
      (observer: Observer<boolean>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/ConfirmEmailByAdmin/${id}`,
        })
    );

  public getProducts = (): Observable<IUserProductModel[]> =>
    new Observable(
      (observer: Observer<IUserProductModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/GetProducts`,
        })
    );

  public unlockUser = (data: IUnlockUserModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Unlock`,
        })
    );

  public lockUser = (data: ILockUserModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Lock`,
        })
    );
}
