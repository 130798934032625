import {
  RecordStatus,
  ILanguageModel,
  GuidHelper,
  LanguageService,
  useDataLoader,
  useServiceCaller,
} from "@xala/common-services";
import {
  Checkbox,
  ITableColumnProps,
  NotificationService,
  PageContent,
  PageHeader,
  TableWithDraggableSorter,
  Button,
  Popconfirm,
  Icon,
} from "@xala/common-ui";
import { FormModal } from "../../../../components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { DictionaryLanguageForm } from "../DictionaryLanguageForm";

const notificationService = NotificationService.getInstance();
const languageService = new LanguageService().promisify();

export interface IDictionaryLanguageListProps {}

const defaultLanguage: ILanguageModel = {
  Id: -1,
  Code: "",
  Guid: "",
  Name: "",
  Description: "",
  UpToDate: true,
  Sequence: 1,
  RecordStatus: RecordStatus.Inserted,
};

export const DictionaryLanguageList: React.FC<IDictionaryLanguageListProps> = () => {
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [editableLanguage, setEditableLanguage] = useState<ILanguageModel>(
    defaultLanguage
  );
  const { t } = useTranslation();

  const languageLoader = useDataLoader({
    loader: () => languageService.select(),
    deps: [],
  });

  const [insertLanguage, { processing: insertProcessing }] = useServiceCaller(
    async (language: ILanguageModel) => {
      const result = await languageService.insert({
        ...language,
        Guid: GuidHelper.newGuid(),
        RecordStatus: RecordStatus.Inserted,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_LANGUAGE_INSERT_SUCCESS"),
        });
        await languageLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_LANGUAGE_INSERT_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [updateLanguage, { processing: updateProcessing }] = useServiceCaller(
    async (language: ILanguageModel) => {
      const result = await languageService.update({
        ...language,
        RecordStatus: RecordStatus.Updated,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_LANGUAGE_UPDATE_SUCCESS"),
        });
        await languageLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_LANGUAGE_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [deleteLanguage, { processing: deleteProcessing }] = useServiceCaller(
    async (language: ILanguageModel) => {
      const result = await languageService.delete({
        ...language,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_LANGUAGE_DELETE_SUCCESS"),
        });
        await languageLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_LANGUAGE_DELETE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const getColumnsProps = (): Array<ITableColumnProps<ILanguageModel>> => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        render: (_: any, row: ILanguageModel) => {
          return (
            <a
              title={row.Code}
              className="imageUrl"
              onClick={() => {
                setShowLanguageModal(true);
                setEditableLanguage(row);
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        className: "drag-visible",
        title: t("MODEL_NAME"),
      },
      {
        key: "Description",
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
      },
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
      },
      {
        key: "UpToDate",
        dataIndex: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        render: (_, record) => (
          <Checkbox checked={record.UpToDate} disabled={true} />
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: ILanguageModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              deleteLanguage(row);
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedLanguage = languageLoader.data?.[dragIndex];
    const hoveredLanguage = languageLoader.data?.[hoverIndex];

    if (!draggedLanguage || !hoveredLanguage) {
      return;
    }

    const languageToUpdate: ILanguageModel = {
      ...draggedLanguage,
      Sequence: hoveredLanguage.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedLanguage.Sequence !== languageToUpdate.Sequence;
    const draggedToNewPosition = draggedLanguage.Guid !== hoveredLanguage.Guid;

    if (draggedToNewPosition && sequenceChanged) {
      updateLanguage(languageToUpdate);
    }
  };

  const onRefreshClick = () => {
    languageLoader.refresh();
  };

  const onDeleteLanguage = () => {
    if (editableLanguage) {
      deleteLanguage(editableLanguage);
    }
  };

  const onAddNewLanguageClick = () => {
    setShowLanguageModal(true);
  };

  const closeModal = () => {
    setShowLanguageModal(false);
    setEditableLanguage(defaultLanguage);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewLanguageClick}
          title={t("DICTIONARY_LANGUAGE_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title="Refresh data"
        />
      </>
    );
  };

  return (
    <div className="DictionaryLanguageList">
      <PageHeader
        title={t("DICTIONARY_LANGUAGE_TITLE")}
        extra={getExtraButtons()}
      />
      <PageContent>
        <TableWithDraggableSorter<ILanguageModel>
          dragType="handler"
          columns={getColumnsProps()}
          dataSource={languageLoader.data}
          loading={languageLoader.loading}
          pagination={false}
          onMoveRow={onMoveRow}
        />
      </PageContent>

      <FormModal
        isLoading={insertProcessing || updateProcessing || deleteProcessing}
        isVisible={showLanguageModal}
        isNewForm={editableLanguage.Id >= 0 ? false : true}
        isDeleteButtonEnabled={editableLanguage.Id >= 0 ? true : false}
        createFormTitle={t("DICTIONARY_LANGUAGE_MODAL_NEW")}
        editFormTitle={t("DICTIONARY_LANGUAGE_MODAL_EDIT")}
        modalClassName="DictionaryLanguageModal"
        submitFormName="DictionaryLanguageForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={onDeleteLanguage}
      >
        <DictionaryLanguageForm
          language={editableLanguage}
          insertLanguage={insertLanguage}
          updateLanguage={updateLanguage}
          isProcessing={
            insertProcessing || updateProcessing || deleteProcessing
          }
        />
      </FormModal>
    </div>
  );
};
