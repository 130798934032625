import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const PAGE_PARAM_NAME = "page";
const PAGE_SIZE_PARAM_NAME = "pageSize";
const DEFAULT_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = ["10", "30", "50", "100"];

export interface ICurrentPagination {
  PageNumber: number;
  PageSize: number;
  IncludeCount: boolean;
}

export const useSearchParamsPagination = (total?: number) => {
  const history = useHistory();
  const { t } = useTranslation();

  const updatePaginationQueryParams = useCallback(
    (current?: number, pageSize?: number) => {
      const newSearchParams = new URLSearchParams(history.location.search);
      if (current && current > 1) {
        newSearchParams.set(PAGE_PARAM_NAME, current.toString());
      } else {
        newSearchParams.delete(PAGE_PARAM_NAME);
      }
      if (pageSize && pageSize !== 10) {
        newSearchParams.set(PAGE_SIZE_PARAM_NAME, pageSize.toString());
      } else {
        newSearchParams.delete(PAGE_SIZE_PARAM_NAME);
      }

      history.push({ ...history.location, search: newSearchParams.toString() });
    },
    [history]
  );

  const searchParams = new URLSearchParams(location.search);

  const current = useMemo(() => {
    return {
      PageNumber: Number(searchParams.get(PAGE_PARAM_NAME)) || 1,
      PageSize:
        Number(searchParams.get(PAGE_SIZE_PARAM_NAME)) || DEFAULT_PAGE_SIZE,
      IncludeCount: true,
    };
  }, [
    searchParams.get(PAGE_PARAM_NAME),
    searchParams.get(PAGE_SIZE_PARAM_NAME),
  ]);

  return useMemo(() => {
    const props = {
      defaultPageSize: DEFAULT_PAGE_SIZE,
      current: current.PageNumber,
      pageSize: current.PageSize,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      showSizeChanger: true,
      showTotal: (total: number, range: number[]) =>
        t("ITEMS_RANGE_LABEL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      total: total ?? 0,
      onShowSizeChange: updatePaginationQueryParams,
      onChange: updatePaginationQueryParams,
    };

    return {
      props,
      get current() {
        return current;
      },
    };
  }, [JSON.stringify(current), total, updatePaginationQueryParams]);
};
