import {
  Choose,
  ChooseOption,
  Col,
  IChooseValue,
  Input,
  Row,
  TextArea,
  Form,
  IFormValues,
  Spin,
} from "@xala/common-ui";
import {
  IApplicationScreenPlatformModel,
  IPlatformModel,
  RecordStatus,
} from "@xala/common-services";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { WithScreenContextProps } from "../../context";
import "./ApplicationScreenDetailsForm.scss";
import { ScreenTypeHelper } from "../../helpers";
import { PlatformTypeHelper, UserHelper } from "../../../../helpers";

export interface IApplicationScreenDetailsFormProps
  extends WithTranslation,
    WithScreenContextProps {
  actionType?: string;
  platforms: IPlatformModel[];
  updateScreen: ActionCreator<Action>;
  isActive: boolean;
  isProcessingData: boolean;
}

export class ApplicationScreenDetailsForm extends React.PureComponent<
  IApplicationScreenDetailsFormProps
> {
  public static defaultProps = {
    isActive: false,
    isProcessingData: false,
  };

  public onFinish = (values: IFormValues) => {
    const { screen, updateScreen } = this.props;

    if (screen) {
      screen.Name = values.Name;

      updateScreen(screen);
    }
  };

  public onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { screen, onScreenChange } = this.props;

    if (!screen) {
      return;
    }

    screen.Name = e.target.value;

    if (onScreenChange) {
      onScreenChange(screen);
    }
  };

  public renderNameField = () => {
    const { isProcessingData, t, screen } = this.props;

    if (!screen) {
      return;
    }

    return (
      <Form.Item name="Name" label={t("Name")} initialValue={screen?.Name}>
        <Input
          disabled={isProcessingData}
          placeholder={t("Enter name")}
          onChange={this.onNameChange}
        />
      </Form.Item>
    );
  };

  public onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { screen, onScreenChange } = this.props;

    if (!screen) {
      return;
    }

    screen.Description = e.target.value;

    if (onScreenChange) {
      onScreenChange(screen);
    }
  };

  public renderDescriptionField = () => {
    const { isProcessingData, t, screen } = this.props;

    if (!screen) {
      return;
    }

    return (
      <Form.Item
        name="Description"
        label={t("Description")}
        initialValue={screen?.Description}
      >
        <TextArea
          disabled={isProcessingData}
          placeholder={t("Enter Description")}
          onChange={this.onDescriptionChange}
        />
      </Form.Item>
    );
  };

  public onPlatformSelect = (value: IChooseValue) => {
    const { screen, onScreenChange } = this.props;
    const platformCode = PlatformTypeHelper.getValue(value as string);

    if (!screen) {
      return;
    }

    if (!screen.Platforms) {
      screen.Platforms = [];
    }

    const index = screen.Platforms.findIndex(
      (platform) => platform.PlatformCode === platformCode
    );

    if (index >= 0) {
      const screenPlatform = screen.Platforms[index];
      screenPlatform.RecordStatus = RecordStatus.NoChange;
    } else {
      const screenPlatform: IApplicationScreenPlatformModel = {
        ApplicationScreenId: screen.Id,
        PlatformCode: platformCode,
        RecordStatus: RecordStatus.Inserted,
      };

      screen.Platforms.push(screenPlatform);
    }

    if (onScreenChange) {
      onScreenChange(screen);
    }
  };

  public onPlatformDeselect = (value: IChooseValue) => {
    const { screen, onScreenChange } = this.props;
    const platformCode = PlatformTypeHelper.getValue(value as string);

    if (!screen) {
      return;
    }

    if (!screen.Platforms) {
      screen.Platforms = [];
    }

    const index = screen.Platforms.findIndex(
      (platform) => platform.PlatformCode === platformCode
    );

    if (index >= 0) {
      const screenPlatform = screen.Platforms[index];

      switch (screenPlatform.RecordStatus) {
        case RecordStatus.NoChange:
          screenPlatform.RecordStatus = RecordStatus.Deleted;
          break;
        case RecordStatus.Inserted:
          screen.Platforms.splice(index, 1);
          break;
        default:
          screenPlatform.RecordStatus = RecordStatus.Deleted;
          break;
      }
    }

    if (onScreenChange) {
      onScreenChange(screen);
    }
  };

  public renderPlatformsField = () => {
    const { isProcessingData, t, screen, platforms } = this.props;
    if (!screen) {
      return;
    }

    const options: React.ReactElement[] = [];

    for (const platform of platforms) {
      options.push(
        <ChooseOption key={platform.Code} value={platform.Code}>
          {platform.DisplayName}
        </ChooseOption>
      );
    }

    return (
      <Form.Item
        name="Platforms"
        label={t("Platforms", "Platforms")}
        key="Director"
        initialValue={
          screen && screen.Platforms
            ? screen.Platforms.map(
                (screenPlatform: IApplicationScreenPlatformModel) =>
                  screenPlatform.PlatformCode
              )
            : []
        }
      >
        <Choose
          mode="multiple"
          tagRender={PlatformTypeHelper.getTagOption}
          placeholder={t("Platforms", "Choose platform")}
          loading={isProcessingData}
          onSelect={this.onPlatformSelect}
          onDeselect={this.onPlatformDeselect}
        >
          {options}
        </Choose>
      </Form.Item>
    );
  };

  public renderUserField = () => {
    const { t, screen } = this.props;

    if (!screen?.UserId) {
      return null;
    }

    return (
      <Form.Item label={t("User")}>
        {UserHelper.getTag(screen.UserFullName)}
      </Form.Item>
    );
  };

  public render() {
    const { screen, t } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
        lg: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 18 },
        lg: { span: 12 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
        md: {
          span: 18,
          offset: 6,
        },
        lg: {
          span: 21,
          offset: 3,
        },
      },
    };

    return (
      <Spin spinning={!screen}>
        <Form
          name="ApplicationScreenDetailsForm"
          {...formItemLayout}
          className="ApplicationScreenDetailsForm"
        >
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              {this.renderNameField()}
              {this.renderDescriptionField()}
              {this.renderPlatformsField()}
              <Form.Item label={t("Type")}>
                {ScreenTypeHelper.getTag(screen?.ScreenTypeCode)}
              </Form.Item>
              {this.renderUserField()}
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}
