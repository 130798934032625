import {
  ApplicationConfigurationStore,
  CommonStore,
  IApplicationScreenModel,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ApplicationScreenAddModal as ApplicationScreenAddModalDefinition,
  IApplicationScreenAddModalDispatchProps,
  IApplicationScreenAddModalOwnProps,
  IApplicationScreenAddModalStateProps,
} from "./ApplicationScreenAddModal";

const mapStateToProps = (
  state: ICommonAppState,
  ownProps: IApplicationScreenAddModalOwnProps
): IApplicationScreenAddModalStateProps => {
  return {
    screen: state.applicationConfiguration.screen,
    actionType: state.applicationConfiguration.actionType,
    error: state.applicationConfiguration.error,
    platforms: state.common.platforms,
    screensTypes: state.applicationConfiguration.screensTypes,
    isProcessingData: state.applicationConfiguration.isProcessingData,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): IApplicationScreenAddModalDispatchProps => ({
  selectScreensTypes: () => {
    return dispatch(ApplicationConfigurationStore.Actions.selectScreensTypes());
  },
  selectPlatforms: () => {
    return dispatch(CommonStore.Actions.selectPlatforms());
  },
  insertScreen: (data: IApplicationScreenModel) => {
    return dispatch(ApplicationConfigurationStore.Actions.insertScreen(data));
  },
});

export const ApplicationScreenAddModal = connect<
  IApplicationScreenAddModalStateProps,
  IApplicationScreenAddModalDispatchProps,
  IApplicationScreenAddModalOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationScreenAddModalDefinition));
