import {
  AssetStore,
  IAssetCategoriesSearchFilterModel,
  IAssetCategoryModel,
  ICommonAppState,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AssetCategoryList as AssetCategoryListDefinition } from "./AssetCategoryList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    action: state.asset.action,
    categories: state.asset.assetCategories,
    isFetching: state.asset.isFetching,
    isProcessing: state.asset.isProcessing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCategory: (category: IAssetCategoryModel) => {
    return dispatch(AssetStore.Actions.updateAssetCategory(category));
  },
  searchAssetCategories: (filter: IAssetCategoriesSearchFilterModel) => {
    return dispatch(AssetStore.Actions.searchAssetCategories(filter));
  },
});

export const AssetCategoryList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AssetCategoryListDefinition));
