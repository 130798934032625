import React, { useState } from "react";
import { Form, Switch, TextArea, TextEditor } from "@xala/common-ui";
import { useTranslation } from "react-i18next";
import { INotificationField } from "./INotificationField";

export const TextContent = ({
  notification,
  isEditMode,
  isHidden,
}: INotificationField) => {
  const { t } = useTranslation();
  const [isHTMLPreview, setIsHTMLPreview] = useState(false);

  if (isHidden) {
    return null;
  }

  return (
    <Form.Item label={t("NOTIFICATION_TEXT_CONTENT_FIELD")}>
      {notification?.Provider !== "FIREBASE" && (
        <Form.Item>
          <Switch
            checkedChildren={t("NOTIFICATION_HTML_ON_FIELD")}
            unCheckedChildren={t("NOTIFICATION_HTML_OFF_FIELD")}
            onChange={setIsHTMLPreview}
            defaultChecked={isHTMLPreview}
          />
        </Form.Item>
      )}
      <Form.Item
        name="TextContent"
        key="TextContent"
        initialValue={isEditMode ? notification?.TextContent : ""}
      >
        {!isHTMLPreview || notification?.Provider === "FIREBASE" ? (
          <TextArea
            rows={5}
            placeholder={t("NOTIFICATION_TEXT_CONTENT_INPUT_FIELD")}
          />
        ) : (
          <TextEditor addImage={false} />
        )}
      </Form.Item>
    </Form.Item>
  );
};
