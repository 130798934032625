import {
  AssetStore,
  IAssetCategoriesSearchFilterModel,
  IAssetSearchFilterModel,
  ICommonAppState,
  IUserModel,
  UserSettingsStore,
  UserStore,
} from "@xala/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IUserDetailsDispatchProps,
  IUserDetailsOwnProps,
  IUserDetailsStateProps,
  UserDetails as UserDetailsDefinition,
} from "./UserDetails";

const mapStateToProps = (state: ICommonAppState): IUserDetailsStateProps => ({
  actionType: state.user.actionType,
  user: state.user.user,
  isProcessing: state.user.isProcessingData,
  isLoading: state.user.isLoadingData,
  resetUserPasswordResult: state.user.resetUserPasswordResult,
  error: state.user.error,
  userSettings: state.userSettings.userSettings,
  categories: state.asset.assetCategories.Entities,
  channels: state.asset.assets.Entities,
});

const mapDispatchToProps = (dispatch: Dispatch): IUserDetailsDispatchProps => ({
  getUser: (id: number) => {
    return dispatch(
      UserStore.Actions.getUserDetails(id, { IncludeUploadFilesInfo: true })
    );
  },
  getUserSettings: (id: number) => {
    return dispatch(UserSettingsStore.Actions.getUserSettingsById(id));
  },
  anonymizeUser: (data: IUserModel) => {
    return dispatch(UserStore.Actions.anonymizeUser(data));
  },
  getCategories: (data: IAssetCategoriesSearchFilterModel) => {
    return dispatch(AssetStore.Actions.searchAssetCategories(data));
  },
  getChannels: (data: IAssetSearchFilterModel) => {
    return dispatch(AssetStore.Actions.searchAsset(data));
  },
});

export const UserDetails = connect<
  IUserDetailsStateProps,
  IUserDetailsDispatchProps,
  IUserDetailsOwnProps,
  ICommonAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserDetailsDefinition));
