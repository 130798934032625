import {
  ComponentTypeHelper,
  IApplicationComponentModel,
  IApplicationScreenModel,
  PlatformType,
} from "@xala/common-services";
import { Button, Col, Dropdown, Icon, Menu, MenuItem } from "@xala/common-ui";
import cx from "classnames";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenContextProps } from "../../../../context";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationMenuItemPropertyModel } from "../../models";
import { ApplicationMenuItemPreview } from "../ApplicationMenuItemPreview";
import "./ApplicationMenuPreview.scss";

export interface IApplicationMenuPreviewStateProps {}

export interface IApplicationMenuPreviewDispatchProps {}

export interface IApplicationMenuPreviewOwnProps {
  applicationMenuScreen: IApplicationScreenModel;
}

export interface IApplicationMenuPreviewProps
  extends IApplicationMenuPreviewStateProps,
    IApplicationMenuPreviewDispatchProps,
    IApplicationMenuPreviewOwnProps,
    WithTranslation,
    WithScreenDesignerContextProps,
    WithScreenContextProps {}

export interface IApplicationMenuPreviewState {}

export class ApplicationMenuPreview extends React.PureComponent<
  IApplicationMenuPreviewProps,
  IApplicationMenuPreviewState
> {
  public static defaultProps = {};

  public state: Readonly<IApplicationMenuPreviewState> = {};

  public onComponentSelect = (componentId: number) => {
    const { applicationMenuScreen, screen, onComponentSelect } = this.props;

    if (
      screen &&
      screen.Id === applicationMenuScreen.Id &&
      screen.Components &&
      onComponentSelect
    ) {
      const component = screen.Components.find(
        (row: IApplicationComponentModel) => row.Id === componentId
      );

      onComponentSelect(component);
    }
  };

  public renderApplicationItemsForMobile = () => {
    const {
      applicationMenuScreen,
      screen,
      component,
      t,
      layoutOptions,
    } = this.props;

    const itemsView: React.ReactNode[] = [];
    const moreMenuItemsView: React.ReactNode[] = [];
    const moreMenuView: React.ReactNode = (
      <div className="Component">
        <Dropdown
          key="dropdown"
          placement="topRight"
          trigger={["click"]}
          overlay={
            <Menu
              className={cx(
                "ApplicationMenuPreview__Items--Dropdown-Menu",
                { "zoom-50": layoutOptions.Zoom === 0.5 },
                { "zoom-75": layoutOptions.Zoom === 0.75 }
              )}
            >
              {moreMenuItemsView}
            </Menu>
          }
        >
          <div>
            <Icon
              type="more"
              className="ApplicationMenuPreview__Items--Dropdown-Icon"
            />
            <span className="ApplicationMenuPreview__Items--Dropdown-Title">
              MORE
            </span>
          </div>
        </Dropdown>
      </div>
    );

    if (applicationMenuScreen.Components) {
      const itemsIterations: number = Math.max(
        5,
        applicationMenuScreen.Components.length
      );

      for (let i = 0; i < itemsIterations; i++) {
        if (i >= applicationMenuScreen.Components.length) {
          itemsView.push(<Col></Col>);

          continue;
        }

        const screenComponent = applicationMenuScreen.Components[i];

        const properties = new ApplicationMenuItemPropertyModel(
          screenComponent
        );

        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        if (i < 3) {
          itemsView.push(
            <div
              key={screenComponent.Id}
              className="Component"
              onClick={() => this.onComponentSelect(screenComponent.Id)}
            >
              <div className="Component--content">
                <div className="Component--options"></div>
                <div className="Component--preview" style={style}>
                  <ApplicationMenuItemPreview
                    key={screenComponent.Id}
                    component={screenComponent}
                    properties={properties}
                  />
                </div>
              </div>
            </div>
          );
        }

        if (i === 1) {
          itemsView.push(
            <div className="ApplicationMenuPreview__AddButton-container">
              <Button
                className="ApplicationMenuPreview__AddButton"
                shape="circle"
                icon="plus"
              />
            </div>
          );
        }

        if (i === 2) {
          itemsView.push(moreMenuView);
        }

        if (i >= 3) {
          moreMenuItemsView.push(
            <MenuItem style={{ padding: "8px" }}>
              <div
                key={screenComponent.Id}
                className="Component"
                style={{ padding: 0 }}
                onClick={() => this.onComponentSelect(screenComponent.Id)}
              >
                <div className="Component--content">
                  <div className="Component--options"></div>
                  <div className="Component--preview" style={style}>
                    <ApplicationMenuItemPreview
                      key={screenComponent.Id}
                      component={screenComponent}
                      properties={properties}
                    />
                  </div>
                </div>
              </div>
            </MenuItem>
          );
        }
      }
    }

    return itemsView;
  };

  public renderApplicationItemsForWeb = () => {
    const {
      applicationMenuScreen,
      screen,
      component,
      t,
      layoutOptions,
    } = this.props;

    const itemsView: React.ReactNode[] = [];

    if (applicationMenuScreen.Components) {
      for (let i = 0; i < applicationMenuScreen.Components.length; i++) {
        const screenComponent = applicationMenuScreen.Components[i];
        const properties = new ApplicationMenuItemPropertyModel(
          screenComponent
        );
        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        itemsView.push(
          <MenuItem style={{ padding: "8px", height: "56px" }}>
            <div
              key={screenComponent.Id}
              className="Component"
              style={{ padding: 0 }}
              onClick={() => this.onComponentSelect(screenComponent.Id)}
            >
              <div className="Component--content">
                <div className="Component--options"></div>
                <div className="Component--preview" style={style}>
                  <ApplicationMenuItemPreview
                    key={screenComponent.Id}
                    component={screenComponent}
                    properties={properties}
                  />
                </div>
              </div>
            </div>
          </MenuItem>
        );
      }
    }

    return itemsView;
  };

  public render() {
    const { layoutOptions } = this.props;

    switch (layoutOptions.Platform) {
      case PlatformType.AndroidPhone:
      case PlatformType.AndroidTablet:
      case PlatformType.iOSPhone:
      case PlatformType.iPad:
        return (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <div className="ApplicationMenuPreview">
              <div className="ApplicationMenuPreview__Items">
                {this.renderApplicationItemsForMobile()}
              </div>
            </div>
          </div>
        );
      case PlatformType.Web:
        return (
          <div className="ApplicationMenuPreview">
            <Menu mode="inline" selectable={false} inlineIndent={10}>
              {this.renderApplicationItemsForWeb()}
            </Menu>
          </div>
        );
      default:
        return (
          <div className="ApplicationMenuPreview">
            <div className="ApplicationMenuPreview__Items"></div>
          </div>
        );
    }
  }
}
